import {  Animation, createAnimation } from '@ionic/core';

export const customNavigation = (baseElem: HTMLElement, opts?: any): Animation => {
  const DURATION = 300;
  const enteringAnimation = createAnimation()
  .addElement(opts.enteringEl)
  .duration(DURATION)
  .fromTo('opacity', 0, 1);
  const exitingAnimation = createAnimation()
  .addElement(opts.leavingEl)
  .duration(DURATION)
  .fromTo('opacity', 1, 0);
  const rootAnimation = createAnimation();
  rootAnimation.addAnimation([enteringAnimation, exitingAnimation]);
  return rootAnimation;
};

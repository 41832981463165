import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isMatch } from 'date-fns';

@Component({
  selector: 'gs-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
})
export class DateFieldComponent implements OnInit {
  @Input() fieldLabel: string;
  @Input() fieldPlaceholder: string;
  @Input() inputId: string;
  @Input() inputMode: string;
  @Input() type: string;
  @Input() error = '';
  @Input() inputValue: string;
  @Input() information = '';
  @Output() inputBlurEvent = new EventEmitter<void>();
  @Output() inputChangeEvent = new EventEmitter<string>();
  previousInput = '';

  constructor() { }

  ngOnInit() {}

  handleBlur() {
    this.inputBlurEvent.emit();
  }

  handleChange(event: any) {
    const inputValue: string = event.detail.value;
    if (
      (inputValue.length === 2 || inputValue.length === 5) &&
      this.previousInput.length < inputValue.length &&
      inputValue.split('/').length < 3
    ) {
      event.detail.value += '/';
    }
    if ((inputValue.length === 3 || inputValue.length === 6) && this.previousInput.length > inputValue.length) {
      event.detail.value = event.detail.value.slice(0, -1);
    }
    this.inputChangeEvent.emit(event.detail.value);
    this.previousInput = event.detail.value;
  }

  handleClickOnInput(event) {
    const input = event.target;
    event.stopPropagation();
    if (!isMatch(input.value, 'dd/MM/yyyy')) {
      return;
    }
    if (input.selectionStart <= 2) {
      let selectionEnd = 0;
      if (input.value.split('/').length === 3) {
        selectionEnd = input.value.indexOf('/');
      }
      input.setSelectionRange(0, selectionEnd);
      return;
    }
    if (input.selectionStart <= 5) {
      let selectionEnd = 3;
      if (input.value.split('/').length === 3) {
        selectionEnd = input.value.lastIndexOf('/');
      }
      input?.setSelectionRange(3, selectionEnd);
      return;
    }
    input.setSelectionRange(6, 10);
  }

  moveToNextSelectionOnKeyUp(event) {
    const input = event.target;
    if (!isMatch(input.value, 'dd/MM/yyyy')) {
      return;
    }
    if (input.selectionStart === 2 && input.value.split('/').length === 3) {
      input.setSelectionRange(3, 5);
    }
    if (input.selectionStart === 5 && input.value.split('/').length === 3) {
      input.setSelectionRange(6, 10);
      return;
    }
  }

  handleBackspaceAndAddingSlashOnKeyUp(event) {
    if (event.target.value === '/' || event.target.value === '//') {
      return;
    }
    if(event.key === 'Backspace' && event.target.value[event.target.selectionStart - 1] === '/') {
      event.target.setSelectionRange(event.target.selectionStart - 1, event.target.selectionStart - 1);
      return;
    }
    if (((isMatch(event.target.value, 'dd/MM') && event.target.value.length === 5) ||
         (isMatch(event.target.value, 'dd') && event.target.value.length === 2)) &&
        event.key !== 'Backspace'
    ) {
      event.target.value += '/';
    }
  }
}

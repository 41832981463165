import { navigationReducer } from './reducers/navigation.reducers';
import { authenticationReducer } from './reducers/authentication.reducers';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { appConfigReducer } from './reducers/app-config.reducers';
import { activitiesReducer, sessionsReducer, sitesReducer, bookTimePickerReducer, bookDatePickerReducer, recommendedActivitiesReducer } from './reducers/book.reducers';
import { IAppState } from './state/app-config.state';
import { basketReducer, bookingHistoryReducer, futureBookingsReducer } from './reducers/basket.reducers';
import { favouritesReducer } from './reducers/favourites.reducers';
import { walletReducer } from './reducers/wallet.reducer';
import { accountReducer } from './reducers/account.reducers';
import { hydrationMetaReducer } from './reducers/offline-support-reducers';
import { paymentReducer } from './reducers/payment.reducer';
import { entryReducer } from './reducers/entry.reducers';
import { plaftformReducer } from './reducers/platform.reducers';
import { notificationHandlingReducer } from './reducers/notification-handling.reducer';

//TODO: look over feature state
export const reducers: ActionReducerMap<IAppState> = {
  appConfig: appConfigReducer,
  sites: sitesReducer,
  sessions: sessionsReducer,
  basket: basketReducer,
  authentication: authenticationReducer,
  notificationHandling: notificationHandlingReducer,
  activities: activitiesReducer,
  bookTimePicker: bookTimePickerReducer,
  bookDatePicker: bookDatePickerReducer,
  account: accountReducer,
  favourites: favouritesReducer,
  payment: paymentReducer,
  wallet: walletReducer,
  entry: entryReducer,
  futureBookings: futureBookingsReducer,
  bookingHistory: bookingHistoryReducer,
  platform: plaftformReducer,
  navigation: navigationReducer,
  recommendedActivities: recommendedActivitiesReducer,
};
export const metaReducers: MetaReducer[] = [hydrationMetaReducer];


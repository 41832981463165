import { UP_ARROW, DOWN_ARROW, TimePickerComponentState } from '@core/constants';
import { TimeButtonComponent } from '../time-picker/time-button/time-button.component';
import { IonDatetime } from '@ionic/angular';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { dropDownAnimation, dropUpAnimation } from '@shared/animations/component-animations';
import { TimeUtilitiesHelper } from '@core/helpers/time-utilities-helper';
import { range } from 'radash';
import { compareAsc, getHours, getMinutes, set } from 'date-fns';

@Component({
  selector: 'gs-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
  @ViewChild(IonDatetime) timePicker!: IonDatetime;
  @ViewChildren(TimeButtonComponent) timeButtons: QueryList<TimeButtonComponent>;
  @Output() getTimeRequest = new EventEmitter<void>();
  @Output() updateTimeRequest = new EventEmitter<({startTime: string; endTime: string})>();
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() currentDateBoundaries = false;
  timeUtilitiesHelper = TimeUtilitiesHelper;

  currentTimeState: TimePickerComponentState =
    TimePickerComponentState.noTimeSelected;
  startTimeButtonStyleClass = '';
  endTimeButtonStyleClass = '';
  timeStateEnumType = TimePickerComponentState;

  constructor() {}

  ngOnInit() {
  }

  onTimeButtonClick(timeButtonClicked: TimePickerComponentState) {
    if (timeButtonClicked === TimePickerComponentState.endTimeSelected
        && this.currentTimeState === TimePickerComponentState.noTimeSelected
        ) {
      return;
    }
    if (this.currentTimeState === timeButtonClicked) {
      return this.collapseTimePicker();
    }
    if (this.currentTimeState === TimePickerComponentState.noTimeSelected) {
      dropDownAnimation('.time-and-button').play();
    }
    this.currentTimeState = timeButtonClicked;
    this.updateTimeButtons(timeButtonClicked);
  }

  updateTimeButtons(timeButtonClicked: TimePickerComponentState) {
    this.startTimeButtonStyleClass = '';
    this.endTimeButtonStyleClass = '';
    this.timeButtons.first.iconName = this.timeButtons.last.iconName = DOWN_ARROW;
    if (timeButtonClicked === TimePickerComponentState.startTimeSelected) {
      this.startTimeButtonStyleClass = 'focus-button';
      this.timePicker.value = TimeUtilitiesHelper.dateToTimeString(this.startTime);
      this.setHoursStartingTime();
      this.timeButtons.first.iconName = UP_ARROW;
    } else {
      this.timeButtons.last.iconName = UP_ARROW;
      this.endTimeButtonStyleClass = 'focus-button';
      this.timePicker.value = TimeUtilitiesHelper.dateToTimeString(this.endTime);
      if (new Date(this.startTime).getHours() === 23 && new Date(this.startTime).getMinutes() === 30) {
        this.timePicker.hourValues = [23];
        this.timePicker.minuteValues = [59];
      }
      else {
        this.timePicker.hourValues = this.getHoursInInterval(
          getHours(new Date(TimeUtilitiesHelper.nextHalfAnHour(this.startTime))),
          23
        );
      }
    }
  }

  setHoursStartingTime() {
    if (!this.currentDateBoundaries) {
      this.timePicker.hourValues = this.getHoursInInterval(0, 23);
    }
    else {
      this.timePicker.hourValues = this.getHoursInInterval(
        getHours(new Date(TimeUtilitiesHelper.getCurrentTimeRounded())), 23);
    }
  }

  collapseTimePicker() {
    dropUpAnimation('.time-and-button').play().then(() => this.currentTimeState = TimePickerComponentState.noTimeSelected);
    this.startTimeButtonStyleClass = this.endTimeButtonStyleClass = '';
    this.timeButtons.first.iconName = this.timeButtons.last.iconName = DOWN_ARROW;
    this.getTimeRequest.emit();
  }

  getHoursInInterval(lowerBound: number, upperBound: number): number[] {
    return Array.from(range(lowerBound, upperBound, 1));
  }

  timePickerHourChanged(event) {
    const timeToSet = TimeUtilitiesHelper.getCurrentTimeRounded();
    event.detail.value = TimeUtilitiesHelper.newTime(event.detail.value);
    this.timePicker.minuteValues = [0, 30];
    if (this.currentTimeState === TimePickerComponentState.startTimeSelected) {
      if (this.currentDateBoundaries
          && getHours(new Date(timeToSet)) === getHours(event.detail.value)
          && getMinutes(new Date(timeToSet)) === 30) {
        this.timePicker.minuteValues = [30];
        event.detail.value = set(event.detail.value, {minutes: 30});
      }
      this.startTime = event.detail.value.toISOString();
      this.timePicker.value = TimeUtilitiesHelper.dateToTimeString(event.detail.value);
      if (new Date(this.startTime).getHours() === 23 && new Date(this.startTime).getMinutes() === 30) {
        this.endTime = set(new Date(), {hours: 23, minutes: 59}).toISOString();
      }
      else {
        if (compareAsc(new Date(this.startTime), new Date(this.endTime)) >= 0) {
          this.endTime = TimeUtilitiesHelper.nextHalfAnHour(new Date(this.startTime).toISOString());
        }
      }
    } else {

      if (getHours(event.detail.value) === getHours(new Date(this.startTime))
          && getMinutes(new Date(this.startTime)) === 0) {
          this.timePicker.minuteValues = [30];
          if (getHours(event.detail.value) !== 23) {
            event.detail.value = set(event.detail.value, {minutes: 30});
          }
      }
      if (getHours(event.detail.value) === 23) {
        this.timePicker.minuteValues = [...this.timePicker.minuteValues, 59];
      }
      this.timePicker.value = TimeUtilitiesHelper.dateToTimeString(event.detail.value);
      this.endTime = event.detail.value.toISOString();
    }
  }

  onSaveTime() {
    this.updateTimeRequest.emit({startTime: this.startTime, endTime: this.endTime});
    this.collapseTimePicker();
  }

}

import { Injectable } from '@angular/core';
import {
  ResourceGroup,
  SessionModel,
  SlotsByActivityIdMap,
  SlotsByResourceIdMap,
} from '../models/session.model';
import { TimeUtilitiesHelper } from '../helpers/time-utilities-helper';
import { ResourceGroupDTO, SessionDTO, SlotDTO } from '@app/api/dtos/session.dto';
import { compareAsc, isPast, format } from 'date-fns';
import { SessionSearchParams } from '../models/session-search-params.model';
import { SessionMapper } from '@app/api/mappers/session.mapper';

@Injectable({
  providedIn: 'root',
})
export class SessionAdapterService {
  constructor() {}

  public mapSessions(
    sessions: SessionDTO[],
    params: SessionSearchParams
  ): {
    slotsByActivityId: SlotsByActivityIdMap;
    allSlotsGroupedInOneSession: SlotsByResourceIdMap;
    additionalSlotsGroupedInOneSession: SlotsByResourceIdMap;
  } {
    const slotsByActivityId: SlotsByActivityIdMap = {};
    const allSlotsGroupedInOneSession: SlotsByResourceIdMap = {};
    const additionalSlotsByActivityId: SlotsByActivityIdMap = {};
    const additionalSlotsGroupedInOneSession: SlotsByResourceIdMap = {};

    sessions.forEach((session: SessionDTO) => {
      session.locations.forEach((location: ResourceGroupDTO) => {
        location.slots.forEach((slot: SlotDTO) => {
          if (this.isSlotInTimeBoundaries(slot, params)) {
            SessionMapper.mapSlotToSlotByResourceId(allSlotsGroupedInOneSession, slot, location, params, session);
            SessionMapper.mapSlotToSlotByActivityId(slotsByActivityId, slot, location, params, session);
          }
          else if(TimeUtilitiesHelper.isDateInFuture(slot.startTime)){
            SessionMapper.mapSlotToSlotByResourceId(additionalSlotsGroupedInOneSession, slot, location, params, session);
            SessionMapper.mapSlotToSlotByActivityId(additionalSlotsByActivityId, slot, location, params, session);
          }
        });
      });
    });
    this.sortSlotsByActivityIdMapByDate(slotsByActivityId);
    this.sortSlotsByResourceIdMapByDate(allSlotsGroupedInOneSession);
    this.sortSlotsByActivityIdMapByDate(additionalSlotsByActivityId);
    this.sortSlotsByResourceIdMapByDate(additionalSlotsGroupedInOneSession);
    return {
      slotsByActivityId,
      allSlotsGroupedInOneSession,
      additionalSlotsGroupedInOneSession,
    };
  }

  private isSlotInTimeBoundaries(
    slot: SlotDTO,
    params: SessionSearchParams
  ): boolean {
    return (
      TimeUtilitiesHelper.compareTimes(slot.startTime, params.startTime) >= 0 &&
      compareAsc(new Date(slot.endTime), new Date(params.endDate)) <= 0 &&
      TimeUtilitiesHelper.compareTimes(slot.endTime, params.endTime) <= 0 &&
      !isPast(new Date(slot.startTime))
    );
  }

  private sortSlotsByResourceIdMapByDate(
    slotsByResourceId: SlotsByResourceIdMap
  ): void {
    if (slotsByResourceId === undefined) {
      return;
    }
    Object.entries(slotsByResourceId).forEach(
      ([_resourceId, resourceGroup]: [string, ResourceGroup]) => {
        resourceGroup.slotsByDate = Object.keys(resourceGroup.slotsByDate)
          .sort(TimeUtilitiesHelper.compareDates)
          .reduce(
            (slotsByDateObject, slotsByDateKey) => (
              (slotsByDateObject[slotsByDateKey] =
                resourceGroup.slotsByDate[slotsByDateKey]),
              slotsByDateObject
            ),
            {}
          );
      }
    );
  }

  private sortSlotsByActivityIdMapByDate(
    slotsByActivityId: SlotsByActivityIdMap
  ): void {
    if (slotsByActivityId === undefined) {
      return;
    }
    Object.entries(slotsByActivityId).forEach(
      ([_activityId, session]: [string, SessionModel]) => {
        this.sortSlotsByResourceIdMapByDate(session.resourceGroups);
      }
    );
  }
}

import {
  parse,
  getHours,
  getMinutes,
  compareAsc,
  startOfHour,
  addMinutes,
  addHours,
  set,
  format,
  getYear,
  getMonth,
  getDate,
  eachDayOfInterval,
  lastDayOfMonth,
  addMonths,
  subMonths,
  startOfMonth,
  isBefore,
  addDays,
  parseISO,
  isPast,
  isFuture,
} from 'date-fns';

export class TimeUtilitiesHelper {

  public static compareTimes(leftTime: string, rightTime: string): number {
    const rightTimeDate = new Date(rightTime);
    return compareAsc(
      set(new Date(leftTime),{year: getYear(rightTimeDate), month: getMonth(rightTimeDate), date: getDate(rightTimeDate)}),
      rightTimeDate
    );
  }

  public static compareDates(leftTime: string, rightTime: string): number {
    return compareAsc(
      new Date(leftTime),
      new Date(rightTime)
    );
  }

  public static newTime(timeValue: string) {
    return parse(timeValue, 'HH:mm', new Date());
  }

  public static nextHalfAnHour(time: string): string {
    const timeDate = new Date(time);
    if (getHours(timeDate) === 23 && getMinutes(timeDate) === 30) { return this.newTime('00:00').toISOString(); }
    if (getMinutes(timeDate) >= 30) { return addHours(startOfHour(timeDate), 1).toISOString(); }
    return addMinutes(startOfHour(timeDate), 30).toISOString();
  }

  public static dateToTimeString(time: string): string {
    return format(new Date(time), 'HH:mm');
  }

  public static getCurrentTimeRounded(): string {
    const currentDate: string = new Date().toISOString();
    return this.nextHalfAnHour(new Date().toISOString());
  }
  public static areDatesEqualLocal(leftDate: string, rightDate: string): boolean {
    return format(new Date(leftDate), 'dd/MM/yyyy') === format(new Date(rightDate), 'dd/MM/yyyy');
  }

  public static parseDateCard(customDateString: string, isStartOfMonth: boolean): Date{
    //customString format:  "MM / yy"
    if(customDateString.length !== 7){
      return null;
    }
    const date = parse(customDateString, 'MM / yy', new Date());
    if (isStartOfMonth) {
      date.setDate(1);
      date.setHours(0, 0);
      return date;
    }
    date.setDate(lastDayOfMonth(date).getDate());
    date.setHours(23, 59);
    return date;
  }

  public static formatTimeInterval(startTime: string, endTime: string): string{
    const startTimeString = this.dateToTimeString(startTime);
    const endTimeString = this.dateToTimeString(endTime);
    return `${startTimeString} - ${endTimeString}`;
  }

  public static eachDayInterval(start: string, end: string){
    return eachDayOfInterval({start: new Date(start), end: new Date(end)});
  }

  public static formatStringForCardDate(dateString): string {
    const oldDate = dateString.replace(/[^0-9]+/g, '');
    const month = oldDate.substring(0,2);
    const year = oldDate.substring(2,4);
    let newDate = month;
    if(year !== ''){
      newDate += ' / ' + year;
    }
    return newDate;
  }

  public static isDateInPast(date: string){
    return isPast(new Date(date));
  }

  public static isDateInFuture(date: string){
    return isFuture(new Date(date));
  }

  public static getLastDayOfNextMonth(date: Date) {
    return lastDayOfMonth(addMonths(date, 1));
  }

  public static getFirstDayOf12MonthsAgo(date: Date) {
    return startOfMonth(subMonths(date, 12));
  }

  public static isDateFromPastMonths(date: Date) {
    return isBefore(addDays(date, 1), new Date());
  }

  public static getMothYearFormatFromISO(dateIso: string): string {
    return format(parseISO(dateIso), 'MM,yyyy');
  }

  public static formatDateAndTime(dateString): { formattedDate: string; formattedTime: string } {
    const date = parseISO(dateString);
    const formattedDate = format(date, 'do MMMM');
    const formattedTime = format(date, 'HH:mm');
    return {formattedDate, formattedTime };
  }
}

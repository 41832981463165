import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../core/authentication/authentication.service';
import { initiateBiometricLogin, Login, SetAuthenticationRedirectUrl } from '@app/store/actions/authentication.actions';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class AuthenticationErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private store: Store
    ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.url.includes('/identity/connect/token')) {
          if (!error.url.includes('/identity/connect/token') && environment.useBiometric) {
            this.store.dispatch(new SetAuthenticationRedirectUrl(window.location.pathname));
            const platform = Capacitor.getPlatform();
            if (platform === 'ios' || platform === 'android') {
              this.store.dispatch(initiateBiometricLogin());
            }
          }
          else {
            this.store.dispatch(new Login());
          }
        } else if (error.status === 403) {
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  public async reAuthenticate(): Promise<void> {
    await this.authService.loginFlow();
  }
}

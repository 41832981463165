import { environment } from '@environments/environment';
import {
  ResourceGroup,
  SlotWithPrice,
  SlotsByActivityIdMap,
  SlotsByResourceIdMap,
} from '@app/core/models/session.model';
import { TabProps } from '@app/shared/components/icon-container/tab-props';
import { Site } from '@app/core/models/site.model';
import { Slot } from '@app/core/models/session.model';
import { IAppColors } from '@app/core/models/app-config.interface';
import { BasketItem } from '@app/core/models/basket-item.model';
import { WalletState } from './wallet.state';
import { FavouriteBooking } from '@app/core/models/favourite-booking.model';
import { AccountState } from './account.state';
import { PaymentState } from './payment.state';
import { FutureBooking, FutureBookingsAvailability } from '@app/core/models/future-booking.model';
import { Activity, ActivityDetails } from '@app/core/models/activity.model';
import { PreviousBooking } from '@app/core/models/previous-booking.model';
import { Identifier } from '@app/core/models/identifier.model';
import { Language } from '@app/core/models/language.model';
import { ColorThemes } from '@app/core/constants';
import { PriceInformation, PricingQuote } from '@app/core/models/pricing.model';
import { TagGroupDTO } from '@app/api/dtos/tags.dto';
import { Config } from '@app/core/models/configuration.model';

//TODO: split interfaces in specific files
export interface IAppConfigState {
  colors: IAppColors;
  isFooterVisible: boolean;
  tabProps: TabProps[];
  languages: Language[];
  selectedLanguage: string;
  languagePersonalisationId: number;
  colorTheme: ColorThemes;
  systemColorTheme: 'light' | 'dark';
  isWalletEnabled: boolean;
  merchantId: Config;
}

export interface NavigationState {
  currentPage: string;
  navigationHistory: string[];
}
export interface ISiteState {
  sites: Site[];
  errorOnGettingSites: boolean;
  searchFilter: string;
  selectedSite: Site;
  isSearchingSite: boolean;
  isSingleSite: boolean;
  defaultSite: Site;
}

export interface IActivityState {
  activitiesGroupedByName: { [name: string]: Activity };
  activityNames: string[];
  activityTagGroup: TagGroupDTO[];
  selectedActivity: Activity;
  searchText: string;
  isSearchingActivity: boolean;
  searchActivityTagId: number;
  isSearchingActivityByTagId: boolean;
  errorOnGettingActivities: boolean;
}

export interface ISessionState {
  slotsByActivityId: SlotsByActivityIdMap;
  allSlotsByResourceGroup: SlotsByResourceIdMap;
  isSearchingSessions: boolean;
  selectedSlot: Slot;
  previouslySelectedSlot: Slot;
  selectedResource: ResourceGroup;
  slotsWithPrices: { [slotReference: string]: SlotWithPrice };
  selectedActivityDetails: ActivityDetails;
  isLoadingActivityDetails: boolean;
  slotPriceLevels: PriceInformation;
  slotPriceQuote: PricingQuote;
  additionalSlots: SlotsByResourceIdMap;
}

export interface BookTimePickerState {
  startTime: string;
  endTime: string;
  timeIsSet: boolean;
}

export interface IBasketState {
  basketItems: BasketItem[];
  checkedOutBookings: BasketItem[];
  totalPrice: number;
  VAT: number;
  currency: string;
  isBasketItemModified: boolean;
  futureBasketItem: BasketItem;
}

export interface FutureBookingsState {
  futureBookings: FutureBooking[];
  isLoadingFutureBookings: boolean;
  futureBookingsAvailability: FutureBookingsAvailability;
  alertList: FutureBooking[];
}

export interface BookingHistoryState {
  bookingHistory: PreviousBooking[];
  isLoadingBookingHistory: boolean;
}

export interface RecommendedActivitiesState {
  promotedTagId: number;
  recommendedActivities: Slot[];
  isLoadingRecommendedActivities: boolean;
}

export interface BookDatePickerState {
  startDate: string;
  endDate: string;
  isCurrentDate: boolean;
  selectedDateDots: string[];
}

export interface AuthenticationState {
  authenticating: boolean;
  authenticated: boolean;
  memberUserId: number;
  failure?: {
    message: string;
  } | null;
  authenticationRedirectUrl: string;
}

export const initialAuthenticationState: AuthenticationState = {
  authenticating: false,
  authenticated: false,
  failure: null,
  memberUserId: null,
  authenticationRedirectUrl: '',
};

export interface NotificationHandlingState {
  errorOnApiCall: boolean;
  errorOnConnection: boolean;
}

export interface IFavouritesState {
  favouriteBookings: FavouriteBooking[];
}

export interface IEntryState {
  shortIdentifier: Identifier;
  offlineIdentifier: Identifier;
  primaryIdentifier: Identifier;
  isRefreshingShortIdentifier: boolean;
  targetTime: string;
}

export interface IPlatformState {
  isKeyboardOpen: boolean;
  hasInternetConnection: boolean;
  isAppActive: boolean;
}

export interface IAppState {
  appConfig: IAppConfigState;
  sites: ISiteState;
  activities: IActivityState;
  sessions: ISessionState;
  basket: IBasketState;
  bookTimePicker: BookTimePickerState;
  bookDatePicker: BookDatePickerState;
  authentication: AuthenticationState;
  notificationHandling: NotificationHandlingState;
  account: AccountState;
  favourites: IFavouritesState;
  payment: PaymentState;
  wallet: WalletState;
  entry: IEntryState;
  futureBookings: FutureBookingsState;
  bookingHistory: BookingHistoryState;
  recommendedActivities: RecommendedActivitiesState;
  platform: IPlatformState;
  navigation: NavigationState;
}

export const initialAppConfigState: IAppConfigState = {
  colors: {
    primaryColor1: environment.primaryColor1,
    primaryColor2: environment.primaryColor2,
    primaryColorDark: environment.primaryColorDark,
  },
  isFooterVisible: true,
  tabProps: [
    {
      iconName: 'icon-gs-icon-heart-filled',
      iconLabel: 'FOOTER.TAB_FAV',
      dimension: '40px',
      fontSize: '24px',
      navRoute: '/favourites',
      isCenterButton: false,
      selected: false,
      badgeValue: 0,
    },
    {
      iconName: 'icon-gs-icon-qr-code',
      iconLabel: 'FOOTER.TAB_ENTRY',
      dimension: '40px',
      fontSize: '24px',
      navRoute: '/entry',
      isCenterButton: false,
      selected: false,
      badgeValue: 0,
    },
    {
      iconName: 'icon-gs-icon-add',
      iconLabel: 'FOOTER.TAB_BOOK',
      dimension: '62px',
      fontSize: '48px',
      navRoute: '/book',
      isCenterButton: true,
      selected: true,
      badgeValue: 0,
    },
    {
      iconName: 'icon-gs-icon-my-account',
      iconLabel: 'FOOTER.TAB_ACC',
      dimension: '40px',
      fontSize: '24px',
      navRoute: '/my-account',
      isCenterButton: false,
      selected: false,
      badgeValue: 0,
    },
    {
      iconName: 'icon-gs-icon-basket',
      iconLabel: 'FOOTER.TAB_BASKET',
      dimension: '40px',
      fontSize: '24px',
      navRoute: '/basket',
      isCenterButton: false,
      selected: false,
      badgeValue: 0,
    },
  ],
  languages: [],
  selectedLanguage: null,
  languagePersonalisationId: 0,
  colorTheme: ColorThemes.system,
  systemColorTheme: 'light',
  isWalletEnabled: false,
  merchantId: null,
};

export const getInitialState = (): IAppConfigState => initialAppConfigState;

import { DeviceInformationService } from '@core/services/device-information.service';
import { SaveCardBodyDTO } from '../dtos/wallet-dto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { SAVE_CARD_API_ENDPOINT, WALLET_API_ENDPOINT } from '@app/core/constants';
import { WalletDTO } from '../dtos/wallet-dto';
import { CardDetails } from '@app/core/models/card-details.model';
import { WalletMapper } from '../mappers/wallet.mapper';
import { DeviceInformationDTO } from '../dtos/payment.dto';

@Injectable({
 providedIn: 'root'
})
export class WalletService {
  constructor(private http: HttpClient, private deviceInformationService: DeviceInformationService) { }

  fetchSavedCards(memberId: number): Observable<CardDetails[]> {
    const endpoint = `${environment.apiUrl}/${WALLET_API_ENDPOINT}`.replace('{memberId}', `${memberId}`);
    return this.http.get<WalletDTO>(endpoint).pipe(
      map(WalletMapper.mapToCardDetails)
    );
  }

  saveNewCard(paymentToken: string, memberId: number): Observable<void> {
    const endpoint = `${environment.apiUrl}/${SAVE_CARD_API_ENDPOINT}`.replace('{memberId}', `${memberId}`);
    const body: SaveCardBodyDTO = WalletMapper.mapToSaveCardBodyDTO(
      paymentToken,
      this.deviceInformationService.getDeviceInformation() as DeviceInformationDTO
    );
    return this.http.post<void>(endpoint, body);
  }
}

import { Activity } from '@core/models/activity.model';
import { group } from 'radash';
import { ActivityDTO } from '@api/dtos/activity.dto';

export class ActivityMapper {
  private static mapActivitiesToSiteIdAndActivityId(
    activities: ActivityDTO[]
  ): {
    [siteId: string]: string[];
  } {
    const activitiyIdsBySiteId: { [siteId: string]: string[] } = {};
    activities.forEach((activity: ActivityDTO) => {
      activitiyIdsBySiteId[activity.siteId] = activitiyIdsBySiteId[
        activity.siteId
      ]
        ? [...activitiyIdsBySiteId[activity.siteId]]
        : [];
      activitiyIdsBySiteId[activity.siteId] = [
        ...activitiyIdsBySiteId[activity.siteId],
        activity.id,
      ];
    });
    return activitiyIdsBySiteId;
  }

  public static groupActivitiesByName(activities: ActivityDTO[]): {[name: string]: Activity} {
    const activitiesByName: {[name: string]: Activity} = {};
    Object.entries(group(activities, (activity) => activity.name)).forEach(
      ([name, activitiesWithSameName]: [string, ActivityDTO[]]) => {
        activitiesByName[name] = {
          name,
          activityIdsBySiteId:
            ActivityMapper.mapActivitiesToSiteIdAndActivityId(
              activitiesWithSameName
            ),
        };
      }
    );
    return activitiesByName;
  }
}

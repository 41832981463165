/* eslint-disable jsdoc/no-types */
import { isMatch, isPast, isValid, parse } from 'date-fns';
import { isValidPhoneNumber } from 'libphonenumber-js';

type ValidatorContext =  {input: string; errors: string[]};
type ValidatorFunction = (context: ValidatorContext) => ValidatorContext;
type Validator = (context: ValidatorContext, next: NextValidator, validatorFunc: ValidatorFunction) => string[];
type NextValidator = (context: ValidatorContext) => string[];

/*
  In order to add a new validator, simply create a function that has as parameter
a ValidatorContext (input and error array). If the the input is valid, return the
same context, if not, add an error message to the error array and return the new
context.
  After this step, create an array of delegates(functions) which contains the desired
validators and pass it as parameter to the validate(validateBoolean if you don't care
about messages).
*/

const validateRequired = (context: ValidatorContext) =>
  context.input.length === 0 ? {...context, errors: [...context.errors, 'Field is required']} : context;


const validateName = (context: ValidatorContext) =>
  !/^[a-z -]*$/i.test(context.input) ? {...context, errors: [...context.errors, 'Field should respect name format']} : context;

const validateNumeric = (context: ValidatorContext) =>
  !/^\d*$/.test(context.input) ? {...context, errors: [...context.errors, 'Field should be numeric']} : context;

const validateEmail = (context: ValidatorContext) => !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(context.input) ?
  {...context, errors: [...context.errors, 'Field should respect email format']} : context;

const validateDate = (context: ValidatorContext) => {
  let isInputInvalid = true;
  const doesMatchFormat = isMatch(context.input, 'dd/MM/yyyy');
  if (!doesMatchFormat) {
    isInputInvalid =  false;
  }
  const date = parse(context.input, 'dd/MM/yyyy', new Date());
  isInputInvalid =  !isValid(date) || !isPast(date);
  if (isInputInvalid) {
    context.errors = [...context.errors, 'Field should respect date format'];
  }
  return context;
};

const validatePhoneNumber = (context: ValidatorContext) => !isValidPhoneNumber(context.input) ?
  {...context, errors: [...context.errors, 'Field should respect phone number national format']} : context;


const genericValidator: Validator = (context: ValidatorContext, next: NextValidator, validatorFn: ValidatorFunction) => {
  context = validatorFn(context);

  if(next === null) {
    return context.errors;
  }
  return next(context);
};

const nextCaller = (
  context: ValidatorContext,
  previous: NextValidator,
  currentVal: ValidatorFunction
) => genericValidator(context, previous, currentVal);


export const assignNext = (previous: NextValidator, currentValidator: ValidatorFunction) => {
  const next: NextValidator = (context: ValidatorContext) => nextCaller(context, previous, currentValidator);
  return next;
};

/**
 * This function is a functional implementation of the chain of responsability pattern.
 * Firstly, we go through each validator function from the end to the beginning and set
 * its next function (we need to do that in order to have access to the previous nexts).
 * After that, we call the first validator, which will go through each binded validator.

 * @param   {string}              input      the string input you want to validate
 * @param   {ValidatorFunction[]} validators the array of validator functions you want to apply to the input
 * @returns {string[]}                      `the array of error strings resulting of the validation
 */
export const validate = (input: string, validators: ValidatorFunction[]): string[] => {
  let previous: NextValidator = null;
  const validatorsCopy = [...validators];
  while (validatorsCopy.length !== 0) {
    const currentValidator = validatorsCopy.pop();
    previous = assignNext(previous, currentValidator);
  }
  return previous({input, errors: []});
};

export const validateBoolean = (input: string, validators): boolean => validate(input, validators).length === 0;

export const TEXT: ValidatorFunction[] = [validateRequired];
export const NAME: ValidatorFunction[] = [validateRequired, validateName];
export const NAME_NOT_REQUIRED: ValidatorFunction[] = [validateName];
export const NUMBER: ValidatorFunction[] = [validateRequired, validateNumeric];
export const PHONE_NUMBER: ValidatorFunction[] = [validateRequired, validatePhoneNumber];
export const EMAIL: ValidatorFunction[] = [validateRequired, validateEmail];
export const DATE: ValidatorFunction[] = [validateRequired, validateDate];

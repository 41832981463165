import { TagGroupDTO } from '@app/api/dtos/tags.dto';
import { SiteDTO } from '@app/api/dtos/site.dto';
import { Activity, ActivityDetails } from '@app/core/models/activity.model';
import { AlertList } from '@app/core/models/alert-list.model';
import { PriceInformation, PricingQuote } from '@app/core/models/pricing.model';
import { AdditionalBooking, SessionSearchParams } from '@app/core/models/session-search-params.model';
import { AdditionalBookees, Slot, SlotsByActivityIdMap, SlotsByResourceIdMap } from '@app/core/models/session.model';
import { Site } from '@app/core/models/site.model';
import { PreviousBooking } from '@core/models/previous-booking.model';
import { createAction, props, union } from '@ngrx/store';

export const LOAD_SITES = '[Book] Get sites from API';
export const LOADED_SITES_SUCCESS = '[Book] Get sites from API success';
export const LOADED_SITES_FAIL = '[Book] Get sites from API fail';
export const LOAD_SITE_DETAILS = '[Book] Get site details from API';
export const LOADED_SITE_DETAILS_SUCCESS = '[Book] Get site details from API success';
export const LOADED_SITE_DETAILS_FAIL = '[Book] Get site details from API fail';

export const CHANGE_STATE_IS_SEARCHING_SITES = '[Sites] Change state of is searching sites';
export const USER_IS_SEARCHING_SITES = '[Sites] User searches for site';
const LOAD_DEFAULT_SITE = '[Sites] Load the default site';
const LOADED_DEFAULT_SITE_SUCCESS = '[Sites] Load the default site succes';
const LOADED_DEFAULT_SITE_FAIL = '[Sites] Load the default site fail';

export const SET_CENTRE_SEARCH_FILTER = '[Centre] Set search filter for sites';
export const SET_SELECTED_CENTRE = '[Centre] Set selected centre';

export const OPEN_SEARCH_ACTIVITY_MODAL = '[Activity] Open the search activity modal and clear activities list';
export const LOAD_ACTIVITIES = '[Activity] Get all activities from api';
export const LOADED_ACTIVITIES_SUCCESS = '[Activity] Get all activities from api success';
export const LOADED_ACTIVITIES_FAIL = '[Activity] Get all activities from api fail';
export const LOAD_ACTIVITIES_BY_TAG_GROUP = '[Activity] Get all activities by tag group from api';
export const LOADED_ACTIVITIES_BY_TAG_GROUP_SUCCESS = '[Activity] Get all activities by tag group from api success';
export const LOADED_ACTIVITIES_BY_TAG_GROUP_FAIL = '[Activity] Get all activities by tag group from api fail';
export const SEARCH_ACTIVITIES = '[Activity] Get matching activities from api';
export const SEARCHED_ACTIVITIES_SUCCESS = '[Activity] Get matching activities from api success';
export const SEARCHED_ACTIVITIES_FAIL = '[Activity] Get matching activities from api fail';
export const SEARCH_ACTIVITIES_BY_TAG = '[Activity] Get activities matching tag from api';
export const SEARCHED_ACTIVITIES_BY_TAG_SUCCESS = '[Activity] Get activities matching tag from api success';
export const SEARCHED_ACTIVITIES_BY_TAG_FAIL = '[Activity] Get activities matching tag from api fail';
export const CANCEL_SEARCH_ACTIVITIES_BY_TAG = '[Activity] Cancel get activities matching tag from api';
export const SET_SELECTED_ACTIVITY = '[Activity] Set selected activity';
export const CLEAR_SELECTED_CHOICES = '[Book] Clear all selected choices';

// SITE ACTIONS
export const loadSites = createAction(LOAD_SITES);
export const changeStateOfIsSearchingSite = createAction(
  CHANGE_STATE_IS_SEARCHING_SITES,
  props<{
    isSearching: boolean;
  }>()
);
export const userIsSearchingSites = createAction(
  USER_IS_SEARCHING_SITES,
  props<{
    searchFilter: string;
  }>()
);
export const loadSitesSuccess = createAction(
  LOADED_SITES_SUCCESS,
  props<{
    sites: Site[];
  }>()
);
export const loadSitesFail = createAction(LOADED_SITES_FAIL);
export const loadSiteDetails = createAction(
  LOAD_SITE_DETAILS,
  props<{
    siteId: string;
  }>()
);
export const loadSiteDetailsSuccess = createAction(
  LOADED_SITE_DETAILS_SUCCESS,
  props<{
    site: SiteDTO;
  }>()
);
export const loadSiteDetailsFail = createAction(LOADED_SITE_DETAILS_FAIL);
export const setCentreSearchFilter = createAction(
  SET_CENTRE_SEARCH_FILTER,
  props<{
    searchFilter: string;
  }>()
);
export const setSelectedCentre = createAction(
  SET_SELECTED_CENTRE,
  props<{
    site: Site;
  }>()
);
export const loadDefaultSite = createAction(LOAD_DEFAULT_SITE);
export const loadedDefaultSiteSuccess = createAction(LOADED_DEFAULT_SITE_SUCCESS, props<{ site: Site }>());
export const loadedDefaultSiteFail = createAction(LOADED_DEFAULT_SITE_FAIL);

//ACTIVITY ACTIONS
export const openSearchActivityModal = createAction(OPEN_SEARCH_ACTIVITY_MODAL);

export const loadActivities = createAction(LOAD_ACTIVITIES);
export const loadActivitiesSuccess = createAction(
  LOADED_ACTIVITIES_SUCCESS,
  props<{
    activities: { [name: string]: Activity };
  }>()
);
export const loadActivitiesFail = createAction(LOADED_ACTIVITIES_FAIL);

export const loadActivitiesByTagGroup = createAction(LOAD_ACTIVITIES_BY_TAG_GROUP);
export const loadActivitiesByTagGroupSuccess = createAction(
  LOADED_ACTIVITIES_BY_TAG_GROUP_SUCCESS,
  props<{
    activityTagGroup: TagGroupDTO[];
  }>()
);
export const loadActivitiesByTagGroupFail = createAction(LOADED_ACTIVITIES_BY_TAG_GROUP_FAIL);

export const searchActivities = createAction(
  SEARCH_ACTIVITIES,
  props<{
    searchText: string;
  }>()
);
export const searchActivitiesSuccess = createAction(
  SEARCHED_ACTIVITIES_SUCCESS,
  props<{
    activities: { [name: string]: Activity };
  }>()
);
export const searchActivitiesFail = createAction(SEARCHED_ACTIVITIES_FAIL);

export const searchActivitiesByTag = createAction(
  SEARCH_ACTIVITIES_BY_TAG,
  props<{
    tagId: number;
  }>()
);
export const searchActivitiesByTagSuccess = createAction(
  SEARCHED_ACTIVITIES_BY_TAG_SUCCESS,
  props<{
    activities: { [name: string]: Activity };
  }>()
);
export const searchActivitiesByTagFail = createAction(SEARCHED_ACTIVITIES_BY_TAG_FAIL);
export const cancelSearchActivitiesByTag = createAction(CANCEL_SEARCH_ACTIVITIES_BY_TAG);

export const setSelectedActivity = createAction(
  SET_SELECTED_ACTIVITY,
  props<{
    activity: Activity;
  }>()
);

export const clearSelectedChoices = createAction(CLEAR_SELECTED_CHOICES);

// SESSION ACTIONS
export const LOAD_SESSIONS = '[Session] Get matching sessions from API';
export const LOADED_SESSIONS_SUCCESS = '[Session] Get matching sessions from API success';
export const LOADED_SESSIONS_FAIL = '[Session] Get matching sessions from API fail';
export const SET_SELECTED_SLOT = '[Session] Set selected slot';
export const LOAD_ACTIVITY_DETAILS = '[Session] Get slot details from API';
export const LOADED_ACTIVITY_DETAILS_SUCCESS = '[Session] Get slot details from API success';
export const LOADED_ACTIVITY_DETAILS_FAIL = '[Session] Get slot details from API fail';
export const LOAD_SLOT_PRICE = '[Session] Get slot price from API';
export const LOADED_SLOT_PRICE_SUCCESS = '[Session] Get slot price from API success';
export const LOADED_SLOT_PRICE_FAIL = '[Session] Get slot price from API fail';
export const LOAD_SESSION_PRICE = '[Session] Get session price from API';
export const LOADED_SESSION_PRICE_SUCCESS = '[Session] Get session price from API success';
export const LOADED_SESSION_PRICE_FAIL = '[Session] Get session price from API fail';
export const CLEAR_SLOT_PRICES = '[Session] Clear prices of existing slots';
export const RESET_SESSION_LIST = '[Session] Resset session list for virtual scrolling';
export const CLEAR_SELECTED_SLOT = '[Session] Clear selected slot';
export const SET_SEARCH_PARAMETERS_BY_BOOKING =
  '[Session] Load sessions being given a certain booking (+- 2 hours for time)';
const SET_SESSION_SEARCH_FILTERS = '[Session] Set all the filters for session search';
const SET_SELECTED_RESOURCE_BY_ID = '[Session] Set the selected resource group';
const SET_SELECTED_DATE_DOTS = '[Session] Set the selected date dots';

export const loadSessions = createAction(LOAD_SESSIONS, props<{ params: SessionSearchParams }>());
export const loadSessionsSuccess = createAction(
  LOADED_SESSIONS_SUCCESS,
  props<{
    slotsByActivityId: SlotsByActivityIdMap;
    allSlotsGroupedInOneSession: SlotsByResourceIdMap;
    additionalSlotsGroupedInOneSession: SlotsByResourceIdMap;
  }>()
);

export const setSearchParametersBySelectedBooking = createAction(
  SET_SEARCH_PARAMETERS_BY_BOOKING,
  props<{ booking: PreviousBooking }>()
);

export const loadSessionsFail = createAction(LOADED_SESSIONS_FAIL);
export const setSelectedSlot = createAction(SET_SELECTED_SLOT, props<{ slot: Slot }>());

export const loadActivityDetails = createAction(LOAD_ACTIVITY_DETAILS, props<{ activityId: string }>());
export const loadActivityDetailsSuccess = createAction(
  LOADED_ACTIVITY_DETAILS_SUCCESS,
  props<{ activityDetails: ActivityDetails }>()
);
export const loadActivityDetailsFail = createAction(LOADED_ACTIVITY_DETAILS_FAIL);

export const loadSlotPrice = createAction(
  LOAD_SLOT_PRICE,
  props<{ additionalBookees: AdditionalBooking[]; slot: Slot; isMemberAttending: boolean }>()
);
export const loadSlotPriceSuccess = createAction(
  LOADED_SLOT_PRICE_SUCCESS,
  props<{ pricing: PricingQuote; slot: Slot }>()
);
export const loadSlotPriceFail = createAction(LOADED_SLOT_PRICE_FAIL);

export const loadSessionPrice = createAction(LOAD_SESSION_PRICE, props<{ slot: Slot }>());
export const loadSessionPriceSuccess = createAction(
  LOADED_SESSION_PRICE_SUCCESS,
  props<{ priceInformation: PriceInformation; slot: Slot }>()
);
export const loadSessionPriceFail = createAction(LOADED_SESSION_PRICE_FAIL);

export const clearSlotPrices = createAction(CLEAR_SLOT_PRICES);
export const resetSessionList = createAction(RESET_SESSION_LIST);
export const clearSelectedSlot = createAction(CLEAR_SELECTED_SLOT);
export const setSessionSearchFilters = createAction(
  SET_SESSION_SEARCH_FILTERS,
  props<{ parameters: SessionSearchParams }>()
);
export const setSelectedResourceById = createAction(SET_SELECTED_RESOURCE_BY_ID, props<{ resourceId: string }>());
export const setSelectedDateDots = createAction(SET_SELECTED_DATE_DOTS, props<{ dateDots: Date[] }>());

const sitesActions = union({
  loadSites,
  loadSitesSuccess,
  loadSitesFail,
  loadSiteDetails,
  loadSiteDetailsSuccess,
  loadSiteDetailsFail,
  setCentreSearchFilter,
});

export const activitiesActions = union({
  loadActivities,
  loadActivitiesSuccess,
  loadActivitiesFail,
  loadActivitiesByTagGroup,
  loadActivitiesByTagGroupSuccess,
  loadActivitiesByTagGroupFail,
  searchActivities,
  searchActivitiesSuccess,
  searchActivitiesFail,
  searchActivitiesByTag,
  searchActivitiesByTagSuccess,
  searchActivitiesByTagFail,
  cancelSearchActivitiesByTag,
  setSelectedActivity,
  openSearchActivityModal,
});

export const sessionsActions = union({
  loadSessions,
  loadSessionsSuccess,
  loadSessionsFail,
  setSelectedSlot,
  loadSlotPrice,
  loadSlotPriceSuccess,
  loadSlotPriceFail,
  clearSlotPrices,
});

// TIME ACTIONS
export const SET_TIME_BOUNDARIES = '[BookTimePicker] Set the lower bound and upper bound of time';
export const TIME_WAS_SET_BY_USER = '[BookTimePicker] Time was set by the user';

export const setTimeBoundaries = createAction(
  SET_TIME_BOUNDARIES,
  props<{
    startTime: string;
    endTime: string;
  }>()
);

export const timeWasSetByTheUser = createAction(
  TIME_WAS_SET_BY_USER,
  props<{
    shouldModify: boolean;
  }>()
);

// DATE ACTIONS
export const SET_DATE_BOUNDARIES = '[BookDatePicker] Set the lower bound and upper bound of date';

export const setDateBoundaries = createAction(
  SET_DATE_BOUNDARIES,
  props<{
    startDate: string;
    endDate: string;
    currentStartTime: string;
    isTimeSet: boolean;
  }>()
);

export type BookSitesActions = typeof sitesActions;
export type BookActivitiesActions = typeof activitiesActions;
export type BookSessionActions = typeof sessionsActions;

//RECOMENDED ACTIVITIES
export const LOAD_RECOMMENDED_ACTIVITIES = '[Recommended] Get recommended activities';
export const LOADED_RECOMMENDED_ACTIVITIES_SUCCESS = '[Recommended] Get recommended activities success';
export const LOADED_RECOMMENDED_ACTIVITIES_FAIL = '[Recommended] Get recommended activities fail';

export const loadRecommendedActivities = createAction(LOAD_RECOMMENDED_ACTIVITIES);
export const loadedRecommendedActivitiesSuccess = createAction(
  LOADED_RECOMMENDED_ACTIVITIES_SUCCESS,
  props<{ recommendedActivities: Slot[] }>()
);
export const loadedRecommendedActivitiesFail = createAction(LOADED_RECOMMENDED_ACTIVITIES_FAIL);

//ALERT LIST
const JOIN_ALERT_LIST = '[Session] Join alert list';
const JOINED_ALERT_LIST_SUCCESS = '[Session] Joined alert list success';
const JOINED_ALERT_LIST_FAIL = '[Session] Joined alert list fail';
const UNSUBSCRIBE_FROM_ALERT_LIST = '[Session] Unsubscribe from alert list';
const UNSUBSCRIBED_FROM_ALERT_LIST_SUCCESS = '[Session] Unsubscribed from alert list successfully';
const UNSUBSCRIBED_FROM_ALERT_LIST_FAIL = '[Session] Unsubscribe from alert list failed';

export const joinAlertList = createAction(JOIN_ALERT_LIST, props<{ memberId: number; slotReference: string }>());
export const joinedAlertListSuccess = createAction(JOINED_ALERT_LIST_SUCCESS, props<{ alertListItem: AlertList }>());
export const joinedAlertListFail = createAction(JOINED_ALERT_LIST_FAIL);
export const unsubscribeFromAlertList = createAction(
  UNSUBSCRIBE_FROM_ALERT_LIST,
  props<{ memberId: number; bookingId: string; activityName: string; activityDate: string }>()
);
export const unsubscribedFromAlertListSuccess = createAction(
  UNSUBSCRIBED_FROM_ALERT_LIST_SUCCESS,
  props<{ bookingId: string; activityName: string; activityDate: string }>()
);
export const unsubscribedFromAlertListFail = createAction(UNSUBSCRIBED_FROM_ALERT_LIST_FAIL);

const SET_PROMOTED_TAG_ID = '[Tag] Set promoted tag id';
export const setPromotedTagId = createAction(SET_PROMOTED_TAG_ID, props<{ promotedTagId: number }>());

const ADD_BOOKEES = '[Session] Add bookees';
export const addBookees = createAction(ADD_BOOKEES, props<{ bookees: string }>());

import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '@app/core/models/session.model';
import { format } from 'date-fns';

@Component({
  selector: 'gs-confirmation-message-card',
  templateUrl: './confirmation-message-card.component.html',
  styleUrls: ['./confirmation-message-card.component.scss'],
})
export class ConfirmationMessageCardComponent implements OnInit {
  @Input() slot: Slot;

  constructor() { }

  ngOnInit() {}

  getFormattedDate(): string{
    if (!this.slot) {
      return '';
    }
    return format(new Date(this.slot.startTime), 'EEEE do, LLLL');
  }

  getFormattedTimeInterval(): string {
    if (!this.slot) {
      return '';
    }
    const startTimeFormatted = format(new Date(this.slot.startTime), 'HH:mm');
    const endTimeFormatted = format(new Date(this.slot.endTime), 'HH:mm');
    return `${startTimeFormatted} - ${endTimeFormatted}`;
  }

}

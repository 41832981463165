import { Injectable } from '@angular/core';
import { AccessControlService } from '@app/api/services/access-control.service';
import { EntryIdentifierTypes } from '@app/core/constants';
import { Identifier } from '@app/core/models/identifier.model';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, EMPTY, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import {
  deletedShortIdentifierSuccess,
  EntryActions,
  loadIdentifiers,
  loadIdentifiersFail,
  loadIdentifiersSuccess,
  deletedShortIdentifierFail,
  deletedOfflineIdentifierSuccess,
  deletedOfflineIdentifierFail,
  handleShortIdentifier,
  handleOfflineIdentifier,
  createdShortIdentifierFail,
  createdOfflineIdentifierSuccess,
  createdOfflineIdentifierFail,
  refreshEntryIdentifiers,
} from '../actions/entry.actions';
import { getAccountMemberId } from '../selectors/authentication.selectors';
import { selectOfflineIdentifier, selectShortIdentifier } from '../selectors/entry.selectors';
import { selectHasInternetConnection } from '../selectors/platform.selectors';

@Injectable()
export class EntryEffects{

  loadIdentifier$ = createEffect(()=>
    this.actions$.pipe(
      ofType(loadIdentifiers),
      switchMap((action) => this.service.getAll(action.memberId).pipe(
        map((identifiers: Identifier[]) => loadIdentifiersSuccess({identifiers, isLoadingIdentifiers: action.isLoadingIdentifiers})),
        catchError(() => {
          this.store.dispatch(loadIdentifiersFail({isLoadingIdentifiers: action.isLoadingIdentifiers}));
          return  EMPTY;
        })
      )),
  ));

  handleShortIdentifier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleShortIdentifier),
      debounceTime(250),
      withLatestFrom(
        this.store.select(selectShortIdentifier),
        this.store.select(getAccountMemberId),
        this.store.select(selectHasInternetConnection)
      ),
      switchMap(([action, shortIdentifier, memberId, isOnline]) => {
        if(!isOnline){
          return [refreshEntryIdentifiers(), deletedShortIdentifierFail()];
        }
        if(action.delete === true && action.create === false){
          if(shortIdentifier){
            return this.service.deleteIdentifier(memberId, shortIdentifier).pipe(
              switchMap(() => [deletedShortIdentifierSuccess()]),
              catchError(() => of(deletedShortIdentifierFail()))
            );
          }
          else{
            return EMPTY;
          }
        }

        else if(action.delete === true && action.create === true){
          if(shortIdentifier){
            return this.service.deleteIdentifier(memberId, shortIdentifier).pipe(
              tap(()=>this.store.dispatch(deletedShortIdentifierSuccess())),
              switchMap(() => this.service.createIdentifier(memberId, EntryIdentifierTypes.short).pipe(
                switchMap(() => [loadIdentifiers({memberId, isLoadingIdentifiers: false})]),
                catchError(() => of(createdShortIdentifierFail()))
              )),
              catchError(() => of(deletedShortIdentifierFail()))
            );
          }
          else{
            return this.service.createIdentifier(memberId,EntryIdentifierTypes.short).pipe(
              switchMap(() => [loadIdentifiers({memberId, isLoadingIdentifiers: false})]),
              catchError(() => of(createdShortIdentifierFail()))
            );
          }
        }
      })
    )
  );

  handleOfflineIdentifier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleOfflineIdentifier),
      debounceTime(250),
      withLatestFrom(this.store.select(selectOfflineIdentifier), this.store.select(getAccountMemberId)),
      switchMap(([action, offlineIdentifier, memberId]) => {
        if(action.delete === true && action.create === true){
          if(offlineIdentifier){
            return this.service.deleteIdentifier(memberId, offlineIdentifier).pipe(
              tap(() => this.store.dispatch(deletedOfflineIdentifierSuccess())),
              switchMap(() => this.service.createIdentifier(memberId, EntryIdentifierTypes.offline).pipe(
                switchMap((identifier) => [createdOfflineIdentifierSuccess({identifier})]),
                catchError(() => of(createdOfflineIdentifierFail()))
              )),
              catchError(() => of(deletedOfflineIdentifierFail()))
            );
          }
          else{
            return this.service.createIdentifier(memberId, EntryIdentifierTypes.offline).pipe(
              switchMap(() => [loadIdentifiers({memberId, isLoadingIdentifiers: false})]),
              catchError(() => of(createdOfflineIdentifierFail()))
            );
          }
        }
      })
    )
  );

  createdOfflineIdentifierSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createdOfflineIdentifierSuccess),
      withLatestFrom(this.store.select(getAccountMemberId)),
      map(([_, memberId]) => loadIdentifiers({memberId, isLoadingIdentifiers: false}))
    )
  );

  constructor(private actions$: Actions<EntryActions>, private service: AccessControlService, private store: Store){}
}


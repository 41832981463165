import { AuthenticationActions, AuthenticationActionTypes } from '../actions/authentication.actions';
import { AuthenticationState, initialAuthenticationState } from '../state/app-config.state';


  export const authenticationReducer = (
    state: AuthenticationState = initialAuthenticationState,
    action: AuthenticationActions,
  ): AuthenticationState => {
    switch (action?.type) {
      case AuthenticationActionTypes.SetAuthenticationRedirectUrl:
        return {
          ...state,
          authenticationRedirectUrl: action.authenticationRedirectUrl,
        };
      case AuthenticationActionTypes.AuthenticationRequest:
        return {
          ...state,
          authenticating: true,
        };

      case AuthenticationActionTypes.AuthenticationSuccess:
        return {
          ...state,
          authenticating: false,
          authenticated: action.authenticated,
          memberUserId: action.memberUserId,
          failure: null,
        };

      case AuthenticationActionTypes.AuthenticationFailure:
        return {
          ...state,
          authenticating: false,
          authenticated: action.authenticated,
          memberUserId: null,
          failure: {
            message: action.message,
          },
        };

      case AuthenticationActionTypes.ReauthenticationRequest:
        return {
          ...state,
          authenticating: true,
        };

      case AuthenticationActionTypes.ReauthenticationSuccess:
        return {
          ...state,
          authenticating: false,
          failure: null,
        };

      case AuthenticationActionTypes.ReauthenticationFailure:
        return {
          ...state,
          authenticating: false,
          memberUserId: null,
          failure: {
            message: action.message,
          },
        };

      case AuthenticationActionTypes.LogOut:
        return initialAuthenticationState;

      default:
        return state;
    }
  };

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gs-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss'],
})
export class AvailabilityComponent implements OnInit {
  @Input() hasAvailableSpaces: boolean = null;
  @Input() hasAlertList: boolean = null;
  @Input() isAvailableForBooking: boolean = null;
  @Input() noLongerBookable: boolean = null;
  @Input() notYetBookable: boolean = null;
  constructor() { }

  ngOnInit() {}
}

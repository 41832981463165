import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Site } from '@app/core/models/site.model';
import { SITES_API_ENDPOINT, SYSTEM_CONFIG_API_ENDPOINT } from '@app/core/constants';
import { SiteDTO } from '../dtos/site.dto';
import { SiteMapper } from '../mappers/site.mapper';
import { alphabetical } from 'radash';
import { ConfigurationSetting } from '@app/core/models/configuration.model';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(private httpService: HttpClient) {}

  public getAll(): Observable<Site[]> {
    return this.httpService
      .get<SiteDTO[]>(`${environment.apiUrl}${SITES_API_ENDPOINT}?includeFacilitiesAndAmenities=true&isActive=true`)
      .pipe(
        map((sites) => sites.filter((site) => site.isActive && site.isWebVisible)),
        map((sites) => sites.map(SiteMapper.mapToSite)),
        map((sites) => alphabetical(sites, (site) => site.name)),
        catchError((err) => throwError(() => err))
      );
  }

  public getSiteById(siteId): Observable<Site> {
    return this.httpService.get<SiteDTO>(`${environment.apiUrl}${SITES_API_ENDPOINT}/${siteId}`).pipe(
      map((site) => SiteMapper.mapToSiteDetails(site)),
      catchError((err) => throwError(() => err))
    );
  }

  public getDefaultSiteId(): Observable<string> {
    const defaultMobileSiteKey = ConfigurationSetting.MobileApp_DefaultSite;
    const endpoint = `${environment.apiUrl}${SYSTEM_CONFIG_API_ENDPOINT}`.replace(
      '{configurationKey}',
      defaultMobileSiteKey
    );
    return this.httpService.get<{ value: string }>(endpoint).pipe(map((object) => object.value));
  }
}

import { Injectable } from '@angular/core';
import { StorageService } from '@core/storage/storage.service';
import { Actions, OnInitEffects, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest, from, of } from 'rxjs';
import { catchError, debounceTime, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  HYDRATE_STORE,
  hydrate,
  hydrateFailure,
  hydrateSuccess,
  loadDataAfterNewtorkConnection,
  reauthPostNetworkConnection,
  saveStateOfStore,
  savedStateSuccessfully,
} from '../actions/offline-support.actions';
import { changeNetworkStatus } from '../actions/platform.actions';
import { authenticationCheck, AuthService } from '@app/core/authentication/authentication.service';
import { loadSites, loadDefaultSite, loadRecommendedActivities } from '../actions/book.actions';
import { isAuthenticated } from '../selectors/authentication.selectors';
import { selectIsLoadingFutureBookings } from '../selectors/basket.selectors';
import { selectDefaultSite, selectSites } from '../selectors/book.selectors';
import { selectHasInternetConnection } from '../selectors/platform.selectors';
import { loadLanguages, loadMerchantId, loadWalletAvailability } from '../actions/app-config.actions';
import { filterNull } from '@app/core/helpers/filter-null';

@Injectable()
export class HydrationEffects implements OnInitEffects {
  constructor(private action$: Actions, private storage: StorageService) {}

  ngrxOnInitEffects(): Action {
    return hydrate();
  }

  hydrate$ = createEffect(() =>
    this.action$.pipe(
      ofType(HYDRATE_STORE),
      mergeMap(() =>
        from(this.storage.get('state')).pipe(
          map((state) => hydrateSuccess({ state })),
          catchError(() => of(hydrateFailure()))
        )
      )
    )
  );
}

@Injectable()
export class StorePersistenceEffects {
  constructor(private action$: Actions, private storage: StorageService) {}

  saveState$ = createEffect(() =>
    this.action$.pipe(
      ofType(saveStateOfStore),
      debounceTime(1000),
      switchMap((state) => from(this.storage.set('state', state.state))),
      map(() => savedStateSuccessfully())
    )
  );
}

@Injectable()
export class OfflineSupportEffect {
  constructor(private action$: Actions, private store: Store, private authService: AuthService) {}

  networkStatusChange$ = createEffect(() =>
    this.action$.pipe(
      ofType(changeNetworkStatus),
      filter((status) => status.networkStatus === true),
      switchMap(() => [reauthPostNetworkConnection(), loadDataAfterNewtorkConnection()])
    )
  );

  reauthPostNetworkConnection$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(reauthPostNetworkConnection),
        concatLatestFrom(() => [this.store.select(isAuthenticated), this.store.select(selectHasInternetConnection)]),
        filter(([_, isAuthed, isOnline]) => isOnline && !isAuthed),
        tap(async () => {
          await authenticationCheck(this.authService)();
        })
      ),
    { dispatch: false }
  );

  loadDataPostNetworkConnection$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(loadDataAfterNewtorkConnection),
        concatLatestFrom(() => [this.store.select(selectHasInternetConnection)]),
        filter(([_, isOnline]) => isOnline),
        tap(async () => {
          this.store.dispatch(loadSites());
          this.store.dispatch(loadLanguages());
          this.store
            .select(selectSites)
            .pipe(
              filter((sites) => sites.length > 0),
              first()
            )
            .subscribe(() => {
              this.store.dispatch(loadDefaultSite());
            });

          combineLatest([this.store.select(selectIsLoadingFutureBookings), this.store.select(selectSites)])
            .pipe(
              filter(([isLoading, sites]) => !isLoading && sites.length > 0),
              first()
            )
            .subscribe(() => {
              this.store.dispatch(loadRecommendedActivities());
            });
          this.store.dispatch(loadWalletAvailability());

          this.store
            .select(selectDefaultSite)
            .pipe(filterNull(), first())
            .subscribe((site) => {
              if (site) {
                this.store.dispatch(loadMerchantId({ siteId: site.id }));
              }
            });
        })
      ),
    { dispatch: false }
  );
}

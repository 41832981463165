/* eslint-disable @typescript-eslint/member-ordering */

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app-config.state';
import { catchError, exhaustMap, map, mergeMap, of, switchMap} from 'rxjs';
import { getAccountMemberId } from '../selectors/authentication.selectors';
import {
  WalletActionTypes,
  fetchSavedCardsFailure,
  setSavedCards,
  saveNewCard,
  saveNewCardSuccess,
  saveNewCardError,
  saveNewCardFromMyAccount,
  saveNewCardFromMyAccountSuccess,
} from '../actions/wallet.actions';
import { PaymentService, WalletService } from '@app/api/services';
import { SUCCESS_TOAST_CLASS, TOAST_ONLINE_ICON_NAME, TOAST_SUCCESSFULLY_SAVED_CARD } from '@app/core/constants';
import { navigateBack } from '../actions/navigation.actions';
import { displayInfoToast } from '../actions/notification-handling.actions';


@Injectable()
export class WalletEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly walletService: WalletService,
    private readonly paymentService: PaymentService,
    private readonly store$: Store<IAppState>,
  ) { }

  public fetchSavedCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.FetchSavedCards, saveNewCardSuccess),
      concatLatestFrom(() => this.store$.select(getAccountMemberId)),
      mergeMap(([, memeberId]) => this.walletService.fetchSavedCards(memeberId).pipe(
        map((savedCards) => setSavedCards({ savedCards })),
        catchError(() => of(fetchSavedCardsFailure()))
      )),
  ));

  public saveNewCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveNewCard),
      concatLatestFrom(() => this.store$.select(getAccountMemberId)),
      exhaustMap(([action, memberId]) => this.walletService.saveNewCard(action.paymentToken, memberId).pipe(
        map(() => saveNewCardSuccess()),
        catchError(() => of(saveNewCardError()))
      )),
    )
  );

  public saveNewCardFromMyAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveNewCardFromMyAccount),
      concatLatestFrom(() => this.store$.select(getAccountMemberId)),
      switchMap(([_, memberId]) => this.paymentService.getPaymentToken().pipe(
        exhaustMap((paymentToken) => this.walletService.saveNewCard(paymentToken, memberId).pipe(
          map(() => saveNewCardFromMyAccountSuccess()),
          catchError(() => of(saveNewCardError()))
        ))
      )),
    )
  );

  public saveNewCardFromMyAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveNewCardFromMyAccountSuccess),
      switchMap(() => [navigateBack(),
        displayInfoToast({
          message: TOAST_SUCCESSFULLY_SAVED_CARD,
          iconName: TOAST_ONLINE_ICON_NAME,
          toastClass: SUCCESS_TOAST_CLASS,
        })])
    )
  );
}

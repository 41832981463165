import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidateTenantService {
  constructor(private http: HttpClient) {}

  public validateTenant(): Promise<void> {
    return this.http
      .get(`${environment.apiUrl}/validate/tenant/valid`, {
        observe: 'response',
      })
      .toPromise()
      .then((response: HttpResponse<unknown>) => {
        if (response.status !== 200) {
          throw Error('');
        }
      })
      .catch(() => {
        if (window.location.origin.indexOf('localhost') < 0) {
          window.location.href = 'https://www.gladstonesoftware.co.uk';
        }
      });
  }
}

/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  setSessionSearchFilters,
  setSelectedResourceById,
  setSelectedDateDots,
  loadedDefaultSiteSuccess,
  loadedRecommendedActivitiesSuccess,
  loadRecommendedActivities,
  loadedRecommendedActivitiesFail,
  loadActivityDetailsSuccess,
  loadActivityDetails,
  setPromotedTagId,
  loadSessionPriceSuccess,
  loadSessionPrice,
  searchActivities,
  searchActivitiesFail,
  searchActivitiesSuccess,
  loadActivitiesByTagGroupSuccess,
  loadActivitiesByTagGroupFail,
  searchActivitiesByTag,
  searchActivitiesByTagFail,
  searchActivitiesByTagSuccess,
  cancelSearchActivitiesByTag,
  loadSiteDetailsFail,
  loadSiteDetailsSuccess,
} from '@store/actions/book.actions';
import { SessionModel } from '@app/core/models/session.model';
import { clearSelectedChoices, clearSlotPrices, resetSessionList } from './../actions/book.actions';
import { ISiteState } from '@store/state/app-config.state';
import {
  loadSites,
  loadSessions,
  loadSessionsFail,
  changeStateOfIsSearchingSite,
  loadSlotPriceSuccess,
  loadSlotPrice,
} from './../actions/book.actions';
import { timeWasSetByTheUser } from '@store/actions/book.actions';
import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';
import { compareAsc, isToday } from 'date-fns';
import { BookDatePickerState, BookTimePickerState, ISessionState } from '@store/state/app-config.state';
import { IActivityState } from '@store/state/app-config.state';
import {
  loadActivitiesSuccess,
  setSelectedActivity,
  loadActivities,
  openSearchActivityModal,
  setSelectedCentre,
  loadSitesSuccess,
  loadSitesFail,
  setCentreSearchFilter,
  setTimeBoundaries,
  setDateBoundaries,
  loadActivitiesFail,
  loadSessionsSuccess,
  setSelectedSlot,
  clearSelectedSlot,
} from '@store/actions/book.actions';
import { changeTab } from '../actions/app-config.actions';
import { createReducer, on } from '@ngrx/store';
import { addBasketItemSuccess } from '../actions/basket.actions';
import { Activity } from '@app/core/models/activity.model';
import { Site } from '@app/core/models/site.model';
import { SiteDTO } from '@app/api/dtos/site.dto';

const sitesReducerCreator = createReducer(
  {
    sites: [],
    searchFilter: '',
    selectedSite: undefined,
    errorOnGettingSites: false,
    isSearchingSite: false,
    isSingleSite: false,
    defaultSite: undefined,
  },
  on(
    loadSites,
    (state): ISiteState => ({
      ...state,
      isSearchingSite: true,
    })
  ),
  on(
    changeStateOfIsSearchingSite,
    (state, data): ISiteState => ({
      ...state,
      isSearchingSite: data.isSearching,
    })
  ),
  on(
    loadSitesSuccess,
    (state, data): ISiteState => ({
      ...state,
      sites: data.sites,
      errorOnGettingSites: false,
      isSearchingSite: false,
      isSingleSite: data.sites.length === 1,
      selectedSite: data.sites.length === 1 ? data.sites[0] : state.selectedSite,
    })
  ),
  on(
    loadSitesFail,
    (state): ISiteState => ({
      ...state,
      sites: [],
      errorOnGettingSites: true,
      isSearchingSite: false,
    })
  ),
  on(loadSiteDetailsSuccess, (state, data): ISiteState => {
    const updatedSites: SiteDTO[] = state.sites.map((site) => {
      if (site.id === data.site.id) {
        const mergedSite = {
          ...site,
          ...data.site,
        };

        return mergedSite;
      } else {
        return site;
      }
    });
    return {
      ...state,
      sites: updatedSites,
    };
  }),
  on(
    loadSiteDetailsFail,
    (state): ISiteState => ({
      ...state,
    })
  ),
  on(
    setCentreSearchFilter,
    (state, data): ISiteState => ({
      ...state,
      searchFilter: data.searchFilter,
    })
  ),
  on(
    setSelectedCentre,
    (state, data): ISiteState => ({
      ...state,
      selectedSite: data.site,
    })
  ),
  on(
    changeTab,
    (state): ISiteState => ({
      ...state,
      searchFilter: '',
    })
  ),
  on(
    setSessionSearchFilters,
    (state: ISiteState, data): ISiteState => ({
      ...state,
      selectedSite: state.sites.find((site) => site.id === data.parameters.siteId),
    })
  ),
  on(loadedDefaultSiteSuccess, (state: ISiteState, data) => ({
    ...state,
    defaultSite: data.site,
  }))
);
const initialActivitiesState = {
  activitiesGroupedByName: {},
  activityNames: [],
  activityTagGroup: [],
  selectedActivity: undefined,
  searchText: '',
  isSearchingActivity: false,
  searchActivityTagId: null,
  isSearchingActivityByTagId: false,
  errorOnGettingActivities: false,
};
const _activitiesReducer = createReducer(
  initialActivitiesState,
  on(
    loadActivities,
    (state, data): IActivityState => ({
      ...state,
    })
  ),
  on(
    loadActivitiesSuccess,
    (state, data): IActivityState => ({
      ...state,
      activitiesGroupedByName: data.activities,
      activityNames: Object.keys(data.activities),
      isSearchingActivity: false,
      errorOnGettingActivities: false,
    })
  ),
  on(
    loadActivitiesFail,
    (state): IActivityState => ({
      ...state,
      activitiesGroupedByName: {},
      activityNames: [],
      isSearchingActivity: false,
      errorOnGettingActivities: true,
    })
  ),
  on(
    searchActivities,
    (state, data): IActivityState => ({
      ...state,
      searchText: data.searchText,
      isSearchingActivity: data.searchText.length !== 0,
    })
  ),
  on(
    searchActivitiesSuccess,
    (state, data): IActivityState => ({
      ...state,
      activitiesGroupedByName: data.activities,
      activityNames: Object.keys(data.activities),
      isSearchingActivity: false,
      errorOnGettingActivities: false,
    })
  ),
  on(
    searchActivitiesFail,
    (state): IActivityState => ({
      ...state,
      activitiesGroupedByName: {},
      activityNames: [],
      isSearchingActivity: false,
      errorOnGettingActivities: true,
    })
  ),
  on(
    loadActivitiesByTagGroupSuccess,
    (state, data): IActivityState => ({
      ...state,
      activityTagGroup: data.activityTagGroup,
    })
  ),
  on(
    loadActivitiesByTagGroupFail,
    (state): IActivityState => ({
      ...state,
    })
  ),
  on(
    searchActivitiesByTag,
    (state, data): IActivityState => ({
      ...state,
      searchActivityTagId: data.tagId,
      isSearchingActivityByTagId: true,
    })
  ),
  on(
    searchActivitiesByTagSuccess,
    (state, data): IActivityState => ({
      ...state,
      activitiesGroupedByName: data.activities,
      activityNames: Object.keys(data.activities),
      isSearchingActivityByTagId: false,
      errorOnGettingActivities: false,
    })
  ),
  on(
    searchActivitiesByTagFail,
    cancelSearchActivitiesByTag,
    (state): IActivityState => ({
      ...state,
      activitiesGroupedByName: {},
      activityNames: [],
      isSearchingActivity: false,
      searchActivityTagId: null,
      isSearchingActivityByTagId: false,
      errorOnGettingActivities: true,
    })
  ),
  on(
    setSelectedActivity,
    (state, data): IActivityState => ({
      ...state,
      selectedActivity: data.activity,
    })
  ),
  on(
    openSearchActivityModal,
    (state): IActivityState => ({
      ...state,
      activitiesGroupedByName: {},
      activityNames: [],
      searchText: '',
      errorOnGettingActivities: false,
      isSearchingActivity: false,
    })
  ),
  on(
    changeTab,
    (state): IActivityState => ({
      ...state,
      activitiesGroupedByName: {},
      activityNames: [],
      searchText: '',
      isSearchingActivity: false,
      selectedActivity: undefined,
    })
  ),
  on(
    clearSelectedChoices,
    (): IActivityState => ({
      ...initialActivitiesState,
    })
  ),
  on(
    setSessionSearchFilters,
    (state: IActivityState, data): IActivityState => ({
      ...state,
      selectedActivity: {
        activityIdsBySiteId: {
          [data.parameters.siteId]: data.parameters.activityId,
        },
        name: data.parameters.activityName,
      } as Activity,
    })
  )
);

const initialTimeState = {
  startTime: TimeUtilitiesHelper.dateToTimeString(TimeUtilitiesHelper.getCurrentTimeRounded()),
  endTime: '23:59',
  timeIsSet: false,
};

const timeReducerCreator = createReducer(
  initialTimeState,
  on(
    setTimeBoundaries,
    (state, data): BookTimePickerState => ({
      ...state,
      startTime: TimeUtilitiesHelper.dateToTimeString(data.startTime),
      endTime: TimeUtilitiesHelper.dateToTimeString(data.endTime),
    })
  ),
  on(
    timeWasSetByTheUser,
    (state, data): BookTimePickerState => ({
      ...state,
      timeIsSet: data.shouldModify,
    })
  ),
  on(changeTab, (state) => ({
    ...state,
    startTime: TimeUtilitiesHelper.dateToTimeString(TimeUtilitiesHelper.getCurrentTimeRounded()),
    endTime: '23:59',
    timeIsSet: false,
  })),
  on(
    clearSelectedChoices,
    (): BookTimePickerState => ({
      ...initialTimeState,
    })
  ),
  on(
    setSessionSearchFilters,
    (state: BookTimePickerState, data): BookTimePickerState => ({
      ...state,
      startTime: TimeUtilitiesHelper.dateToTimeString(data.parameters.startTime),
      endTime: TimeUtilitiesHelper.dateToTimeString(data.parameters.endTime),
      timeIsSet: true,
    })
  )
);

const initialDateState = {
  startDate: new Date().toDateString(),
  endDate: new Date().toDateString(),
  isCurrentDate: true,
  selectedDateDots: [new Date().toDateString()],
};

const dateReducerCreator = createReducer(
  initialDateState,
  on(
    setDateBoundaries,
    (state, data): BookDatePickerState => ({
      ...state,
      startDate: data.startDate,
      endDate: data.endDate,
      isCurrentDate:
        compareAsc(new Date(data.startDate), new Date(data.endDate)) === 0 && isToday(new Date(data.startDate)),
      selectedDateDots: TimeUtilitiesHelper.eachDayInterval(data.startDate, data.endDate).map((date) =>
        date.toDateString()
      ),
    })
  ),
  on(changeTab, (state) => ({
    ...initialDateState,
  })),
  on(
    clearSelectedChoices,
    (): BookDatePickerState => ({
      ...initialDateState,
    })
  ),
  on(
    setSessionSearchFilters,
    (state: BookDatePickerState, data): BookDatePickerState => ({
      ...state,
      startDate: data.parameters.startTime,
      endDate: data.parameters.endTime,
      isCurrentDate:
        compareAsc(new Date(data.parameters.startTime), new Date(data.parameters.endTime)) === 0 &&
        isToday(new Date(data.parameters.startTime)),
      selectedDateDots: TimeUtilitiesHelper.eachDayInterval(data.parameters.startTime, data.parameters.endTime).map(
        (date) => date.toDateString()
      ),
    })
  ),
  on(setSelectedDateDots, (state: BookDatePickerState, data) => ({
    ...state,
    selectedDateDots: data.dateDots.map((dateDot) => dateDot.toDateString()),
  }))
);

const sessionsReducerCreator = createReducer(
  {
    slotsByActivityId: {},
    allSlotsByResourceGroup: {},
    isSearchingSessions: false,
    selectedSlot: undefined,
    previouslySelectedSlot: undefined,
    slotsWithPrices: {},
    selectedResource: undefined,
    selectedActivityDetails: undefined,
    isLoadingActivityDetails: false,
    slotPriceLevels: undefined,
    slotPriceQuote: undefined,
    additionalSlots: {},
  },
  on(loadSessions, (state: ISessionState) => ({
    ...state,
    isSearchingSessions: true,
    isLoadingPrices: true,
  })),
  on(loadSessionsSuccess, (state: ISessionState, data) => ({
    ...state,
    slotsByActivityId: { ...data.slotsByActivityId },
    allSlotsByResourceGroup: { ...data.allSlotsGroupedInOneSession },
    additionalSlots: { ...data.additionalSlotsGroupedInOneSession },
    isSearchingSessions: false,
  })),
  on(loadSessionsFail, (state: ISessionState) => ({
    ...state,
    isSearchingSessions: false,
  })),
  on(
    changeTab,
    (state): ISessionState => ({
      ...state,
      slotsByActivityId: { ...state.slotsByActivityId },
    })
  ),
  on(setSelectedSlot, (state: ISessionState, data) => ({
    ...state,
    selectedSlot: data.slot,
  })),
  on(loadActivityDetails, (state: ISessionState) => ({
    ...state,
    isLoadingActivityDetails: true,
  })),
  on(loadActivityDetailsSuccess, (state: ISessionState, data) => ({
    ...state,
    selectedActivityDetails: data.activityDetails,
    isLoadingActivityDetails: false,
  })),
  on(loadActivitiesFail, (state: ISessionState) => ({
    ...state,
    isLoadingActivityDetails: false,
  })),
  on(setSelectedResourceById, (state: ISessionState, data) => ({
    ...state,
    selectedResource: Object.values(state.slotsByActivityId).find((session: SessionModel) =>
      Object.keys(session.resourceGroups).includes(data.resourceId)
    )?.resourceGroups[data.resourceId],
  })),
  on(addBasketItemSuccess, (state: ISessionState) => ({
    ...state,
    selectedSlot: {
      ...state.selectedSlot,
      availability: {
        inCentre: state.selectedSlot.availability.inCentre - 1,
        virtual: state.selectedSlot.availability.virtual,
      },
    },
  })),
  on(loadSessionPrice, (state, data) => ({
    ...state,
    slotsWithPrices: {
      ...state.slotsWithPrices,
      [data.slot.slotReferences.inCentre]: { isLoading: true },
    },
  })),
  on(loadSlotPriceSuccess, (state, data) => ({
    ...state,
    slotPriceQuote: data.pricing,
  })),
  on(loadSessionPriceSuccess, (state, data) => ({
    ...state,
    slotPriceLevels: data.priceInformation,
    slotsWithPrices: {
      ...state.slotsWithPrices,
      [data.slot.slotReferences.inCentre]: {
        isLoading: false,
        price: data.priceInformation,
      },
    },
  })),

  on(clearSlotPrices, (state: ISessionState) => ({
    ...state,
    slotsWithPrices: {},
  })),
  on(resetSessionList, (state: ISessionState) => ({
    ...state,
    slotsByActivityId: { ...state.slotsByActivityId },
  })),
  on(clearSelectedSlot, (state: ISessionState) => ({
    ...state,
    previouslySelectedSlot: { ...state.selectedSlot },
    selectedSlot: undefined,
  }))
);

export const recommendedActivitiesReducer = createReducer(
  {
    promotedTagId: null,
    recommendedActivities: [],
    isLoadingRecommendedActivities: false,
  },
  on(loadRecommendedActivities, (state) => ({
    ...state,
    recommendedActivities: [],
    isLoadingRecommendedActivities: true,
  })),
  on(loadedRecommendedActivitiesSuccess, (state, data) => ({
    ...state,
    recommendedActivities: data.recommendedActivities,
    isLoadingRecommendedActivities: false,
  })),
  on(loadedRecommendedActivitiesFail, (state) => ({
    ...state,
    isLoadingRecommendedActivities: false,
  })),
  on(setPromotedTagId, (state, data) => ({
    ...state,
    promotedTagId: data.promotedTagId,
  }))
);

export function sitesReducer(state, action) {
  return sitesReducerCreator(state, action);
}

export function activitiesReducer(state, action) {
  return _activitiesReducer(state, action);
}

export function sessionsReducer(state, action) {
  return sessionsReducerCreator(state, action);
}

export function bookTimePickerReducer(state, action) {
  return timeReducerCreator(state, action);
}

export function bookDatePickerReducer(state, action) {
  return dateReducerCreator(state, action);
}

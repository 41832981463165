/* eslint-disable @typescript-eslint/naming-convention */
import { CardDetails } from '@app/core/models/card-details.model';
import { createAction, props } from '@ngrx/store';

export enum WalletActionTypes {
  FetchSavedCards = '[Wallet] Fetch Saved Cards',
  FetchSavedCardsFailure = '[Wallet] Fetch Saved Cards Failure',
  SetSavedCards = '[Wallet] Set Saved Cards',
  SetCurrentSelectedCard = '[Wallet] Set Current Selected Card',
  SaveNewCard = '[Wallet] Save New Card',
  SaveNewCardSuccess = '[Wallet] Save New Card Success',
  SaveNewCardError = '[Wallet] Save New Card Error',
  SaveNewCardFromMyAccount = '[Wallet] Save card from my account',
  SaveNewCardFromMyAccountSuccess = '[Wallet] Save card from my account Success',
  SaveNewCardFromMyAccountError = '[Wallet] Save card from my account Error',
}

export const fetchSavedCards = createAction(WalletActionTypes.FetchSavedCards);
export const fetchSavedCardsFailure = createAction(WalletActionTypes.FetchSavedCardsFailure);
export const setSavedCards = createAction(WalletActionTypes.SetSavedCards, props<{ savedCards: CardDetails[] }>());
export const setCurrentSelectedCard = createAction(WalletActionTypes.SetCurrentSelectedCard, props<{ currentSelectedCard: CardDetails }>());
export const saveNewCard = createAction(WalletActionTypes.SaveNewCard, props<{ paymentToken: string }>());
export const saveNewCardSuccess = createAction(WalletActionTypes.SaveNewCardSuccess);
export const saveNewCardError = createAction(WalletActionTypes.SaveNewCardError);
export const saveNewCardFromMyAccount = createAction(WalletActionTypes.SaveNewCardFromMyAccount);
export const saveNewCardFromMyAccountSuccess = createAction(WalletActionTypes.SaveNewCardFromMyAccountSuccess);
export const saveNewCardFromMyAccountError = createAction(WalletActionTypes.SaveNewCardFromMyAccountError);

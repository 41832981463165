/* eslint-disable radix */
import { Injectable } from '@angular/core';
import {
  handleOfflineIdentifier,
  handleShortIdentifier,
  resetEntryTimerTarget
} from '@app/store/actions/entry.actions';
import { Store } from '@ngrx/store';
import { addHours, addMilliseconds, addSeconds, addYears, isPast, subSeconds } from 'date-fns';
import { EntryIdentifierTypes, SHORT_IDENTIFIER_REFRESH_RATE } from '../constants';
import { Identifier } from '../models/identifier.model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IdentifiersService {
  private shortIdentifierInterval;

  constructor(private store: Store) {}

  static isIdentifierExpired(identifier: Identifier): boolean {
    if (!identifier) {
      return true;
    }
    return isPast(subSeconds(new Date(identifier.expirationDate), 1));
  }

  static isPrimaryIdentifierValidToUse(identifier: Identifier): boolean {
    const expiryTime = parseInt(environment.qrPermanentTokenExpiryTime);
    const expirationDateWithExpiry = addSeconds(new Date(identifier.expirationDate), expiryTime * 3600);
    return !isPast(subSeconds(expirationDateWithExpiry, 1));
  }

  refreshIdentifiers(store: Store): void {
    store.dispatch(handleShortIdentifier({ delete: true, create: true }));
    store.dispatch(handleOfflineIdentifier({ delete: true, create: true }));
    store.dispatch(
      resetEntryTimerTarget({ target: addMilliseconds(new Date(), SHORT_IDENTIFIER_REFRESH_RATE).toISOString() })
    );
  }

  startIdentifierRefreshLoop(): void {
    clearInterval(this.shortIdentifierInterval);
    this.refreshIdentifiers(this.store);
    this.shortIdentifierInterval = setInterval(this.refreshIdentifiers, SHORT_IDENTIFIER_REFRESH_RATE, this.store);
  }

  stopIdentifierRefreshLoop(): void {
    this.store.dispatch(handleShortIdentifier({ delete: true, create: false }));
    clearInterval(this.shortIdentifierInterval);
  }
}

// This component was reused from signify repo
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ThreeDSecureChallengeFields } from '@app/api/dtos/payment.dto';
import { filterNull } from '@app/core/helpers/filter-null';
import { BehaviorSubject, fromEvent, interval, ReplaySubject } from 'rxjs';
import { delay, first, map, takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  templateUrl: './three-d-secure-modal.component.html',
  styleUrls: ['./three-d-secure-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreeDSecureModalComponent implements AfterViewInit, OnDestroy {
  @Input() public threeDSecureChallengeUrl: string;

  private _threeDSecureChallengeFields: ThreeDSecureChallengeFields;
  @Input() set threeDSecureChallengeFields(value: ThreeDSecureChallengeFields) {
    this._threeDSecureChallengeFields = value;
  }
  get threeDSecureChallengeFields(): ThreeDSecureChallengeFields {
    if (this.isFrictionless) {
      this.cancelTimer = true;
      this.cancelTimer = false;
      this.interval();
    } else {
      this.cancelTimer;
    }
    return this._threeDSecureChallengeFields;
  }

  @Output() public handleThreeDSecureChallengeResponse: EventEmitter<string> = new EventEmitter();

  @ViewChild('threeDSecureIframe') public threeDSecureIframe: ElementRef<HTMLIFrameElement>;

  public threeDSecureIframeVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private onDestroy$: ReplaySubject<void> = new ReplaySubject();

  cancelTimer = true;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  private interval() {
    const intervalDuration = 10000; // 10 second timeout
    interval(intervalDuration)
      .pipe(
        takeUntil(this.onDestroy$),
        takeWhile(() => !this.cancelTimer),
      )
      .subscribe(() => {
        this.threeDSecureIframeVisible$.next(false);
        this.handleThreeDSecureChallengeResponse.emit('threeDSMethodData=timeout');
        this.changeDetectorRef.detectChanges();
      });
  }

  public ngAfterViewInit(): void {
    if (!this.threeDSecureChallengeUrl || !this.threeDSecureChallengeFields) {
      console.error('ThreeDSecureModalComponent not passed threeDSecureChallengeUrl || threeDSecureChallengeFields');
      return;
    }
    this.listenForThreeDSecureChallengeLoad();
    this.listenForThreeDSecureChallengeResponse();
    this.loadThreeDSecureChallenge();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadThreeDSecureChallenge(): void {
    const threeDSecureForm: string = `
      <form
        style="display: none; width: auto"
        id="threeDSChallengeForm"
        action="${this.threeDSecureChallengeUrl}"
        method="POST"
        target="_self"
      >
        ${Object.entries(this.threeDSecureChallengeFields)
          .map(
            ([key, value]: string[]) => `
            <input
              type="hidden"
              name="${key}"
              value="${value}"
            />`,
          )
          .join('')}
      </form>
    `;
    this.threeDSecureIframe.nativeElement.contentDocument.body.innerHTML = threeDSecureForm;
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.appendChild(document.createTextNode('document.forms.threeDSChallengeForm.submit();'));
    this.threeDSecureIframe.nativeElement.contentDocument.body.appendChild(scriptElement);
  }

  private listenForThreeDSecureChallengeLoad(): void {
    fromEvent(this.threeDSecureIframe.nativeElement, 'load')
      .pipe(first(), delay(300), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.threeDSecureIframeVisible$.next(true);
        this.changeDetectorRef.detectChanges();
      });
  }

  private get isFrictionless() {
    return !!this._threeDSecureChallengeFields?.threeDSMethodData;
  }

  private listenForThreeDSecureChallengeResponse(): void {
    fromEvent(window, 'message')
      .pipe(
        map((message: MessageEvent) => message?.data?.threeDSecureChallengeResponse),
        filterNull(),
        takeUntil(this.onDestroy$),
      )
      .subscribe((threeDSecureChallengeResponse: string) => {
        this.cancelTimer = true;
        this.threeDSecureIframeVisible$.next(false);
        this.handleThreeDSecureChallengeResponse.emit(threeDSecureChallengeResponse);
        this.changeDetectorRef.detectChanges();
      });
  }
}

import { DATES_CONTAINER_PADDING, DATE_DOT_WIDTH, SKELETON_ITEMS_MARGIN_PX } from '@core/constants';

export const nrOfFittingDots = (): number => {
  const containerWidth = window.innerWidth - DATES_CONTAINER_PADDING;
  if (containerWidth === 0) {
    return 0;
  }
  return Math.max(0,Math.floor(containerWidth / DATE_DOT_WIDTH));
};

export const nrOfElementsThatFitVertically = (heightOfAnElement: number, containerReference: HTMLElement): number => {
  heightOfAnElement += SKELETON_ITEMS_MARGIN_PX;
  const containerHeight = containerReference?.offsetHeight;
  if (!containerHeight) {
    return 0;
  }
  return Math.max(0,Math.floor((containerHeight) / heightOfAnElement));
};

export const nrOfElementsThatFitHorizontally = (widthOfAnElement: number, containerReference: HTMLElement): number => {
  const containerWidth = containerReference?.offsetWidth;
    if (!containerWidth) {
      return 0;
    }
    return Math.floor(containerWidth / widthOfAnElement + 1);
};

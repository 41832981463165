import { ResourceGroup } from '@app/core/models/session.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AccesibilityHelper } from '@app/core/helpers/accessibility';
import { lighten } from 'color2k';

@Component({
  selector: 'gs-resource-groups-segment',
  templateUrl: './resource-groups-segment.component.html',
  styleUrls: ['./resource-groups-segment.component.scss'],
})
export class ResourceGroupsSegmentComponent implements OnInit {
  @Input() resourceGroupsList: ResourceGroup[];
  @Output() segmentChangedEmitter = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

  segmentChanged(resourceGroupId) {
    this.segmentChangedEmitter.emit(resourceGroupId.detail.value);
  }

  isFontColorBlack(): boolean {
    const element = document.getElementById('toolbar');
    return element ? AccesibilityHelper.shouldElementFontColorBeBlack(element) : true;
  }

}

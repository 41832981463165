import { changeNetworkStatus, setIsAppActive } from './../actions/platform.actions';
import { createReducer, on } from '@ngrx/store';
import { setIsKeyboardOpen } from '../actions/platform.actions';

export const plaftformReducer = createReducer({
    isKeyboardOpen: false,
    hasInternetConnection: true,
    isAppActive: true
  },
  on(setIsKeyboardOpen, (state, data)=>({
    ...state,
    isKeyboardOpen: data.isKeyboardOpen
  })),
  on(changeNetworkStatus, (state, data) => ({
    ...state,
    hasInternetConnection: data.networkStatus
  })),
  on(setIsAppActive, (state, data) => ({
    ...state,
    isAppActive: data.isAppActive
  }))
);

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gs-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
})
export class DateTimeComponent implements OnInit {
  @Input() startTime: string;
  @Input() endTime: string;

  dateFormat = 'do MMMM';
  timeFormat = 'HH:mm';

  constructor() { }

  ngOnInit() {}

}

import { initiateBiometricLogin } from '@store/actions/authentication.actions';
import { BiometricService } from '@core/services/biometric.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  Login,
  SetAuthenticationRedirectUrl,
} from '@app/store/actions/authentication.actions';
import { isAuthenticated } from '@app/store/selectors/authentication.selectors';
import { IAppState } from '@app/store/state/app-config.state';
import { select, Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { AuthService } from './authentication.service';
import { environment } from '@environments/environment';
import { isAnIdentifierValidForEntry } from '@app/store/selectors/entry.selectors';
import { selectHasInternetConnection } from '@app/store/selectors/platform.selectors';

@Injectable({
  providedIn: 'root',
})
export class EntryGuard implements CanActivate {
  constructor(
    private readonly store: Store<IAppState>,
    private oAuthService: AuthService,
    private biometricService: BiometricService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return combineLatest([
      this.store.pipe(select(isAnIdentifierValidForEntry)),
      this.store.pipe(select(isAuthenticated)),
      this.store.pipe(select(selectHasInternetConnection)),
    ]).pipe(map(([isIdentifierValid, authenticated, hasInternetConnection]) => {
      if ((!hasInternetConnection && !isIdentifierValid) || (!authenticated && hasInternetConnection)) {
        this.store.dispatch(new SetAuthenticationRedirectUrl(state.url));
        const platform = Capacitor.getPlatform();
        if (
          (platform === 'ios' || platform === 'android') &&
          this.oAuthService.hasRefreshToken() &&
          environment.useBiometric
        ) {
          this.store.dispatch(initiateBiometricLogin());
        } else {
          this.store.dispatch(new Login());
        }
        return false;
      }
      return true;
    }));
  }
}

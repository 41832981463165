import { createAction, props } from '@ngrx/store';

export const SET_IS_KEYBOARD_OPEN = '[Platform] Set is keyboard open';
export const CHANGE_NETWORK_STATUS = '[Network status] Device changed its connectivity status';
export const SET_IS_APP_ACTIVE = '[Platform] Set is app active';

export const setIsKeyboardOpen = createAction(SET_IS_KEYBOARD_OPEN, props<{isKeyboardOpen: boolean}>());
export const changeNetworkStatus = createAction(
  CHANGE_NETWORK_STATUS,
  props<{networkStatus: boolean}>()
);
export const setIsAppActive = createAction(SET_IS_APP_ACTIVE, props<{isAppActive: boolean}>());

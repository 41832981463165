import { PreviousBookingDTO } from '@api/dtos/previous-booking.dto';
import { PreviousBooking } from '@core/models/previous-booking.model';
import { AdditionalBookeesBasketItem, BasketItem } from '@core/models/basket-item.model';
import { FutureBooking, PriceLevel } from '@core/models/future-booking.model';
import { Member } from '@core/models/member.model';
import { BookingLeaseDTO, LeaseMemberDTO } from '../dtos/booking-lease.dto';
import { FutureBookingDTO } from '@api/dtos/future-booking.dto';
import { AdditionalBookees } from '@app/core/models/session.model';

export class BasketMapper{
  static mapToBookingLeaseDTO(basketItem: BasketItem, member: Member): BookingLeaseDTO {
    return {
      id: basketItem.leaseId,
      slotReference: basketItem.slotReference,
      additionalBookees: basketItem.additionalBookees.length === 0 ? undefined : basketItem.additionalBookees,
      member: {
        firstName: member.firstName,
        lastName: member.lastName,
        id: member.memberId,
        priceLevelId: member.priceLevelId,
        attending: basketItem.isMemberAttending
      } as LeaseMemberDTO
    } as BookingLeaseDTO;
  }

  static mapToFutureBooking(bookingDTOs: FutureBookingDTO[]): FutureBooking[] {
    return bookingDTOs.map((bookingDTO: FutureBookingDTO): FutureBooking => ({
      bookingId: bookingDTO.bookingId,
      activityId: bookingDTO.activityId,
      activityName: bookingDTO.activityName,
      siteId: bookingDTO.siteId,
      startTime: bookingDTO.dateTime,
      bookeePriceLevels: bookingDTO.bookeePriceLevels,
      bookingRef: bookingDTO.bookingRef,
      inCentre: bookingDTO.inCentre,
      slotReference: bookingDTO.slotReference,
      location: bookingDTO.location,
      isMemberAttending: bookingDTO.keyBookeeIncludedInd
    } as FutureBooking));
  }

  static mapToPreviousBooking(bookingDTOs: PreviousBookingDTO[]): PreviousBooking[] {
    return bookingDTOs.map((bookingDTO: PreviousBookingDTO): PreviousBooking => ({
      bookingId: bookingDTO.bookingId,
      activityId: bookingDTO.activityId,
      activityName: bookingDTO.activityName,
      siteId: bookingDTO.siteId,
      startTime: bookingDTO.startDateTime,
      endTime: bookingDTO.endDateTime,
      bookingRef: bookingDTO.bookingRef
    } as PreviousBooking));
  }

  static mapBasketBookeeToAdditionalBookees(basketAdditionalBookee: AdditionalBookeesBasketItem): AdditionalBookees {
    return {
      count: basketAdditionalBookee.count,
      priceLevels: {
        id: basketAdditionalBookee.priceLevelId,
        price: {inCentre: basketAdditionalBookee.price},
        description: undefined
      }
    };
  }

  static mapAdditionalBookeeToBasketBookees(additionalBookee: AdditionalBookees): AdditionalBookeesBasketItem {
    return {
      count: additionalBookee.count,
      price: additionalBookee.priceLevels.price.inCentre,
      priceLevelId: additionalBookee.priceLevels.id
    };
  }


  static mapPriceLevelToAdditionalBookees(priceLevel: PriceLevel): AdditionalBookees {
    return {
      count: priceLevel.count,
      priceLevels: {
        id: priceLevel.id,
        price: {inCentre: priceLevel.price},
        description: priceLevel.description
      }
    };
  }
}


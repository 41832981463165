import { IAppState } from '@store/state/app-config.state';
import { createAction, props } from '@ngrx/store';

export const HYDRATE_STORE = '[Hydration] Hydrate';
export const HYDRATED_STORE_SUCCESS = '[Hydration] Hydrate Success';
export const HYDRATED_STORE_FAIL = '[Hydration] Hydrate Failure';
export const SAVE_STATE = '[Store Persistance] Save state of store';
const LOAD_DATA_AFTER_NETWORK_CONNECTION = '[Network] Load data after coming back online';
const REAUTH_POST_NETWORK_CONNECTION = '[Authentication] Reauthenticate after coming back online';
export const SAVED_STATE_SUCCESSFULLY = '[Store Persistance] Saved state of store successfully';

export const hydrate = createAction(HYDRATE_STORE);

export const hydrateSuccess = createAction(
  HYDRATED_STORE_SUCCESS,
  props<{ state: IAppState }>()
);

export const hydrateFailure = createAction(HYDRATED_STORE_FAIL);

export const saveStateOfStore = createAction(SAVE_STATE, props<{state: IAppState}>());

export const loadDataAfterNewtorkConnection = createAction(LOAD_DATA_AFTER_NETWORK_CONNECTION);

export const reauthPostNetworkConnection = createAction(REAUTH_POST_NETWORK_CONNECTION);

export const savedStateSuccessfully = createAction(SAVED_STATE_SUCCESSFULLY);


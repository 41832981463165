import { Injectable } from '@angular/core';
import {
  InAppBrowser,
  InAppBrowserObject,
} from '@awesome-cordova-plugins/in-app-browser/ngx';
import { takeWhile } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InAppBrowserService {
  private browserInstance: InAppBrowserObject = undefined;
  constructor(private iab: InAppBrowser) {}

  public initAppBrowserForLogin(uri: string, options: string): void {
    this.browserInstance = this.iab.create(uri, '_blank', options);
    this.browserInstance.on('loadstop').subscribe(() => {
      this.browserInstance.insertCSS({
        code: 'div.mt-2{display:none !important;}',
      });
    });
    this.browserInstance
      .on('loadstart')
      .pipe(takeWhile((event) => !event.url.includes('code='), true))
      .subscribe((event) => {
        const url = event.url;
        if (url.includes('code=')) {
          this.closeBrowserIfExists();
          window?.location.assign(url);
        }
      });
  }

  public closeBrowserIfExists(): void {
    if (this.browserInstance === undefined) {
      return;
    }
    this.browserInstance.close();
    this.browserInstance = undefined;
  }
}

import { AuthenticationState, IBasketState } from './../state/app-config.state';
import { createSelector } from '@ngrx/store';
import { IAppConfigState, IAppState } from '../state/app-config.state';
import { ColorThemes } from '@app/core/constants';
import { selectBasketItemsCount } from './basket.selectors';
import { LanguagePersonalisation } from '@app/core/models/language.model';

const _appConfigState = (state: IAppState) => state.appConfig;
const _basketItemsState = (state: IAppState) => state.basket;

export const selectAppConfig = createSelector(_appConfigState, (appConfigState: IAppConfigState) => appConfigState);

export const selectFooterStatus = createSelector(
  _appConfigState,
  (appConfigState: IAppConfigState) => appConfigState.isFooterVisible
);

export const selectSelectedLanguage = createSelector(
  _appConfigState,
  (appConfigState: IAppConfigState): LanguagePersonalisation => ({
    language: appConfigState.selectedLanguage,
    personalisationId: appConfigState.languagePersonalisationId,
  })
);

export const selectLanguages = createSelector(
  _appConfigState,
  (appConfigState: IAppConfigState) => appConfigState.languages
);

export const selectTabProps = createSelector(
  _appConfigState,
  _basketItemsState,
  selectBasketItemsCount,
  (appConfigState: IAppConfigState, basketItemState: IBasketState, basketItemsCount: number) => [
    ...appConfigState.tabProps.map((tabProp) =>
      tabProp.navRoute === '/basket'
        ? {
            ...tabProp,
            badgeValue: basketItemsCount,
          }
        : tabProp
    ),
  ]
);

export const selectPreferredColorTheme = createSelector(_appConfigState, (state: IAppConfigState) => state.colorTheme);
export const selectComputedColorTheme = createSelector(_appConfigState, (state: IAppConfigState): 'light' | 'dark' => {
  if (state.colorTheme === ColorThemes.system) {
    return state.systemColorTheme;
  } else {
    return state.colorTheme;
  }
});

export const selectIsWalletEnabled = createSelector(_appConfigState, (state: IAppConfigState) => state.isWalletEnabled);
export const selectMerchantId = createSelector(_appConfigState, (state: IAppConfigState) => state.merchantId.value);

export const showLanguagesOption = createSelector(
  _appConfigState,
  selectSelectedLanguage,
  (appConfigState: IAppConfigState, selectedLanguage: LanguagePersonalisation) =>
    selectedLanguage.language === null && appConfigState.languages.length > 1
);

import { PhoneNumber, ContactDetails } from '@app/core/models/contact-details.model';
import { LANGUAGE_PERSONALISATION_KEY, MEMBER_CONTACT_DETAILS_ENDPOINT, MEMBER_PERSONALISATION_ENDPOINT } from '../../core/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  MEMBER_PROFILE_ENDPOINT,
} from 'src/app/core/constants';
import { environment } from 'src/environments/environment';
import { Member, PersonalDetails } from '../../core/models/member.model';
import { ContactDetailsDTO } from '../dtos/contact-details.dto';
import { MemberDTO, MemberPersonalisationDTO } from '../dtos/member.dto';
import { MemberMapper } from '../mappers/member.mapper';
import { LanguagePersonalisation } from '@app/core/models/language.model';

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  constructor(private readonly http: HttpClient) {}

  public getContactDetails(memberId: number): Observable<ContactDetails> {
    const endpoint = `${environment.apiUrl}${MEMBER_CONTACT_DETAILS_ENDPOINT}`.replace('{memberId}', `${memberId}`);
    return this.http.get<ContactDetailsDTO>(endpoint).pipe(map(
      (contactDetails: ContactDetailsDTO): ContactDetails => contactDetails as ContactDetails
    ));
  }

  public getMemberProfile(memberId: number): Observable<Member> {
    const endpoint = `${environment.apiUrl}${MEMBER_PROFILE_ENDPOINT}`.replace('{memberId}', `${memberId}`);
    return this.http.get<MemberDTO>(endpoint).pipe(
      map(MemberMapper.mapToMember),
      catchError(error => throwError(error))
      );
    }

  public updateProfileDetails(personalDetails: PersonalDetails, memberId: number): Observable<string> {
    const endpoint = `${environment.apiUrl}${MEMBER_PROFILE_ENDPOINT}`.replace('{memberId}', `${memberId}`);
    return this.http.put<string>(endpoint, MemberMapper.mapToPersonalDetailsBodyDTO(personalDetails));
  }

  public updateContactDetails(personalDetails: PersonalDetails, primaryPhone: PhoneNumber, memberId: number): Observable<string> {
    const endpoint = `${environment.apiUrl}${MEMBER_CONTACT_DETAILS_ENDPOINT}`.replace('{memberId}', `${memberId}`);
    return this.http.patch<string>(endpoint, [
      MemberMapper.mapPersonalDetailsToPatchContactDTO(personalDetails, primaryPhone),
      MemberMapper.mapPrimaryPhoneToPatchContactDTO(primaryPhone),
    ]);
  }

  public getMemberPreferredLanguage(memberId: number): Observable<MemberPersonalisationDTO> {
    const endpoint = `${environment.apiUrl}${MEMBER_PERSONALISATION_ENDPOINT}`.replace('{memberId}', `${memberId}`).replace('{personalisationKey}', LANGUAGE_PERSONALISATION_KEY);
    return this.http.get<MemberPersonalisationDTO>(endpoint);
  }

  public postMemberPreferredLanguage(memberId: number, language: string): Observable<number> {
    const payload: MemberPersonalisationDTO = {
      memberId: memberId,
      typeKey: LANGUAGE_PERSONALISATION_KEY,
      value: language
    };
    const endpoint = `${environment.apiUrl}${MEMBER_PERSONALISATION_ENDPOINT}`.replace('{memberId}', `${memberId}`).replace('/{personalisationKey}', '');

    return this.http.post<number>(endpoint, payload);
  }

  public putMemberPreferredLanguage(memberId: number, language: string, personalisationId: number): Observable<void> {
    const payload: MemberPersonalisationDTO = {
      memberId: memberId,
      typeKey: LANGUAGE_PERSONALISATION_KEY,
      value: language,
      id: personalisationId
    };
    const endpoint = `${environment.apiUrl}${MEMBER_PERSONALISATION_ENDPOINT}`.replace('{memberId}', `${memberId}`).replace('{personalisationKey}', `${personalisationId}`);

    return this.http.put<void>(endpoint, payload);
  }
}

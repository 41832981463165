export enum GladstonePaymentProvider {
    Cashless = 'Cashless',
    Loyalty = 'Loyalty',
    Card = 'GladstonePay',
  }

export interface BasePaymentMethod {
  amount: number;
  paymentProvider: GladstonePaymentProvider;
  paymentToken?: string;
}

export interface ExistingCardPaymentMethod extends BasePaymentMethod {
  cardId: number;
  paymentProvider: GladstonePaymentProvider.Card;
  threeDSecureChallengeResponse?: string;
  threeDSecureChallengeReference?: string;
  deviceInformation?: DeviceInformationDTO;
}

export interface DeviceInformationDTO {
  timeZone: string;
  capabilities: string;
  screenResolution: string;
}

export interface NewCardPaymentMethod extends BasePaymentMethod {
  paymentProvider: GladstonePaymentProvider.Card;
  paymentToken: string;
  saveCardToWallet: boolean;
  saveRCPcard: boolean;
  threeDSecureChallengeResponse?: string;
  threeDSecureChallengeReference?: string;
  deviceInformation: DeviceInformationDTO;
}

export interface CashlessPaymentMethod extends BasePaymentMethod {
  paymentProvider: GladstonePaymentProvider.Cashless;
}

export interface LoyaltyPaymentMethod extends BasePaymentMethod {
  paymentProvider: GladstonePaymentProvider.Loyalty;
}

export type GladstonePaymentMethod =
  | ExistingCardPaymentMethod
  | CashlessPaymentMethod
  | LoyaltyPaymentMethod
  | NewCardPaymentMethod;

export interface GladstonePaymentRequestDTO {
  salesInvoiceIds: number[];
  memberId: number;
  paymentMethods: GladstonePaymentMethod[];
}

export interface CreateBookingResponseDTO {
  memberId?: number;
  bookings: BookingDTO[];
}

export interface BookeePriceLevelDTO {
  id: string;
  description: string;
  count: number;
  price: number;
}

interface BookingDTO {
  bookingRef: string;
  leaseID: string;
  bookingID: string;
  salesInvoiceIds: number[];
  bookeePriceLevels: BookeePriceLevelDTO[];
}

export enum GladstonePaymentRequestResponseStatus {
  PaymentSuccessful = 0,
  PaymentFailed = 1,
  InvalidPaymentToken = 66479,
  InternalServerError = 'InternalServerError',
  ThreeDSecureChallengeRequired = 'ThreeDSecureChallengeRequired',
}

export enum GladstoneAddPaymentMethodRequestResponseStatus {
  ThreeDSecureChallengeRequired = 65802,
  PaymentFailed = 'PaymentFailed',
}

export interface ThreeDSecureChallengeFields {
  [key: string]: string;
}

export interface GladstonePaymentRequestResponseDTO {
  transactionId: string;
  paymentProviderReference: string;
  responseStatus: GladstonePaymentRequestResponseStatus;
  threeDSecureChallengeUrl?: string;
  threeDSecureChallengeFields?: ThreeDSecureChallengeFields;
}

export interface GladstoneAddPaymentMethodRequest {
  paymentToken: string;
}
export interface GladstoneAddPaymentMethodRequestResponse {
  paymentToken: string;
  paymentProviderReference: string;
  responseCode: GladstoneAddPaymentMethodRequestResponseStatus;
  threeDSecureChallengeUrl?: string;
  threeDSecureChallengeFields?: ThreeDSecureChallengeFields;
}

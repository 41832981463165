import { initiateBiometricLogin } from '@store/actions/authentication.actions';
import { BiometricService } from '@core/services/biometric.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  Login,
  SetAuthenticationRedirectUrl,
} from '@app/store/actions/authentication.actions';
import { isAuthenticated } from '@app/store/selectors/authentication.selectors';
import { IAppState } from '@app/store/state/app-config.state';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { AuthService } from './authentication.service';
import { environment } from '@environments/environment';
import { concatLatestFrom } from '@ngrx/effects';
import { selectHasInternetConnection } from '@app/store/selectors/platform.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private readonly store: Store<IAppState>,
    private oAuthService: AuthService,
    private biometricService: BiometricService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.store.select(isAuthenticated).pipe(
      concatLatestFrom(() => [this.store.select(selectHasInternetConnection)]),
      tap(([authenticated, online]) => {
        if (!authenticated && online) {
          this.store.dispatch(new SetAuthenticationRedirectUrl(state.url));
          const platform = Capacitor.getPlatform();
          if (
            (platform === 'ios' || platform === 'android') &&
            this.oAuthService.hasRefreshToken() &&
            environment.useBiometric
          ) {
            this.store.dispatch(initiateBiometricLogin());
          } else {
            this.store.dispatch(new Login());
          }
        }
      }),
      map(([authenticated,_]) => authenticated)
    );
  }
}

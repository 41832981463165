import { ErrorData } from '@app/core/models/error-data.model';
import { createAction, props } from '@ngrx/store';

export const SET_API_ERROR = '[Error] Error on API call';
export const SET_API_ERROR_SUCCESS = '[Error] Successfully set the error from API';
export const DISPLAY_SUCCESS_TOAST = '[Success] Display success toast';
export const DISPLAY_INFO_TOAST = '[Info] Display info toast';
export const UNSUBSCRIBE_FROM_LISTENERS = '[Nofication] Unsubscribe from all the observables';

export const setApiError = createAction(SET_API_ERROR,
  props<{error: ErrorData}>()
);
export const setApiErrorSuccess = createAction(SET_API_ERROR_SUCCESS);
export const displaySuccessToast = createAction(DISPLAY_SUCCESS_TOAST,
  props<{message: string; iconName: string; toastClass: string; parameters?: any}>()
  );
export const displayInfoToast = createAction(DISPLAY_INFO_TOAST,
  props<{message: string; iconName: string; toastClass: string}>()
  );
export const unsubscribeFromListenenrs = createAction(UNSUBSCRIBE_FROM_LISTENERS);


import { NotificationHandlingState } from '../state/app-config.state';
import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app-config.state';


const notificationHandlingState = (state: IAppState) => state.notificationHandling;

export const selectErrorsOnApi = createSelector(
    notificationHandlingState,
    (errorHandlingStateParam: NotificationHandlingState) => errorHandlingStateParam.errorOnApiCall
);

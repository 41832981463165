import { fetchProfileDetailsError, setSelectedDateForBookings, updatePersonalDetails } from './../actions/account.actions';
import { logOut } from './../actions/authentication.actions';
import { createReducer, on } from '@ngrx/store';
import { fetchProfileDetailsSuccess } from '../actions/account.actions';
import { AccountState, initialAccountState } from '../state/account.state';
import { PHONE_NUMBER } from '@shared/validators/custom-validator';
import { NAME, EMAIL, DATE, validateBoolean } from '@shared/validators/custom-validator';
import {
  changeFirstname,
  changeSurname,
  changeEmail,
  changePhoneNumber,
  changeDateOfBirth,
  blurFirstname,
  blurSurname,
  blurEmail,
  blurPhoneNumber,
  blurDateOfBirth,
  setCountryDialCode,
  resetAccountDetailsFormState,
  resetFormWasTouched,
} from '../actions/account.actions';
import { fetchContactDetailsSuccess } from '../actions/account.actions';
import { formatISO, parse } from 'date-fns';
// Actions
// Interfaces

const initialState: AccountState = initialAccountState;



export const accountReducer = createReducer(initialState,
  on(fetchProfileDetailsSuccess, (state, data) => ({
        ...state,
        personalDetails: {
          dateOfBirth: data.profileDetails.dateOfBirth,
          email: data.profileDetails.email,
          firstName: data.profileDetails.firstName,
          gender: data.profileDetails.gender,
          lastName: data.profileDetails.lastName,
          title: data.profileDetails.title,
          titleId: data.profileDetails.titleId,
          memberId: data.profileDetails.memberId
        }
    })
  ),
  on(fetchContactDetailsSuccess, (state, data) => ({
    ...state,
    contactDetails: data.payload,
  })),
  on(changeFirstname, (state, data) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      firstname: {
        value: data.fieldValue,
        isValid: validateBoolean(data.fieldValue, NAME) ? true : false,
        isFocused: state.personalDetailsFormState.firstname.isValid
          ? true
          : state.personalDetailsFormState.firstname.isFocused,
      },
    },
  })),
  on(changeSurname, (state, data) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      surname: {
        value: data.fieldValue,
        isValid: validateBoolean(data.fieldValue, NAME) ? true : false,
        isFocused: state.personalDetailsFormState.surname.isValid
          ? true
          : state.personalDetailsFormState.surname.isFocused,
      },
    },
  })),
  on(changeEmail, (state, data) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      email: {
        value: data.fieldValue,
        isValid: validateBoolean(data.fieldValue, EMAIL) ? true : false,
        isFocused: state.personalDetailsFormState.email.isValid
          ? true
          : state.personalDetailsFormState.email.isFocused,
      },
    },
  })),
  on(changePhoneNumber, (state, data) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      phoneNumber: {
        value: data.fieldValue,
        isValid: validateBoolean(data.fieldValue, PHONE_NUMBER) ? true : false,
        isFocused: state.personalDetailsFormState.phoneNumber.isValid
          ? true
          : state.personalDetailsFormState.phoneNumber.isFocused,
      },
    },
  })),
  on(changeDateOfBirth, (state, data) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      dateOfBirth: {
        value: data.fieldValue,
        isValid: validateBoolean(data.fieldValue, DATE) ? true : false,
        isFocused: state.personalDetailsFormState.dateOfBirth.isValid
          ? true
          : state.personalDetailsFormState.dateOfBirth.isFocused,
      },
    },
  })),
  on(blurFirstname, (state) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      firstname: {
        ...state.personalDetailsFormState.firstname,
        isFocused: false,
      },
      formWasTouched: true,
    },
  })),
  on(blurSurname, (state) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      surname: {
        ...state.personalDetailsFormState.surname,
        isFocused: false,
      },
      formWasTouched: true,
    },
  })),
  on(blurEmail, (state) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      email: {
        ...state.personalDetailsFormState.email,
        isFocused: false,
      },
      formWasTouched: true,
    },
  })),
  on(blurPhoneNumber, (state) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      phoneNumber: {
        ...state.personalDetailsFormState.phoneNumber,
        isFocused: false,
      },
      formWasTouched: true,
    },
  })),
  on(blurDateOfBirth, (state) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      dateOfBirth: {
        ...state.personalDetailsFormState.dateOfBirth,
        isFocused: false,
      },
      formWasTouched: true,
    },
  })),
  on(setCountryDialCode, (state, action) => ({
    ...state,
    contactDetails: {
      ...state.contactDetails,
      countryDialCode: action.dialCode,
      formWasTouched: true,
    },
  })),
  on(resetAccountDetailsFormState, (state) => ({
    ...state,
    personalDetailsFormState: {...initialAccountState.personalDetailsFormState}
  })),
  on(resetFormWasTouched, (state) => ({
    ...state,
    personalDetailsFormState: {
      ...state.personalDetailsFormState,
      formWasTouched: false
    }
  })),
  on(logOut, () => initialState),
  on(setSelectedDateForBookings, (state, data) => ({
    ...state,
    selectedDateForBookings: data.dateIso
  })),
  on(updatePersonalDetails, (state, data) => ({
    ...state,
    personalDetails: {
      ...state.personalDetails,
      firstName: data.personalDetailsUpdated.firstName,
      lastName: data.personalDetailsUpdated.lastName,
      phoneNumber: data.personalDetailsUpdated.phoneNumber,
      email: data.personalDetailsUpdated.email,
      dateOfBirth: formatISO(parse(data.personalDetailsUpdated.dateOfBirth, 'dd/MM/yyyy', new Date())),
    }
  })),
);

import { createAction, props } from '@ngrx/store';

export const NAVIGATE_TO_URL = '[Navigation] Navigate to url';
export const NAVIGATE_BACK = '[Navigation] Navigate back';
export const NAVIGATED_BACK_SUCCESSFULLY = '[Navigation] Navigated back successfully';
export const RESET_NAVIGATION_HISTORY = '[Navigation] Clear navigation history';

export const navigateToUrl = createAction(NAVIGATE_TO_URL,
  props<{url: string}>()
);
export const navigateBack = createAction(NAVIGATE_BACK);

export const navigatedBackSuccesfully = createAction(NAVIGATED_BACK_SUCCESSFULLY);

export const resetNavigationHistory = createAction(RESET_NAVIGATION_HISTORY,
  props<{url: string}>()
);

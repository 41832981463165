import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthHelperService } from '@app/core/authentication/authentication.helper';
import { isAuthenticating } from '@app/store/selectors/authentication.selectors';
import { IAppState } from '@app/store/state/app-config.state';
import { Store } from '@ngrx/store';
// RxJS
import { Observable, of } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';
// Constants
import {
  ANONYMOUS_AUTH_ENDPOINT,
  AUTH_IDENTITY_ENDPOINT,
  LOCAL_ASSET_ENDPOINT,
  VALIDATE_TENANT_ENDPOINT,
} from 'src/app/core/constants';

@Injectable()
export class AuthenticationTokenInterceptor implements HttpInterceptor {
  private ignoredUrls: string[] = [
    ANONYMOUS_AUTH_ENDPOINT,
    VALIDATE_TENANT_ENDPOINT,
    LOCAL_ASSET_ENDPOINT,
    AUTH_IDENTITY_ENDPOINT,
    '/public',
  ];

  constructor(private readonly authHelperService: AuthHelperService, private store: Store<IAppState>) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isIgnoredUrl(request.url)) {
      return next.handle(request);
    }

    return this.store.select(isAuthenticating).pipe(
      first(),
      mergeMap((isAuthenticating: boolean) =>
        isAuthenticating ? this.getTokenPostAuthenticationFlow() : of(this.authHelperService.token),
      ),
      mergeMap((accessToken: string) => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return next.handle(request);
      }),
    );
  }

  private isIgnoredUrl(url: string): boolean {
    return this.ignoredUrls.some((ignoredUrl: string) => url.includes(ignoredUrl));
  }

  private getTokenPostAuthenticationFlow(): Observable<string> {
    return this.store.select(isAuthenticating).pipe(
      filter((isAuthenticating: boolean) => !isAuthenticating),
      first(),
      map(() => this.authHelperService.token),
    );
  }
}

import { Action, ActionReducer } from '@ngrx/store';
import { IAppState } from '@store/state/app-config.state';
import * as HydrationActions from '../actions/offline-support.actions';
import { initialAccountState } from './../state/account.state';

const isHydrateSuccess = (
  action: Action
): action is ReturnType<typeof HydrationActions.hydrateSuccess> =>
  action.type === HydrationActions.hydrateSuccess.type;

export const hydrationMetaReducer = (reducer: ActionReducer<IAppState>): ActionReducer<IAppState> => (state, action) => {
    if (isHydrateSuccess(action) && action.state !== null) {
      return {
        ...state,
        appConfig: {
          ...action.state.appConfig,
          colors: state.appConfig.colors,
          isFooterVisible: true,
          isWalletEnabled: state.appConfig.isWalletEnabled
        },
        navigation: {
          currentPage:
            action.state.authentication.authenticationRedirectUrl !== ''
              ? action.state.navigation.currentPage
              : state.navigation.currentPage,
          navigationHistory:
            action.state.authentication.authenticationRedirectUrl !== ''
              ? action.state.navigation.navigationHistory
              : state.navigation.navigationHistory,
        },
        authentication: {
          ...state.authentication,
          authenticationRedirectUrl: action.state.authentication.authenticationRedirectUrl
        },
        basket: {
          ...action.state.basket,
          checkedOutBookings: []
        },
        sites: action.state.sites,
        activities: action.state.activities,
        bookDatePicker: action.state.bookDatePicker,
        bookTimePicker: action.state.bookTimePicker,
        sessions: {
          ...state.sessions,
          selectedSlot: action.state.sessions.selectedSlot,
          slotsWithPrices: action.state.sessions.slotsWithPrices,
          slotPriceLevels: action.state.sessions.slotPriceLevels
        },
        account: {
          ...action.state.account,
          personalDetails: {...initialAccountState.personalDetails},
          personalDetailsFormState: {
            ...initialAccountState.personalDetailsFormState,
          },
        },
        favourites: action.state.favourites,
        entry: action.state.entry,
        recommendedActivities: {
          ...state.recommendedActivities,
          promotedTagId: action.state.recommendedActivities.promotedTagId
        }
      };
    } else {
      return reducer(state, action);
    }
  };

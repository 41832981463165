import { Component, OnInit } from '@angular/core';
import { selectComputedColorTheme } from '@app/store/selectors/app-config.selectors';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Component({
  selector: 'gs-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss'],
})
export class UnderConstructionComponent implements OnInit {

  underConstructionImageUrl$ = this.store.select(selectComputedColorTheme).pipe(
    map(colorTheme => `assets/icon/${colorTheme}/under-construction.svg`)
  );
  constructor(private store: Store) { }

  ngOnInit() {}

}

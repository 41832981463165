import { PriceInformation } from '@core/models/pricing.model';
import { ActivityDetails } from '@core/models/activity.model';
import { combineLatest, filter, first } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {
  DATE_REBOOK_FORMAT,
  RECOMMENDED_ACTIVITY_CARD_HEIGHT,
  RECOMMENDED_ACTIVITY_CARD_WIDTH,
  RoutingAbsolutePaths,
  TIME_24H_FORMAT,
} from '@app/core/constants';
import { Slot } from '@app/core/models/session.model';
import {
  loadActivityDetails,
  loadSessionPrice,
  loadSlotPrice,
  setSelectedCentre,
  setSelectedSlot,
} from '@app/store/actions/book.actions';
import { navigateToUrl } from '@app/store/actions/navigation.actions';
import {
  selectActivityDetails,
  selectIsLoadingRecommendedActivities,
  selectRecommendedActivities,
  selectSiteById,
  selectSlotPriceLevels,
} from '@store/selectors/book.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'gs-recommended-activities',
  templateUrl: './recommended-activities.component.html',
  styleUrls: ['./recommended-activities.component.scss'],
})
export class RecommendedActivitiesComponent implements OnInit {
  recommendedActivites$ = this.store.select(selectRecommendedActivities);
  isLoadingRecommendedActivities$ = this.store.select(selectIsLoadingRecommendedActivities);
  widthOfACard = RECOMMENDED_ACTIVITY_CARD_WIDTH;
  heightOfACard = RECOMMENDED_ACTIVITY_CARD_HEIGHT;
  dateFormat = DATE_REBOOK_FORMAT;
  timeFormat = TIME_24H_FORMAT;

  constructor(private store: Store) {}

  ngOnInit() {}

  onRecommendedActivityClick(recommededActivity: Slot) {
    this.store
      .select(selectSiteById(recommededActivity.siteId))
      .pipe(first())
      .subscribe((site) => {
        this.store.dispatch(setSelectedCentre({ site }));
      });
    this.store.dispatch(setSelectedSlot({ slot: recommededActivity }));
    this.store.dispatch(loadActivityDetails({ activityId: recommededActivity.activityId }));
    this.store.dispatch(loadSessionPrice({slot: recommededActivity}));
    combineLatest([this.store.select(selectActivityDetails), this.store.select(selectSlotPriceLevels)]).pipe(
      filter(
        ([activityDetails, slotPriceLevels]: [ActivityDetails, PriceInformation]) =>
          recommededActivity.activityName === activityDetails?.name && !!slotPriceLevels
      ),
      first()
    ).subscribe(() => this.store.dispatch(navigateToUrl({ url: RoutingAbsolutePaths.bookActivityDetailsPage })));

  }
}

import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gs-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit {
  @Input() errorMessage: string;
  @Input() iconName = '';
  @Input() iconClass = '';
  constructor() { }

  ngOnInit() {}

}

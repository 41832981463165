import { setApiError } from '@store/actions/notification-handling.actions';
import { ERROR_ON_API_MSG_KEY, LANGUAGE_PERSONALISATION_API, WALLET_API } from '@core/constants';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ERROR_ON_API_HEADER_KEY } from '@app/core/constants';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private store: Store
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status !==401){
            if (error.status !== 404 || (!error.url.includes(WALLET_API) && !error.url.includes(LANGUAGE_PERSONALISATION_API))){
              this.store.dispatch(setApiError({
                error: {
                  header: ERROR_ON_API_HEADER_KEY,
                  message: ERROR_ON_API_MSG_KEY,
                  errorMessage: typeof error.error === 'string' ? error.error : undefined,
                  statusCode: error.status,
                  path: error.url.replace(environment.apiUrl, '')
                }
              }));
            }
          }
          return throwError(() => error);
        })
      );
  }
}

import { navigateToUrl, resetNavigationHistory } from '@store/actions/navigation.actions';
import { selectTabProps } from '@store/selectors/app-config.selectors';
import { Store } from '@ngrx/store';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { TabProps } from '@app/shared/components/icon-container/tab-props';
import { changeTab } from '@app/store/actions/app-config.actions';
import { Observable, first } from 'rxjs';
import { selectCurrentRouteUrl } from '@app/store/selectors/navigation.selectors';
import { RoutingAbsolutePaths } from '@app/core/constants';

@Component({
  selector: 'gs-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  public tabsProps: Observable<TabProps[]> = this.store.select(selectTabProps);

  constructor(private store: Store) {}

  ngOnInit() {}

  public navigate(route: string) {
    this.store.dispatch(changeTab());

    this.store.select(selectCurrentRouteUrl).pipe(first()).subscribe(currentRoute => {
      if(route === RoutingAbsolutePaths.bookHomePage &&
        (currentRoute === RoutingAbsolutePaths.bookHomePage || currentRoute === RoutingAbsolutePaths.emptyUrl)){
        this.store.dispatch(navigateToUrl({url: RoutingAbsolutePaths.bookSearchPage}));
      }else{
        this.store.dispatch(resetNavigationHistory({url: route}));
      }
    });
  }

  public getIdOfTab(tab: TabProps): string {
    return `footer-${tab.navRoute.slice(1)}-btn`;
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SHORT_IDENTIFIER_REFRESH_RATE } from '@app/core/constants';
import { addSeconds, differenceInSeconds, format } from 'date-fns';

@Component({
  selector: 'gs-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input() targetDate: string;
  timer = '';
  interval: NodeJS.Timeout;
  constructor() { }

  ngOnInit(): void {
    this.interval = setInterval(() => this.updateTimer(), 1000);
  }

  updateTimer(){
    if(!this.targetDate){
      return;
    }
    const date = new Date().setHours(0,0,0,0);
    let seconds = differenceInSeconds(new Date(this.targetDate), new Date());
    if(seconds < 0){
      seconds = 0;
    }
    if(seconds > SHORT_IDENTIFIER_REFRESH_RATE / 1000){
      seconds = seconds % (SHORT_IDENTIFIER_REFRESH_RATE / 1000);
    }
    this.timer = format(addSeconds(date,seconds), 'mm:ss');
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}

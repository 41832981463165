import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gs-add-favourite-button',
  templateUrl: './add-favourite-button.component.html',
  styleUrls: ['./add-favourite-button.component.scss'],
})
export class AddFavouriteButtonComponent implements OnInit {

  @Input() isFavourite = false;
  @Input() iconSize = 20;
  @Output() newFavouriteState = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {}

  changeFavouriteState(event): void{
    this.newFavouriteState.emit(event);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Language } from '@app/core/models/language.model';
import { loadLanguages, setLanguage } from '@app/store/actions/app-config.actions';
import { selectLanguages } from '@app/store/selectors/app-config.selectors';
import { getAccountMemberId } from '@app/store/selectors/authentication.selectors';
import { environment } from '@environments/environment';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'gs-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit{
  @Input() selectedLanguage = environment.defaultLanguage;
  public languages: Language[];
  public subscription: Subscription;
  public memberId: number;

  languages$ = this.store.select(selectLanguages);

  constructor(private store: Store, private modalCtrl: ModalController, private translate: TranslateService) { }

  ngOnInit() {
    this.store.dispatch(loadLanguages());
    this.store.select(getAccountMemberId).subscribe(memberId => this.memberId = memberId);
  }

  cancel() {
    this.modalCtrl.dismiss({});
  }

  onApplyChanges(e){
    this.translate.use(this.selectedLanguage);
    this.store.dispatch(setLanguage({memberId: this.memberId, language: this.selectedLanguage}));
    this.cancel();
  }

  isLanguageSelected(language): boolean{
    if(language.code !== this.selectedLanguage){
      return false;
    }
    return true;
  }

  checkboxClick(event): void{
    this.selectedLanguage = event.target.value;
  }
}

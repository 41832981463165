import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { dropDownAnimation, dropUpAnimation } from '@shared/animations/component-animations';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { IonSegment } from '@ionic/angular';
import { format, isEqual } from 'date-fns';

@Component({
  selector: 'gs-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @ViewChild(MatCalendar) datePicker!: MatCalendar<DateRange<Date>>;
  @ViewChild(IonSegment) segment: IonSegment;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() selectedDateRange: DateRange<Date>;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() hasBorder = true;
  @Input() roundedCalendar = false;
  @Output() updateDateRequest = new EventEmitter<({startDate: string; endDate: string})>();
  dateSelected = true;

  constructor() {}

  ngOnInit() {}

  selectDateButton(value: string) {
    this.segment.value = value;
  }

  selectedChange(date: Date) {
    if(this.segment.value === 'start-date'){
      this.selectedDateRange = new DateRange(date, null);
      this.selectDateButton('end-date');
    }
    else if(this.segment.value === 'end-date'){
      if(date <= this.selectedDateRange.start){
        this.selectedDateRange = new DateRange(date, null);
      }else{
        this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
      }
    }
    this.datePicker.selected = this.selectedDateRange;
    this.startDate = this.selectedDateRange.start.toISOString();
    // this is added because if we have a single selection the calendar requires selectedDateRange.end to be null.
    this.endDate = (this.selectedDateRange.end !== null ? this.selectedDateRange.end : this.selectedDateRange.start).toISOString();
  }

  onDateButtonClick() {
    this.dateSelected = false;
    dropDownAnimation('.date-and-button').play();
  }

  areDatesEqual(firstDate: string, secondDate: string): boolean {
    return isEqual(new Date(firstDate), new Date(secondDate));
  }

  collapseDatePicker() {
    this.dateSelected = true;
    dropUpAnimation('.date-and-button').play();
  }

  onSaveDate(event) {
    event.stopPropagation();
    this.updateDateRequest.emit({startDate: this.startDate, endDate: this.endDate});
    this.collapseDatePicker();
  }

  getDisplayDate(date: string) {
    return format(new Date(date), 'E do, MMM');
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { selectComputedColorTheme } from '@app/store/selectors/app-config.selectors';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Component({
  selector: 'gs-logo-header',
  templateUrl: './logo-header.component.html',
  styleUrls: ['./logo-header.component.scss'],
})
export class LogoHeaderComponent implements OnInit {
  @Input() hasBorderRadius = false;
  logoUrl$ = this.store.select(selectComputedColorTheme).pipe(
    map(colorTheme => `assets/icon/${colorTheme}/logo.png`)
  );
  constructor(private store: Store) { }

  ngOnInit() {}

}

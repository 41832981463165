import { lighten, parseToRgba } from 'color2k';

export class AccesibilityHelper {

  public static shouldFontColorBeBlack(backgroundColor: string): boolean {
    const [r, g, b, a] = parseToRgba(backgroundColor);

    const blendedR = Math.round(r * a + 255 * (1 - a));
    const blendedG = Math.round(g * a + 255 * (1 - a));
    const blendedB = Math.round(b * a + 255 * (1 - a));

    const luminance = (0.299 * blendedR + 0.587 * blendedG + 0.114 * blendedB);
    return luminance > 186;
  }

  public static shouldElementFontColorBeBlack(element: HTMLElement): boolean {
    const styles = window.getComputedStyle(element);
    const backgroundColor = styles.getPropertyValue('background-color');
    return this.shouldFontColorBeBlack(backgroundColor);
  }
}


import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';
import { TimeUtilitiesHelper } from '@core/helpers/time-utilities-helper';
import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';

@Component({
  selector: 'gs-calendar-display',
  templateUrl: './calendar-display.component.html',
  styleUrls: ['./calendar-display.component.scss'],
})
export class CalendarDisplayComponent implements OnInit {
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;
  @Input() dateClassFunctions: (date: Date) => MatCalendarCellCssClasses;
  @Input() isLoadingData = true;
  @Output() calendarClicked = new EventEmitter<Date>();
  customCalendarHeader = CalendarHeaderComponent;
  isPreviousMonth = false;
  constructor(private element: ElementRef) { }

  ngOnInit() {}

  public getMaxDate(): Date {
    return TimeUtilitiesHelper.getLastDayOfNextMonth(new Date());
  }

  public getMinDate(): Date {
    return TimeUtilitiesHelper.getFirstDayOf12MonthsAgo(new Date());
  }

  public getElementRef(): ElementRef {
    return this.element;
  }

  public refreshCalendar() {
    this.calendar?.updateTodaysDate();
  }

  public onMonthChanged(event) {
    this.isPreviousMonth = TimeUtilitiesHelper.isDateFromPastMonths(event);
  }

  public checkPreviousMonthFromCalendarLabel(): boolean {
    return this.isPreviousMonth;
  }

  public onCalendarClicked(): void {
    this.calendarClicked.emit(this.calendar.activeDate);
  }
}

import { createSelector } from '@ngrx/store';
import { IAppState, IPlatformState } from '../state/app-config.state';

const platformState = (state: IAppState) => state.platform;

export const selectHasInternetConnection = createSelector(
    platformState,
    (state: IPlatformState) => state.hasInternetConnection
  );
export const selectIsKeyboardOpen = createSelector(platformState, (state: IPlatformState) => state.isKeyboardOpen);
export const selectIsAppActive = createSelector(platformState, (state: IPlatformState) => state.isAppActive);

import { AfterViewInit, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { navigateBack } from '@app/store/actions/navigation.actions';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
// import { Geolocation } from '@capacitor/geolocation';
import { Network } from '@capacitor/network';
import { select, Store } from '@ngrx/store';
import { combineLatest, filter, first, map, pairwise, startWith, Subscription } from 'rxjs';
import {
  BACK_BUTTON_HANDLING_PRIORITY,
  TOAST_CONNECTION_CLASS, TOAST_ALERT_CIRCLE_ICON_NAME, TOAST_OFFLINE_MESSAGE, TOAST_ONLINE_ICON_NAME, TOAST_ONLINE_MESSAGE
} from './core/constants';
import { NetworkStatusService } from './core/network-status/network-status.service';
import { displayInfoToast, unsubscribeFromListenenrs } from './store/actions/notification-handling.actions';
import { loadDataAfterNewtorkConnection, saveStateOfStore } from './store/actions/offline-support.actions';
import { SplashScreen } from '@capacitor/splash-screen';
import { selectAppConfig, selectComputedColorTheme, selectFooterStatus, selectSelectedLanguage } from './store/selectors/app-config.selectors';
import { selectErrorsOnApi } from './store/selectors/notification-handling.selector';
import { selectAppState } from './store/selectors/offline-support-selectors';
import { IAppState } from './store/state/app-config.state';
import { Keyboard } from '@capacitor/keyboard';
import { changeNetworkStatus, setIsAppActive, setIsKeyboardOpen } from './store/actions/platform.actions';
import { NavigationEnd, Router } from '@angular/router';
import { setSelectedTab, setSystemColorTheme } from './store/actions/app-config.actions';
import { selectIsKeyboardOpen } from './store/selectors/platform.selectors';
import { darken, parseToHsla, transparentize } from 'color2k';
import { TranslateService } from '@ngx-translate/core';
import { loadDefaultSite, loadRecommendedActivities, loadSites } from './store/actions/book.actions';
import { selectSites } from './store/selectors/book.selectors';
import { AccesibilityHelper } from './core/helpers/accessibility';
import { loadFutureBookings } from './store/actions/basket.actions';
import { getAccountMemberId } from './store/selectors/authentication.selectors';
import { selectIsLoadingFutureBookings } from './store/selectors/basket.selectors';
import OneSignal from 'onesignal-cordova-plugin';
import { OpenedEvent } from 'onesignal-cordova-plugin/dist/models/NotificationOpened';
import { environment } from '@environments/environment';
import { PushNotificationService } from './core/services/push-notification.service';

@Component({
  selector: 'gs-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  appConfig$ = this.store.pipe(select(selectAppConfig));
  errorHandler$ = this.store.pipe(select(selectErrorsOnApi));
  shouldDisplayFooter$ = this.store.pipe(select(selectFooterStatus));
  appState$ = this.store.select(selectAppState);
  isKeyboardOpen$ = this.store.select(selectIsKeyboardOpen);
  private notificationsSubscription: Subscription;
  private configSubscription: Subscription;
  private appStateSubscription: Subscription;
  private languageSubscription: Subscription;
  private colorThemeSubscription: Subscription;

  constructor(
    private store: Store<IAppState>,
    private networkStatusService: NetworkStatusService,
    private pushNotificationService: PushNotificationService,
    private router: Router,
    private ngZone: NgZone,
    private platform: Platform,
    private translate: TranslateService) {
  }

  ngOnInit() {


    this.initializeApp();
    this.initApplicationPrimaryColors();
    this.appStateSubscription = this.appState$.subscribe(state => this.store.dispatch(saveStateOfStore({state})));
    this.initNetworkStatus();
    this.initKeyboardListeners();
    this.initAppStateListeners();
    this.initRoutingEvents();
    this.initBackButtonHandling();
    this.initApplicationLanguage();
    this.makeInitialApiCalls();
    this.initializeColorThemeListeners();
    // TODO: enable this when we implement features that use location services
    //this.initGeolocation();
    this.OneSignalInit();
  }

  OneSignalInit(): void {
    const platform = Capacitor.getPlatform();
    if (environment.oneSignalAppId && (platform === 'ios' || platform === 'android')) {
      OneSignal.setAppId(environment.oneSignalAppId);
      OneSignal.promptForPushNotificationsWithUserResponse(false); 
      OneSignal.setNotificationOpenedHandler((event: OpenedEvent) => {
        this.pushNotificationService.handleNotificationOpened(event);
      });
    }
  }

  ngAfterViewInit() {
    SplashScreen.hide();
  }

  initBackButtonHandling() {
    /*
    We need to set a priority to the handler in order to let ionic know whick callback should be called.
    It will start from the one with the highest priority (in case of keyboard, ion-modal etc.).
    This one will replace the implicit back navigation (which has priority 0)
    */
    this.platform.backButton.subscribeWithPriority(BACK_BUTTON_HANDLING_PRIORITY, () => {
      this.store.dispatch(navigateBack());
    });
  }

  initRoutingEvents() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.store.dispatch(setSelectedTab({ selectedTab: `/${event.url.split('/')[1]}` }));
    });
  }

  // initGeolocation(){
  //   const platform = Capacitor.getPlatform();

  //   if(platform ==='ios' || platform === 'android'){
  //     Geolocation.checkPermissions().then(response=>{
  //       if(response.location !== 'granted'){
  //         Geolocation.requestPermissions();
  //       }
  //     });
  //   }
  //   else{
  //     Geolocation.getCurrentPosition().catch(()=>{});
  //   }
  // }

    initKeyboardListeners(): void {
    const platform = Capacitor.getPlatform();
    if(platform === 'ios' || platform === 'android'){
      Keyboard.addListener('keyboardWillShow',() => {
        this.ngZone.run(() => {
          this.store.dispatch(setIsKeyboardOpen({isKeyboardOpen: true}));
        });
      });
      Keyboard.addListener('keyboardWillHide',() => {
        this.ngZone.run(() => {
          this.store.dispatch(setIsKeyboardOpen({isKeyboardOpen: false}));
        });
      });
    }
  }

  initializeApp() {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url;
      if (slug) {
          window.location.assign(slug);
      }
    });
  }

  initAppStateListeners() {
    App.addListener('appStateChange', ({isActive}) => {
      this.ngZone.run(() => {
        this.store.dispatch(setIsAppActive({isAppActive: isActive}));
      });
    });
  }

  initNetworkStatus() {
    this.notificationsSubscription = this.networkStatusService.networkStatus$.pipe(
      startWith(true),
      pairwise(),
      filter(([prevStatus, currentStatus]) => prevStatus !== currentStatus),
      map(([_, currentStatus]) => currentStatus)
    )
    .subscribe(status => {
      this.ngZone.run(() => {
        this.store.dispatch(changeNetworkStatus({networkStatus: status}));
      });
      if (status) {
        this.store.dispatch(
          displayInfoToast({
            message: TOAST_ONLINE_MESSAGE,
            iconName: TOAST_ONLINE_ICON_NAME,
            toastClass: TOAST_CONNECTION_CLASS,
          })
        );
      }
      else {
        this.store.dispatch(
          displayInfoToast({
            message: TOAST_OFFLINE_MESSAGE,
            iconName: TOAST_ALERT_CIRCLE_ICON_NAME,
            toastClass: TOAST_CONNECTION_CLASS,
          })
        );
      }
    });
  }

  initApplicationPrimaryColors() {
    this.configSubscription =  this.appConfig$.subscribe(config => {
      const html = document.querySelector('html');
      html.style.setProperty('--primary-color-1', config.colors.primaryColor1);
      html.style.setProperty('--primary-color-2', config.colors.primaryColor2);
      html.style.setProperty('--primary-color-dark', config.colors.primaryColorDark);

      html.style.setProperty('--secondary-color-1-30B', darken(config.colors.primaryColor1, 0.3 * parseToHsla(config.colors.primaryColor1)[2]));
      html.style.setProperty('--secondary-color-1-50', transparentize(config.colors.primaryColor1, 0.5 * parseToHsla(config.colors.primaryColor1)[3]));
      html.style.setProperty('--secondary-color-1-20', transparentize(config.colors.primaryColor1, 0.8 * parseToHsla(config.colors.primaryColor1)[3]));

      html.style.setProperty('--secondary-color-2-50', transparentize(config.colors.primaryColor2, 0.5 * parseToHsla(config.colors.primaryColor2)[3]));
      html.style.setProperty('--secondary-color-2-20', transparentize(config.colors.primaryColor2, 0.8 * parseToHsla(config.colors.primaryColor2)[3]));

      html.style.setProperty('--secondary-color-dark-30B', darken(config.colors.primaryColorDark, 0.3 * parseToHsla(config.colors.primaryColorDark)[2]));
      html.style.setProperty('--secondary-color-dark-50', transparentize(config.colors.primaryColorDark, 0.5 * parseToHsla(config.colors.primaryColorDark)[3]));
      html.style.setProperty('--secondary-color-dark-20', transparentize(config.colors.primaryColorDark, 0.8 * parseToHsla(config.colors.primaryColorDark)[3]));

      const fontColorBlack = getComputedStyle(document.documentElement).getPropertyValue('--black');
      const fontColorWhite =  getComputedStyle(document.documentElement).getPropertyValue('--white');
      const fontColor = AccesibilityHelper.shouldFontColorBeBlack(config.colors.primaryColor1) ? fontColorBlack : fontColorWhite;
      html.style.setProperty('--contrast-color', fontColor);
    });
  }

  initializeColorThemeListeners(): void {
    if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
      this.store.dispatch(setSystemColorTheme({colorTheme: 'dark'}));
    }
    else{
      this.store.dispatch(setSystemColorTheme({colorTheme: 'light'}));
    }
    this.configSubscription = this.store.select(selectComputedColorTheme).subscribe((colorTheme) => {
      document.querySelector(':root').setAttribute('color-theme', colorTheme);
    });
  }

  initApplicationLanguage(){
    this.languageSubscription = this.store.select(selectSelectedLanguage).subscribe(selectedLanguage => {
      if (selectedLanguage.language) {this.translate.use(selectedLanguage.language);}
    });
  }

  makeInitialApiCalls(){
    this.store.dispatch(loadDataAfterNewtorkConnection());
  }

  ngOnDestroy() {
    this.configSubscription.unsubscribe();
    this.appStateSubscription.unsubscribe();
    this.languageSubscription.unsubscribe();
    Network.removeAllListeners();
    App.removeAllListeners();
    this.store.dispatch(unsubscribeFromListenenrs());
    this.notificationsSubscription.unsubscribe();
    Keyboard.removeAllListeners();
    this.colorThemeSubscription.unsubscribe();
  }
}

import { payByNewCard } from '@app/store/actions/payment.actions';
import { createReducer, on } from '@ngrx/store';
import {
  addBasketItemSuccess,
  removeBasketItemSuccess,
  removeZeroPricedBookingSuccess,
  initiateZeroPricedBooking,
  loadFutureBookings,
  loadedFutureBookingsSuccess,
  loadedFutureBookingsFail,
  clearBasketItems,
  removeBasketItem,
  clearCheckedOutBookings,
  setFutureBookingsAvailability,
  removeBookee,
  updateAdditionalBookees,
  updateMemberAttendanceOnBasketItem,
  setIsBasketItemModified,
  updateBasketItemsFromActivityDetails,
  updatePriceQuoteSuccess,
  clearExpiredBasketLeases,
  setFutureBasketItem,
} from '../actions/basket.actions';
import { loadSlotPriceSuccess, unsubscribedFromAlertListSuccess } from '../actions/book.actions';
import {
  payByCard,
  payedByCardSuccess,
  paymentError
} from '../actions/payment.actions';
import { logOut } from './../actions/authentication.actions';
import {
  loadBookingHistory,
  loadedBookingHistoryFail,
  loadedBookingHistorySuccess,
} from './../actions/basket.actions';
import { PricingHelper } from './pricing-helper';
import { FutureBookingsAvailabilityService } from '@app/core/services/future-bookings-availability.service';
import { FutureBooking } from '@app/core/models/future-booking.model';
import { AdditionalBookeesBasketItem, BasketItem } from '@app/core/models/basket-item.model';
import { BasketMapper } from '@app/api/mappers/basket.mapper';
import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';

const basketReducerCreator = createReducer(
  {
    basketItems: [],
    checkedOutBookings: [],
    totalPrice: 0,
    VAT: 0,
    currency: '',
    isBasketItemModified: false,
    futureBasketItem: undefined
  },
  on(addBasketItemSuccess, (state, data) => {
    const newBasketItems = [...state.basketItems, data.basketItem];
    const newTotalPrice = PricingHelper.computeTotalPrice(newBasketItems);
    const newVAT = PricingHelper.computeVAT(newTotalPrice);
    return {
      ...state,
      basketItems: newBasketItems,
      totalPrice: newTotalPrice,
      VAT: newVAT,
      currency: newBasketItems[0].price.totalPrice.currency,
    };
  }),
  on(removeBasketItem, (state, data) => {
    const newBasketItems = state.basketItems.filter(
      (basketItem) => basketItem.leaseId !== data.leaseId
    );
    const newTotalPrice = PricingHelper.computeTotalPrice(newBasketItems);
    const newVAT = PricingHelper.computeVAT(newTotalPrice);
    return {
      ...state,
      basketItems: newBasketItems,
      totalPrice: newTotalPrice,
      VAT: newVAT,
    };
  }),
  on(payByCard, payByNewCard, (state) => ({
    ...state,
    checkedOutBookings : state.basketItems,
  })),
  on(payedByCardSuccess, paymentError, (state) => ({
    ...state,
    basketItems: [],
  })),
  on(clearBasketItems, (state) => ({
    ...state,
    basketItems: []
  })),
  on(clearCheckedOutBookings, (state) => ({
    ...state,
    checkedOutBookings: [],
  })),
  on(initiateZeroPricedBooking, (state, data) => ({
    ...state,
    checkedOutBookings: [data.basketItem],
  })),
  on(logOut, (state) => ({
    ...state,
    basketItems: [],
    checkedOutBookings: [],
  })),
  on(loadSlotPriceSuccess, (state, data)=>({
    ...state,
    currency: data.pricing.breakdown.currency
  })),
  on(removeBookee, (state, data) => {
    const newBasketItems = state.basketItems.map((basketItem: BasketItem) => {
      if (basketItem.leaseId === data.leaseId) {
        let additionalBookees: AdditionalBookeesBasketItem[] = basketItem.additionalBookees.filter(
          (bookee) => bookee.priceLevelId !== data.bookeeId || bookee.count > 1
        );
        additionalBookees = additionalBookees.map(
          (bookee): AdditionalBookeesBasketItem =>
            bookee.priceLevelId === data.bookeeId ? { ...bookee, count: bookee.count - 1 } : bookee
        );
        return { ...basketItem, additionalBookees };
      }
      return basketItem;
    });
    const newTotalPrice = PricingHelper.computeTotalPrice(newBasketItems);
    const newVAT = PricingHelper.computeVAT(newTotalPrice);
    return {
      ...state,
      basketItems: newBasketItems,
      totalPrice: newTotalPrice,
      VAT: newVAT,
    };
  }),
  on(updateAdditionalBookees, (state, data) => {
    const newBasketItems = state.basketItems.map((basketItem: BasketItem) => {
      if (basketItem.slotReference === data.slotReference) {
        return {
          ...basketItem,
          additionalBookees: data.additionalBookees.map((additionalBookee) =>
            BasketMapper.mapAdditionalBookeeToBasketBookees(additionalBookee)
          ),
        };
      }
      return basketItem;
    });
    const newTotalPrice = PricingHelper.computeTotalPrice(newBasketItems);
    const newVAT = PricingHelper.computeVAT(newTotalPrice);
    return {
      ...state,
      basketItems: newBasketItems,
      totalPrice: newTotalPrice,
      VAT: newVAT,
    };
  }),
  on(updateMemberAttendanceOnBasketItem, (state, data) => {
    const newBasketItems = state.basketItems.map((basketItem: BasketItem) => {
      if (basketItem.slotReference === data.slotReference) {
        return {
          ...basketItem,
          isMemberAttending: data.isMemberAttending,
        };
      }
      return basketItem;
    });
    const newTotalPrice = PricingHelper.computeTotalPrice(newBasketItems);
    const newVAT = PricingHelper.computeVAT(newTotalPrice);
    return {
      ...state,
      basketItems: newBasketItems,
      totalPrice: newTotalPrice,
      VAT: newVAT,
    };
  }),
  on(updateBasketItemsFromActivityDetails, (state, data) => {
    const newBasketItems = state.basketItems.map((basketItem: BasketItem) => {
      if (basketItem.slotReference === data.slotReference) {
        return {
          ...basketItem,
          isMemberAttending: data.isMemberAttending,
          additionalBookees: data.additionalBookees.map((additionalBookee) =>
            BasketMapper.mapAdditionalBookeeToBasketBookees(additionalBookee)),
          price: data.priceQuote
        };
      }
      return basketItem;
    });
    const newTotalPrice = PricingHelper.computeTotalPrice(newBasketItems);
    const newVAT = PricingHelper.computeVAT(newTotalPrice);
    return {
      ...state,
      basketItems: newBasketItems,
      totalPrice: newTotalPrice,
      VAT: newVAT,
    };
  }),
  on(updatePriceQuoteSuccess, (state, data) => {
    const newBasketItems = state.basketItems.map((basketItem: BasketItem) => {
      if (basketItem.slotReference === data.basketItem.slotReference) {
        return {
          ...basketItem,
          price: data.priceQuote,
        };
      }
      return basketItem;
    });

    const newTotal = PricingHelper.computeTotalPrice(newBasketItems);
    const newVAT = PricingHelper.computeVAT(newTotal);
    return {
      ...state,
      basketItems: newBasketItems,
      totalPrice: newTotal,
      VAT: newVAT
    };
  }),
  on(setIsBasketItemModified, (state, data) => ({
    ...state,
    isBasketItemModified: data.isBasketItemModified
  })),
  on(setFutureBasketItem, (state, action) => ({
    ...state,
    futureBasketItem: action.futureBasketItem
  })),
  on(clearExpiredBasketLeases, (state) => ({
    ...state,
    basketItems: state.basketItems.filter((basketItem: BasketItem) => TimeUtilitiesHelper.isDateInFuture(basketItem.leaseExpirationTime))
  })),
);

const futureBookingsReducerCreator = createReducer(
  {
    futureBookings: [],
    alertList: [],
    isLoadingFutureBookings: false,
    futureBookingsAvailability: {},
  },
  on(loadFutureBookings, (state) => ({
    ...state,
    isLoadingFutureBookings: true,
  })),
  on(loadedFutureBookingsSuccess, (state, data) => ({
    ...state,
    futureBookings: data.futureBookings.filter(
      (booking) => booking.inCentre.bookingConfirmed
    ),
    alertList: data.futureBookings.filter(
      (booking) => booking.inCentre.onAlertList
    ),
    isLoadingFutureBookings: false,
  })),
  on(unsubscribedFromAlertListSuccess, (state, data) => ({
    ...state,
    alertList: state.alertList.filter((futureBooking: FutureBooking) => futureBooking.bookingId !== +data.bookingId),
    futureBookingsAvailability: {...state.futureBookingsAvailability, [data.bookingId]: undefined}
  })),
  on(loadedFutureBookingsFail, (state) => ({
    ...state,
    isLoadingFutureBookings: false,
  })),
  on(removeZeroPricedBookingSuccess, (state, data) => {
    const newFutureBookings = state.futureBookings.filter(
      (booking) => booking.bookingId !== data.bookingId
    );
    return {
      ...state,
      futureBookings: newFutureBookings,
    };
  }),
  on(setFutureBookingsAvailability, (state, data) => ({
    ...state,
    futureBookingsAvailability:
      FutureBookingsAvailabilityService.extractAvailabilityForFutureBookings(
        [...state.futureBookings, ...state.alertList],
        data.slotsByActivityId
      ),
  }))
);

const bookingHistoryReducerCreator = createReducer(
  {
    bookingHistory: [],
    isLoadingBookingHistory: false,
  },
  on(loadBookingHistory, (state) => ({
    ...state,
    isLoadingBookingHistory: true,
  })),
  on(loadedBookingHistorySuccess, (state, data) => ({
    ...state,
    bookingHistory: data.bookingHistory,
    isLoadingBookingHistory: false,
  })),
  on(loadedBookingHistoryFail, (state) => ({
    ...state,
    isLoadingBookingHistory: false,
  })),
);

export const basketReducer = (state, action) =>
  basketReducerCreator(state, action);

export const futureBookingsReducer = (state, action) =>
  futureBookingsReducerCreator(state, action);

export const bookingHistoryReducer = (state, action) =>
  bookingHistoryReducerCreator(state, action);

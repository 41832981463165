import { Action, createAction } from '@ngrx/store';

export enum AuthenticationActionTypes {
  Login = '[Authentication] Login',
  SetAuthenticationRedirectUrl = '[Authentication] Set Authentication Redirect Url',
  AuthenticationRequest = '[Authentication] Authentication Request',
  AuthenticationSuccess = '[Authentication] Authentication Success',
  AuthenticationFailure = '[Authentication] Authentication Failed',
  ReauthenticationRequest = '[Authentication] Reauthentication Request',
  ReauthenticationSuccess = '[Authentication] Reauthentication Success',
  ReauthenticationFailure = '[Authentication] Reauthentication Failed',
  LogOut = '[Authentication] Logout',
  RefreshStoreDataPostAuthentication = '[Authentication] Refresh store data after authentication',
  InitiateBiometricLogin = '[Authentication] Initiate biometric login',
  BiometricLoggedInSuccess = '[Authentication] Successfully logged in through biometric function',
  CloseInAppBrowserIfAvailable = '[Authentication] Close the in app browser if an instance exists'
}

export class Login implements Action {
  public readonly type = AuthenticationActionTypes.Login;
}

export class SetAuthenticationRedirectUrl implements Action {
  public readonly type = AuthenticationActionTypes.SetAuthenticationRedirectUrl;
  constructor(public authenticationRedirectUrl: string) {}
}

export class AuthenticationRequest implements Action {
  public readonly type = AuthenticationActionTypes.AuthenticationRequest;
}

export class AuthenticationSuccess implements Action {
  public readonly type = AuthenticationActionTypes.AuthenticationSuccess;

  constructor(public authenticated: boolean, public memberUserId: number) {}
}

export class AuthenticationFailure implements Action {
  public readonly type = AuthenticationActionTypes.AuthenticationFailure;

  constructor(public authenticated: boolean, public message: string) {}
}

export class ReauthenticationRequest implements Action {
  public readonly type = AuthenticationActionTypes.ReauthenticationRequest;
}

export class ReauthenticationSuccess implements Action {
  public readonly type = AuthenticationActionTypes.ReauthenticationSuccess;
}

export class ReauthenticationFailure implements Action {
  public readonly type = AuthenticationActionTypes.ReauthenticationFailure;

  constructor(public message: string) {}
}

export class LogOut implements Action {
  public readonly type = AuthenticationActionTypes.LogOut;

  constructor(public memberId: number) {}
}

export class BiometricLoggedInSuccess implements Action {
  public readonly type = AuthenticationActionTypes.BiometricLoggedInSuccess;
}

export const logOut = createAction(AuthenticationActionTypes.LogOut);

export const refreshStoreDataPostAuthentication = createAction(AuthenticationActionTypes.RefreshStoreDataPostAuthentication);

export const initiateBiometricLogin = createAction(AuthenticationActionTypes.InitiateBiometricLogin);

export const closeInAppBrowserIfAvailable = createAction(AuthenticationActionTypes.CloseInAppBrowserIfAvailable);


export type AuthenticationActions =
  | SetAuthenticationRedirectUrl
  | AuthenticationRequest
  | AuthenticationSuccess
  | ReauthenticationRequest
  | ReauthenticationSuccess
  | ReauthenticationFailure
  | AuthenticationFailure
  | LogOut
  | BiometricLoggedInSuccess;

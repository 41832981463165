import { Injectable } from '@angular/core';
import { NativeBiometric } from 'capacitor-native-biometric';
import { AvailableResult } from 'capacitor-native-biometric/dist/esm/definitions';
import { Observable, exhaustMap, of, map, catchError, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BiometricService {

  constructor() { }

  performBiometricVerification(): Observable<boolean> {
    return from(NativeBiometric.isAvailable()).pipe(
      exhaustMap((result: AvailableResult) => {
        if (!result.isAvailable) {
          return of(false);
        }
        return from(NativeBiometric.verifyIdentity({
          reason: 'Log in using biometric function',
          title: 'Log in using biometric function',
          negativeButtonText: 'Redirect to login page',
          maxAttempts: 3
        })).pipe(
          map(() => true),
          catchError(() => of(false))
        );
      })
    );
  }
}

import { set, formatISO, parse } from 'date-fns';
import { PhoneNumber } from '../../core/models/contact-details.model';
import { Member, PersonalDetails } from '../../core/models/member.model';
import { PatchContactDTO } from '../dtos/contact-details.dto';
import { MemberDTO, PersonalDetailsBodyDTO } from '../dtos/member.dto';

export class MemberMapper{
  static mapPersonalDetailsToPatchContactDTO = (personalDetails: PersonalDetails, primaryPhone: PhoneNumber): PatchContactDTO => ({
   value: personalDetails.phoneNumber,
   path: `${Object.keys(primaryPhone || {}).filter(key => key !== 'isPrimary')[0] || 'mobile'}/number`,
   op: 'replace'
  } as PatchContactDTO);

  static mapPrimaryPhoneToPatchContactDTO = (primaryPhone: PhoneNumber): PatchContactDTO => ({
    value: true,
    path: primaryPhone? `${Object.keys(primaryPhone || {}).filter(key => key !== 'isPrimary')[0]}/isPrimary` : `mobile/isPrimary`,
    op: 'replace'
   } as PatchContactDTO);

  static mapToPersonalDetailsBodyDTO = (personalDetails: PersonalDetails): PersonalDetailsBodyDTO => {
   let date = new Date();
   date = set(date, {hours: 11});
   return {
    firstName: personalDetails.firstName,
    lastName: personalDetails.lastName,
    dateOfBirth: formatISO(set(parse(personalDetails.dateOfBirth.toString(), 'dd/MM/yyyy', date), {hours: 12})),
    email: personalDetails.email,
    gender: personalDetails.gender,
    memberId: personalDetails.memberId,
    title: personalDetails.title,
    titleId: personalDetails.titleId,
    phoneNumber: personalDetails.phoneNumber
    } as PersonalDetailsBodyDTO;
  };

  static mapToMember(response: MemberDTO): Member {
    return {
      dateOfBirth: response.dateOfBirth,
      email: response.email,
      firstName: response.firstName,
      gender: response.gender,
      lastName: response.lastName,
      memberId: response.memberId,
      priceLevelId: response.priceLevelId,
      title: response.title,
      titleId: response.titleId,
    };
  }
}


/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { TabProps } from '@shared/components/icon-container/tab-props';
import {
  changeFooterStatus,
  loadMerchantIdSuccess,
  loadPreferredLanguageFail,
  loadPreferredLanguageSuccess,
  loadedLanguagesSuccess,
  loadedWalletAvailabilityFailure,
  loadedWalletAvailabilitySuccess,
  setColorTheme,
  setLanguage,
  setLanguageSuccess,
  setSelectedTab,
  setSystemColorTheme,
} from './../actions/app-config.actions';
import { IAppConfigState, initialAppConfigState } from '@store/state/app-config.state';
import { loadAppConfig } from '@store/actions/app-config.actions';
import { createReducer, on } from '@ngrx/store';
import { getInitialState } from '@store/state/app-config.state';
import { environment } from '@environments/environment';
import { logOut } from '../actions/authentication.actions';

const _appConfigReducer = createReducer(
  getInitialState(),
  on(
    loadAppConfig,
    (state): IAppConfigState => ({
      ...state,
    })
  ),
  on(
    changeFooterStatus,
    (state): IAppConfigState => ({
      ...state,
      isFooterVisible: !state.isFooterVisible,
    })
  ),
  on(setSelectedTab, (state, data) => ({
    ...state,
    tabProps: [
      ...state.tabProps.map((tab: TabProps) => ({
        ...tab,
        selected: tab.navRoute === data.selectedTab || (data.selectedTab === '/' && tab.navRoute === '/book'),
      })),
    ],
  })),
  on(loadedLanguagesSuccess, (state, data) => ({
    ...state,
    languages: data.languages,
  })),
  on(setColorTheme, (state, action) => ({
    ...state,
    colorTheme: action.colorTheme,
  })),
  on(setSystemColorTheme, (state, action) => ({
    ...state,
    systemColorTheme: action.colorTheme,
  })),
  on(loadedWalletAvailabilitySuccess, (state, action) => ({
    ...state,
    isWalletEnabled: action.isWalletEnabled,
  })),
  on(loadedWalletAvailabilityFailure, (state) => ({
    ...state,
    isWalletEnabled: false,
  })),
  on(loadPreferredLanguageSuccess, (state, data) => ({
    ...state,
    selectedLanguage: data.languagePersonalisation.value,
    languagePersonalisationId: data.languagePersonalisation.id,
  })),
  on(setLanguageSuccess, (state, action) => ({
    ...state,
    selectedLanguage: action.languagePersonalisation.language,
    languagePersonalisationId: action.languagePersonalisation.personalisationId,
  })),
  on(loadPreferredLanguageFail, (state) => ({
    ...state,
    selectedLanguage: null,
    languagePersonalisationId: 0,
  })),
  on(loadMerchantIdSuccess, (state, action) => ({
    ...state,
    merchantId: action.merchantId,
  })),
  on(logOut, () => initialAppConfigState)
);

export function appConfigReducer(state, action) {
  return _appConfigReducer(state, action);
}

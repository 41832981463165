import { Site } from '@app/core/models/site.model';
import { SiteDTO } from '../dtos/site.dto';

export class SiteMapper {
  static mapToSite(site: SiteDTO): Site {
    return {
      id: site.id,
      name: site.name,
      logoUrl: site.logoUrl,
      address: site.address,
      isActive: site.isActive,
      isWebVisible: site.isWebVisible,
      facilities: site?.facilities,
      amenities: site?.amenities
    } as Site;
  }
  static mapToSiteDetails(site: SiteDTO): SiteDTO {
    return {
      id: site.id,
      name: site.name,
      logoUrl: site.logoUrl,
      address: site.address,
      isActive: site.isActive,
      isWebVisible: site.isWebVisible,
      contact: site.contact,
      description: site.description,
      images: site.images,
      legalName: site.legalName,
      location: site.location,
      openActiveTaxMode: site.openActiveTaxMode,
      organisations: site.organisations,
      privacyPolicyUrl: site.privacyPolicyUrl,
      socialMedia: site.socialMedia,
      termsConditionsUrl: site.termsConditionsUrl,
      vatId: site.vatId,
      websiteUrl: site.websiteUrl
    } as SiteDTO;
  }
}

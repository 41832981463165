import { displayInfoToast } from '@store/actions/notification-handling.actions';
import { NetworkStatusService } from '@core/network-status/network-status.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  ASSETS_PATH_HTTP,
  TOAST_ALERT_CIRCLE_ICON_NAME,
  TOAST_CONNECTION_CLASS,
  TOAST_OFFLINE_MESSAGE,
} from '@app/core/constants';
import { Store } from '@ngrx/store';

@Injectable()
export class NetwokStatusInterceptor implements HttpInterceptor {

  constructor(
    private networkStatusService: NetworkStatusService, private store: Store
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.networkStatusService.networkStatus$.pipe(
      first(),
      switchMap(status => {
        if (!status && !request.url.includes(ASSETS_PATH_HTTP)) {
          this.store.dispatch(displayInfoToast({
            message: TOAST_OFFLINE_MESSAGE,
            iconName: TOAST_ALERT_CIRCLE_ICON_NAME,
            toastClass: TOAST_CONNECTION_CLASS
          }));
          throw new HttpErrorResponse({ error: 'Internet is required.' });
        }
        return next
        .handle(request);
      })
      );
  }
}

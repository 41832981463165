import { nrOfElementsThatFitVertically } from '@core/helpers/number-of-fitting-objects-helper';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gs-skeleton-placeholder',
  templateUrl: './skeleton-placeholder.component.html',
  styleUrls: ['./skeleton-placeholder.component.scss'],
})
export class SkeletonPlaceholderComponent implements OnInit {
  @Input() heightOfAnElement: number;
  @Input() containerReference: HTMLElement;
  @Input() hasPadding = true;
  constructor() { }

  ngOnInit() {}

  counter(boundary: number) {
    return Array(boundary);
  }

  nrOfElementsThatFitVertically() {
    return nrOfElementsThatFitVertically(this.heightOfAnElement, this.containerReference);
  }
}

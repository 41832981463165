/* eslint-disable @typescript-eslint/naming-convention */

export interface CardDetails {
  id: number;
  holder: string;
  cardNumber4Digits: string;
  expiryDate: string;
  type: CardType;
}

export enum CardType {
  MasterCardCredit = 'MC',
  MasterCardDebit = 'MD',
  VisaCredit = 'VC',
  VisaDebit = 'VD',
}

import { CountryCode } from '@app/core/models/contact-details.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormMessageType } from '@app/core/constants';

@Component({
  selector: 'gs-form-field-phone-number',
  templateUrl: './form-field-phone-number.component.html',
  styleUrls: ['./form-field-phone-number.component.scss'],
})
export class FormFieldPhoneNumberComponent implements OnInit {
  @Input() fieldLabel: string;
  @Input() fieldPlaceholder: string;
  @Input() inputId: string;
  @Input() inputMode: string;
  @Input() type: string;
  @Input() error: string;
  @Input() inputValue: string;
  @Input() selectedCountryCode: CountryCode;
  @Output() inputBlurEvent = new EventEmitter<void>();
  @Output() inputChangeEvent = new EventEmitter<string>();
  @Output() openModalEvent = new EventEmitter<void>();
  localFormMessageType = FormMessageType;
  constructor() { }

  ngOnInit() {}

  onInputBlur(): void {
    this.inputBlurEvent.emit();
  }

  onInputChange(event): void {
    if(event.detail.value.length === 0) {
      this.inputChangeEvent.emit(`${event.detail.value}`);
    }
    else {
      this.inputChangeEvent.emit(`${this.selectedCountryCode.dialCode}${event.detail.value}`);
    }
  }

  openModal(): void {
    this.openModalEvent.emit();
  }

}

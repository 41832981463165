import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';
import { setShouldSaveCardValue } from '@app/store/actions/payment.actions';
import { selectPersonalDetails } from '@app/store/selectors/account.selectors';
import { selectIsWalletEnabled, selectMerchantId } from '@app/store/selectors/app-config.selectors';
import { Store } from '@ngrx/store';
import { isFuture, isPast, isValid } from 'date-fns';
import { ReplaySubject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'gs-add-card',
  templateUrl: 'add-card.component.html',
  styleUrls: ['add-card.component.scss'],
})
export class AddCardComponent implements OnInit, OnDestroy {
  @ViewChild('cardNumberInput') cardNumberInput;
  @ViewChild('startInput') startInput;
  @ViewChild('expiryInput') expiryInput;
  @ViewChild('cvvInput') cvvInput;
  @Input() shouldDisplayToggle = true;
  @Input() shouldDisplayHorizontalLine = true;
  personalDetails$ = this.store.select(selectPersonalDetails);
  isWalletEnabled$ = this.store.select(selectIsWalletEnabled);
  selectMerchantId$ = this.store.select(selectMerchantId);

  merchantID: string;
  cardNumber = '';
  startDate = '';
  expiryDate = '';
  cvv = '';
  customerName = '';
  email = '';
  postCode = '';
  shouldSaveCard = false;

  private onDestroy$: ReplaySubject<void> = new ReplaySubject();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.selectMerchantId$.pipe(takeUntil(this.onDestroy$)).subscribe((res) => (this.merchantID = res));
    this.personalDetails$.pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
      this.customerName = `${result.firstName || ''} ${result.lastName || ''}`.trim();
      this.email = result.email;
    });
    this.buildCardStreamForm();
  }

  ngOnDestroy(): void {
    window.hostedFields.forms = [];
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  cardNumberInputChanged(event): void {
    this.cardNumber =
      event.detail.value
        .replace(/[^0-9]+/g, '')
        .match(/.{1,4}/g)
        ?.join(' ') || '';
    this.cardNumberInput.value = this.cardNumber;
  }

  expiryDateInputChanged(event): void {
    const newExpiry = TimeUtilitiesHelper.formatStringForCardDate(event.detail.value);
    this.expiryDate = newExpiry;
    this.expiryInput.value = newExpiry;
  }

  startDateInputChanged(event): void {
    const newStart = TimeUtilitiesHelper.formatStringForCardDate(event.detail.value);
    this.startDate = newStart;
    this.startInput.value = newStart;
  }

  isDateInPast(): boolean {
    const expiryDate = TimeUtilitiesHelper.parseDateCard(this.expiryDate, false);
    if (expiryDate === null) {
      return false;
    }
    return isPast(expiryDate);
  }

  isExpiryDateValid() {
    return !this.isDateInPast() && isValid(TimeUtilitiesHelper.parseDateCard(this.expiryDate, false));
  }

  isDateInFuture(): boolean {
    const startDate = TimeUtilitiesHelper.parseDateCard(this.startDate, true);
    if (startDate === null) {
      return false;
    }
    return isFuture(startDate);
  }

  isStartDateValid() {
    return (
      this.startDate.trim() === '' ||
      (!this.isDateInFuture() && isValid(TimeUtilitiesHelper.parseDateCard(this.startDate, true)))
    );
  }

  isMonthInvalid(date: string): boolean {
    const [month, year] = date.split('/');
    if (parseInt(month, 10) < 1 || parseInt(month, 10) > 12) {
      return true;
    }
    return false;
  }

  cvvInputChanged(event): void {
    this.cvv = event.detail.value.replace(/[^0-9]+/g, '');
    this.cvvInput.value = this.cvv;
  }

  shouldSaveCardInputChanged(): void {
    this.store.dispatch(setShouldSaveCardValue({ shouldSaveCard: this.shouldSaveCard }));
  }

  isAddCardFormValid(): boolean {
    return (
      this.isExpiryDateValid() &&
      this.isStartDateValid() &&
      this.cardNumber.length === 19 &&
      this.expiryDate.length === 7 &&
      this.cvv.length >= 3 &&
      (this.startDate.length === 7 || this.startDate === '')
    );
  }

  private buildCardStreamForm(): any {
    const formElement = document.getElementById('addCardFormHidden');
    const cardstreamForm = new window.hostedFields.classes.Form(formElement, {
      autoSetup: true,
      autoSubmit: true,
      merchantID: this.merchantID,
      fields: {
        customerName: {
          value: this.customerName,
        },
        customerEmail: {
          value: this.email,
        },
        // eslint-disable-next-line id-blacklist
        any: {
          nativeEvents: true,
        },
      },
    });

    window.hostedFields.forms.push(cardstreamForm);
  }
}

import { OriginForPaymentPage } from '@core/constants';
/* eslint-disable @typescript-eslint/naming-convention */

import { createAction, props } from '@ngrx/store';

export enum PaymentActionTypes {
  PayByNewCard = '[Payment] Pay by New Card',
  PayByNewCardAndSave = '[Payment] Pay by New Card and save',
  PayByCard = '[Payment] Pay by Card',
  PayedByNewCardSuccess = '[Payment] Payed by New Card Success',
  PayedByCardSuccess = '[Payment] Payed By Existing Card Success',
  PaymentError = '[Payment] Payment error',
  SetShouldSaveCardValue = '[Payment] Set shouldSaveCard Value',
};

export const  payByNewCard = createAction(PaymentActionTypes.PayByNewCard);
export const  payByCard = createAction(PaymentActionTypes.PayByCard);
export const  payedByNewCardSuccess = createAction(PaymentActionTypes.PayedByNewCardSuccess);
export const  payedByCardSuccess = createAction(PaymentActionTypes.PayedByCardSuccess);
export const  payByNewCardAndSave = createAction(PaymentActionTypes.PayByNewCardAndSave, props<{ paymentToken: string, saveCardToWallet: boolean }>());
export const  paymentError = createAction(PaymentActionTypes.PaymentError);
export const  setShouldSaveCardValue = createAction(PaymentActionTypes.SetShouldSaveCardValue, props<{ shouldSaveCard: boolean }>());


import { AdditionalBookees, SlotsByActivityIdMap } from '@core/models/session.model';
import { BookingBodyElement } from '@app/core/models/booking-body.model';
import { BasketItem } from '@app/core/models/basket-item.model';
import { Member } from '@app/core/models/member.model';
import { createAction, props, union } from '@ngrx/store';
import { FutureBooking } from '@app/core/models/future-booking.model';
import { PreviousBooking } from '@app/core/models/previous-booking.model';
import { PricingQuote } from '@app/core/models/pricing.model';

export const ADD_BASKET_ITEM = '[Basket] Add item to basket';
export const ADD_BASKET_ITEM_SUCCESS = '[Basket] Add item to basket success';
export const ADD_BASKET_ITEM_FAIL = '[Basket] Add item to basket fail';
export const REMOVE_BASKET_ITEM = '[Basket] Delete basket item through API';
export const REMOVED_BASKET_ITEM_SUCCESS = '[Basket] Delete basket item through API success';
export const REMOVED_BASKET_ITEM_FAIL = '[Basket] Delete basket item through API fail';
export const REMOVE_BOOKEE = '[Basket] Delete bookee from basket item';
export const INITIATE_ZERO_PRICED_BOOKING = '[BASKET] Create a lease for zero priced booking';
export const MAKE_ZERO_PRICED_BOOKING = '[BASKET] Make a zero priced booking';
export const MADE_ZERO_PRICED_BOOKING_SUCCESS = '[BASKET] Make a zero priced booking through the API success';
export const REMOVE_ZERO_PRICED_BOOKING = '[Basket] Cancel booking item through API';
export const REMOVED_ZERO_PRICED_BOOKING_SUCCESS = '[Basket] Cancel booking item through API success';
export const REMOVED_ZERO_PRICED_BOOKING_FAIL = '[Basket] Cancel booking item through API fail';
export const MAKE_BASKET_BOOKING = '[BASKET] Make a basket booking';
export const MADE_BASKET_BOOKING_SUCCESS = '[BASKET] Make a basket booking through the API success';
export const CLEAR_BASKET = '[BASKET] Clear Basket Items';
export const CLEAR_CHECKED_OUT_BOOKINGS = '[BASKET] Clear checked out bookings';
export const UPDATE_ADDITIONAL_BOOKEES = '[BASKET] Update additional bookees by slot reference';
export const UPDATE_MEMBER_ATTENDANCE_ON_BASKET_ITEM = '[BASKET] Update member attendance on basket item';
export const SET_IS_BASKET_ITEM_MODIFIED = '[Basket] Set is basket item modified';
export const CLEAR_EXPIRED_BASKET_LEASES = '[Basket] Remove basket items with expired leases';
export const UPDATE_FROM_ACTIVITY_DETAILS = '[Basket] Update basket item from activity details';
export const UPDATE_LEASE_SUCCESS = '[Basket] Update lease success';
export const UPDATE_PRICE_QUOTE = '[Basket] Update price quote';
export const UPDATE_PRICE_QUOTE_SUCCESS = '[Basket] Update price quote success';
export const SET_FUTURE_BASKET_ITEM = '[Basket] Set the future basket update';

export const addBasketItem = createAction(ADD_BASKET_ITEM, props<{ basketItem: BasketItem; userDetails: Member }>());
export const addBasketItemSuccess = createAction(ADD_BASKET_ITEM_SUCCESS, props<{ basketItem: BasketItem }>());
export const addBasketItemFail = createAction(ADD_BASKET_ITEM_FAIL);
export const removeBasketItem = createAction(REMOVE_BASKET_ITEM, props<{ leaseId: string }>());
export const removeBasketItemSuccess = createAction(REMOVED_BASKET_ITEM_SUCCESS, props<{ leaseId: string }>());
export const removeBasketItemFail = createAction(REMOVED_BASKET_ITEM_FAIL);
export const removeBookee = createAction(REMOVE_BOOKEE, props<{ leaseId: string; bookeeId: string }>());
export const initiateZeroPricedBooking = createAction(
  INITIATE_ZERO_PRICED_BOOKING,
  props<{ basketItem: BasketItem; userDetails: Member }>()
);
export const makeZeroPricedBooking = createAction(MAKE_ZERO_PRICED_BOOKING, props<{ booking: BookingBodyElement }>());
export const madeZeroPricedBookingSuccess = createAction(MADE_ZERO_PRICED_BOOKING_SUCCESS);
export const removeZeroPricedBooking = createAction(
  REMOVE_ZERO_PRICED_BOOKING,
  props<{ memberId: number; bookingId: string }>()
);
export const removeZeroPricedBookingSuccess = createAction(
  REMOVED_ZERO_PRICED_BOOKING_SUCCESS,
  props<{ memberId: number; bookingId: string }>()
);
export const removeZeroPricedBookingFail = createAction(REMOVED_ZERO_PRICED_BOOKING_SUCCESS);
export const makeBasketBooking = createAction(MAKE_BASKET_BOOKING, props<{ bookings: BookingBodyElement[] }>());
export const madeBasketBookingSuccess = createAction(MADE_BASKET_BOOKING_SUCCESS);
export const clearBasketItems = createAction(CLEAR_BASKET);
export const clearCheckedOutBookings = createAction(CLEAR_CHECKED_OUT_BOOKINGS);
export const updateAdditionalBookees = createAction(
  UPDATE_ADDITIONAL_BOOKEES,
  props<{ slotReference: string; additionalBookees: AdditionalBookees[]}>()
);
export const updateMemberAttendanceOnBasketItem = createAction(
  UPDATE_MEMBER_ATTENDANCE_ON_BASKET_ITEM,
  props<{ slotReference: string; isMemberAttending: boolean}>()
);

export const updateBasketItemsFromActivityDetails = createAction(
  UPDATE_FROM_ACTIVITY_DETAILS,
  props<{slotReference: string; isMemberAttending: boolean; additionalBookees: AdditionalBookees[]; priceQuote: PricingQuote}>()
);

export const updateLeaseSuccess = createAction(
  UPDATE_LEASE_SUCCESS,
  props<{basketItem: BasketItem}>()
);

export const updatePriceQuote = createAction(
  UPDATE_PRICE_QUOTE,
  props<{basketItem: BasketItem}>()
);

export const updatePriceQuoteSuccess = createAction(
  UPDATE_PRICE_QUOTE_SUCCESS,
  props<{basketItem: BasketItem; priceQuote: PricingQuote}>()
);

export const setIsBasketItemModified = createAction(
  SET_IS_BASKET_ITEM_MODIFIED,
  props<{isBasketItemModified: boolean}>()
);
export const clearExpiredBasketLeases = createAction(CLEAR_EXPIRED_BASKET_LEASES);

export const itemsActions = union({
  addBasketItem,
  addBasketItemSuccess,
  addBasketItemFail,
  removeBasketItem,
  removeBasketItemSuccess,
  removeBasketItemFail,
  removeBookee,
  makeZeroPricedBooking,
  initiateZeroPricedBooking,
  makeBasketBooking,
  madeBasketBookingSuccess,
  removeZeroPricedBooking,
  removeZeroPricedBookingSuccess,
  removeZeroPricedBookingFail,
  clearBasketItems,
  updateMemberAttendanceOnBasketItem,
  updateAdditionalBookees,
  updateBasketItemsFromActivityDetails,
  updatePriceQuote,
  updatePriceQuoteSuccess
});

export type BasketItemsActions = typeof itemsActions;

export const LOAD_FUTURE_BOOKINGS = '[Future-booking] Get the future bookings based on member id';
export const LOADED_FUTURE_BOOKINGS_SUCCESS =
  '[Future-booking] Successfully got the future bookings based on member id';
export const LOADED_FUTURE_BOOKINGS_FAIL = '[Future-booking] Get the future bookings based on member id failed';
const SET_FUTURE_BOOKINGS_AVAILABILITY = '[Future-booking] Set future bookings availability';

export const LOAD_BOOKING_HISTORY =
  '[Booking-history] Get the booking history based on member id and optional, starting date';
export const LOADED_BOOKING_HISTORY_SUCCESS = '[Booking-history] Successfully got the booking history';
export const LOADED_BOOKING_HISTORY_FAIL = '[Booking-history] Get the booking history failed';
export const LOAD_ONE_YEAR_OF_BOOKING_HISTORY = '[Booking-history] Load one year of booking history';
export const LOADED_ONE_YEAR_OF_BOOKING_HISTORY_SUCCESS =
  '[Booking-history] Successfully got one year of booking history';
export const LOADED_ONE_YEAR_OF_BOOKING_HISTORY_FAIL = '[Booking-history] Get one year of booking history failed';

export const loadFutureBookings = createAction(
  LOAD_FUTURE_BOOKINGS,
  props<{ memberId: number; shouldLoadSessions?: boolean }>()
);
export const loadedFutureBookingsSuccess = createAction(
  LOADED_FUTURE_BOOKINGS_SUCCESS,
  props<{ futureBookings: FutureBooking[]; shouldLoadSessions?: boolean }>()
);
export const loadedFutureBookingsFail = createAction(LOADED_FUTURE_BOOKINGS_FAIL);
export const setFutureBookingsAvailability = createAction(
  SET_FUTURE_BOOKINGS_AVAILABILITY,
  props<{ slotsByActivityId: SlotsByActivityIdMap }>()
);

export const loadBookingHistory = createAction(
  LOAD_BOOKING_HISTORY,
  props<{memberId: number; dateTimeFrom?: string; dateTimeTo?: string}>()
);
export const loadedBookingHistorySuccess = createAction(LOADED_BOOKING_HISTORY_SUCCESS, props<{bookingHistory: PreviousBooking[]}>());
export const loadedBookingHistoryFail = createAction(LOADED_BOOKING_HISTORY_FAIL);
export const setFutureBasketItem = createAction(SET_FUTURE_BASKET_ITEM, props<{futureBasketItem: BasketItem}>());

import { Component, Input, OnInit } from '@angular/core';
import { GenericBooking } from '@app/core/models/generic-booking.model';

@Component({
  selector: 'gs-entry-booking-item',
  templateUrl: './entry-booking-item.component.html',
  styleUrls: ['./entry-booking-item.component.scss'],
})
export class EntryBookingItemComponent implements OnInit {

  @Input() futureBooking: GenericBooking;
  constructor() {}

  ngOnInit() {}
}

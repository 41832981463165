import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gs-no-results-generic',
  templateUrl: './no-results-generic.component.html',
  styleUrls: ['./no-results-generic.component.scss'],
})
export class NoResultsGenericComponent implements OnInit {
  @Input() message: string;
  @Input() image: string;
  constructor() { }

  ngOnInit() {}

}

import { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { AnimationController } from '@ionic/angular';

import { TabProps } from '@app/shared/components/icon-container/tab-props';

@Component({
  selector: 'gs-icon-container',
  templateUrl: './icon-container.component.html',
  styleUrls: ['./icon-container.component.scss'],
})
export class IconContainerComponent implements OnInit {
  @Input() tabProps: TabProps;



  constructor() {}

  ngOnInit() {}
}

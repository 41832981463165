import { MemberPersonalisationDTO } from '@app/api/dtos/member.dto';
import { ColorThemes } from '@app/core/constants';
import { Language, LanguagePersonalisation } from '@app/core/models/language.model';
import { createAction, props } from '@ngrx/store';

export const LOAD_APP_CONFIG = '[App Config] Get App Config from API';
export const CHANGE_FOOTER_STATUS = '[App Config] Display/Hide footer';
export const LOAD_LANGUAGES = '[App Config] Load languages';
export const LOADED_LANGUAGES_SUCCESS = '[App Config] Load languages success';
export const LOADED_LANGUAGES_FAIL = '[App Config] Load languages fail';
export const SET_LANGUAGE = '[App Config] Set language';
export const SET_LANGUAGE_SUCCESS = '[App Config] Set language success';
export const SET_LANGUAGE_FAIL = '[App Config] Set language fail';
export const CHANGE_TAB = '[Tabs] Tab changed';
export const SET_SELECTED_TAB = '[Tabs] New tab selected';
export const SET_COLOR_THEME = '[Theme] Set color theme';
export const SET_SYSTEM_COLOR_THEME = '[Theme] Set system color theme';
export const LOAD_WALLET_AVAILABILITY = '[App config] Load is wallet functionality enabled';
export const LOADED_WALLET_AVAILABILITY_SUCCESS = '[App config] Loaded is wallet functionality enabled success';
export const LOADED_WALLET_AVAILABILITY_FAILURE = '[App config] Loaded is wallet functionality enabled failure';
export const LOAD_PREFERRED_LANGUAGE = '[App Config] Load member preferred language';
export const LOAD_PREFERRED_LANGUAGE_SUCCESS = '[App Config] Load member preferred language success';
export const LOAD_PREFERRED_LANGUAGE_FAIL = '[App Config] Load member preferred language fail';
export const LOAD_MERCHANT_ID = '[App Config] Load MerchantId';
export const LOAD_MERCHANT_ID_SUCCESS = '[App Config] MerchantId success';
export const LOAD_MERCHANT_ID_FAIL = '[App Config] MerchantId fail';

export const changeTab = createAction(CHANGE_TAB);
export const setSelectedTab = createAction(SET_SELECTED_TAB, props<{ selectedTab: string }>());
export const setLanguage = createAction(SET_LANGUAGE, props<{ memberId: number; language: string }>());
export const setLanguageSuccess = createAction(
  SET_LANGUAGE_SUCCESS,
  props<{ languagePersonalisation: LanguagePersonalisation }>()
);
export const setLanguageFail = createAction(SET_LANGUAGE_FAIL);
export const loadLanguages = createAction(LOAD_LANGUAGES);
export const loadedLanguagesSuccess = createAction(LOADED_LANGUAGES_SUCCESS, props<{ languages: Language[] }>());
export const loadLanguagesFail = createAction(LOADED_LANGUAGES_FAIL);
export const loadAppConfig = createAction(LOAD_APP_CONFIG);
export const changeFooterStatus = createAction(CHANGE_FOOTER_STATUS);
export const setColorTheme = createAction(SET_COLOR_THEME, props<{ colorTheme: ColorThemes }>());
export const setSystemColorTheme = createAction(SET_SYSTEM_COLOR_THEME, props<{ colorTheme: 'light' | 'dark' }>());
export const loadWalletAvailability = createAction(LOAD_WALLET_AVAILABILITY);
export const loadedWalletAvailabilitySuccess = createAction(
  LOADED_WALLET_AVAILABILITY_SUCCESS,
  props<{ isWalletEnabled: boolean }>()
);
export const loadedWalletAvailabilityFailure = createAction(LOADED_WALLET_AVAILABILITY_FAILURE);

export const loadPreferredLanguage = createAction(LOAD_PREFERRED_LANGUAGE, props<{ memberId: number }>());
export const loadPreferredLanguageSuccess = createAction(
  LOAD_PREFERRED_LANGUAGE_SUCCESS,
  props<{ languagePersonalisation: MemberPersonalisationDTO }>()
);
export const loadPreferredLanguageFail = createAction(LOAD_PREFERRED_LANGUAGE_FAIL);
export const loadMerchantId = createAction(LOAD_MERCHANT_ID, props<{ siteId?: string }>());
export const loadMerchantIdSuccess = createAction(LOAD_MERCHANT_ID_SUCCESS, props<{ merchantId }>());
export const loadMerchantIdFail = createAction(LOAD_MERCHANT_ID_FAIL);

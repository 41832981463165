import { DOWN_ARROW } from '@core/constants';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gs-time-button',
  templateUrl: './time-button.component.html',
  styleUrls: ['./time-button.component.scss'],
})
export class TimeButtonComponent implements OnInit {
  @Input() iconName = DOWN_ARROW;
  @Input() header = '';
  @Input() buttonClass = '';
  @Input() timeValue = '';

  constructor() { }

  ngOnInit() {}

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gs-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder = '';
  @Input() question = '';
  @Input() id = '';
  @Input() focusedSearchBar = false;
  @Input() selectedValue = false;
  @Input() fontSize = '14px';
  @Input() borderType = 'regular';
  @Output() searchbarClickEvent = new EventEmitter<void>();

  constructor() { }

  onSearchbarClick() {
    this.searchbarClickEvent.emit();
  }

  ngOnInit() {}

}

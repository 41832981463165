import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: `${environment.apiUrl}/identity`,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + window.location.pathname,

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: 'AppConsumerClient',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'offline_access openid api cloud',

  // Also requires enabling "Verbose" level in devtools
  showDebugInformation: false,

  // Set this to true if you want to use silent refresh together with code flow
  useSilentRefresh: true,
  // Defines when the token_timeout event should be raised. If you set this to the default value 0.80,
  // the event is triggered after 80% of the token's life time.
  timeoutFactor: 0.8,

  // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040
  clearHashAfterLogin: false,
  postLogoutRedirectUri: window.location.origin + '/book',
  // Set this to 0 in order to not have any delay between the real expiration time of token
  // and the one that angular-oauth2-oidc library considers to be (this should have a value
  // set if there is a delay between the server storing of value and when the frontend recieves it)
  clockSkewInSec: 10,
};

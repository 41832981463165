import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gs-is-booked',
  templateUrl: './is-booked.component.html',
  styleUrls: ['./is-booked.component.scss'],
})
export class IsBookedComponent implements OnInit {
  @Input() isBooked: boolean = null;
  constructor() { }

  ngOnInit() {}
}

import { Component, Input, HostBinding } from '@angular/core';
import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';
import { CardDetails, CardType } from '@app/core/models/card-details.model';

@Component({
  selector: 'gs-payment-card',
  templateUrl: 'payment-card.component.html',
  styleUrls: ['payment-card.component.scss']
})
export class PaymentCardComponent {
  @Input() card: CardDetails;
  @HostBinding('class') get hostClass(): string {
    const expiryClass = this.isCardExpired() ? 'expired-card' : '';
    const cardTypeClass = this.getCardTypeCssClass();
    return cardTypeClass + ' ' + expiryClass;
  }

  isVisaCard(): boolean {
    return this.card.type === CardType.VisaCredit || this.card.type === CardType.VisaDebit;
  }

  getCardTypeCssClass(): string {
    switch (this.card.type) {
      case CardType.VisaDebit:
        return 'visa-debit';
      case CardType.VisaCredit:
        return 'visa-credit';
      case CardType.MasterCardDebit:
        return 'mastercard-debit';
      case CardType.MasterCardCredit:
        return 'mastercard-credit';
      default:
        return '';
    }
  }

  getCardTypeName(): string {
    if (this.card.type === CardType.MasterCardCredit || this.card.type === CardType.VisaCredit) {
      return 'CONTENT.SAVED_CARDS.CREDIT_CARD';
    }
    return 'CONTENT.SAVED_CARDS.DEBIT_CARD';
  }

  getCardLogo(): string {
    return this.isVisaCard() ? 'icon-gs-icon-visa' : 'icon-gs-icon-mastercard';
  }

  isCardExpired(): boolean{
    const cardDate = TimeUtilitiesHelper.parseDateCard(this.card.expiryDate, true);
    return TimeUtilitiesHelper.isDateFromPastMonths(cardDate);
  }
}

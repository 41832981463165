import { Component } from '@angular/core';
import { MatCalendarHeader } from '@angular/material/datepicker';

@Component({
  selector: 'gs-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
})
export class CalendarHeaderComponent extends MatCalendarHeader<any> {
  /** Handles user clicks on the previous button. */
  customPrev(event): void {
    this.previousClicked();
    event.stopPropagation();
    this.calendar.monthSelected.emit(this.calendar.activeDate);
  }

  /** Handles user clicks on the next button. */
  customNext(event): void {
    this.nextClicked();
    event.stopPropagation();
    this.calendar.monthSelected.emit(this.calendar.activeDate);
  }

  stopPropagation(event): void {
    event.stopPropagation();
  }

}

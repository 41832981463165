import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { navigateBack } from '@store/actions/navigation.actions';
import { HeaderTabs } from '@app/core/constants';
import { AccesibilityHelper } from '@app/core/helpers/accessibility';
import { lighten } from 'color2k';

@Component({
  selector: 'gs-tabs-header',
  templateUrl: './tabs-header.component.html',
  styleUrls: ['./tabs-header.component.scss'],
})
export class TabsHeaderComponent {
  @Input() hasBorderRadius = false;
  @Input() showBackButton = false;
  @Input() isSingleOnHeader = false;
  @Input() styling;
  @Input() isWhiteHeader = false;
  @Input() firstTabText = '';
  @Input() secondTabText = '';
  @Input() firstTabValue: HeaderTabs;
  @Input() secondTabValue: HeaderTabs;
  @Output() changeTabEvent =  new EventEmitter<HeaderTabs>();

  constructor(private store: Store) {
  }

  isFontColorBlack(): boolean {
    const element = document.getElementById('toolbar');
    return element ? AccesibilityHelper.shouldElementFontColorBeBlack(element) : true;
  }

  navigateBack() {
    this.store.dispatch(navigateBack());
  }

  changeTab(event): void{
    this.changeTabEvent.emit(event.detail.value);
  }
}

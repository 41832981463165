import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isAfter, isBefore, subMonths } from 'date-fns';

@Component({
  selector: 'gs-month-control',
  templateUrl: './month-control.component.html',
  styleUrls: ['./month-control.component.scss'],
})
export class MonthControlComponent implements OnInit {
  @Input() currentMonth: Date;
  @Output() nextMonth = new EventEmitter<Date>();
  @Output() previousMonth = new EventEmitter<Date>();
  constructor() { }

  ngOnInit() {}

  onPreviousButtonClicked() {
    this.previousMonth.emit(this.currentMonth);
  }

  onNextButtonClicked() {
    this.nextMonth.emit(this.currentMonth);
  }

  previousEnabled(): boolean {
    return isAfter(this.currentMonth, subMonths(new Date(), 11).setDate(1));
  }

  nextEnabled(): boolean {
    return isBefore(this.currentMonth, (new Date()).setDate(31));
  }
}

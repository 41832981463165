import { HttpClient } from '@angular/common/http';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, OnDestroy } from '@angular/core';
import { ActivationEnd, Router, RouterEvent } from '@angular/router';
import { FINANCIAL_ENDPOINT } from '@app/core/constants';
import { ThreeDSecureModalComponent } from '@app/shared/components/three-d-secure-modal/three-d-secure-modal.component';
import { environment } from '@environments/environment';
import { catchError, filter, first, from, map, mergeMap, Observable, of, ReplaySubject, Subject, takeUntil, throwError } from 'rxjs';
import { GladstonePaymentMethod, GladstonePaymentProvider, GladstonePaymentRequestDTO, GladstonePaymentRequestResponseDTO, GladstonePaymentRequestResponseStatus, ThreeDSecureChallengeFields } from '../dtos/payment.dto';

declare global {
  interface Window {
    hostedFields;
  }
}

@Injectable({
  providedIn: 'root'
 })
 export class PaymentService implements OnDestroy{
  private threeDSecureChallengeResponse$: Subject<string> = new Subject();
  private threeDSecureChallengeModalComponentDestroyed$: Subject<void> = new Subject();
  private threeDSecureChallengeModalComponentReference: ComponentRef<ThreeDSecureModalComponent>;
  private onDestroy$: ReplaySubject<void> = new ReplaySubject();

  constructor(
    private readonly applicationRef: ApplicationRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly http: HttpClient,
    private readonly injector: Injector,
    private readonly router: Router,
  ) {
    this.removeThreeDSecureChallengeModalOnNavigation();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.removeThreeDSecureChallengeModal();
  }

  getPaymentToken(): Observable<string> {
    const cardForm = window.hostedFields.forms[0];

    return from(cardForm.getPaymentDetails()).pipe(
      map((response: any) => response.paymentToken)
    );
  }

  processPayment(paymentDetail: GladstonePaymentRequestDTO): Observable<GladstonePaymentRequestResponseDTO> {
    const endpoint = `${environment.apiUrl}${FINANCIAL_ENDPOINT}`;

    return this.http.post<GladstonePaymentRequestResponseDTO>(endpoint, paymentDetail).pipe(
      mergeMap((response: GladstonePaymentRequestResponseDTO) => {
        if (response.responseStatus === GladstonePaymentRequestResponseStatus.ThreeDSecureChallengeRequired) {
          return this.processThreeDSecureChallenge(paymentDetail, response);
        }
        return of(response);
      }),
      catchError(error => throwError(() => error)),
    );
  }

  public processThreeDSecureChallenge(
    paymentDetail: GladstonePaymentRequestDTO,
    response: GladstonePaymentRequestResponseDTO,
  ): Observable<GladstonePaymentRequestResponseDTO> {
    this.renderThreeDSecureChallengeModal(response.threeDSecureChallengeUrl, response.threeDSecureChallengeFields);
    return this.threeDSecureChallengeResponse$.pipe(
      first(),
      mergeMap((threeDSecureChallengeResponse: string) =>
        this.processPayment({
          ...paymentDetail,
          paymentMethods: paymentDetail.paymentMethods.map((paymentMethod: GladstonePaymentMethod) => {
            if (paymentMethod.paymentProvider === GladstonePaymentProvider.Card) {
              const threeDSecureChallengeReference =
                response?.threeDSecureChallengeFields?.threeDSRef || response?.paymentProviderReference;
              return {
                ...paymentMethod,
                threeDSecureChallengeReference,
                threeDSecureChallengeResponse,
              };
            }
            return paymentMethod;
          }),
        }),
      ),
    );
  }

  private renderThreeDSecureChallengeModal(
    threeDSecureChallengeUrl: string,
    threeDSecureChallengeFields: ThreeDSecureChallengeFields,
  ): void {
    if (this.threeDSecureChallengeModalComponentReference) {
      return;
    }

    const componentRef: ComponentRef<ThreeDSecureModalComponent> = this.componentFactoryResolver
      .resolveComponentFactory(ThreeDSecureModalComponent)
      .create(this.injector);

    componentRef.instance.threeDSecureChallengeUrl = threeDSecureChallengeUrl;
    componentRef.instance.threeDSecureChallengeFields = threeDSecureChallengeFields;

    componentRef.instance.handleThreeDSecureChallengeResponse
      .pipe(first(), takeUntil(this.threeDSecureChallengeModalComponentDestroyed$))
      .subscribe((threeDSecureChallengeResponse: string) => {
        this.threeDSecureChallengeResponse$.next(threeDSecureChallengeResponse);
        this.removeThreeDSecureChallengeModal();
      });

    this.threeDSecureChallengeModalComponentReference = componentRef;
    this.applicationRef.attachView(componentRef.hostView);
    this.applicationRef.tick();
  }

  private removeThreeDSecureChallengeModal(): void {
    if (!this.threeDSecureChallengeModalComponentReference) {
      return;
    }
    this.applicationRef.detachView(this.threeDSecureChallengeModalComponentReference.hostView);
    this.applicationRef.tick();
    this.threeDSecureChallengeModalComponentReference.destroy();
    this.threeDSecureChallengeModalComponentReference = undefined;
    this.threeDSecureChallengeModalComponentDestroyed$.next();
  }

  private removeThreeDSecureChallengeModalOnNavigation(): void {
    this.router.events
      .pipe(
        filter((routerEvent: RouterEvent) => routerEvent instanceof ActivationEnd),
        filter(() => !!this.threeDSecureChallengeModalComponentReference),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => this.removeThreeDSecureChallengeModal());
  }
 }

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';

@Component({
  selector: 'gs-sliding-card',
  templateUrl: './sliding-card.component.html',
  styleUrls: ['./sliding-card.component.scss'],
})
export class SlidingCardComponent implements OnInit {
  @ViewChild('slidingContainer') slider: IonItemSliding;
  @Input() backgroundColor = '';
  @Input() hasBoxShadow = true;
  @Input() hasBorder = true;
  @Output() optionClick = new EventEmitter<void>();

  ratio: number;

  constructor() { }

  ngOnInit() {}

  openSlidingItem(event): void{
    this.slider.open('end');
    event.stopPropagation();
  }

  updateSlideRatio(): void{
    this.slider.getSlidingRatio().then(ratio => {
      this.ratio = ratio;
    });
  }

  onOptionClick(event): void{
    this.optionClick.emit();
    event.stopPropagation();

  }
}

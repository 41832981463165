import { createSelector } from '@ngrx/store';
import { alphabetical } from 'radash';
import { IAppState, IFavouritesState } from '../state/app-config.state';

const _favouritesState = (state: IAppState) => state.favourites;

export const selectIsBookingFavourite = (locationAndActivityIds: {siteId: string; activityId: string}) =>
  createSelector(_favouritesState, (state: IFavouritesState) => state.favouriteBookings.some(
    favouriteBooking =>
      favouriteBooking.siteId === locationAndActivityIds.siteId && favouriteBooking.activityId === locationAndActivityIds.activityId
  )
);

export const selectFavouriteBookings = createSelector(
  _favouritesState,
  (state: IFavouritesState) => alphabetical(state.favouriteBookings, booking => booking.activityName)
);

export const selectAreAllBookingsSelected = createSelector(
  _favouritesState,
  (state: IFavouritesState) => state.favouriteBookings.filter(booking => booking.isChecked)
    .reduce((count, booking) => count + 1, 0) === state.favouriteBookings.length
);

export const selectNumberOfSelectedBookings = createSelector(
  _favouritesState,
  (state: IFavouritesState) => state.favouriteBookings.filter(booking => booking.isChecked)
    .reduce((count, booking) => count + 1, 0)
);

import { Identifier } from '@app/core/models/identifier.model';
import { createAction, props, union } from '@ngrx/store';

export const LOAD_IDENTIFIERS = '[Entry] Load member identifiers';
export const LOADED_IDENTIFIERS_SUCCESS = '[Entry] Load member identifiers success';
export const LOADED_IDENTIFIERS_FAIL = '[Entry] Load member identifiers fail';

export const CREATE_SHORT_IDENTIFIER = '[Entry] Create and post the short entry identifier';
export const CREATED_SHORT_IDENTIFIER_SUCCESS = '[Entry] Create and post the short entry identifier success';
export const CREATED_SHORT_IDENTIFIER_FAIL = '[Entry] Create and post the short entry identifier fail';
export const DELETE_SHORT_IDENTIFIER = '[Entry] Delete the short entry identifier';
export const DELETED_SHORT_IDENTIFIER_SUCCESS = '[Entry] Delete the short entry identifier success';
export const DELETED_SHORT_IDENTIFIER_FAIL = '[Entry] Delete the short entry identifier fail';
export const LOAD_TODAYS_BOOKINGS = '[Entry] Load bookings of today';

export const CREATE_OFFLINE_IDENTIFIER = '[Entry] Create and post the offline identifier';
export const CREATED_OFFLINE_IDENTIFIER_SUCCESS = '[Entry] Create and post the offline identifier success';
export const CREATED_OFFLINE_IDENTIFIER_FAIL = '[Entry] Create and post the offline identifier fail';
export const DELETE_OFFLINE_IDENTIFIER = '[Entry] Delete the offline entry identifier';
export const DELETED_OFFLINE_IDENTIFIER_SUCCESS = '[Entry] Delete the offline entry identifier success';
export const DELETED_OFFLINE_IDENTIFIER_FAIL = '[Entry] Delete the offline entry identifier fail';

export const RESET_ENTRY_TIMER_TARGET = '[Entry] Reset entry timer target';
export const resetEntryTimerTarget = createAction(RESET_ENTRY_TIMER_TARGET, props<{target: string}>());

export const loadIdentifiers = createAction(LOAD_IDENTIFIERS, props<{memberId: number; isLoadingIdentifiers: boolean}>());
export const loadIdentifiersSuccess = createAction(LOADED_IDENTIFIERS_SUCCESS,
  props<{identifiers: Identifier[]; isLoadingIdentifiers: boolean}>());
export const loadIdentifiersFail = createAction(LOADED_IDENTIFIERS_FAIL, props<{isLoadingIdentifiers: boolean}>());

export const createShortIdentifier = createAction(CREATE_SHORT_IDENTIFIER);
export const createdShortIdentifierSuccess = createAction(CREATED_SHORT_IDENTIFIER_SUCCESS);
export const createdShortIdentifierFail = createAction(CREATED_SHORT_IDENTIFIER_FAIL);
export const deleteShortIdentifier = createAction(DELETE_SHORT_IDENTIFIER);
export const deletedShortIdentifierSuccess = createAction(DELETED_SHORT_IDENTIFIER_SUCCESS);
export const deletedShortIdentifierFail = createAction(DELETED_SHORT_IDENTIFIER_FAIL);
export const loadTodaysBookings = createAction(LOAD_TODAYS_BOOKINGS);

export const createOfflineIdentifier = createAction(CREATE_OFFLINE_IDENTIFIER);
export const createdOfflineIdentifierSuccess = createAction(CREATED_OFFLINE_IDENTIFIER_SUCCESS, props<{identifier: Identifier}>());
export const createdOfflineIdentifierFail = createAction(CREATED_OFFLINE_IDENTIFIER_FAIL);
export const deleteOfflineIdentifier = createAction(DELETE_OFFLINE_IDENTIFIER);
export const deletedOfflineIdentifierSuccess = createAction(DELETED_OFFLINE_IDENTIFIER_SUCCESS);
export const deletedOfflineIdentifierFail = createAction(DELETED_OFFLINE_IDENTIFIER_FAIL);


const HANDLE_SHORT_IDENTIFIER = '[Entry] Handle the creation and deletion of the short identifier';
export const handleShortIdentifier = createAction(HANDLE_SHORT_IDENTIFIER, props<{delete: boolean; create: boolean}>());
const HANDLE_OFFLINE_IDENTIFIER = '[Entry] Handle the creation and deletion of the offline identifier';
export const handleOfflineIdentifier = createAction(HANDLE_OFFLINE_IDENTIFIER, props<{delete: boolean; create: boolean}>());
const REFRESH_ENTRY_IDENTIFIERS = '[Entry] Refresh entry identifiers selectors';
export const refreshEntryIdentifiers = createAction(REFRESH_ENTRY_IDENTIFIERS);

export const entryActions = union({
  loadIdentifiers,
  loadIdentifiersSuccess,
  createShortIdentifier,
  createdShortIdentifierSuccess,
  createdShortIdentifierFail,
  deleteShortIdentifier,
  createOfflineIdentifier,
  deleteOfflineIdentifier
});

export type EntryActions = typeof entryActions;

import { Injectable } from '@angular/core';
import { LanguageService } from '@app/api/services/language.service';
import { TOAST_ONLINE_ICON_NAME, SET_LANGUAGE_SUCCESS_MESSAGE, SUCCESS_TOAST_CLASS } from '@app/core/constants';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, map, catchError, of, switchMap, EMPTY, withLatestFrom, tap } from 'rxjs';
import {
  LOAD_LANGUAGES,
  LOADED_LANGUAGES_SUCCESS,
  LOADED_LANGUAGES_FAIL,
  SET_LANGUAGE,
  loadWalletAvailability,
  loadedWalletAvailabilitySuccess,
  loadedWalletAvailabilityFailure,
  loadPreferredLanguage,
  loadPreferredLanguageSuccess,
  loadPreferredLanguageFail,
  setLanguage,
  setLanguageSuccess,
  setLanguageFail,
  loadMerchantId,
  loadMerchantIdFail,
  loadMerchantIdSuccess,
} from '../actions/app-config.actions';
import { displaySuccessToast } from '../actions/notification-handling.actions';
import { ConfigurationService } from '@app/api/services/configuration.service';
import { MembersService } from '@app/api/services/members.service';
import { Store } from '@ngrx/store';
import { selectSelectedLanguage } from '../selectors/app-config.selectors';
import { selectAccountState } from '../selectors/account.selectors';
import { selectActivities } from '../selectors/book.selectors';
import { Config } from '@ionic/angular';

@Injectable()
export class AppConfigEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private languageService: LanguageService,
    private configurationService: ConfigurationService,
    private membersService: MembersService
  ) {}

  loadLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_LANGUAGES),
      mergeMap(() =>
        this.languageService.getAll().pipe(
          map((languages) => ({ type: LOADED_LANGUAGES_SUCCESS, languages })),
          catchError(() => of({ type: LOADED_LANGUAGES_FAIL }))
        )
      )
    )
  );

  displaySuccessToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_LANGUAGE),
      map(() =>
        displaySuccessToast({
          message: SET_LANGUAGE_SUCCESS_MESSAGE,
          iconName: TOAST_ONLINE_ICON_NAME,
          toastClass: SUCCESS_TOAST_CLASS,
        })
      )
    )
  );

  loadWalletAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWalletAvailability),
      switchMap(() =>
        this.configurationService.getIsMemberWalletEnabled().pipe(
          map((isWalletEnabled) => loadedWalletAvailabilitySuccess({ isWalletEnabled })),
          catchError(() => of(loadedWalletAvailabilityFailure()))
        )
      )
    )
  );

  loadMerchantId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMerchantId),
      switchMap((action) =>
        this.configurationService.getMerchantId(action?.siteId).pipe(
          map((configEntry) => loadMerchantIdSuccess({ merchantId: configEntry })),
          catchError(() => of(loadMerchantIdFail()))
        )
      )
    )
  );

  loadMemberPreferredLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPreferredLanguage),
      switchMap((action) =>
        this.membersService.getMemberPreferredLanguage(action.memberId).pipe(
          map((languagePersonalisation) => loadPreferredLanguageSuccess({ languagePersonalisation })),
          catchError(() => of(loadPreferredLanguageFail()))
        )
      )
    )
  );

  setLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setLanguage),
      withLatestFrom(this.store.select(selectSelectedLanguage)),
      mergeMap(([action, selectedLanguage]) => {
        if (action.memberId) {
          if (selectedLanguage.language === null) {
            return this.membersService.postMemberPreferredLanguage(action.memberId, action.language).pipe(
              map((resp) =>
                setLanguageSuccess({ languagePersonalisation: { language: action.language, personalisationId: resp } })
              ),
              catchError(() => of(setLanguageFail()))
            );
          } else {
            return this.membersService
              .putMemberPreferredLanguage(action.memberId, action.language, selectedLanguage.personalisationId)
              .pipe(
                map(() =>
                  setLanguageSuccess({
                    languagePersonalisation: {
                      language: action.language,
                      personalisationId: selectedLanguage.personalisationId,
                    },
                  })
                ),
                catchError(() => of(setLanguageFail()))
              );
          }
        }
      })
    )
  );
}

import { Store } from '@ngrx/store';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { navigateBack } from '@app/store/actions/navigation.actions';
import { Subscription } from 'rxjs';
import {
  selectAreAllBookingsSelected,
  selectFavouriteBookings,
  selectNumberOfSelectedBookings
} from '@app/store/selectors/favourites.selectors';
import { selectAllBookings, unselectAllBookings } from '@app/store/actions/favourites.actions';
import { tr } from 'date-fns/locale';
import { selectComputedColorTheme, selectPreferredColorTheme } from '@app/store/selectors/app-config.selectors';

@Component({
  selector: 'gs-title-header',
  templateUrl: './title-header.component.html',
  styleUrls: ['./title-header.component.scss']
})
export class TitleHeaderComponent implements OnInit, OnDestroy {
  @Input() pageTitle = '';
  @Input() subtitle;
  @Input() hasBorderRadius = false;
  @Input() showBackButton = false;
  @Input() isSingleOnHeader = false;
  @Input() showFavouriteButton = false;
  @Input() showEditButton = false;
  @Input() isFavourite = false;
  @Input() isWhiteHeader = false;
  @Input() isOpaque = false;
  @Input() styling;
  @Input() editModeOn = false;
  @Output() favouriteStateChange = new EventEmitter<boolean>();
  @Output() editListMode = new EventEmitter<boolean>();

  areAllBookingsSelected = false;
  isDarkTheme = false;
  favouriteBookings$ = this.store.select(selectFavouriteBookings);
  numberOfSelectedBookings$ = this.store.select(selectNumberOfSelectedBookings);
  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private store: Store) {}

  ngOnInit() {
    const subscription = this.store
      .select(selectAreAllBookingsSelected)
      .subscribe((result) => (this.areAllBookingsSelected = result));
    this.subscriptions.push(subscription);

    const themeSubscription = this.store.select(selectComputedColorTheme).subscribe((colorTheme) => {
      this.isDarkTheme = colorTheme === 'dark';
    });
    this.subscriptions.push(themeSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = new Array<Subscription>();
  }

  navigateBack(): void {
    this.store.dispatch(navigateBack());
  }

  changeFavouriteState(): void {
    this.favouriteStateChange.emit(!this.isFavourite);
  }

  hasSubtitle(): boolean {
    return this.subtitle !== undefined && this.subtitle !== null;
  }

  editFavoritesList(): void {
    this.editModeOn = !this.editModeOn;
    this.editListMode.emit(this.editModeOn);
    if (!this.editModeOn) {
      this.store.dispatch(unselectAllBookings());
    }
  }

  selectAllElements(): void {
    if (this.areAllBookingsSelected) {
      this.store.dispatch(unselectAllBookings());
    } else {
      this.store.dispatch(selectAllBookings());
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gs-generic-radio-list-modal',
  templateUrl: './generic-radio-list-modal.component.html',
  styleUrls: ['./generic-radio-list-modal.component.scss'],
})
export class GenericRadioListModalComponent<Type> implements OnInit {

  @Input() modalTitle: string;
  @Input() items: {name: string; value: Type}[];
  @Input() selectedValue: Type;
  @Output() cancel = new EventEmitter<void>();
  @Output() applyChanges = new EventEmitter<Type>();

  constructor() { }

  ngOnInit() {}

  onApplyChanges(): void{
    this.applyChanges.emit(this.selectedValue);
  }

  onCancel(): void{
    this.cancel.emit();
  }
}

import { Component, OnInit } from '@angular/core';
import { ColorThemes } from '@app/core/constants';
import { setColorTheme } from '@app/store/actions/app-config.actions';
import { selectPreferredColorTheme } from '@app/store/selectors/app-config.selectors';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';

@Component({
  selector: 'gs-dark-mode-modal',
  templateUrl: './dark-mode-modal.component.html',
  styleUrls: ['./dark-mode-modal.component.scss']
})
export class DarkModeModalComponent implements OnInit {
  constructor(private store: Store, private modalController: ModalController) {}
  items: { name: string; value: ColorThemes }[];
  selectedColor$ = this.store.select(selectPreferredColorTheme);

  ngOnInit() {
    this.items = [
      {
        name: 'On',
        value: ColorThemes.dark
      },
      {
        name: 'Off',
        value: ColorThemes.light
      },
      {
        name: 'System',
        value: ColorThemes.system
      }
    ];
  }

  onApplyChanges(colorTheme: ColorThemes): void {
    this.store.dispatch(setColorTheme({ colorTheme }));
    this.modalController.dismiss({});
  }
  onCancel(): void {
    this.modalController.dismiss({});
  }
}

import { FutureBooking, FutureBookingsAvailability } from '../models/future-booking.model';
import { Slot, SlotsByActivityIdMap } from '../models/session.model';
import { format, formatISO, parseISO, set } from 'date-fns';
import { SessionSearchParams } from '../models/session-search-params.model';
import { TimeUtilitiesHelper } from '../helpers/time-utilities-helper';


export class FutureBookingsAvailabilityService {
  public static extractAvailabilityForFutureBookings(
    futureBookings: FutureBooking[],
    slotsByActivityId: SlotsByActivityIdMap
  ): FutureBookingsAvailability {
    return futureBookings.reduce(
      (futureBookingsAvailability: FutureBookingsAvailability, futureBooking: FutureBooking) => {
        const dateKey = format(new Date(futureBooking.startTime), 'yyyy-MM-dd');
        return {
          ...futureBookingsAvailability,
          [futureBooking.bookingId]: slotsByActivityId[futureBooking.activityId]?.resourceGroups[
            futureBooking.location?.id
          ]?.slotsByDate[dateKey]?.find(
            (slot: Slot) => JSON.parse(slot.slotReferences.inCentre).BookingId === futureBooking.bookingId
          )?.availability,
        };
      },
      {}
    );
  }

  public static computeParametersForSessionCall(futureBookings: FutureBooking[]): SessionSearchParams {
    const sessionSearchParameters: SessionSearchParams = {
      activityId: [],
      siteId: '',
      endDate: '',
      endTime: '',
      startDate: '',
      startTime: '',
    };
    if (futureBookings.length === 0) {
      return sessionSearchParameters;
    }
    sessionSearchParameters.startDate = sessionSearchParameters.startTime = futureBookings[0].startTime;
    sessionSearchParameters.endDate = sessionSearchParameters.endTime = formatISO(set(parseISO(futureBookings[0].startTime), {
      hours: 23,
      minutes: 59,
      seconds: 59,
    }));
    futureBookings.forEach((futureBooking: FutureBooking) => {
      FutureBookingsAvailabilityService.updateStartDate(futureBooking, sessionSearchParameters);
      FutureBookingsAvailabilityService.updateStartTime(futureBooking, sessionSearchParameters);
      FutureBookingsAvailabilityService.updateEndTime(futureBooking, sessionSearchParameters);
      FutureBookingsAvailabilityService.updateActivityIds(futureBooking, sessionSearchParameters);
      FutureBookingsAvailabilityService.updateSiteIds(futureBooking, sessionSearchParameters);
    });

    return sessionSearchParameters;
  }

  private static updateStartTime(futureBooking: FutureBooking, sessionSearchParameters: SessionSearchParams) {
    if (TimeUtilitiesHelper.compareTimes(futureBooking.startTime, sessionSearchParameters.startTime) < 0) {
      sessionSearchParameters.startTime = futureBooking.startTime;
    }
  }

  private static updateSiteIds(futureBooking: FutureBooking, sessionSearchParameters: SessionSearchParams) {
    if (sessionSearchParameters.siteId === '') {
      sessionSearchParameters.siteId = futureBooking.siteId;
      return;
    }
    if (!sessionSearchParameters.siteId.split(',').includes(futureBooking.siteId)) {
      sessionSearchParameters.siteId = `${sessionSearchParameters.siteId},${futureBooking.siteId}`;
    }
  }

  private static updateActivityIds(futureBooking: FutureBooking, sessionSearchParameters: SessionSearchParams) {
    if (!sessionSearchParameters.activityId.includes(futureBooking.activityId)) {
      sessionSearchParameters.activityId.push(futureBooking.activityId);
    }
  }

  private static updateEndTime(futureBooking: FutureBooking, sessionSearchParameters: SessionSearchParams) {
    if (TimeUtilitiesHelper.compareDates(futureBooking.startTime, sessionSearchParameters.endDate) > 0) {
      sessionSearchParameters.endDate = sessionSearchParameters.endTime = formatISO(
        set(parseISO(futureBooking.startTime), { hours: 23, minutes: 59, seconds: 59 })
      );
    }
  }

  private static updateStartDate(futureBooking: FutureBooking, sessionSearchParameters: SessionSearchParams) {
    if (TimeUtilitiesHelper.compareDates(futureBooking.startTime, sessionSearchParameters.startDate) < 0) {
      sessionSearchParameters.startDate = futureBooking.startTime;
    }
  }
}

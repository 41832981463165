import { createSelector } from '@ngrx/store';
import { AuthenticationState, IAppState } from '../state/app-config.state';

export const selectAuthenticationState = (state: IAppState) => state.authentication;

export const selectAuthenticationRedirectUrl = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState): string => state.authenticationRedirectUrl,
);

export const isAuthenticating = createSelector(selectAuthenticationState, (state: AuthenticationState): boolean => state.authenticating);

export const isAuthenticated = createSelector(selectAuthenticationState, (state: AuthenticationState): boolean => state.authenticated);

export const getAccountMemberId = createSelector(selectAuthenticationState, (state: AuthenticationState): number => state.memberUserId);

export const authenticatedMemberId = createSelector(selectAuthenticationState, (state: AuthenticationState): number => state.memberUserId);

export const getAuthenticatedUser = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState): AuthenticationState => state,
);

export const getFailureMessage = createSelector(selectAuthenticationState, (state: AuthenticationState): string => {
  if (!state.failure) {
    return null;
  }
  return state.failure.message;
});

import { Injectable } from '@angular/core';
import { BasketService } from '@app/api/services/basket.service';
import { RoutingAbsolutePaths } from '@app/core/constants';
import { BookingBodyElement } from '@app/core/models/booking-body.model';
import { PreviousBooking } from '@app/core/models/previous-booking.model';
import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';
import { SUCCESS_TOAST_CLASS, TOAST_BASKET_SUCCESS_MESSAGE, TOAST_ONLINE_ICON_NAME } from '@core/constants';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { formatISO, startOfToday } from 'date-fns';
import { v4 as uuid } from 'uuid';
import {
  catchError,
  combineLatest,
  EMPTY,
  exhaustMap,
  filter,
  first,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  addBasketItemSuccess,
  ADD_BASKET_ITEM,
  ADD_BASKET_ITEM_SUCCESS,
  BasketItemsActions,
  INITIATE_ZERO_PRICED_BOOKING,
  loadFutureBookings,
  madeZeroPricedBookingSuccess,
  MADE_ZERO_PRICED_BOOKING_SUCCESS,
  makeZeroPricedBooking,
  MAKE_ZERO_PRICED_BOOKING,
  removeBasketItemSuccess,
  removeZeroPricedBookingSuccess,
  REMOVE_BASKET_ITEM,
  REMOVE_ZERO_PRICED_BOOKING,
  removeBookee,
  REMOVE_BOOKEE,
  addBasketItem,
  UPDATE_MEMBER_ATTENDANCE_ON_BASKET_ITEM,
  UPDATE_ADDITIONAL_BOOKEES,
  UPDATE_FROM_ACTIVITY_DETAILS,
  updateLeaseSuccess,
  UPDATE_PRICE_QUOTE,
  updatePriceQuoteSuccess,
  updatePriceQuote,
  UPDATE_LEASE_SUCCESS,
} from '../actions/basket.actions';
import { displaySuccessToast } from '../actions/notification-handling.actions';
import {
  loadBookingHistory,
  loadedBookingHistoryFail,
  loadedBookingHistorySuccess,
  loadedFutureBookingsFail,
  loadedFutureBookingsSuccess,
  madeBasketBookingSuccess,
  MAKE_BASKET_BOOKING,
} from '@store/actions/basket.actions';
import { navigateToUrl } from '@store/actions/navigation.actions';
import { selectSiteById, selectSites } from '@store/selectors/book.selectors';
import { FutureBooking } from '@core/models/future-booking.model';
import { loadTodaysBookings } from '../actions/entry.actions';
import { getAccountMemberId } from '../selectors/authentication.selectors';
import { loadSessions } from '../actions/book.actions';
import { FutureBookingsAvailabilityService } from '@app/core/services/future-bookings-availability.service';
import { selectBasketItems } from '../selectors/basket.selectors';
import { selectPersonalDetails, userProfileDetails } from '../selectors/account.selectors';
import { SessionService } from '@app/api/services/session.service';
import { AdditionalBooking, SessionSearchParams } from '@app/core/models/session-search-params.model';

@Injectable()
export class BasketItemsEffects {
  createLeaseForBasket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_BASKET_ITEM),
      mergeMap((action) =>
        this.basketService
          .createBookingLease(action.basketItem, action.userDetails)
          .pipe(map(() => addBasketItemSuccess({ basketItem: action.basketItem })))
      )
    )
  );

  displaySuccessToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_BASKET_ITEM_SUCCESS),
      map(() =>
        displaySuccessToast({
          message: TOAST_BASKET_SUCCESS_MESSAGE,
          iconName: TOAST_ONLINE_ICON_NAME,
          toastClass: SUCCESS_TOAST_CLASS,
        })
      )
    )
  );

  createLeaseForZeroBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(INITIATE_ZERO_PRICED_BOOKING),
      mergeMap((action) =>
        this.basketService.createBookingLease(action.basketItem, action.userDetails).pipe(
          map(() =>
            makeZeroPricedBooking({
              booking: {
                leaseId: action.basketItem.leaseId,
                memberId: action.userDetails.memberId,
                pricingQuote: action.basketItem.price,
              } as BookingBodyElement,
            })
          )
        )
      )
    )
  );

  removeBasketItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(REMOVE_BASKET_ITEM),
      mergeMap((action) =>
        this.basketService
          .deleteBooking(action.leaseId)
          .pipe(map(() => removeBasketItemSuccess({ leaseId: action.leaseId })))
      )
    )
  );

  zeroPricedBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MAKE_ZERO_PRICED_BOOKING),
      mergeMap((action) =>
        this.basketService.makeBooking([action.booking]).pipe(map(() => madeZeroPricedBookingSuccess()))
      )
    )
  );

  removeZeroPriceBookingItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(REMOVE_ZERO_PRICED_BOOKING),
      exhaustMap((action) =>
        this.basketService
          .deleteZeroPriceBooking(action.memberId, action.bookingId)
          .pipe(map(() => removeZeroPricedBookingSuccess({ memberId: action.memberId, bookingId: action.bookingId })))
      )
    )
  );

  basketBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MAKE_BASKET_BOOKING),
      mergeMap((action) => this.basketService.makeBooking(action.bookings).pipe(map(() => madeBasketBookingSuccess())))
    )
  );

  bookingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MADE_ZERO_PRICED_BOOKING_SUCCESS),
      map(() => navigateToUrl({ url: RoutingAbsolutePaths.bookConfirmationPage }))
    )
  );

  removeBookee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(REMOVE_BOOKEE),
      withLatestFrom(this.store.select(selectBasketItems), this.store.select(userProfileDetails)),
      mergeMap(([action, basketItems, userDetails]) =>{
        const basketItem = basketItems.find((item) => item.leaseId === action.leaseId);
        return this.basketService
          .createBookingLease(basketItem, userDetails)
          .pipe(switchMap(() => [updateLeaseSuccess({basketItem}), updatePriceQuote({basketItem})]));}
      )
    )
  );

  updateBasketItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_MEMBER_ATTENDANCE_ON_BASKET_ITEM, UPDATE_FROM_ACTIVITY_DETAILS),
      withLatestFrom(this.store.select(selectBasketItems), this.store.select(userProfileDetails)),
      mergeMap(([action, basketItems, userDetails]) =>{
      const basketItem = basketItems.find((item) => item.slotReference === action.slotReference);
      return this.basketService
        .createBookingLease(basketItem, userDetails)
        .pipe(switchMap(() => [updateLeaseSuccess({basketItem}), updatePriceQuote({basketItem})]));
      })
    )
  );

  updatePriceQuote$ = createEffect(() =>
      this.actions$.pipe(
        ofType(UPDATE_PRICE_QUOTE),
        withLatestFrom(this.store.select(userProfileDetails)),
        mergeMap(([action, userDetails]) => {
          const additionalBooking = action.basketItem.additionalBookees.map((bookee) =>({
            priceLevelId: bookee.priceLevelId,
            count: bookee.count
          } as AdditionalBooking));
          return this.sessionService
            .getPrice(action.basketItem.slotReference, additionalBooking, userDetails.memberId, action.basketItem.isMemberAttending)
            .pipe(map((pricingQuote) => (updatePriceQuoteSuccess({basketItem: action.basketItem, priceQuote: pricingQuote}))));
        })
      )
  );

  constructor(private actions$: Actions<BasketItemsActions>,
     private basketService: BasketService, private store: Store, private sessionService: SessionService) {}
}

@Injectable()
export class FutureBookingsEffects {
  loadFutureBookings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFutureBookings),
      withLatestFrom(this.store.select(selectSites)),
      mergeMap(([action, sites]) => {
        if (action.memberId === null) {
          return of(loadedFutureBookingsSuccess({ futureBookings: [] }));
        }
        return this.sessionService.getFutureBookings(action.memberId).pipe(
          map(
            (futureBookings: FutureBooking[]) =>
              futureBookings.map((booking: FutureBooking) => ({
                ...booking,
                siteName: sites.find((site) => site.id === booking.siteId).name,
              })) as FutureBooking[]
          ),
          first(),
          map((futureBookings) => loadedFutureBookingsSuccess({ futureBookings, shouldLoadSessions: action.shouldLoadSessions })),
          catchError(() => {
            this.store.dispatch(loadedFutureBookingsFail());
            return EMPTY;
          })
        );
      })
    )
  );

  loadFutureBookingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadedFutureBookingsSuccess),
      filter((action) => action.shouldLoadSessions && action.futureBookings.length !== 0),
      map((action) =>
        loadSessions({
          params: FutureBookingsAvailabilityService.computeParametersForSessionCall(action.futureBookings),
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private sessionService: BasketService,
    private store: Store
  ) {}
}

@Injectable()
export class BookingHistoryEffects {
  loadBookingHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBookingHistory),
      exhaustMap((action) => {
        if (action.memberId === null) {
          return of(loadedBookingHistorySuccess({ bookingHistory: [] }));
        }
        return this.sessionService
          .getBookingHistory(action.memberId, action.dateTimeFrom)
          .pipe(
            map((bookings: PreviousBooking[]) => bookings.map(booking => this.store.select(selectSiteById(booking.siteId)).pipe(
              map(site => ({...booking, siteName: site.name}))
            ))),
            exhaustMap((bookingObservables: Observable<PreviousBooking>[]) => combineLatest(bookingObservables)),
            first(),
            map((bookingHistory) =>
            loadedBookingHistorySuccess({ bookingHistory })
            ), catchError(() => {
              this.store.dispatch(loadedBookingHistoryFail());
              return EMPTY;
            })
          );
      })
    )
  );

  loadTodaysBookings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTodaysBookings),
      withLatestFrom(this.store.select(getAccountMemberId)),
      mergeMap(([_, memberId]) => [
        loadBookingHistory({memberId, dateTimeFrom: startOfToday().toISOString()}),
        loadFutureBookings({memberId})
      ])
    )
  );

  constructor(private actions$: Actions, private sessionService: BasketService, private store: Store) {}
}

import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'dateFnsFormat'
})
export class DateFnsFormat implements PipeTransform {

  transform(value: Date | string, arg: string): string {
    if (value === undefined) {
      return '';
    }
    return format(new Date(value), arg);
  }

}

import { Injectable } from '@angular/core';
import { IAppState } from '@app/store/state/app-config.state';
import { Storage } from '@ionic/storage';
import { Observable, firstValueFrom, from, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) { }

  public async set(key: string, value: any): Promise<any> {
    if (!this._storage) {
      this._storage = await this.storage.create();
    }
    return this._storage?.set(key, value);
  }

  public async get(key: string): Promise<any> {
    if (!this._storage) {
      this._storage = await this.storage.create();
    }
    return this._storage?.get(key);
  }

  public async isRedirectUrlSyncInStorage(): Promise<boolean> {
    if(!this._storage) {
      this._storage = await this.storage.create();
    }
    return this._storage?.get('state').then((state: IAppState | undefined) => {
      if (state === undefined) {
        return false;
      }
      return !!state?.authentication.authenticationRedirectUrl;
    });
  }
}

import { createSelector } from '@ngrx/store';
import { WalletState } from '../state/wallet.state';
import { IAppState } from '../state/app-config.state';

const walletState = (state: IAppState) => state.wallet;

export const savedCards = createSelector(walletState, (state: WalletState) => state.savedCards);

export const currentSelectedCardIndex = createSelector(walletState, (state: WalletState) => {
  const index = state.savedCards.findIndex((savedCard) => savedCard.id === state.currentSelectedCard?.id);
  return index !== -1 ? index : 0;
});

export const currentSelectedCard = createSelector(walletState, (state: WalletState) => {
  const card = state.savedCards.find((savedCard) => savedCard.id === state.currentSelectedCard?.id);
  return card;
});

export const isLoadingCards = createSelector(walletState, (state: WalletState) => state.isLoading);

import { DeviceInformationDTO } from './../../api/dtos/payment.dto';
import { DeviceInformationService } from '@core/services/device-information.service';
import { GladstonePaymentProvider, NewCardPaymentMethod } from '@app/api/dtos/payment.dto';
import { ExistingCardPaymentMethod, GladstonePaymentRequestDTO } from '@app/api/dtos/payment.dto';
import { BasketService } from '@app/api/services/basket.service';
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { PaymentService } from '@app/api/services';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  payByCard,
  payByNewCard,
  payByNewCardAndSave,
  payedByCardSuccess,
  paymentError,
} from '../actions/payment.actions';
import { shouldSaveCard } from '../selectors/payment.selectors';
import { getAccountMemberId } from '../selectors/authentication.selectors';
import { IAppState } from '../state/app-config.state';
import { BookingBodyElement } from '@app/core/models/booking-body.model';
import { selectBasketItems, selectBasketPricing, selectCheckedOutBookings } from '../selectors/basket.selectors';
import { currentSelectedCard } from '../selectors/wallet.selectors';
import { flat } from 'radash';
import {
  ERROR_ON_API_HEADER_KEY,
  ERROR_ON_API_MSG_KEY,
  RoutingAbsolutePaths,
} from '@app/core/constants';
import { navigateToUrl } from '../actions/navigation.actions';
import { setApiError } from '../actions/notification-handling.actions';
import { saveNewCard } from '../actions/wallet.actions';
import { SessionService } from '@app/api/services/session.service';
import { selectSelectedSlot } from '../selectors/book.selectors';
import { AdditionalBooking } from '@app/core/models/session-search-params.model';

@Injectable()
export class PaymentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<IAppState>,
    private paymentService: PaymentService,
    private basketService: BasketService,
    private sessionService: SessionService,
    private deviceInformationService: DeviceInformationService,
  ) { }

  public payForBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payByCard),
      concatLatestFrom(() => [
        this.store$.select(selectCheckedOutBookings),
        this.store$.select(getAccountMemberId),
        this.store$.select(currentSelectedCard),
        this.store$.select(selectBasketPricing)
      ]),
      switchMap(([_, basketItems, memberId, selectedCard, basketPricing]) => {
        const bookings: BookingBodyElement[] = basketItems.map(basketItem =>
        ({
          leaseId: basketItem.leaseId,
          memberId,
          pricingQuote: basketItem.price
        })
        );
        return this.basketService.makeBooking(bookings).pipe(
          map((bookingResponse) =>
            ({
              bookingResponse,
              memberId,
              selectedCard,
              basketItems,
              basketPricing
            })
          )
        );
      }),
      switchMap((result) => {
        const invoiceIds = flat(result.bookingResponse.bookings.map(element => element.salesInvoiceIds));
        const paymentMethods: ExistingCardPaymentMethod[] = [{
          amount: result.basketPricing.totalPrice,
          cardId: result.selectedCard.id,
          paymentProvider: GladstonePaymentProvider.Card
        }];

        const paymentBody: GladstonePaymentRequestDTO = {
          memberId: result.memberId,
          paymentMethods,
          salesInvoiceIds: invoiceIds
        };
        return this.paymentService.processPayment(paymentBody).pipe(
          switchMap(() => [payedByCardSuccess(), navigateToUrl({url: RoutingAbsolutePaths.paymentConfirmationPage})]),
          catchError(() => of(paymentError()))
        );
      })
    )
  );

  public payForBookingWithNewCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payByNewCardAndSave),
      concatLatestFrom(() => [this.store$.select(selectBasketItems),
      this.store$.select(getAccountMemberId),
      this.store$.select(selectBasketPricing)
      ]),
      switchMap(([action, basketItems, memberId, basketPricing]) => {
        const bookings: BookingBodyElement[] = basketItems.map(basketItem =>
        ({
          leaseId: basketItem.leaseId,
          memberId,
          pricingQuote: basketItem.price
        })
        );
        return this.basketService.makeBooking(bookings).pipe(
          map((bookingResponse) =>
            ({
              bookingResponse,
              memberId,
              paymentToken: action.paymentToken,
              basketItems,
              basketPricing,
              saveCardToWallet: action.saveCardToWallet
            })
          )
        );
      }),
      switchMap((result) => {
        const invoiceIds = flat(result.bookingResponse.bookings.map(element => element.salesInvoiceIds));
        const paymentMethods: NewCardPaymentMethod[] = [{
          amount: result.basketPricing.totalPrice,
          paymentToken: result.paymentToken,
          paymentProvider: GladstonePaymentProvider.Card,
          deviceInformation: this.deviceInformationService.getDeviceInformation() as DeviceInformationDTO,
          saveCardToWallet: result.saveCardToWallet,
          saveRCPcard: false
        }];

        const paymentBody: GladstonePaymentRequestDTO = {
          memberId: result.memberId,
          paymentMethods,
          salesInvoiceIds: invoiceIds
        };
        return this.paymentService.processPayment(paymentBody).pipe(
          switchMap(() => [payedByCardSuccess(), navigateToUrl({url: RoutingAbsolutePaths.paymentConfirmationPage})]),
          catchError(() => of(paymentError()))
        );
      }),
    )
);

  public payByNewCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payByNewCard),
      concatLatestFrom(() => this.store$.select(shouldSaveCard)),
      switchMap(([, shouldSaveCardValue]) => this.paymentService.getPaymentToken().pipe(
        switchMap((paymentToken) => {
          const actions: Action[] = [ payByNewCardAndSave({ paymentToken, saveCardToWallet: shouldSaveCardValue }) ];
          if (shouldSaveCardValue) {
            actions.push(saveNewCard({ paymentToken }));
          }
          return actions;
        })
      )),
    )
  );

  paymentSuccess$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType(payedByCardSuccess),
      map(() => navigateToUrl({url: RoutingAbsolutePaths.paymentConfirmationPage}))
    )
  );

  paymentError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentError),
      switchMap(() => [
        navigateToUrl({url: RoutingAbsolutePaths.bookHomePage}),
        setApiError({error: {
          header: ERROR_ON_API_HEADER_KEY,
          message: ERROR_ON_API_MSG_KEY
        }})
      ])
    )
  );
}

/* eslint-disable @typescript-eslint/member-ordering */
import { extractDialCodeFromPrimaryNumber } from '@core/helpers/phone-number-helper';
import { LocationStrategy } from '@angular/common';
import {
  fetchContactDetails,
  fetchContactDetailsSuccess,
  fetchContactDetailsError,
  setCountryDialCode,
  updatePersonalDetails,
  updatedProfileDetailsSuccess,
  updateProfileDetailsError,
  updatedContactDetailsSuccess,
  updateContactDetailsError,
} from './../actions/account.actions';
import { Injectable } from '@angular/core';
import { MembersService } from '@app/api/services/members.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, tap, withLatestFrom} from 'rxjs';
import {
  fetchProfileDetails,
  fetchProfileDetailsError,
  fetchProfileDetailsSuccess,
} from '../actions/account.actions';

import {
  AuthenticationActionTypes,
  AuthenticationSuccess,
} from '../actions/authentication.actions';
import { Store } from '@ngrx/store';
import { selectPersonalDetails } from '../selectors/account.selectors';
import { navigateBack } from '../actions/navigation.actions';


@Injectable()
export class AccountsEffects {

  public fetchMemberInformationPostAuthentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActionTypes.AuthenticationSuccess),
      map((action: AuthenticationSuccess) => fetchProfileDetails({ memberId: action.memberUserId })),
    )
  );

  public profileDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchProfileDetails),
      mergeMap((action) =>
        this.memberService.getMemberProfile(action.memberId).pipe(
          map((personalDetails) =>
          fetchProfileDetailsSuccess({ profileDetails: personalDetails })
          ),
          catchError((_) => of(fetchProfileDetailsError()))
        )
      )
    )
  );

  public contactDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchProfileDetailsSuccess),
      map((action) =>
        fetchContactDetails({ memberId: action.profileDetails.memberId })
      )
    )
  );

  public contactDetailsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchContactDetails),
      mergeMap((action) =>
        this.memberService.getContactDetails(action.memberId).pipe(
          map((contactDetails) =>
            fetchContactDetailsSuccess({ payload: contactDetails })
          ),
          catchError((_) => of(fetchContactDetailsError()))
        )
      )
    )
  );

  public setDialCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchContactDetailsSuccess),
      map((action) =>
        setCountryDialCode({
          dialCode: extractDialCodeFromPrimaryNumber(action.payload.telephone),
        })
      )
    )
  );

  public updateProfileDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePersonalDetails),
      withLatestFrom(this.store.select(selectPersonalDetails)),
      switchMap(([action, personalDetails]) =>
        this.memberService
          .updateProfileDetails(personalDetails, action.memberId)
          .pipe(
            map(() =>
              updatedProfileDetailsSuccess({
                personalDetailsUpdated: action.personalDetailsUpdated,
                primaryPhoneNumber: action.primaryPhoneNumber,
                memberId: action.memberId,
              })
            ),
            catchError((_) => of(updateProfileDetailsError()))
          )
      )
    )
  );



  public updateContactDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatedProfileDetailsSuccess),
      mergeMap((action) =>
        this.memberService
          .updateContactDetails(
            action.personalDetailsUpdated,
            action.primaryPhoneNumber,
            action.memberId
          )
          .pipe(
            map(() => updatedContactDetailsSuccess()),
            catchError((_) => of(updateContactDetailsError()))
          )
      )
    )
  );

    public navigateBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatedContactDetailsSuccess),
      tap(_ => this.store.dispatch(navigateBack()))
    ), {dispatch: false}
  );

  constructor(
    private readonly actions$: Actions,
    private readonly memberService: MembersService,
    private store: Store
  ) {}
}

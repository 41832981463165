/* eslint-disable @typescript-eslint/no-shadow */
import { RoutingAbsolutePaths } from '@core/constants';
import { logOut } from './../actions/authentication.actions';
import { navigatedBackSuccesfully } from './../actions/navigation.actions';
import { NavigationState } from './../state/app-config.state';
import { resetNavigationHistory, navigateToUrl } from './../actions/navigation.actions';
import { createReducer, on } from '@ngrx/store';

export const navigationReducer = createReducer({
  currentPage: '/book',
  navigationHistory: []
  },
  on(navigateToUrl, (state: NavigationState, data) => ({
    ...state,
    navigationHistory: data.url !== state.currentPage ? [...state.navigationHistory, state.currentPage] : [...state.navigationHistory],
    currentPage: data.url
  })),
  on(navigatedBackSuccesfully, (state: NavigationState) => ({
    ...state,
    currentPage: state.navigationHistory[state.navigationHistory.length - 1],
    navigationHistory: [...state.navigationHistory.slice(0, -1)]
  })),
  on(resetNavigationHistory, (state: NavigationState, data) => ({
    ...state,
    navigationHistory: [],
    currentPage: data.url
  })),
  on(logOut, (state: NavigationState) => ({
    ...state,
    currentPage: RoutingAbsolutePaths.bookHomePage,
    navigationHistory: []
  }))
);

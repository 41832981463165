import { CardDetails } from '@app/core/models/card-details.model';
import { DeviceInformationDTO } from '../dtos/payment.dto';
import { SaveCardBodyDTO, WalletDTO } from '../dtos/wallet-dto';

export class WalletMapper{
  static mapToCardDetails(response: WalletDTO): CardDetails[] {
    return response.data.map(card => {
      if (card.id && card.holder && card.typeCode && card.number && card.expiryDate) {
        return {
          id: card.id,
          holder: card.holder,
          type: card.typeCode,
          cardNumber4Digits: card.number.slice(-4),
          expiryDate: `${card.expiryDate.slice(0,2)} / ${card.expiryDate.slice(-2)}`
        };
      } else {
        return null;
      }
    }).filter(card => card !== null) as CardDetails[];
  }

  static mapToSaveCardBodyDTO(paymentToken: string, deviceInformation: DeviceInformationDTO): SaveCardBodyDTO{
    return {
      paymentToken,
      deviceInformation
    } as SaveCardBodyDTO;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '@app/core/models/session.model';
import { loadSessionPrice, loadSlotPrice } from '@app/store/actions/book.actions';
import { selectCurrency } from '@app/store/selectors/basket.selectors';
import { selectPriceOfSlot } from '@app/store/selectors/book.selectors';
import { Store } from '@ngrx/store';
import { first, map, Observable } from 'rxjs';

@Component({
  selector: 'gs-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit {
  @Input() price = null;
  @Input() slot: Slot = null;
  price$: Observable<number>;
  currency$ = this.store.select(selectCurrency);

  constructor(private store: Store) { }
  ngOnInit() {
    if(this.price === 'load' && this.slot){
      this.loadPrice();
    }
  }

  loadPrice(): void {
    this.price$ = this.store.select(selectPriceOfSlot(this.slot?.slotReferences.inCentre)).pipe(
      map(price => price?.memberPriceLevel?.price?.inCentre)
    );
    this.price$.pipe(first()).subscribe(result => {
      if (result === undefined) {
        this.store.dispatch(loadSessionPrice({slot: this.slot}));
      }
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ErrorData } from '@app/core/models/error-data.model';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'gs-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  @Input() error: ErrorData;
  constructor(private modalController: ModalController) {

  }

  ngOnInit() {}

  onDismissClick(): void {
    this.modalController.dismiss();
  }
}

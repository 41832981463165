import { SessionSearchParams } from '@core/models/session-search-params.model';
import {
  ResourceGroup,
  SessionModel,
  Slot,
  SlotsByActivityIdMap,
  SlotsByResourceIdMap,
} from '@core/models/session.model';
import { format } from 'date-fns';
import { SessionSearchParamsDTO } from '../dtos/session-search-params.dto';
import { ResourceGroupDTO, SessionDTO, SlotDTO } from '../dtos/session.dto';
import { ActivityDetails } from '@core/models/activity.model';
import { Site } from '@core/models/site.model';
import { first, group } from 'radash';
import { ActivityDetailsDTO } from '../dtos/activity.dto';
import { FutureBooking } from '@app/core/models/future-booking.model';

export class SessionMapper {
  public static mapSlotToSlotByResourceId(
    slotsByResourceId: SlotsByResourceIdMap,
    slot: SlotDTO,
    resourceGroup: ResourceGroupDTO,
    sessionSearchParams: SessionSearchParams,
    sessionDTO: SessionDTO
  ): void {
    const curResourceGroup: ResourceGroup =
      slotsByResourceId[resourceGroup.locationId] ?? this.mapToResourceGroup(resourceGroup);
    const dateKey = format(new Date(slot.startTime), 'yyyy-MM-dd');
    curResourceGroup.slotsByDate[dateKey] = [
      ...(curResourceGroup.slotsByDate[dateKey] ?? []),
      this.mapToSlot(slot, sessionSearchParams, sessionDTO, curResourceGroup),
    ];
    slotsByResourceId[resourceGroup.locationId] = { ...curResourceGroup };
  }

  public static mapSlotToSlotByActivityId(
    slotsByActivityId: SlotsByActivityIdMap,
    slot: SlotDTO,
    resourceGroup: ResourceGroupDTO,
    sessionSearchParams: SessionSearchParams,
    sessionDTO: SessionDTO
  ): void {
    const curSession: SessionModel =
      slotsByActivityId[sessionDTO.id] ??
      ({
        ...sessionDTO,
        resourceGroups: {},
      } as SessionModel);

    this.mapSlotToSlotByResourceId(
      curSession.resourceGroups,
      slot,
      resourceGroup,
      sessionSearchParams,
      sessionDTO
    );
    slotsByActivityId[sessionDTO.id] = { ...curSession };
  }

  static mapSessionSearchParamsToDTO(sessionSearchParams: SessionSearchParams): SessionSearchParamsDTO {
    return {
      activityIds: sessionSearchParams.activityId.join(','),
      siteIds: sessionSearchParams.siteId,
      webBookableOnly: true,
      dateFrom: sessionSearchParams.startDate
    } as SessionSearchParamsDTO;
  }

  static mapToResourceGroup(resourceGroup: ResourceGroupDTO): ResourceGroup {
    return {
      resourceId: resourceGroup.locationId,
      resourceName: resourceGroup.locationName,
      slotsByDate: {}
    } as ResourceGroup;
  }

  static mapToSlot(slot: SlotDTO, params: SessionSearchParams, sessionDTO: SessionDTO, resource: ResourceGroup): Slot {
    return {
      ...slot,
      startTime: slot.startTime,
      endTime: slot.endTime,
      activityId: sessionDTO.id,
      activityName: sessionDTO.name,
      siteId: sessionDTO.siteId,
      siteName: params.siteName,
      locationId: resource.resourceId,
      locationName: resource.resourceName,
      description: sessionDTO.description,
      imageUrl: sessionDTO.description,
      webComments: sessionDTO.webComments,
      groupActivityDetails: sessionDTO.groupActivityDetails,
    } as Slot;
  }

  static mapToRecommendedActivities(allSessions: SessionDTO[], sites: Site[], futureBookings: FutureBooking[]): Slot[]{
    return Object.values(group(allSessions, (session) => session.id)).map(sessions => {

      const firstSession = first(sessions, null);
      const firstLocation = first(firstSession?.locations, null);
      let nextSession = {
        session: firstSession,
        location: firstLocation,
        slot: first(firstLocation?.slots, null)
      };

      const availableSession = this.findFirstAvailableSlot(sessions, futureBookings);
      if(availableSession){
        nextSession = availableSession;
      }

      const recommendedActivity = {
        activityId: nextSession.session.id,
        activityName: nextSession.session.name,
        siteId: nextSession.session.siteId,
        siteName: sites.find(site => site.id === nextSession.session.siteId).name,
        locationId: nextSession.location.locationId,
        locationName: nextSession.location.locationName,
        startTime: nextSession.slot.startTime,
        endTime: nextSession.slot.endTime,
        availability: nextSession.slot.availability,
        slotReferences: nextSession.slot.slotReferences,
        bookableFrom: nextSession.slot.bookableFrom,
        bookableUntil: nextSession.slot.bookableUntil,
        imageUrl: nextSession.session.imageUrl ? nextSession.session.imageUrl : 'DEFAULT',
        description: nextSession.session.description,
        alertListEnabled: nextSession.slot.alertListEnabled,
        webComments: nextSession.session.webComments,
        groupActivityDetails: nextSession.session.groupActivityDetails,

      } as Slot;
      return recommendedActivity;
    });
  }

  static mapToActivityDetails(activityDetailsDTO: ActivityDetailsDTO): ActivityDetails{
    return {...activityDetailsDTO} as ActivityDetails;
  }

  private static findFirstAvailableSlot(sessions: SessionDTO[], futureBookings: FutureBooking[]):
  {session: SessionDTO; location: ResourceGroupDTO; slot: SlotDTO}{
    for(const session of sessions){
      for(const location of session.locations){
        for(const slot of location.slots){
          if(slot.availability.inCentre > 0 && !this.isSlotBooked(session, location, slot, futureBookings)){
            return {session, location, slot};
          }
        }
      }
    }
    return null;
  }

  private static isSlotBooked(session: SessionDTO, location: ResourceGroupDTO, slot: SlotDTO, futureBookings: FutureBooking[]): boolean{
    return futureBookings.find(futureBooking =>
      futureBooking.siteId === session.siteId &&
      futureBooking.location?.id === location.locationId &&
      futureBooking.activityId === session.id &&
      futureBooking.startTime === slot.startTime
    ) !== undefined;
  }
}

import { ModalController } from '@ionic/angular';
import { Slot } from '@app/core/models/session.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gs-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() confirmMessageKey: string;
  @Input() cancelMessageKey: string;
  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  onCloseModal(): void {
    this.modalController.dismiss(null, 'cancel');
  }

  onConfirmBooking() {
    this.modalController.dismiss(null, 'confirm');
  }
}

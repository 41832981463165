import { AuthenticationSuccess, Login } from './../actions/authentication.actions';
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/authentication/authentication.service';
import { RoutingAbsolutePaths } from '@app/core/constants';
import {
  Actions,
  ROOT_EFFECTS_INIT,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, exhaustMap, of, throwError, from } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  AuthenticationActionTypes,
  AuthenticationRequest,
  LogOut,
  SetAuthenticationRedirectUrl,
  refreshStoreDataPostAuthentication,
} from '../actions/authentication.actions';
import {
  loadBookingHistory,
  loadFutureBookings,
} from '../actions/basket.actions';
import { clearSlotPrices, loadActivityDetails, loadSessionPrice } from '../actions/book.actions';
import { navigateToUrl } from '../actions/navigation.actions';
import {
  getAccountMemberId,
  selectAuthenticationRedirectUrl,
} from '../selectors/authentication.selectors';
import { selectSelectedSlot } from '../selectors/book.selectors';
import { IAppState } from '../state/app-config.state';
import { BiometricService } from '@core/services/biometric.service';
import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';
import { formatISO, subYears } from 'date-fns';
import { savedStateSuccessfully } from '../actions/offline-support.actions';
import { StorageService } from '@app/core/storage/storage.service';
import { loadPreferredLanguage } from '../actions/app-config.actions';
import { PushNotificationsService } from '@app/api/services/push-notifications.service';
import { environment } from '@environments/environment';
import { savePushNotificationToken } from '../actions/push-notification.actions';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly store: Store<IAppState>,
    private readonly biomericService: BiometricService,
    private readonly storageService: StorageService,
    private readonly pushNotificationsService: PushNotificationsService
  ) {}

  public init$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => new AuthenticationRequest())
    )
  );

  public login$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType(AuthenticationActionTypes.Login),
      switchMap(() => this.actions$.pipe(
        ofType(savedStateSuccessfully),
        switchMap(() => from(this.storageService.isRedirectUrlSyncInStorage())),
        filter((isRedirectUrlSaved: boolean) => isRedirectUrlSaved),
        first()
      )),
      tap(() => this.authService.login()),
      ),
    { dispatch: false }
  );

  public redirectPostAuthentication$: Observable<[Action, string]> =
    createEffect(
      () =>
        this.actions$.pipe(
          ofType(AuthenticationActionTypes.AuthenticationSuccess),
          withLatestFrom(this.store.select(selectAuthenticationRedirectUrl)),
          tap(([action, authenticationRedirectUrl]: [AuthenticationSuccess, string]) => {
            if (
              authenticationRedirectUrl.includes('session-expired') ||
              authenticationRedirectUrl.includes('access-forbidden')
            ) {
              this.store.dispatch(
                navigateToUrl({ url: RoutingAbsolutePaths.emptyUrl })
              );
              return;
            } else {
              this.store.dispatch(refreshStoreDataPostAuthentication());
              this.store.dispatch(
                navigateToUrl({ url: `${authenticationRedirectUrl}` })
              );
              this.store.dispatch(
                new SetAuthenticationRedirectUrl(RoutingAbsolutePaths.emptyUrl)
              );
              this.store.dispatch(savePushNotificationToken({ memberId:action.memberUserId }))
            }
          })
        ),
      { dispatch: false }
    );

  public logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LogOut>(AuthenticationActionTypes.LogOut),
        exhaustMap((action) => {
          const platform = Capacitor.getPlatform();
          if (environment.oneSignalAppId && (platform === 'ios' || platform === 'android')) {
            return this.pushNotificationsService.deleteDevicePushToken(action.memberId).pipe(
              catchError(() => of(false))
            );
          }

          return of(action);
        }),
        exhaustMap(() => from(this.authService.logout())),
        tap(() =>
          this.store.dispatch(
            navigateToUrl({ url: RoutingAbsolutePaths.emptyUrl })
          )
        )
      ),
    { dispatch: false }
  );

  public refreshStoreDataPostAuthentication$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthenticationActionTypes.RefreshStoreDataPostAuthentication),
        tap(() => {
          this.store.dispatch(clearSlotPrices());
          this.store.select(selectSelectedSlot).pipe(
              first(),
              withLatestFrom(this.store.select(getAccountMemberId))
          ).subscribe(([selectedSlot, memberId]) => {
            this.store.dispatch(loadBookingHistory({ memberId, dateTimeFrom: formatISO(subYears(new Date(), 1))}));
            this.store.dispatch(loadFutureBookings({ memberId }));
            this.store.dispatch(loadPreferredLanguage({ memberId }));
            if (selectedSlot !== undefined && !TimeUtilitiesHelper.isDateInPast(selectedSlot?.startTime)) {
              this.store.dispatch(loadSessionPrice({slot: selectedSlot}));
              this.store.dispatch(loadActivityDetails({activityId: selectedSlot.activityId}));
            }
          });
        })
      ),
    { dispatch: false }
  );

  public initiateBiometricLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthenticationActionTypes.InitiateBiometricLogin),
        exhaustMap(() => {
          if (this.authService.hasRefreshToken()) {
            return this.biomericService.performBiometricVerification();
          }
          return of(false);
        }),
        exhaustMap((result: boolean) => {
          if (result) {
            return this.authService.refreshToken();
          } else {
            return of(null);
          }
        }),
        tap((token) => {
          if (!token) {
            this.store.dispatch(new Login());
          }
        }),
        catchError((er) => {
          this.store.dispatch(new Login());
          return throwError(() => er);
        })
      ),
    { dispatch: false }
  );
}

/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { PushNotificationsService } from '@app/api/services/push-notifications.service';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import OneSignal from 'onesignal-cordova-plugin';
import { DeviceState } from 'onesignal-cordova-plugin/dist/Subscription';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import {
  savePushNotificationToken,
  savePushNotificationTokenFail,
  savePushNotificationTokenSuccess,
} from '../actions/push-notification.actions';

@Injectable()
export class PushNotificationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly pushNotificationsService: PushNotificationsService
  ) {}

  savePushNotificationToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(savePushNotificationToken),
      mergeMap((action) => {
        const platform = Capacitor.getPlatform();
        if (environment.oneSignalAppId && (platform === 'ios' || platform === 'android')) {
          return new Observable<Action>((obs) =>
            OneSignal.getDeviceState((deviceState: DeviceState) => {
              this.pushNotificationsService
                .saveDevicePushToken(action.memberId, deviceState.userId)
                .pipe(
                  map(() => obs.next(savePushNotificationTokenSuccess())),
                  catchError(() => of(obs.next(savePushNotificationTokenFail())))
                )
                .subscribe();
            })
          );
        } else {
          return of();
        }
      })
    );
  });
}

import { createAnimation } from '@ionic/core';
import { DROP_DOWN_ANIMATION_DURATION, FADE_IN_OUT_DURATION } from '@core/constants';

export const dropDownAnimation = (selector: string) =>
  createAnimation()
    .addElement(document.querySelector(selector))
    .duration(DROP_DOWN_ANIMATION_DURATION)
    .fromTo('height', '0px', '250px');

export const dropUpAnimation = (selector: string) =>
    createAnimation()
      .addElement(document.querySelector(selector))
      .duration(DROP_DOWN_ANIMATION_DURATION)
      .fromTo('height', '250px', '0px');

export const fadeOutAnimation = (selector: string, toolbarHeight: number) =>
  createAnimation()
    .addElement(document.querySelector(selector))
    .duration(FADE_IN_OUT_DURATION)
    .fromTo('opacity', '100%', '0%')
    .fromTo('transform', 'translateY(0px)', `translateY(-${toolbarHeight}px)`)
    .afterStyles({display: 'none'});

export const fadeInAnimation = (selector: string, toolbarHeight: number) =>
    createAnimation()
      .addElement(document.querySelector(selector))
      .beforeStyles({display: 'block'})
      .duration(FADE_IN_OUT_DURATION)
      .fromTo('opacity', '0%', '100%')
      .fromTo('transform', `translateY(-${toolbarHeight}px)`, 'translateY(0px)');


import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from './core/authentication/authenticated.guard';
import { EntryGuard } from './core/authentication/entry.guard';

const routes: Routes = [
  {
    path: 'book',
    loadChildren: () =>
      import('./pages/book/book.module').then((m) => m.BookPageModule),
  },
  {
    path: '',
    redirectTo: 'book',
    pathMatch: 'full',
  },
  {
    path: 'favourites',
    loadChildren: () =>
      import('./pages/favourites/favourites.module').then(
        (m) => m.FavouritesPageModule
      ),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'entry',
    loadChildren: () =>
      import('./pages/entry/entry.module').then((m) => m.EntryPageModule),
    canActivate: [EntryGuard],
  },
  {
    path: 'book',
    loadChildren: () =>
      import('./pages/book/book.module').then((m) => m.BookPageModule),
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./pages/my-account/my-account.module').then(
        (m) => m.MyAccountPageModule
      ),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'basket',
    loadChildren: () =>
      import('./pages/basket/basket.module').then((m) => m.BasketPageModule),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./shared/pages/login/login.module').then( m => m.LoginPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isToday } from 'date-fns';

@Component({
  selector: 'gs-date-button',
  templateUrl: './date-button.component.html',
  styleUrls: ['./date-button.component.scss'],
})
export class DateButtonComponent implements OnInit {
  @Input() date: Date;
  @Output() selectedStateChange = new EventEmitter<boolean>();
  @Input() selected;

  constructor() { }
  ngOnInit() {}

  changeSelectionState() {
    this.selected = !this.selected;
    this.selectedStateChange.emit();
  }
  setSelectionState(selected: boolean){
    this.selected = selected;
  }

  isDateToday(date: Date) {
    return isToday(date);
  }
}

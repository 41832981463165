import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationSetting } from '@app/core/models/configuration.model';
import { Language } from '@app/core/models/language.model';
import { environment } from '@environments/environment';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private httpClient: HttpClient, private storage: Storage) {}

  public getAll(): Observable<Language[]> {
    const allowedLanguagesKey = ConfigurationSetting.Allowed_Languages;
    return this.httpClient.get<Language[]>(`${environment.apiUrl}`.replace('{configurationKey}', allowedLanguagesKey));
  }
}

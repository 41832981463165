import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PUSH_NOTIFICATION_TOKEN } from '@app/core/constants';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { MemberPushNotificationTokenDTO } from '../dtos/member-push-notification-token.dto';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  constructor(private httpClient: HttpClient) { }

  public saveDevicePushToken(memberId: number, pushToken: string): Observable<string> {
    const memberPushToken: MemberPushNotificationTokenDTO = {
      memberId,
      pushToken
    };

    const endpoint = PUSH_NOTIFICATION_TOKEN.replace('{memberId}', memberId.toString());
    return this.httpClient.put<string>(`${environment.apiUrl}${endpoint}`, memberPushToken);
  }

  public deleteDevicePushToken(memberId: number): Observable<string> {
    const endpoint = PUSH_NOTIFICATION_TOKEN.replace('{memberId}', memberId.toString());
    return this.httpClient.delete<string>(`${environment.apiUrl}${endpoint}`);
  }
}

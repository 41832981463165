import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
  })
export class CustomDateAdapter extends NativeDateAdapter {

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    platform: Platform,
    private translate: TranslateService) {
      super(matDateLocale, platform);
  }

  getFirstDayOfWeek(): number {
   return this.translate.instant('CONTENT.BOOK.DATE.FIRST_DAY_OF_WEEK');
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return this.translate.instant('CONTENT.BOOK.DATE.WEEK_DAYS');
  }

}

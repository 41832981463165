/* eslint-disable @typescript-eslint/member-ordering */
import { exhaustMap, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { ConnectionStatus, Network } from '@capacitor/network';
import { first, from, map, merge, Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {
  networkStatus = new Subject<boolean>();
  appResumed = new Subject();
  public networkStatus$: Observable<boolean>;
  constructor() {
    this.init();
  }

  init() {
    Network.addListener('networkStatusChange', (status) => {
      this.networkStatus.next(status.connected);
    });

    App.addListener('resume', () => {
      this.appResumed.next(true);
    });
    const appResumeEventFactory$ = this.appResumed.pipe(exhaustMap(() =>
      from(Network.getStatus()).pipe(
        map((status: ConnectionStatus) => status.connected),
      )
    ));

    const startingNetworkStatus$ = from(Network.getStatus()).pipe(
      map((status: ConnectionStatus) => status.connected),
      first()
    );
    this.networkStatus$ = merge( startingNetworkStatus$ ,this.networkStatus.asObservable(), appResumeEventFactory$).pipe(
      shareReplay(1),
    );
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: "https://thirdpartyintegration-test.gladstonego.cloud",
  // apiBaseHost: "thirdpartyintegration-test.gladstonego.cloud",
  apiUrl: "https://thirdpartyintegration-test.gladstonego.cloud",
  apiBaseHost: "thirdpartyintegration-test.gladstonego.cloud",
  // apiUrl: "https://thirdpartyintegration-test.gladstonego.cloud",
  // apiBaseHost: "thirdpartyintegration-test.gladstonego.cloud",
  useOAuthOverride: false,
  // .trim() is used to handle the case when a property value contains leading or trailing white spaces
  primaryColor1: "#0061c2",
  primaryColor2: "#030203",
  primaryColorDark: "#1978D4",
  defaultLanguage: "en-GB",
  useBiometric: "true",
  qrPermanentTokenExpiryTime: "2",
  leaseDurationMinutes: 30,
  oneSignalAppId: '',
  appVersion: '1.2.0',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

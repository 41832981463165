import { createReducer, on } from '@ngrx/store';
import { PaymentState } from '../state/payment.state';
import { setShouldSaveCardValue } from '../actions/payment.actions';


const initialState: PaymentState = {
  newCardDetails: {
    shouldSavedCard: false
  }
};

export const paymentReducer = createReducer(initialState,
  on(setShouldSaveCardValue, (state, payload) => ({
    ...state,
    newCardDetails: {
      ...state.newCardDetails,
      shouldSavedCard: payload.shouldSaveCard
    }
  }))
);

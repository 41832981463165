import { NoResultsGenericComponent } from './components/no-results-generic/no-results-generic.component';
import { MonthControlComponent } from './components/month-control/month-control.component';
import { CalendarHeaderComponent } from './components/calendar-display/calendar-header/calendar-header.component';
import { CalendarDisplayComponent } from './components/calendar-display/calendar-display.component';
import { SkeletonPlaceholderHorizontallyComponent } from './components/skeleton-placeholder/skeleton-placeholder-horizontally/skeleton-placeholder-horizontally.component';
import { LatestBookingCardComponent } from './components/generic-homepage-card/latest-booking-card/latest-booking-card.component';
import { GenericHomepageCardComponent } from './components/generic-homepage-card/generic-homepage-card.component';
import { PreviousAndFavouriteBookingsComponent } from './components/previous-and-favourite-bookings/previous-and-favourite-bookings.component';
import { ResourceGroupsSegmentComponent } from './components/resource-groups-segment/resource-groups-segment.component';
import { DateFieldComponent } from './components/form/date-field/date-field.component';
import { FormFieldPhoneNumberComponent } from './components/form/form-field-phone-number/form-field-phone-number.component';
import { FormFieldComponent } from './components/form/form-field/form-field.component';
import { FormFieldMessageComponent } from './components/form/form-field-message/form-field-message.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ConfirmationMessageCardComponent } from './components/slot-card/confirmation-message-card/confirmation-message-card.component';
import { DateFnsFormat } from './pipes/date-fns-format.pipe';
import { SkeletonPlaceholderComponent } from './components/skeleton-placeholder/skeleton-placeholder.component';
import { NoSlotsComponent } from './components/no-slots/no-slots.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { TimeButtonComponent } from './components/time-picker/time-button/time-button.component';
import { TimePickerComponent } from '@shared/components/time-picker/time-picker.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { TitleHeaderComponent } from './components/header/title-header/title-header.component';
import { TabsHeaderComponent } from './components/header/tabs-header/tabs-header.component';
import { LogoHeaderComponent } from './components/header/logo-header/logo-header.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { HeaderComponent } from './components/header/header.component';
import { IconContainerComponent } from './components/icon-container/icon-container.component';
import { TabsComponent } from '@shared/components/tabs/tabs.component';
import { HighlightMatchPipe } from './pipes/highlight-match.pipe';
import { CustomDateAdapter } from './components/date-picker/matCalendarCustomAdapter';
import { SavedCardsComponent } from './components/saved-cards/saved-cards.component';
import { PaymentCardComponent } from './components/payment-card/payment-card.component';
import { AddCardComponent } from './components/add-card/add-card.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { SlotCardComponent } from './components/slot-card/slot-card.component';
import { AddFavouriteButtonComponent } from './components/add-favourite-button/add-favourite-button.component';
import { SlidingCardComponent } from './components/sliding-card/sliding-card.component';
import { PriceComponent } from './components/slot-card/price/price.component';
import { AvailabilityComponent } from './components/slot-card/availability/availability.component';
import { IsBookedComponent } from './components/slot-card/is-booked/is-booked.component';
import { LastBookedComponent } from './components/slot-card/last-booked/last-booked.component';
import { DateTimeComponent } from './components/slot-card/date-time/date-time.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { DateButtonComponent } from './components/date-selector/date-button/date-button.component';
import { DatePickerModalComponent } from './components/modals/date-picker-modal/date-picker-modal.component';
import { RecommendedActivitiesComponent } from './components/recommended-activities/recommended-activities.component';
import { TimerComponent } from './components/timer/timer.component';
import { EntryBookingItemComponent } from './components/slot-card/entry-booking-item/entry-booking-item.component';
import { ActivityCardComponent } from './components/slot-card/activity-card/activity-card.component';
import { LanguagePickerComponent } from './components/modals/language-picker-modal/language-picker.component';
import { AngularGenericModal } from './components/modals/angular-generic-modal/angular-generic-modal.component';
import { ThreeDSecureModalComponent } from './components/three-d-secure-modal/three-d-secure-modal.component';
import { DarkModeModalComponent } from './components/modals/dark-mode-modal/dark-mode-modal.component';
import { GenericRadioListModalComponent } from './components/modals/generic-radio-list-modal/generic-radio-list-modal.component';
import { AddGuestComponent } from './components/modals/add-guest-modal/add-guest.component';
import { ErrorModalComponent } from './components/modals/error-modal/error-modal.component';
import { SiteDetailsComponent } from './components/site-details/site-details.component';

@NgModule({
  declarations: [
    TabsComponent,
    HeaderComponent,
    IconContainerComponent,
    LogoHeaderComponent,
    TabsHeaderComponent,
    TitleHeaderComponent,
    SearchBarComponent,
    NoResultsComponent,
    HighlightMatchPipe,
    TimePickerComponent,
    TimeButtonComponent,
    DatePickerComponent,
    FormErrorComponent,
    NoSlotsComponent,
    PaymentCardComponent,
    AddCardComponent,
    SavedCardsComponent,
    SkeletonPlaceholderComponent,
    SkeletonPlaceholderHorizontallyComponent,
    DateFnsFormat,
    ConfirmationMessageCardComponent,
    ConfirmationModalComponent,
    FormFieldMessageComponent,
    FormFieldComponent,
    FormFieldPhoneNumberComponent,
    DateFieldComponent,
    ResourceGroupsSegmentComponent,
    UnderConstructionComponent,
    SlotCardComponent,
    SlidingCardComponent,
    AddFavouriteButtonComponent,
    PriceComponent,
    AvailabilityComponent,
    IsBookedComponent,
    LastBookedComponent,
    DateTimeComponent,
    PreviousAndFavouriteBookingsComponent,
    GenericHomepageCardComponent,
    LatestBookingCardComponent,
    DateSelectorComponent,
    DateButtonComponent,
    DatePickerModalComponent,
    RecommendedActivitiesComponent,
    EntryBookingItemComponent,
    TimerComponent,
    CalendarDisplayComponent,
    ActivityCardComponent,
    CalendarHeaderComponent,
    MonthControlComponent,
    NoResultsGenericComponent,
    LanguagePickerComponent,
    AngularGenericModal,
    ThreeDSecureModalComponent,
    GenericRadioListModalComponent,
    DarkModeModalComponent,
    AddGuestComponent,
    ErrorModalComponent,
    SiteDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    SwiperModule
  ],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
  exports: [
    TabsComponent,
    HeaderComponent,
    IconContainerComponent,
    LogoHeaderComponent,
    TabsHeaderComponent,
    TitleHeaderComponent,
    SearchBarComponent,
    NoResultsComponent,
    HighlightMatchPipe,
    TimePickerComponent,
    TimePickerComponent,
    DatePickerComponent,
    FormErrorComponent,
    NoSlotsComponent,
    PaymentCardComponent,
    AddCardComponent,
    SavedCardsComponent,
    SkeletonPlaceholderComponent,
    SkeletonPlaceholderHorizontallyComponent,
    DateFnsFormat,
    ConfirmationMessageCardComponent,
    ConfirmationModalComponent,
    FormFieldMessageComponent,
    FormFieldComponent,
    FormFieldPhoneNumberComponent,
    DateFieldComponent,
    ResourceGroupsSegmentComponent,
    UnderConstructionComponent,
    SlotCardComponent,
    SlidingCardComponent,
    AddFavouriteButtonComponent,
    PriceComponent,
    AvailabilityComponent,
    IsBookedComponent,
    LastBookedComponent,
    DateTimeComponent,
    DateSelectorComponent,
    DateButtonComponent,
    DatePickerModalComponent,
    PreviousAndFavouriteBookingsComponent,
    GenericHomepageCardComponent,
    LatestBookingCardComponent,
    RecommendedActivitiesComponent,
    EntryBookingItemComponent,
    TimerComponent,
    CalendarDisplayComponent,
    ActivityCardComponent,
    MonthControlComponent,
    NoResultsGenericComponent,
    LanguagePickerComponent,
    AngularGenericModal,
    ThreeDSecureModalComponent,
    GenericRadioListModalComponent,
    DarkModeModalComponent,
    AddGuestComponent,
    SiteDetailsComponent,
    ErrorModalComponent,
  ]
})
export class SharedModule {}

import { FormMessageType } from '@core/constants';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gs-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnInit {
  @Input() fieldLabel: string;
  @Input() fieldPlaceholder: string;
  @Input() inputId: string;
  @Input() inputMode: string;
  @Input() type: string;
  @Input() error = '';
  @Input() inputValue: string;
  @Input() information = '';
  @Output() inputBlurEvent = new EventEmitter<void>();
  @Output() inputChangeEvent = new EventEmitter<any>();
  wasModified = false;
  wasFocused = false;
  formMessageTypeWrapper = FormMessageType;
  constructor() { }

  ngOnInit() {}

  onInputBlur(): void {
    this.inputBlurEvent.emit();
  }

  onInputFocus(): void {
    this.wasFocused = true;
  }

  onInputChange(event): void {
    this.inputChangeEvent.emit(event);
    this.wasModified = true;
  }

}

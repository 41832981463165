/* eslint-disable object-shorthand */
/* eslint-disable radix */
import { AdditionalBookees, ResourceGroup, Slot, areSlotReferencesEqual } from '../../core/models/session.model';
import { FavouriteBooking } from '@core/models/favourite-booking.model';
import { format, getMinutes } from 'date-fns';
import { TimeUtilitiesHelper } from '@core/helpers/time-utilities-helper';
import { getHours } from 'date-fns';
import { SessionSearchParams } from '@core/models/session-search-params.model';
import {
  BookDatePickerState,
  BookTimePickerState,
  IFavouritesState,
  ISessionState,
  FutureBookingsState,
  IActivityState,
  RecommendedActivitiesState,
  IBasketState,
} from './../state/app-config.state';
import { Site } from '@core/models/site.model';
import { createSelector } from '@ngrx/store';
import { ISiteState, IAppState } from '@store/state/app-config.state';
import { FutureBooking, PriceLevel } from '@core/models/future-booking.model';
import { AdditionalBookeesBasketItem, BasketItem } from '@core/models/basket-item.model';
import { BasketMapper } from '@api/mappers/basket.mapper';

const _sitesState = (state: IAppState) => state.sites;
const _activitiesState = (state: IAppState) => state.activities;
const _timeState = (state: IAppState) => state.bookTimePicker;
const _sessionsState = (state: IAppState) => state.sessions;
const _state = (state: IAppState) => state;
const _dateState = (state: IAppState) => state.bookDatePicker;
const _favouriteState = (state: IAppState) => state.favourites;
const _futureBookingsState = (state: IAppState) => state.futureBookings;
const _recommendedState = (state: IAppState) => state.recommendedActivities;
const _basketState = (state: IAppState) => state.basket;

export const selectSessionSearchParams = createSelector(_state, (state: IAppState) => {
  const startDate = new Date(state.bookDatePicker.startDate);
  const endDate = new Date(state.bookDatePicker.endDate);

  const startTime = TimeUtilitiesHelper.newTime(state.bookTimePicker.startTime);
  const endTime = TimeUtilitiesHelper.newTime(state.bookTimePicker.endTime);

  startDate.setHours(getHours(startTime), getMinutes(startTime));
  endDate.setHours(getHours(endTime), getMinutes(endTime));
  return {
    activityId: state.activities.selectedActivity.activityIdsBySiteId[state.sites.selectedSite.id],
    siteId: state.sites.selectedSite.id,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    siteName: state.sites.selectedSite.name,
    activityName: state.activities.selectedActivity.name,
  } as SessionSearchParams;
});

export const selectNumberOfResourceGroups = createSelector(
  _sessionsState,
  (state: ISessionState): number => Object.keys(state.allSlotsByResourceGroup)?.length
);

export const selectGroupedSlotsForVirtualScroll = createSelector(_sessionsState, (state: ISessionState) => {
  const slotsAndDatesContainer = {};
  if (Object.keys(state.allSlotsByResourceGroup).length === 0) {
    return slotsAndDatesContainer;
  }
  Object.values(state.allSlotsByResourceGroup).forEach((resourceGroup) => {
    slotsAndDatesContainer[resourceGroup.resourceId] =
      slotsAndDatesContainer[resourceGroup.resourceId] === undefined
        ? []
        : slotsAndDatesContainer[resourceGroup.resourceId];

    Object.entries(resourceGroup.slotsByDate).forEach(([date, slotsByDate]) => {
      slotsByDate = slotsByDate
        .slice()
        .sort((leftSlot: Slot, rightSlot: Slot) =>
          TimeUtilitiesHelper.compareDates(leftSlot.startTime, rightSlot.startTime)
        );
      slotsAndDatesContainer[resourceGroup.resourceId] = [
        ...slotsAndDatesContainer[resourceGroup.resourceId],
        new Date(date),
      ];
      slotsByDate.forEach((slot) => {
        slotsAndDatesContainer[resourceGroup.resourceId] = [...slotsAndDatesContainer[resourceGroup.resourceId], slot];
      });
    });
  });
  return slotsAndDatesContainer;
});

export const selectResourceGroups = createSelector(_sessionsState, (state: ISessionState): ResourceGroup[] => {
  if (Object.keys(state.allSlotsByResourceGroup).length !== 0) {
    return Object.values(state.allSlotsByResourceGroup);
  } else if (Object.keys(state.additionalSlots).length !== 0) {
    return Object.values(state.additionalSlots);
  } else {
    return [];
  }
});

export const selectSelectedSlot = createSelector(_sessionsState, (state: ISessionState) => state.selectedSlot);

export const selectActivityDetails = createSelector(
  _sessionsState,
  (state: ISessionState) => state.selectedActivityDetails
);

export const selectSelectedResource = createSelector(_sessionsState, (sessionState: ISessionState) => {
  if (sessionState.selectedResource) {
    return sessionState.selectedResource;
  }
  if (Object.keys(sessionState.allSlotsByResourceGroup).length > 0) {
    return sessionState.allSlotsByResourceGroup[Object.keys(sessionState.allSlotsByResourceGroup)[0]];
  } else {
    return sessionState.additionalSlots[Object.keys(sessionState.additionalSlots)[0]];
  }
});

export const selectSelectedDateDots = createSelector(_dateState, (dateState: BookDatePickerState) =>
  dateState.selectedDateDots.map((date) => new Date(date))
);

export const selectIsSearchingSessions = createSelector(
  _sessionsState,
  (state: ISessionState) => state.isSearchingSessions
);

export const selectPriceOfSlot = (slotReference: string) =>
  createSelector(_sessionsState, (state: ISessionState) => state.slotsWithPrices[slotReference]?.price);

export const selectSlotAndPrice = createSelector(_sessionsState, (state: ISessionState) =>
  state.selectedSlot !== undefined
    ? {
        slot: state.selectedSlot,
        price: state.slotsWithPrices[state.selectedSlot.slotReferences.inCentre]?.price,
      }
    : undefined
);

export const selectAdditionalSlots = createSelector(_sessionsState, (state: ISessionState) => state.additionalSlots);

export const selectSlotPriceLevels = createSelector(_sessionsState, (state: ISessionState) => state.slotPriceLevels);

export const selectSlotPriceQuote = createSelector(_sessionsState, (state: ISessionState) => state.slotPriceQuote);

export const selectSlotPriceLevelsFromBasket = createSelector(
  _sessionsState,
  _basketState,
  (sessionState: ISessionState, basketState: IBasketState) => {
    if (sessionState.selectedSlot === undefined) {
      return [];
    }
    const item = basketState.basketItems.find(
      (basketItem) => basketItem.slotReference === sessionState.selectedSlot.slotReferences.inCentre
    );
    if (item) {
      return item.additionalBookees;
    }
    return [];
  }
);

export const selectPriceLevelsFromFutureBooking = createSelector(
  _sessionsState,
  _futureBookingsState,
  (sessionState: ISessionState, futureBookingsState: FutureBookingsState) => {
    if (sessionState.selectedSlot === undefined) {
      return [];
    }
    const selectedBookingId = JSON.parse(sessionState.selectedSlot.slotReferences.inCentre)?.BookingId;
    const matchingBooking = futureBookingsState.futureBookings.find(
      (booking) => booking.bookingId === selectedBookingId
    );
    return matchingBooking ? matchingBooking.bookeePriceLevels : [];
  }
);

export const selectAdditionalBookeesOfSelectedSlot = createSelector(
  _sessionsState,
  _basketState,
  _futureBookingsState,
  (
    sessionState: ISessionState,
    basketState: IBasketState,
    futureBookingsState: FutureBookingsState
  ): {
    additionalBookees: AdditionalBookees[];
    isMemberAttending: boolean;
  } => {
    // no slot
    if (sessionState.selectedSlot === undefined) {
      return {
        isMemberAttending: true,
        additionalBookees: [],
      };
    }

    const selectedBookingId = JSON.parse(sessionState.selectedSlot.slotReferences.inCentre)?.BookingId;
    const matchingBooking = futureBookingsState.futureBookings.find(
      (booking) => booking.bookingId === selectedBookingId
    );

    // state of activity details is saved
    if (
      basketState.futureBasketItem &&
      areSlotReferencesEqual(
        sessionState.selectedSlot.slotReferences.inCentre,
        basketState.futureBasketItem.slotReference
      )
    ) {
      return {
        isMemberAttending: basketState.futureBasketItem.isMemberAttending,
        additionalBookees: basketState.futureBasketItem.additionalBookees.map((x) =>
          BasketMapper.mapBasketBookeeToAdditionalBookees(x)
        ),
      };
    } else {
      // slot is in basket
      const item = basketState.basketItems.find(
        (basketItem) => basketItem.slotReference === sessionState.selectedSlot.slotReferences.inCentre
      );
      if (item) {
        return {
          additionalBookees: item.additionalBookees.map((bookee: AdditionalBookeesBasketItem) =>
            BasketMapper.mapBasketBookeeToAdditionalBookees(bookee)
          ),
          isMemberAttending: item.isMemberAttending,
        };
      }

      // default case (not booked & not in basket)
      if (!matchingBooking) {
        return {
          isMemberAttending: true,
          additionalBookees: [],
        };
      }
    }

    // slot is already booked
    const priceLevels = matchingBooking.bookeePriceLevels
      .map((priceLevel) => {
        priceLevel = { ...priceLevel };
        priceLevel.price /= priceLevel.count;
        if (priceLevel.id === sessionState.slotPriceLevels.memberPriceLevel.id && matchingBooking.isMemberAttending) {
          priceLevel.count--;
        }
        return priceLevel;
      })
      .filter((x) => x.count > 0);

    return {
      additionalBookees: priceLevels.map((priceLevel: PriceLevel) =>
        BasketMapper.mapPriceLevelToAdditionalBookees(priceLevel)
      ),
      isMemberAttending: matchingBooking.isMemberAttending,
    };
  }
);

export const selectIsSelectedSlotFavourite = createSelector(
  _sessionsState,
  _favouriteState,
  (sessionState: ISessionState, favouriteState: IFavouritesState) =>
    favouriteState.favouriteBookings.some(
      (booking: FavouriteBooking) =>
        booking.siteId === sessionState.selectedSlot?.siteId &&
        booking.activityId === sessionState.selectedSlot?.activityId
    )
);

export const selectIsSelectedSlotBooked = createSelector(
  _sessionsState,
  _futureBookingsState,
  (sessionState: ISessionState, futureBookingsState: FutureBookingsState) => {
    if (!sessionState.selectedSlot) {
      return false;
    }
    const selectedBookingId = JSON.parse(sessionState.selectedSlot.slotReferences.inCentre)?.BookingId;
    const matchingBooking = futureBookingsState.futureBookings.find(
      (booking) => booking.bookingId === selectedBookingId
    );
    return matchingBooking ? matchingBooking.inCentre.bookingConfirmed : false;
  }
);

export const selectPreviousSelectedSlotAndPrice = createSelector(_sessionsState, (state: ISessionState) =>
  state.previouslySelectedSlot !== undefined
    ? {
        slot: state.previouslySelectedSlot,
        price: state.slotsWithPrices[state.previouslySelectedSlot.slotReferences.inCentre]?.price,
      }
    : undefined
);

export const selectIsLoadingPrices = (slotReference: string) =>
  createSelector(_sessionsState, (state: ISessionState) => state.slotsWithPrices[slotReference]?.isLoading);

export const selectIsLoadingActivityDetails = createSelector(
  _sessionsState,
  (state: ISessionState) => state.isLoadingActivityDetails
);

export const selectSites = createSelector(_sitesState, (sitesState: ISiteState) => sitesState.sites);

export const selectIsSingleSite = createSelector(_sitesState, (sitesState: ISiteState) => sitesState.isSingleSite);

export const selectIsSearchingSites = createSelector(
  _sitesState,
  (sitesState: ISiteState) => sitesState.isSearchingSite
);

export const selectFilteredSites = createSelector(
  _sitesState,
  _activitiesState,
  (sitesState: ISiteState, activitiesState: IActivityState) => {
    let sitesFromSelectedActivity: Site[] = [];
    if (activitiesState.selectedActivity === undefined) {
      sitesFromSelectedActivity = [...sitesState.sites];
    } else {
      sitesFromSelectedActivity = Object.keys(activitiesState.selectedActivity.activityIdsBySiteId)
        .map((siteId: string) => sitesState.sites.find((site) => site.id === siteId))
        .filter((site) => site !== undefined);
    }

    const resultSitesSet = new Set<Site>();

    const nameMatchingSites = sitesFromSelectedActivity.filter((site) =>
      site.name?.toLowerCase().includes(sitesState.searchFilter.toLowerCase())
    );
    const cityMatchingSites = sitesFromSelectedActivity.filter((site) =>
      site.address.townCity?.toLowerCase().includes(sitesState.searchFilter.toLowerCase())
    );
    const postalCodeMatchingSites = sitesFromSelectedActivity.filter((site) =>
      site.address.postalCode?.toLowerCase().includes(sitesState.searchFilter.toLowerCase())
    );

    nameMatchingSites.forEach((site) => {
      resultSitesSet.add(site);
    });
    cityMatchingSites.forEach((site) => {
      resultSitesSet.add(site);
    });
    postalCodeMatchingSites.forEach((site) => {
      resultSitesSet.add(site);
    });

    const resultSites = Array.from(resultSitesSet).filter((site) => site.isActive);
    return resultSites;
  }
);
export const selectSelectedSite = createSelector(_sitesState, (siteState: ISiteState) =>
  siteState.selectedSite ? siteState.selectedSite : siteState.defaultSite
);

export const selectSiteById = (siteId: string) =>
  createSelector(_sitesState, (siteState: ISiteState) => siteState.sites.find((site) => site.id === siteId));

export const selectDefaultSite = createSelector(_sitesState, (siteState: ISiteState) => siteState.defaultSite);

export const selectActivities = createSelector(
  _activitiesState,
  (activitiesState: IActivityState) => activitiesState.activitiesGroupedByName
);

export const selectActivitiesAsList = createSelector(_activitiesState, (activitiesState: IActivityState) =>
  activitiesState.searchText.length >= 3 || activitiesState.searchActivityTagId !== null
    ? activitiesState.activityNames.map((activityName: string) => activitiesState.activitiesGroupedByName[activityName])
    : []
);

export const selectActivityTagGroup = createSelector(
  _activitiesState,
  (activitiesState: IActivityState) => activitiesState.activityTagGroup
);

export const selectChosenActivity = createSelector(
  _activitiesState,
  (activitiesState: IActivityState) => activitiesState.selectedActivity
);

export const selectSearchTextActivities = createSelector(
  _activitiesState,
  (activitiesState: IActivityState) => activitiesState.searchText
);

export const selectisSearchingActivities = createSelector(
  _activitiesState,
  (activitiesState: IActivityState) => activitiesState.isSearchingActivity
);

export const selectisSearchingActivitiesByTag = createSelector(
  _activitiesState,
  (activitiesState: IActivityState) => activitiesState.isSearchingActivityByTagId
);

export const selectIsActivityAndSiteCombinationPossible = createSelector(
  _activitiesState,
  _sitesState,
  (activitiesState: IActivityState, sitesState: ISiteState) => {
    if (activitiesState.selectedActivity === undefined || sitesState.selectedSite === undefined) {
      return true;
    }
    if (activitiesState.selectedActivity.activityIdsBySiteId[sitesState.selectedSite.id] !== undefined) {
      return true;
    }
    return false;
  }
);

export const selectBookTimeBoundaries = createSelector(_timeState, (timeState: BookTimePickerState) => ({
  startTime: TimeUtilitiesHelper.newTime(timeState.startTime).toISOString(),
  endTime: TimeUtilitiesHelper.newTime(timeState.endTime).toISOString(),
}));

export const selectIsTimeSet = createSelector(_timeState, (timeState: BookTimePickerState) => timeState.timeIsSet);

export const selectBookDateBoundaries = createSelector(_dateState, (dateState: BookDatePickerState) => ({
  startDate: dateState.startDate,
  endDate: dateState.endDate,
}));

export const selectIsCurrentDate = createSelector(
  _dateState,
  (dateState: BookDatePickerState) => dateState.isCurrentDate
);

export const selectRecommendedActivities = createSelector(
  _recommendedState,
  (state: RecommendedActivitiesState) => state.recommendedActivities
);
export const selectIsLoadingRecommendedActivities = createSelector(
  _recommendedState,
  (state: RecommendedActivitiesState) => state.isLoadingRecommendedActivities
);

export const selectJoinedAlertList = createSelector(
  _sessionsState,
  _futureBookingsState,
  (sessionState: ISessionState, futureBookingsState: FutureBookingsState) => {
    if (!sessionState.selectedSlot) {
      return false;
    }
    const selectedBookingId = JSON.parse(sessionState.selectedSlot.slotReferences.inCentre)?.BookingId;
    const matchingBooking = futureBookingsState.alertList.find((booking) => booking.bookingId === selectedBookingId);
    return matchingBooking ? matchingBooking.inCentre.onAlertList : false;
  }
);

export const selectSlotByFutureBooking = (futureBooking: FutureBooking) =>
  createSelector(_sessionsState, (sessionState: ISessionState): Slot => {
    const dateKey = format(new Date(futureBooking.startTime), 'yyyy-MM-dd');
    return sessionState.slotsByActivityId[futureBooking.activityId]?.resourceGroups[
      futureBooking.location.id
    ]?.slotsByDate[dateKey].find(
      (slot: Slot) => JSON.parse(slot.slotReferences.inCentre).BookingId === futureBooking.bookingId
    );
  });

export const selectSlotsByFutureBookings = (futureBookings: FutureBooking[]) =>
  createSelector(_sessionsState, (sessionState: ISessionState): Slot[] =>
    futureBookings.map((futureBooking) => {
      const dateKey = format(new Date(futureBooking.startTime), 'yyyy-MM-dd');
      return sessionState.slotsByActivityId[futureBooking.activityId]?.resourceGroups[
        futureBooking.location.id
      ]?.slotsByDate[dateKey].find(
        (slot: Slot) => JSON.parse(slot.slotReferences.inCentre).BookingId === futureBooking.bookingId
      );
    })
  );

export const selectPromotedTagId = createSelector(
  _recommendedState,
  (recommendedState) => recommendedState.promotedTagId
);

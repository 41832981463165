import { Component, Input, OnInit } from '@angular/core';
import { idToSafeString } from '@app/core/helpers/id-to-safe-string';
import { PriceInformation } from '@app/core/models/pricing.model';
import { AdditionalBookees } from '@app/core/models/session.model';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'gs-add-guest',
  templateUrl: './add-guest.component.html',
  styleUrls: ['./add-guest.component.scss'],
})
export class AddGuestComponent implements OnInit {
  @Input() guests: AdditionalBookees[];
  @Input() priceLevels$: Observable<PriceInformation>;
  priceLevelsWithCounter: AdditionalBookees[] = [];
  totalAmount = 0.0;
  idToSafeString = idToSafeString;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.priceLevels$.subscribe((levels) => {
      this.priceLevelsWithCounter = levels.priceLevels.map((res) => ({
        priceLevels: res,
        count:
          this.guests?.find((additionalBookee: AdditionalBookees) => additionalBookee.priceLevels.id === res.id)
            ?.count ?? 0,
      }));
      this.priceLevelsWithCounter.forEach((res) => (this.totalAmount += res.count * res.priceLevels.price.inCentre));
    });
  }

  cancel() {
    this.modalCtrl.dismiss(undefined, 'cancel');
  }

  onApplyChanges(e) {
    this.modalCtrl.dismiss({
      additionalBookees: this.priceLevelsWithCounter.filter((additionalBookee) => !!additionalBookee.count),
      additionalBookeesTotalPrice: this.totalAmount,
    });
  }

  decreaseCounter(guest: AdditionalBookees) {
    if (guest.count === 0) {
      return;
    } else {
      guest.count -= 1;
      this.totalAmount -= guest.priceLevels.price.inCentre;
    }
  }

  increaseCounter(guest: AdditionalBookees) {
    guest.count += 1;
    this.totalAmount += guest.priceLevels.price.inCentre;
  }
}

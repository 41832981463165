import { Injectable } from '@angular/core';
import aes from 'js-crypto-aes';

import { BehaviorSubject } from 'rxjs';
// Interfaces
import { environment } from 'src/environments/environment';
import { AnonymousTenant } from './models/tenant';

@Injectable({
  providedIn: 'root',
})
export class AuthHelperService {
  private token$: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem('token'));

  public get token(): string {
    return this.token$.value;
  }

  public saveTokenToCookie(key: string, token: string): void {
    if (key === 'token') {
      this.token$.next(token);
    }
    localStorage.setItem(key, token);
  }

  public generateKey(key: string): string {
    if (key.length > 32) {
      key = key.substring(0, 32);
    } else {
      key += key;
      return this.generateKey(key);
    }

    return key;
  }

  public decryptToken(payload: string): Promise<AnonymousTenant> {
    return new Promise(resolve => {
      const key = this.generateKey(environment.apiBaseHost);

      const encryptedText = window.atob(payload);
      const len = encryptedText.length;
      const encryptedTextBytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        encryptedTextBytes[i] = encryptedText.charCodeAt(i);
      }

      const iv = encryptedTextBytes.slice(0, 16);
      const encrypted = encryptedTextBytes.slice(16, encryptedTextBytes.length);
      const keyBytes = new Uint8Array(32);

      for (let i = 0; i < 32; i++) {
        keyBytes[i] = key.charCodeAt(i);
      }

      aes
        .decrypt(encrypted, keyBytes, { name: 'AES-CBC', iv })
        .then(decrypted => {
          let decryptedPlain = '';
          let response;

          for (const decryptedUint8 of decrypted) {
            decryptedPlain += String.fromCharCode(decryptedUint8);
          }

          response = JSON.parse(decryptedPlain);
          resolve(response);
        })
        .catch(err => {
        });
    });
  }
}

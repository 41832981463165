import { CountryCode, PhoneNumber } from '@app/core/models/contact-details.model';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import countryCode from '../../../assets/config/CountryCodes.json';



export const extractDialCodeFromPrimaryNumber = (phoneNumbers: PhoneNumber[]) => {
  const doesHavePrimary = checkIfContactHasPrimaryPhoneNumber(phoneNumbers);
    let countryCodeOfPhoneNumber;
    if (!doesHavePrimary) {
      countryCodeOfPhoneNumber = countryCode.codes.find((code) => code.code === 'GB');
    }
    else {
      const primaryPhoneNumber = extractPrimaryPhoneNumber(phoneNumbers);
      countryCodeOfPhoneNumber = isValidPhoneNumber(primaryPhoneNumber)
        ? countryCode.codes.find(
            (code) => code.code === parsePhoneNumber(primaryPhoneNumber).country
          )
        : countryCode.codes.find((code) => code.code === 'GB');
    }
    return {
      countryCode: countryCodeOfPhoneNumber.code,
      dialCode: countryCodeOfPhoneNumber.dial_code,
      name: countryCodeOfPhoneNumber.name
    };

};

export const checkIfContactHasPrimaryPhoneNumber = (phoneNumbers: PhoneNumber[]): boolean =>
  phoneNumbers.find((phoneNumber) => phoneNumber.isPrimary) ? true : false;

export const extractPrimaryPhoneNumber = (
  phoneNumbers: PhoneNumber[]
): string =>
  Object.values(
    phoneNumbers.find((phoneNumber) => phoneNumber.isPrimary)
  )?.find((field) => field !== true && field !== undefined);

export const removeDialCodeFromNumber = (
  phoneNumber: string,
  dialCode: string
) =>
  phoneNumber.startsWith(dialCode) ? phoneNumber.replace(dialCode, '') : '';

export const mapJsonToCountryCode = (jsonCountry): CountryCode =>({
      name: jsonCountry.name,
      dialCode: jsonCountry.dial_code,
      countryCode: jsonCountry.code,
    } as CountryCode);

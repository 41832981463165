import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ACTIVITIES_API_ENDPOINT, ACTIVITIES_BY_TAGS_API_ENDPOINT } from '@app/core/constants';
import { Activity } from '@app/core/models/activity.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivityDTO, TagsWithActivitiesDTO } from '../dtos/activity.dto';
import { ActivityMapper } from '@api/mappers/activity.mapper';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(private http: HttpClient) {}

  public getAll(siteIds: string[]): Observable<{ [name: string]: Activity }> {
    return this.http
      .get<ActivityDTO[]>(`${environment.apiUrl}${ACTIVITIES_API_ENDPOINT}`, {
        params: {
          webBookableOnly: true,
          siteIds: siteIds.filter((siteId) => siteId !== undefined),
        },
      })
      .pipe(map((activities: ActivityDTO[]) => ActivityMapper.groupActivitiesByName(activities)));
  }

  public searchActivities(searchText: string, siteIds: string[]): Observable<{ [name: string]: Activity }> {
    return this.http
      .get<ActivityDTO[]>(`${environment.apiUrl}${ACTIVITIES_API_ENDPOINT}/${searchText}`, {
        params: {
          webBookableOnly: true,
          siteIds: siteIds.filter((siteId) => siteId !== undefined),
        },
      })
      .pipe(map((activities: ActivityDTO[]) => ActivityMapper.groupActivitiesByName(activities)));
  }

  public getActivitiesByTags(tagIds: number[]): Observable<TagsWithActivitiesDTO[]> {
    const endpoint = `${environment.apiUrl}${ACTIVITIES_BY_TAGS_API_ENDPOINT}`;
    return this.http.get<TagsWithActivitiesDTO[]>(endpoint, { params: { tagIds, webBookableOnly: true } });
  }

  public getActivityIdsByTags(tagIds: number[]): Observable<string[]> {
    return this.getActivitiesByTags(tagIds).pipe(
      map((data) => data[0]?.activities),
      map((activities) => activities.map((activity) => activity.id))
    );
  }
}

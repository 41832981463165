import { formatISO, subYears } from 'date-fns';
import { setSearchParametersBySelectedBooking } from '@store/actions/book.actions';
import { addFavouriteBooking, removeFavouriteBooking } from '@app/store/actions/favourites.actions';
import { BOOK_HISTORY_CARD_WIDTH, BOOK_HISTORY_CARD_HEIGHT } from '@core/constants';
import { selectIsLoadingBookingHistory, selectLastFiveBookings } from '@store/selectors/basket.selectors';
import { getAccountMemberId } from '@store/selectors/authentication.selectors';
import { loadBookingHistory } from '@store/actions/basket.actions';
import { first, Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsBookingFavourite } from '@app/store/selectors/favourites.selectors';
import { PreviousBooking } from '@app/core/models/previous-booking.model';
import { FavouriteBooking } from '@app/core/models/favourite-booking.model';

@Component({
  selector: 'gs-previous-and-favourite-bookings',
  templateUrl: './previous-and-favourite-bookings.component.html',
  styleUrls: ['./previous-and-favourite-bookings.component.scss'],
})
export class PreviousAndFavouriteBookingsComponent implements OnInit {
  previousFiveBookings$: Observable<PreviousBooking[]> = this.store.select(selectLastFiveBookings);
  isLoadingBookings$: Observable<boolean> = this.store.select(selectIsLoadingBookingHistory);
  widthOfACard = BOOK_HISTORY_CARD_WIDTH;
  heightOfACard = BOOK_HISTORY_CARD_HEIGHT;
  constructor(private store: Store) {}

  ngOnInit() {
    this.store.select(getAccountMemberId).pipe(first()).subscribe(memberId => {
      this.store.dispatch(loadBookingHistory({memberId, dateTimeFrom: formatISO(subYears(new Date(), 1))}));
    });
  }

  isBookingFavourite(booking: PreviousBooking): Observable<boolean> {
    return this.store.select(selectIsBookingFavourite({siteId: booking.siteId, activityId: booking.activityId}));
  }

  onFavouriteStatusChanged(event: {favouriteBooking: FavouriteBooking; isFavourite: boolean}) {
    if (event.isFavourite) {
      this.store.dispatch(removeFavouriteBooking({siteId: event.favouriteBooking.siteId, activityId: event.favouriteBooking.activityId}));
    }
    else {
      this.store.dispatch(addFavouriteBooking({favouriteBooking: event.favouriteBooking}));
    }
  }

  onBookingClick(booking: PreviousBooking) {
    this.store.dispatch(setSearchParametersBySelectedBooking({booking}));
  }

}

import { createReducer, on } from '@ngrx/store';
import {fetchSavedCards, fetchSavedCardsFailure, setCurrentSelectedCard, setSavedCards } from '../actions/wallet.actions';
import { WalletState } from '../state/wallet.state';

const initialState: WalletState = {
  isLoading: false,
  savedCards: [],
  currentSelectedCard: null
};

export const walletReducer = createReducer(
  initialState,
  on(fetchSavedCards, (state) => ({...state, isLoading: true})),
  on(fetchSavedCardsFailure, (state) => ({...state, isLoading: false})),
  on(setSavedCards, (state, payload) => ({
    ...state,
    isLoading: false,
    savedCards: payload.savedCards,
    // TODO: When favourite card will be retrieve, add the favourite card as the default selectedCard
    currentSelectedCard: state.currentSelectedCard === null && payload.savedCards.length > 0 ?
       payload.savedCards[0] : state.currentSelectedCard
  })),
  on(setCurrentSelectedCard, (state, payload) => ({...state, currentSelectedCard: {...payload.currentSelectedCard} })),
);

import { EntryIdentifierTypes, SHORT_IDENTIFIER_REFRESH_RATE } from '@app/core/constants';
import { Identifier } from '@app/core/models/identifier.model';
import { addHours, addMilliseconds, addYears } from 'date-fns';
import { IdentifierDTO } from '../dtos/identifier.dto';

export class AccessControlMapper{
  public static  mapToIdentifier(identifier: IdentifierDTO): Identifier{
    const identifierr: Identifier = {
      id: identifier.id,
      type: identifier.type,
      expirationDate: this.computeIdentifierExpirationDate(identifier.type as EntryIdentifierTypes, identifier.createdOn)
    };
    return identifierr;
  }

  private static computeIdentifierExpirationDate(type: EntryIdentifierTypes, creationDate: string): string {
    if(!creationDate || !type){
      return new Date().toISOString();
    }
    if(creationDate[creationDate.length - 1] !== 'Z'){
      creationDate = creationDate+'Z';
    }
    if(type === EntryIdentifierTypes.short){
      const date = new Date(creationDate);
      return addMilliseconds(date, SHORT_IDENTIFIER_REFRESH_RATE).toISOString();
    }
    if(type === EntryIdentifierTypes.offline){
      const date = new Date(creationDate);
      return addHours(date, 24).toISOString();
    }
    return addYears(new Date(), 100).toISOString();
  }
}

import { unsubscribeFromListenenrs } from './../actions/notification-handling.actions';
import { displaySuccessToast } from '../actions/notification-handling.actions';
import { NotificationsService } from '../../core/notifications/notifications.service';
import { displayInfoToast, setApiError } from '../actions/notification-handling.actions';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { tap } from 'rxjs';


@Injectable()
export class NotificationHandlingEffects {
  showInfoToast$ = createEffect(() =>
    this.action$.pipe(
      ofType(displayInfoToast),
      tap((action) => this.notificationService.info(action.message, action.iconName, action.toastClass))
    ), {dispatch: false}
  );

  showSuccessToast$ = createEffect(() =>
    this.action$.pipe(
      ofType(displaySuccessToast),
      tap((action) => this.notificationService.success(action.message, action.iconName, action.toastClass, action.parameters))
    ), {dispatch: false}
  );

  showErrorToast$ = createEffect(() =>
  this.action$.pipe(
    ofType(setApiError),
    tap((action) => this.notificationService.error(action.error))
    ), {dispatch: false}
  );

  unsubscribeFromListenenrs$ = createEffect(() =>
    this.action$.pipe(
      ofType(unsubscribeFromListenenrs),
      tap(() => this.notificationService.clearResources())
    ), {dispatch: false}
  );

  constructor(private action$: Actions, private notificationService: NotificationsService) {}
}

import { Component, Input, OnInit } from '@angular/core';
import { selectComputedColorTheme } from '@app/store/selectors/app-config.selectors';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Component({
  selector: 'gs-no-slots',
  templateUrl: './no-slots.component.html',
  styleUrls: ['./no-slots.component.scss'],
})
export class NoSlotsComponent implements OnInit {
  @Input() isAboutToday: boolean;
  @Input() tryAnotherCourt: boolean;
  noSlotsImageUrl$ = this.store.select(selectComputedColorTheme).pipe(
    map(colorTheme => `assets/icon/${colorTheme}/no-slots.svg`)
  );
  constructor(private store: Store) { }

  ngOnInit() {}

}

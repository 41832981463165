import { filter, Observable } from "rxjs";

export function filterNull<T>(): (source: Observable<T>) => Observable<T> {
    return function(source: Observable<T>): Observable<T> {
      return source.pipe(
        filter((value: T) => {
          if (Array.isArray(value)) {
            return value.every((item: unknown) => item !== null && item !== undefined);
          }
          return value !== null && value !== undefined;
        }),
      );
    };
  }
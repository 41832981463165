import { Component, Output, EventEmitter, OnInit, OnDestroy, Input, NgZone } from '@angular/core';
import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';
import { CardDetails } from '@app/core/models/card-details.model';
import { fetchSavedCards, setCurrentSelectedCard } from '@app/store/actions/wallet.actions';
import { currentSelectedCardIndex, savedCards } from '@app/store/selectors/wallet.selectors';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import SwiperCore, { Pagination, Swiper } from 'swiper';

SwiperCore.use([Pagination]);

@Component({
  selector: 'gs-saved-cards',
  templateUrl: 'saved-cards.component.html',
  styleUrls: ['saved-cards.component.scss']
})
export class SavedCardsComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;
  savedCards: CardDetails[];
  currentSelectedCardIndex$ = this.store.select(currentSelectedCardIndex);

  private subscriptions = new Subscription();

  constructor(private store: Store, private zone: NgZone) {}

  ngOnInit(): void {
    this.subscribeToSavedCards();
  }

  onSlideChange(swiper: Swiper[]) {
    const currentSelectedCard = this.savedCards[swiper[0].activeIndex];
    this.zone.run(() => this.store.dispatch(setCurrentSelectedCard({ currentSelectedCard })));
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }

  isCardExpired(card): boolean{
    const cardDate = TimeUtilitiesHelper.parseDateCard(card.expiryDate, true);
    return TimeUtilitiesHelper.isDateFromPastMonths(cardDate);
  }

  firstValidCard(): CardDetails{
    return this.savedCards.find((card) => !this.isCardExpired(card));
  }

  private subscribeToSavedCards(): void {
    const subscription = this.store.select(savedCards).subscribe((cards) => {
      this.savedCards = cards;
      if (this.firstValidCard()) {
        this.zone.run(() => this.store.dispatch(setCurrentSelectedCard({ currentSelectedCard: this.firstValidCard() })));
      }
    });

    this.subscriptions.add(subscription);
  }
}

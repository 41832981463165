import { Injectable } from '@angular/core';
import { OpenedEvent } from 'onesignal-cordova-plugin/dist/models/NotificationOpened';
import { PushNotificationNavigationService } from './push-notification-navigation.service';
import { NotificationActions, NotificationButtonActions, OpenedNotificationActionType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  constructor(private pushNotificationNavigationService: PushNotificationNavigationService) { }

  public handleNotificationOpened(event: OpenedEvent): void {
    let redirectTo: string;

    if (!event.action || !event.action.type) {
      redirectTo = NotificationButtonActions.Action1RedirectTo;
    }

    if (event.action.type === OpenedNotificationActionType.NotificationClicked) {
      redirectTo = NotificationButtonActions.Action1RedirectTo;
    }

    if (event.action.type === OpenedNotificationActionType.ButtonClicked) {
      redirectTo = event.action.actionId === NotificationButtonActions.Action1ID ? NotificationButtonActions.Action1RedirectTo : NotificationButtonActions.Action2RedirectTo;
    }

    let actionToPerform = event.notification.additionalData ? event.notification.additionalData[redirectTo] : null;

    if (!actionToPerform) {
      // notification doesn't have an action, so just open the app (the app is opened automatically, so nothing to do here)
      return;
    }

    if (actionToPerform === NotificationActions.RedirectToActivitySlot) {
      this.pushNotificationNavigationService.redirectToActivitySlot(event.notification.additionalData);
    }

    if (actionToPerform === NotificationActions.RemoveClassAlert) {
      this.pushNotificationNavigationService.removeClassAlert(event.notification.additionalData);
    }
  }
}

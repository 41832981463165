/* eslint-disable @typescript-eslint/member-ordering */
import { Capacitor } from '@capacitor/core';
import { InAppBrowserService } from './../services/in-app-browser.service';

import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  ANONYMOUS_AUTH_ENDPOINT,
  VALIDATE_TENANT_ENDPOINT,
} from '@app/core/constants';
import {
  AuthenticationFailure,
  AuthenticationRequest,
  AuthenticationSuccess,
  ReauthenticationFailure,
  ReauthenticationRequest,
  ReauthenticationSuccess,
} from '@app/store/actions/authentication.actions';
import { isAuthenticating } from '@app/store/selectors/authentication.selectors';
import { IAppState } from '@app/store/state/app-config.state';
// Store
import { Store } from '@ngrx/store';
import {
  AuthConfig,
  EventType,
  OAuthEvent,
  OAuthService,
  TokenResponse,
} from 'angular-oauth2-oidc';
// RxJS
import { BehaviorSubject, from, Observable, ReplaySubject } from 'rxjs';
import {
  concatMap,
  exhaustMap,
  filter,
  map,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { authConfig } from './authentication.config';
import { AuthHelperService } from './authentication.helper';
import { AuthenticatedUserProfile } from './models/authenticated-user-profile';
import { UserAuthenticationResponse } from './models/user-authentication-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  public tenantID: string;
  public scopes: string;
  public authResponse: UserAuthenticationResponse;

  private _userProfile$: BehaviorSubject<AuthenticatedUserProfile> =
    new BehaviorSubject(undefined);
  private onDestroy$: ReplaySubject<void> = new ReplaySubject();

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<IAppState>,
    private readonly oauthService: OAuthService,
    private readonly authHelper: AuthHelperService,
    private inAppBrowserService: InAppBrowserService
  ) {
    this.listenToAuthenticationEvents();
    this.listenToReauthenticationEvents();
    this.listenToUserProfile();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public hasRefreshToken(): boolean {
    return this.oauthService.getRefreshToken() !== null;
  }

  public refreshToken(): Observable<TokenResponse> {
    return from(this.oauthService.refreshToken()).pipe(
      tap((token: TokenResponse) => {
        this.authHelper.saveTokenToCookie('token', token.access_token);
        this.authHelper.saveTokenToCookie('refresh_token', token.refresh_token);
      }),
      exhaustMap((token: TokenResponse) =>
        from(this.loadUserProfile()).pipe(
          tap((authenticatedUserProfile: AuthenticatedUserProfile) =>
            this.store.dispatch(
              new AuthenticationSuccess(true, authenticatedUserProfile.info.m)
            )
          ),
          map(() => token)
        )
      )
    );
  }

  public get userProfile(): AuthenticatedUserProfile {
    return this._userProfile$.value;
  }

  public get userProfile$(): Observable<AuthenticatedUserProfile> {
    return this._userProfile$;
  }

  private validateTenant(): Observable<boolean> {
    const tenantUrl = `${environment.apiUrl}${VALIDATE_TENANT_ENDPOINT}`;
    return this.http.get<boolean>(tenantUrl);
  }

  public login(): void {
    if (environment.useOAuthOverride) {
      this.setupAuthenticatedUser(10);
    } else {
      this.oauthService.initCodeFlow();
    }
  }

  public async logout(): Promise<void> {
    return await this.oauthService.revokeTokenAndLogout();
  }

  public getAnonymousToken(): Observable<string> {
    return this.http.get(`${environment.apiUrl}${ANONYMOUS_AUTH_ENDPOINT}`, {
      responseType: 'text',
    });
  }

  public loginFlow(): Promise<UserAuthenticationResponse> {
    this.store.dispatch(new AuthenticationRequest());
    return new Promise(async (resolve, reject) => {
      try {
        await this.getAnonymousToken()
          .pipe(
            concatMap((token) => this.authHelper.decryptToken(token)),
            map((anonymous) =>
              this.authHelper.saveTokenToCookie('token', anonymous.Token)
            ),
            concatMap(() => this.validateTenant())
          )
          .toPromise();
        /**
         * Update the authConfig object
         */
        this.oauthService.configure(this.buildConfigurationObject(this.scopes));
        /**
         * Start the oAuth Flow
         */
        await this.oauthService.loadDiscoveryDocument();
        await this.oauthService.tryLoginCodeFlow();

        /**
         * Check access token validity
         */
        if (this.oauthService.hasValidAccessToken()) {
          this.authHelper.saveTokenToCookie(
            'token',
            this.oauthService.getAccessToken()
          );

          await this.loadUserProfile();
          /**
           * Setup authenticated user
           */
          this.setupAuthenticatedUser(this.userProfile.info.m, resolve);
        } else {
          await this.loadAnonymousUserProfile();
          this.authResponse = {
            hasValidToken: false,
            reason: 'No valid access token found',
          };
          this.store.dispatch(
            new AuthenticationFailure(
              this.authResponse.hasValidToken,
              this.authResponse.reason
            )
          );
          resolve(this.authResponse);
        }
      } catch (error) {
        this.authResponse = {
          hasValidToken: false,
          hasErrors: true,
          reason: error.message,
        };
        this.store.dispatch(
          new AuthenticationFailure(
            this.authResponse.hasValidToken,
            this.authResponse.reason
          )
        );
        reject(this.authResponse);
      }
    });
  }

  private setupAuthenticatedUser(memberUserId: number, resolve = null): void {
    /**
     * Create authenticated response
     */
    this.authResponse = { hasValidToken: true };
    /**
     * Assign the authenticated user id to the authenticated user
     */
    this.store.dispatch(
      new AuthenticationSuccess(this.authResponse.hasValidToken, memberUserId)
    );

    if (resolve) {
      resolve(this.authResponse);
    }
  }

  private loadUserProfile(): Promise<AuthenticatedUserProfile> {
    return this.oauthService
      .loadUserProfile()
      .then((userProfile: AuthenticatedUserProfile) => {
        this._userProfile$.next(userProfile);
        return userProfile;
      });
  }

  private initInAppBrowserForLoginFlow(uri: string) {
    const platform = Capacitor.getPlatform();
    const options = `location=yes,clearsessioncache=yes,toolbarposition=top,enableViewportScale=yes${
      platform === 'ios' ? ',location=no' : ''
    }`;
    this.inAppBrowserService.initAppBrowserForLogin(uri, options);
  }

  private overrideOAuthOpenUri() {
    if (Capacitor.isNativePlatform()) {
      authConfig.openUri = async (uri) => {
        if (uri.includes('identity/connect') && !uri.includes('logout')) {
          this.initInAppBrowserForLoginFlow(uri);
        } else if (uri.includes('logout')) {
          window?.location.assign(window?.location.origin);
        }
      };
    }
  }

  private buildConfigurationObject(scopes: string): AuthConfig {
    this.overrideOAuthOpenUri();
    return {
      ...authConfig,
      ...{},
    };
  }

  private loadAnonymousUserProfile(): Promise<AuthenticatedUserProfile> {
    return this.http
      .get<AuthenticatedUserProfile>(
        `${environment.apiUrl}/identity/connect/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${this.authHelper.token}`,
          },
        }
      )
      .pipe(
        tap((userProfile: AuthenticatedUserProfile) =>
          this._userProfile$.next(userProfile)
        )
      )
      .toPromise();
  }

  private listenToUserProfile(): void {
    this._userProfile$.pipe(
      filter((userProfile: AuthenticatedUserProfile) => !!userProfile),
      takeUntil(this.onDestroy$)
    );
  }

  private listenToAuthenticationEvents(): void {
    this.events$('token_received')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() =>
        this.authHelper.saveTokenToCookie(
          'token',
          this.oauthService.getAccessToken()
        )
      );

    this.events$('session_terminated', 'session_error', 'logout')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        // this.swUpdate.checkForUpdate();
      });
  }

  private listenToReauthenticationEvents(): void {
    this.events$('token_expires')
      .pipe(
        withLatestFrom(this.store.select(isAuthenticating)),
        filter(
          ([event, isAuthenticating]: [OAuthEvent, boolean]) =>
            !isAuthenticating
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.oauthService.refreshToken();
        this.store.dispatch(new ReauthenticationRequest());
      });

    this.events$('silently_refreshed', 'token_refreshed')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.store.dispatch(new ReauthenticationSuccess()));

    this.events$('silent_refresh_error', 'token_refresh_error')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() =>
        this.store.dispatch(
          new ReauthenticationFailure('Reauthentication failed')
        )
      );
  }

  private events$(...eventTypes: EventType[]): Observable<OAuthEvent> {
    return this.oauthService.events.pipe(
      filter((event: OAuthEvent) => eventTypes.includes(event.type)),
      throttleTime(50)
    );
  }
}

export function authenticationCheck(
  authService: AuthService
): () => Promise<void> {
  return () =>
    new Promise(async (resolve, reject) => {
      try {
        if (!environment.useOAuthOverride) {
          await authService.loginFlow();
        }
        resolve();
      } catch (error) {
        /**
         * Process oAuth flow when a valid access token isn't present
         */
        if (!error.hasValidToken && !error.hasErrors) {
          resolve();
        }
        /**
         * If there are additional errors - Process another action eg redirect
         */
        if (!error.hasValidToken && error.hasErrors) {
          // redirect to an error page
          resolve();
        }
      }
    });
}

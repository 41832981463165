import { ContactDetails } from '@app/core/models/contact-details.model';
import { PersonalDetails, PersonalDetailsFormState } from '@app/core/models/member.model';

export const initialAccountState: AccountState = {
  personalDetails: {
    dateOfBirth: null,
    email: null,
    firstName: null,
    gender: null,
    lastName: null,
    title: null,
    titleId: null,
    memberId: null,
  },
  contactDetails: null,
  personalDetailsFormState: {
    firstname: {value: '', isValid: false, isFocused: false},
    surname: {value: '', isValid: false, isFocused: false},
    dateOfBirth: {value: '', isValid: false, isFocused: false},
    phoneNumber: {value: '', isValid: false, isFocused: false},
    email: {value: '', isValid: false, isFocused: false},
    formWasTouched: false
  },
  selectedDateForBookings: '',
};
export interface AccountState {
  personalDetails: PersonalDetails;
  contactDetails?: ContactDetails;
  personalDetailsFormState?: PersonalDetailsFormState;
  selectedDateForBookings?: string;
}

import { FavouriteBooking } from '@app/core/models/favourite-booking.model';
import { createAction, props, union } from '@ngrx/store';

export const ADD_FAVOURITE_BOOKING = '[Favourites] Add favourite booking';
export const REMOVE_FAVOURITE_BOOKING = '[Favourites] Remove favourite booking';
export const REMOVE_SELECTED_BOOKINGS = '[Favourites] Remove selected bookings';
export const SELECT_FAVOURITE_BOOKING = '[Favourites] Select favourite booking';
export const UNSELECT_FAVOURITE_BOOKING = '[Favourites] Unselect favourite booking';
export const SELECT_ALL_BOOKINGS = '[Favourites] Select all bookings';
export const UNSELECT_ALL_BOOKINGS = '[Favourites] Unselect all bookings';


export const addFavouriteBooking = createAction(ADD_FAVOURITE_BOOKING, props<{favouriteBooking: FavouriteBooking}>());
export const removeFavouriteBooking = createAction(REMOVE_FAVOURITE_BOOKING, props<{siteId: string; activityId: string}>());
export const removeSelectedBookings = createAction(REMOVE_SELECTED_BOOKINGS);
export const selectFavouriteBooking = createAction(SELECT_FAVOURITE_BOOKING, props<{siteId: string; activityId: string}>());
export const unselectFavouriteBooking = createAction(UNSELECT_FAVOURITE_BOOKING, props<{siteId: string; activityId: string}>());
export const selectAllBookings = createAction(SELECT_ALL_BOOKINGS);
export const unselectAllBookings = createAction(UNSELECT_ALL_BOOKINGS);


export const favouriteActions = union({
  addFavouriteBooking,
  removeFavouriteBooking,
  removeSelectedBookings,
  selectFavouriteBooking,
  unselectFavouriteBooking,
  selectAllBookings,
  unselectAllBookings
});

export type FavouriteActions = typeof favouriteActions;

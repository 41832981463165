import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, Injector } from '@angular/core';
import { DISMISS, SEE_MORE, TOASTER_DURATION } from '../constants';
import { map, exhaustMap, Subject, Observable, Subscription, from } from 'rxjs';
import { ErrorData } from '../models/error-data.model';
import { ErrorModalComponent } from '@app/shared/components/modals/error-modal/error-modal.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  errorSubject = new Subject<{error: ErrorData}>();
  errorStream: Observable<void>;
  errorSubscription: Subscription;
  successSubject = new Subject<{message: string; iconName: string; toastClass: string; parameters?: any}>();
  successStream: Observable<void>;
  successSubscription: Subscription;
  infoSubject = new Subject<{message: string; iconName: string; toastClass: string}>();
  infoStream: Observable<void>;
  infoSubscription: Subscription;



  constructor(private toastController: ToastController, private injector: Injector, private modalController: ModalController) {
    this.initErrorSubject();
    this.initSuccessSubject();
    this.initInfoSubject();
   }

   error(error: ErrorData): void{
    this.errorSubject.next({error});
  }

  success(message: string, iconName: string, toastClass: string, parameters?: any): void {
    this.successSubject.next({message, iconName, toastClass, parameters});
  }

  info(message: string, iconName: string, toastClass: string): void {
    this.infoSubject.next({message, iconName, toastClass});
  }

  clearResources() {
    this.errorSubscription.unsubscribe();
    this.successSubscription.unsubscribe();
    this.infoSubscription.unsubscribe();
  }

  private initErrorSubject() {
    this.errorStream = this.errorSubject.pipe(
      exhaustMap(toastContent =>{
        const translateService = this.injector.get(TranslateService);
        return translateService.get([toastContent.error.header, toastContent.error.message, SEE_MORE, DISMISS]).pipe(
          map(translations => ({translatedText: translations, error: toastContent.error}))
        );
      }),
      exhaustMap(({translatedText, error}) => from(this.toastController.create({
        header: translatedText[Object.keys(translatedText)[0]],
        duration: TOASTER_DURATION,
        position: 'top',
        buttons: [
          {
            text: translatedText[Object.keys(translatedText)[2]],
            role: 'none',
            side: 'end',
            handler: async () => {
              const modal = await this.modalController.create({
                component: ErrorModalComponent,
                componentProps: {error},
                cssClass: 'error-modal',
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                handle: false
              });
              await modal.present();
              return true;
            },
          },
          {
            text: translatedText[Object.keys(translatedText)[3]],
            side: 'end',
            role: 'cancel'
          }
        ],
        cssClass: 'toast-class'
      }))),
      exhaustMap((toast) => from(toast.present())),
      );
    this.errorSubscription = this.errorStream.subscribe();
  }

  private initSuccessSubject() {
    this.successStream = this.successSubject.pipe(
      exhaustMap((toastContent) => {
        const translateService = this.injector.get(TranslateService);
        return translateService
          .get(toastContent.message, toastContent.parameters)
          .pipe(map((messageTranslated) => ({
            message: messageTranslated,
            iconName: toastContent.iconName,
            toastClass: toastContent.toastClass
          })));
      }
      ),
      exhaustMap((translatedTextWithIconAndClass) =>
        from(
          this.toastController.create({
            message: translatedTextWithIconAndClass.message,
            duration: TOASTER_DURATION,
            position: 'top',
            icon: translatedTextWithIconAndClass.iconName,
            buttons: [
              {
                icon: 'close-outline',
                side: 'end',
                role: 'cancel',
              },
            ],
            cssClass: translatedTextWithIconAndClass.toastClass,
          })
        )
      ),
      exhaustMap((toast) => from(toast.present()))
    );
    this.successSubscription = this.successStream.subscribe();
  }

  private initInfoSubject() {
    this.infoStream = this.infoSubject.pipe(
      exhaustMap((toastContent) => {
        const translateService = this.injector.get(TranslateService);
        return translateService
          .get(toastContent.message)
          .pipe(map((messageTranslated) => ({
            message: messageTranslated,
            iconName: toastContent.iconName,
            toastClass: toastContent.toastClass
          })));
      }
      ),
      exhaustMap((translatedTextWithIconAndClass) =>
        from(
          this.toastController.create({
            message: translatedTextWithIconAndClass.message,
            duration: TOASTER_DURATION,
            position: 'top',
            icon: translatedTextWithIconAndClass.iconName,
            buttons: [
              {
                icon: 'close-outline',
                side: 'end',
                role: 'cancel',
              },
            ],
            cssClass: translatedTextWithIconAndClass.toastClass,
          })
        )
      ),
      exhaustMap((toast) => from(toast.present()))
    );
    this.infoSubscription = this.infoStream.subscribe();
  }
}


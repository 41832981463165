import { Component, Input, OnInit } from '@angular/core';
import { selectComputedColorTheme } from '@app/store/selectors/app-config.selectors';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Component({
  selector: 'gs-generic-homepage-card',
  templateUrl: './generic-homepage-card.component.html',
  styleUrls: ['./generic-homepage-card.component.scss'],
})
export class GenericHomepageCardComponent implements OnInit {
  @Input() headline = '';
  @Input() subhead = '';
  @Input() imageUrl? = undefined;
  defaultCardImageUrl$ = this.store.select(selectComputedColorTheme).pipe(
    map(colorTheme => `assets/icon/${colorTheme}/default-card-image.png`)
  );

  constructor(private store: Store) { }

  ngOnInit() {
  }

}

/* eslint-disable @typescript-eslint/naming-convention */
export enum ConfigurationSetting {
  GladstonePay_MerchantId = 'GladstonePay_MerchantId',
  GladstonePay_AllowMemberWallet = 'GladstonePay_AllowMemberWallet',
  MobileApp_DefaultSite = 'MobileApp_DefaultSite',
  Allowed_Languages = 'allowed-languages',
}
export interface SiteOverrides {
  siteId: string;
  value: string;
}
export interface Config {
  value: string;
  overrides?: SiteOverrides[];
}

import { FormMessageType } from '@core/constants';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gs-form-field-message',
  templateUrl: './form-field-message.component.html',
  styleUrls: ['./form-field-message.component.scss'],
})
export class FormFieldMessageComponent implements OnInit {
  @Input() formMesaageType: FormMessageType;
  @Input() messageKey: string;
  textStyle: any;
  localFormMessageType = FormMessageType;

  constructor() { }

  ngOnInit() {
    this.textStyle = {
      'font-size': '12px',
      'font-weight': this.formMesaageType === FormMessageType.error ? '600' : '500',
      color: this.formMesaageType === FormMessageType.information ? 'var(--font-color-2)' : 'var(--error)'
    };

  }

}

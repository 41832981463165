import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';
import { FutureBooking } from '@app/core/models/future-booking.model';
import { GenericBooking } from '@app/core/models/generic-booking.model';
import { PreviousBooking } from '@core/models/previous-booking.model';
import { createSelector } from '@ngrx/store';
import { isToday } from 'date-fns';
import { IAppState, IBasketState } from '../state/app-config.state';
import { BookingHistoryState, FutureBookingsState } from './../state/app-config.state';

const _basketItemsState = (state: IAppState) => state.basket;
const _futureBookingsState = (state: IAppState) => state.futureBookings;
const _bookingHistoryState = (state: IAppState) => state.bookingHistory;

export const selectBasketItems = createSelector(_basketItemsState, (state: IBasketState) => state.basketItems);
export const selectCheckedOutBookings = createSelector(_basketItemsState, (state: IBasketState) => state.checkedOutBookings);

export const selectBasketItemCount = createSelector(_basketItemsState, (state: IBasketState) => state.basketItems.length);

export const selectBasketPricing
 = createSelector(
  _basketItemsState,
  (state: IBasketState)=>({
    totalPrice: state.totalPrice,
    VAT: state.VAT,
    subtotal: state.totalPrice - state.VAT
  })
);

export const selectCurrency = createSelector(_basketItemsState, (state: IBasketState) => state.currency);

export const selectIsBasketItemModified = createSelector(_basketItemsState, (state: IBasketState) => state.isBasketItemModified);

export const selectBasketItemsCount = createSelector(
  _basketItemsState,
  (state: IBasketState) => state.basketItems.reduce(
    (nrOfItems, currentItem) =>
    (nrOfItems += currentItem?.additionalBookees.reduce(
      (count, additionalBookee) =>
        count + additionalBookee.count, 0) + (currentItem.isMemberAttending ? 1 : 0)),
    0
  ),
);


export const selectFutureBookings = createSelector(_futureBookingsState, (state: FutureBookingsState) => state.futureBookings);
export const selectTodaysBookings = createSelector(
  _futureBookingsState,
  _bookingHistoryState,
  (futureBookingsState, bookingHistoryState) =>
    [
      ...bookingHistoryState.bookingHistory
        .filter(booking => isToday(new Date(booking.startTime)))
        .map(booking => booking as GenericBooking),
      ...futureBookingsState.futureBookings
        .filter(booking => isToday(new Date(booking.startTime)))
        .map(booking => booking as GenericBooking)
    ]
);
export const selectIsLoadingFutureBookings = createSelector(
  _futureBookingsState,
  (state: FutureBookingsState) => state.isLoadingFutureBookings
);
export const selectIsLoadingBookings = createSelector(
  _futureBookingsState,
  _bookingHistoryState,
  (futureBookingsState, bookingHistoryState) =>
    futureBookingsState.isLoadingFutureBookings || bookingHistoryState.isLoadingBookingHistory
);

export const selectBookingHistory = createSelector(_bookingHistoryState, (state: BookingHistoryState) => state.bookingHistory);
export const selectIsLoadingBookingHistory = createSelector(
  _bookingHistoryState,
  (state: BookingHistoryState) => state.isLoadingBookingHistory
);

export const selectLastFiveBookings = createSelector(_bookingHistoryState, (state: BookingHistoryState) =>
  state.bookingHistory.length >= 5 ? state.bookingHistory.slice(state.bookingHistory.length - 5).reverse() : state.bookingHistory
);

export const selectIsSlotBooked = (slotReference: string) =>
  createSelector(_futureBookingsState, (state: FutureBookingsState): boolean =>
    {
    const matchingBooking = state.futureBookings.find(
      (booking: FutureBooking) => booking.bookingId === JSON.parse(slotReference)?.BookingId
    );
    return matchingBooking ? matchingBooking.inCentre.bookingConfirmed : false;
  }
);

export const selectPreviousAndFutureBookingsByMonth = (monthReferece: string) =>
  createSelector(
    _futureBookingsState,
    _bookingHistoryState,
    (futureState: FutureBookingsState, historyState: BookingHistoryState): GenericBooking[] => [
      ...futureState.futureBookings
        .filter(
          (futureBooking) =>
            TimeUtilitiesHelper.getMothYearFormatFromISO(futureBooking.startTime) === monthReferece || monthReferece === ''
        ).map((futureBooking: FutureBooking) => ({...futureBooking} as GenericBooking))
        .reverse(),
      ...historyState.bookingHistory
        .filter(
          (previousBooking: PreviousBooking) =>
            TimeUtilitiesHelper.getMothYearFormatFromISO(previousBooking.startTime) === monthReferece || monthReferece === ''
        ).map((previousBooking: PreviousBooking) => ({...previousBooking} as GenericBooking))
        .reverse(),
    ]
  );

export const selectAlertList = createSelector(
  _futureBookingsState,
  (state: FutureBookingsState) => state.alertList
);

export const selectFutureBookingsAvailability = createSelector(
  _futureBookingsState,
  (state: FutureBookingsState) => state.futureBookingsAvailability
);

export const selectFutureBasketItem = createSelector(
  _basketItemsState,
  (state: IBasketState) => state.futureBasketItem
);

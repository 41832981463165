// This component was reused from signify repo (modal component)
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { focusOnFirstElementInContainer } from '@app/core/helpers/focus';
import { clearBasketItems } from '@app/store/actions/basket.actions';
import { Store } from '@ngrx/store';

type ModalSize = 'mini' | 'tiny' | 'small' | 'normal' | 'large';

@Component({
  selector: 'angular-generic-modal',
  templateUrl: './angular-generic-modal.component.html',
  styleUrls: ['./angular-generic-modal.component.scss'],
})
export class AngularGenericModal implements OnInit, AfterViewInit, OnDestroy {
  @Output()
  public dismiss = new EventEmitter();

  @Input()
  public size: ModalSize = 'small';

  @Input()
  public disabled: boolean;

  public modalCanBeDismissed: boolean;

  constructor(private readonly elementRef: ElementRef, private store: Store) {}

  public ngOnInit(): void {
    document.body.append(this.elementRef.nativeElement);

    this.modalCanBeDismissed = this.dismiss.observers.length !== 0;
  }

  public ngAfterViewInit(): void {
    focusOnFirstElementInContainer(this.elementRef.nativeElement);
  }

  public ngOnDestroy(): void {
    this.elementRef.nativeElement.remove();
    this.store.dispatch(clearBasketItems());
  }

  public onDismissClick() {
    if (!this.disabled) {
      this.dismiss.emit();
    }
  }
}

import { NavigationEffects } from './store/effects/navigation.effects';
import { NotificationHandlingEffects } from './store/effects/notification-handling.effects';
import { NetwokStatusInterceptor } from './api/interceptors/network-status-incerceptor';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { customNavigation } from './shared/animations/nav-animation';
import { ErrorInterceptor } from './api/interceptors/error-interceptors';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { metaReducers, reducers } from '@store/index';
import { EffectsModule } from '@ngrx/effects';
import { SitesEffects, ActivityEffects, SessionEffects, DateTimeEffects } from './store/effects/book.effects';
import { Store, StoreModule } from '@ngrx/store';
import { authenticationCheck, AuthService } from './core/authentication/authentication.service';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthenticationEffects } from './store/effects/authentication.effects';
import { AuthHelperService } from './core/authentication/authentication.helper';
import { AuthenticationErrorInterceptor } from './api/interceptors/authentication-error.interceptor';
import { ValidateTenantService } from './core/authentication/validate-tenant.service';
import { BasketItemsEffects, FutureBookingsEffects, BookingHistoryEffects } from './store/effects/basket.effects';
import { AccountsEffects } from './store/effects/account.effects';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HydrationEffects, OfflineSupportEffect, StorePersistenceEffects } from './store/effects/offline-support-effects';
import { EntryEffects } from './store/effects/entry.effects';
import { WalletEffects } from './store/effects/wallet.effects';
import { PaymentEffects } from './store/effects/payment.effects';
import { AuthenticationTokenInterceptor } from './api/interceptors/authentication-token.interceptor';
import { NetworkStatusService } from './core/network-status/network-status.service';
import { AppConfigEffects } from './store/effects/app-config.effects';
import { PushNotificationEffects } from './store/effects/push-notification.effects';
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    IonicModule.forRoot({ mode: 'ios', navAnimation: customNavigation }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    EffectsModule.forRoot([
      OfflineSupportEffect,
      SitesEffects,
      AuthenticationEffects,
      ActivityEffects,
      SessionEffects,
      DateTimeEffects,
      AccountsEffects,
      WalletEffects,
      PaymentEffects,
      BasketItemsEffects,
      EntryEffects,
      HydrationEffects,
      StorePersistenceEffects,
      FutureBookingsEffects,
      BookingHistoryEffects,
      NotificationHandlingEffects,
      NavigationEffects,
      AppConfigEffects,
      PushNotificationEffects
    ]),
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: environment.defaultLanguage,
    }),
    StoreDevtoolsModule.instrument({
      name: 'Gladstone Test',
      maxAge: 50, // Retains last 50 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    IonicStorageModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetwokStatusInterceptor,
      multi: true,
      deps:[NetworkStatusService, Store]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authenticationCheck,
      deps: [AuthService, HttpClient],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (validateTenantService: ValidateTenantService) => () =>
        validateTenantService.validateTenant(),
      multi: true,
      deps: [ValidateTenantService, HttpClient],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationTokenInterceptor,
      multi: true,
      deps: [AuthHelperService, Store],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationErrorInterceptor,
      multi: true,
      deps: [AuthService, Store],
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: OAuthStorage, useFactory: () => localStorage },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
      deps: [Store]
    },
    InAppBrowser
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

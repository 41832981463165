import { selectPreviousRouteUrl } from './../selectors/navigation.selectors';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { navigateToUrl, navigateBack, resetNavigationHistory, navigatedBackSuccesfully } from './../actions/navigation.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, first, map } from 'rxjs';
import { App } from '@capacitor/app';


@Injectable()
export class NavigationEffects{

  navigateToUrl$ = createEffect(()=>
    this.actions$.pipe(
      ofType(navigateToUrl),
      tap((action) => this.router.navigateByUrl(action.url)),
  ), {dispatch: false});

  navigateBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(navigateBack),
      tap(() => {
        this.store.select(selectPreviousRouteUrl).pipe(first()).subscribe(previousRoute => {
          if (previousRoute === undefined) {
            App.exitApp();
          }
          this.router.navigateByUrl(previousRoute);
        });
      }),
      map(() => navigatedBackSuccesfully())
    )
  );

  clearNavigationHistory$ = createEffect(() =>
  this.actions$.pipe(
    ofType(resetNavigationHistory),
    tap((action) => this.router.navigateByUrl(action.url))
  ), {dispatch: false});

  constructor(private actions$: Actions, private router: Router, private store: Store){}
}


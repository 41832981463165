import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntryIdentifierTypes, MEMBER_IDENTIFIER_API_ENDPOINT } from '@app/core/constants';
import { Identifier } from '@app/core/models/identifier.model';
import { environment } from '@environments/environment';
import { map, Observable, tap } from 'rxjs';
import { IdentifierDTO } from '../dtos/identifier.dto';
import { AccessControlMapper } from '../mappers/access-control.mappers';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService{
  constructor(private http: HttpClient){}

  public getAll(memberId: number): Observable<Identifier[]>{
    const endpoint = MEMBER_IDENTIFIER_API_ENDPOINT.replace('{memberId}', memberId?.toString());
    return this.http.get<IdentifierDTO[]>(`${environment.apiUrl}${endpoint}`).pipe(
      map(identifiers => identifiers.map(identifier => AccessControlMapper.mapToIdentifier(identifier)))
    );
  }

  public createIdentifier(memberId: number, identifierType: EntryIdentifierTypes): Observable<Identifier>{
    const endpoint = MEMBER_IDENTIFIER_API_ENDPOINT.replace('{memberId}', memberId?.toString());
    const id = this.generateUniqueIdentifier(memberId);
    const identifier: IdentifierDTO = {
      id,
      type: identifierType
    };
    return this.http.post<void>(`${environment.apiUrl}${endpoint}`, [identifier]).pipe(
      map(() => AccessControlMapper.mapToIdentifier(identifier))
    );
  }
  public deleteIdentifier(memberId: number, identifier: Identifier): Observable<void> {
    const endpoint = MEMBER_IDENTIFIER_API_ENDPOINT.replace('{memberId}', memberId?.toString());
    return this.http.delete<void>(`${environment.apiUrl}${endpoint}/${identifier.id}`);
  }

  private generateUniqueIdentifier(memberId): string{
    return memberId + (Math.random()+1).toString(36).substring(2,10) + (Math.random()+1).toString(36).substring(2,11);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingBodyArrayDTO } from '@api/dtos/booking-body.dto';
import { BookingLeaseDTO } from '@api/dtos/booking-lease.dto';
import { FutureBookingDTO } from '@api/dtos/future-booking.dto';
import { CreateBookingResponseDTO } from '@api/dtos/payment.dto';
import { PreviousBookingDTO } from '@api/dtos/previous-booking.dto';
import { BasketItem } from '@app/core/models/basket-item.model';
import { PreviousBooking } from '@app/core/models/previous-booking.model';
import {
  BOOKING_API_ENDPOINT,
  BOOKING_HISTORY_API_ENDPOINT,
  BOOKING_LEASE_API_ENDPOINT,
  DELETE_BOOKING_ENDPOINT,
  FUTURE_BOOKINGS_API_ENDPOINT
} from '@core/constants';
import { BookingBodyElement } from '@core/models/booking-body.model';
import { FutureBooking } from '@core/models/future-booking.model';
import { Member } from '@core/models/member.model';
import { environment } from '@environments/environment';
import { Observable, map } from 'rxjs';
import { BasketMapper } from '../mappers/basket.mapper';

@Injectable({
  providedIn: 'root'
})

export class BasketService{

  constructor(private httpClient: HttpClient){}

  public createBookingLease(basketItem: BasketItem, member: Member): Observable<string>{
    const lease: BookingLeaseDTO = BasketMapper.mapToBookingLeaseDTO(basketItem, member);
    return this.httpClient.put<string>(`${environment.apiUrl}${BOOKING_LEASE_API_ENDPOINT}/${lease.id}`, lease);
  }

  public deleteBooking(leaseId: string): Observable<string>{
    return this.httpClient.delete<string>(`${environment.apiUrl}${BOOKING_LEASE_API_ENDPOINT}/${leaseId}`);
  }

  public makeBooking(bookings: BookingBodyElement[]): Observable<CreateBookingResponseDTO> {
    const bookingBodyArray: BookingBodyArrayDTO = {bookings};
    return this.httpClient.post<CreateBookingResponseDTO>(`${environment.apiUrl}${BOOKING_API_ENDPOINT}`, bookingBodyArray);
  }

  public deleteZeroPriceBooking(memberId: number, bookingId: string): Observable<string>{
    const endpoint = DELETE_BOOKING_ENDPOINT.replace('{memberId}', memberId.toString())
      .replace('{bookingId}', bookingId.toString());
    return this.httpClient.delete<string>(`${environment.apiUrl}${endpoint}`);
  }

  public getFutureBookings(memberId: number): Observable<FutureBooking[]> {
    const endpoint = `${environment.apiUrl}${FUTURE_BOOKINGS_API_ENDPOINT}`.replace('{memberId}', `${memberId}`);
    return this.httpClient.get<FutureBookingDTO[]>(endpoint).pipe(map(BasketMapper.mapToFutureBooking));
  }

  public getBookingHistory(memberId: number, fromDate: string = undefined): Observable<PreviousBooking[]> {
    const endpoint = `${environment.apiUrl}${BOOKING_HISTORY_API_ENDPOINT}`.replace('{memberId}', `${memberId}`);
    if (fromDate !== undefined) {
      return this.httpClient.get<PreviousBookingDTO[]>(endpoint, {params: {dateTimeFrom: fromDate}}).pipe(
        map(BasketMapper.mapToPreviousBooking)
      );
    }
    return this.httpClient.get<PreviousBookingDTO[]>(endpoint).pipe(map(BasketMapper.mapToPreviousBooking));
  }
}

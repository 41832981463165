import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DATE_HEADER_FORMAT } from '@app/core/constants';
import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';
import { GenericBooking } from '@app/core/models/generic-booking.model';
import { Availability, Slot } from '@app/core/models/session.model';
import { getAccountMemberId } from '@app/store/selectors/authentication.selectors';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';

@Component({
  selector: 'gs-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss'],
})
export class ActivityCardComponent implements OnInit {
  @Input() booking: GenericBooking;
  @Input() showCancelButton: boolean;
  @Input() availability: Availability;
  @Output() cancelBookingEventEmitter = new EventEmitter<GenericBooking>();
  @Input() slot: Slot;
  memberId$ = this.store.select(getAccountMemberId);
  constructor(private store: Store) {}

  onCancelBooking(event, booking: GenericBooking) {
    this.cancelBookingEventEmitter.emit(booking);
  }

  isBookingAvailable(): boolean {
    if (this.slot?.bookableFrom === undefined) {
      return true;
    }
    return TimeUtilitiesHelper.isDateInPast(this.slot.bookableFrom);
  }

  replaceMessagePlaceholders(message: string): string {
    const date = new Date(this.slot.bookableFrom);
    const formattedDate = format(date, DATE_HEADER_FORMAT);
    const formattedTime = format(date, 'HH:mm');
    return message.replace('{{date}}', formattedDate)
      .replace('{{time}}', formattedTime);
  }

  ngOnInit() {}
}

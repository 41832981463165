/* eslint-disable object-shorthand */
import { Injectable } from '@angular/core';
import { ActivityService } from '@api/services/activity.service';
import { SessionService } from '@app/api/services/session.service';
import { SiteService } from '@app/api/services/site.service';
import {
  ADDED_BOOKEES_SUCCESS_MESSAGE,
  DATE_REMOVE_ALERT_LIST_TOAST_FORMAT,
  JOINED_ALERT_LIST_SUCCESS_MESSAGE,
  RoutingAbsolutePaths,
  SUCCESS_TOAST_CLASS,
  TOAST_ONLINE_ICON_NAME,
  UNSUBSCRIBE_FROM_ALERT_LIST_SUCCSES_MESSAGE,
} from '@app/core/constants';
import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';
import {
  LOAD_ACTIVITIES_BY_TAG_GROUP,
  SEARCH_ACTIVITIES,
  LOADED_SITE_DETAILS_FAIL,
  LOADED_SITE_DETAILS_SUCCESS,
  LOAD_SITE_DETAILS,
  addBookees,
  joinAlertList,
  joinedAlertListFail,
  joinedAlertListSuccess,
  loadActivitiesByTagGroupFail,
  loadActivitiesByTagGroupSuccess,
  loadActivitiesSuccess,
  loadActivityDetails,
  loadActivityDetailsFail,
  loadActivityDetailsSuccess,
  loadDefaultSite,
  loadRecommendedActivities,
  loadSessionPrice,
  loadSessionPriceFail,
  loadSessionPriceSuccess,
  loadSessionsFail,
  loadedDefaultSiteFail,
  loadedDefaultSiteSuccess,
  loadedRecommendedActivitiesFail,
  loadedRecommendedActivitiesSuccess,
  searchActivitiesByTag,
  searchActivitiesByTagFail,
  searchActivitiesByTagSuccess,
  searchActivitiesFail,
  searchActivitiesSuccess,
  setPromotedTagId,
  setSearchParametersBySelectedBooking,
  setSessionSearchFilters,
  unsubscribeFromAlertList,
  unsubscribedFromAlertListFail,
  unsubscribedFromAlertListSuccess,
} from '@app/store/actions/book.actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  CHANGE_STATE_IS_SEARCHING_SITES,
  LOADED_SITES_SUCCESS,
  LOAD_SITES,
  setCentreSearchFilter,
  timeWasSetByTheUser,
} from '@store/actions/book.actions';
import { addDays, addHours, compareAsc, format, isSameDay, isToday, subHours } from 'date-fns';
import { EMPTY, exhaustMap, of, withLatestFrom } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { setFutureBookingsAvailability } from '../actions/basket.actions';
import { navigateToUrl } from '../actions/navigation.actions';
import {
  BookActivitiesActions,
  BookSessionActions,
  BookSitesActions,
  LOADED_SITES_FAIL,
  LOAD_ACTIVITIES,
  LOAD_SESSIONS,
  LOAD_SLOT_PRICE,
  loadActivitiesFail,
  loadSessionsSuccess,
  loadSlotPriceFail,
  loadSlotPriceSuccess,
  setDateBoundaries,
  setTimeBoundaries,
} from './../actions/book.actions';
import { getAccountMemberId } from '../selectors/authentication.selectors';
import { selectFutureBookings } from '../selectors/basket.selectors';
import { displaySuccessToast } from '../actions/notification-handling.actions';
import { loadFutureBookings } from '../actions/basket.actions';
import { ConfigurationService } from '@app/api/services/configuration.service';
import { selectSiteById, selectSites } from '../selectors/book.selectors';
import { TagsWithActivitiesDTO } from '@app/api/dtos/activity.dto';
import { Activity } from '@app/core/models/activity.model';
import { group, sort } from 'radash';
@Injectable()
export class SitesEffects {
  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_SITES),
      mergeMap(() =>
        this.siteService.getAll().pipe(
          map((sites) => ({ type: LOADED_SITES_SUCCESS, sites: sites })),
          catchError(() => of({ type: LOADED_SITES_FAIL }))
        )
      )
    )
  );

  loadSiteDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_SITE_DETAILS),
      mergeMap((action) =>
        this.siteService.getSiteById(action.siteId).pipe(
          map((site) => ({ type: LOADED_SITE_DETAILS_SUCCESS, site: site })),
          catchError(() => of({ type: LOADED_SITE_DETAILS_FAIL }))
        )
      )
    )
  );

  userIsSerachingSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCentreSearchFilter),
      mergeMap((action) => {
        if (action.searchFilter.length < 3 && action.searchFilter.length !== 0) {
          return of({
            type: CHANGE_STATE_IS_SEARCHING_SITES,
            isSearching: true,
          });
        }
        return of({
          type: CHANGE_STATE_IS_SEARCHING_SITES,
          isSearching: false,
        });
      })
    )
  );

  getDefaultSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDefaultSite),
      mergeMap(() =>
        this.siteService.getDefaultSiteId().pipe(
          withLatestFrom(this.store.select(selectSites)),
          map(([defaultSiteId, sites]) =>
            loadedDefaultSiteSuccess({
              site: sites.find((site) => site.id === defaultSiteId),
            })
          ),
          catchError(() => of(loadedDefaultSiteFail()))
        )
      )
    )
  );

  constructor(private actions$: Actions<BookSitesActions>, private siteService: SiteService, private store: Store) {}
}

@Injectable()
export class ActivityEffects {
  loadActivities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOAD_ACTIVITIES),
        mergeMap(() => {
          return this.activityService.getAll([]).pipe(
            tap((activities) => this.store.dispatch(loadActivitiesSuccess({ activities }))),
            catchError(() => {
              this.store.dispatch(loadActivitiesFail());
              return EMPTY;
            })
          );
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  searchActivities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SEARCH_ACTIVITIES),
        mergeMap((action) => {
          if (action.searchText.length >= 3) {
            return this.activityService.searchActivities(action.searchText, []).pipe(
              tap((activities) => this.store.dispatch(searchActivitiesSuccess({ activities }))),
              catchError(() => {
                this.store.dispatch(searchActivitiesFail());
                return EMPTY;
              })
            );
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  loadActivitiesByTagGroup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOAD_ACTIVITIES_BY_TAG_GROUP),
        mergeMap(() => {
          return this.configurationService.getActivityTagGroup().pipe(
            tap((activityTags) => {
              activityTags[0].tags.sort((a, b) => a.name.localeCompare(b.name));
              this.store.dispatch(loadActivitiesByTagGroupSuccess({ activityTagGroup: activityTags }));
            }),
            catchError(() => {
              this.store.dispatch(loadActivitiesByTagGroupFail());
              return EMPTY;
            })
          );
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  searchActivitiesByTag$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(searchActivitiesByTag),
        mergeMap((action) => {
          if (action.tagId) {
            return this.activityService.getActivitiesByTags([action.tagId]).pipe(
              tap((tagActivities: TagsWithActivitiesDTO[]) => {
                const tagActivitiesArray = Object.values(tagActivities);

                const derivedActivities = tagActivitiesArray[0].activities.map((item) => {
                  const activityObject = {
                    name: item.name,
                    activityIdsBySiteId: { [item.siteId]: [item.id] },
                  };
                  return activityObject;
                });

                const activitiesFromTags = derivedActivities.reduce(
                  (acc, cur) => ({
                    ...acc,
                    [cur.name]: {
                      name: cur.name,
                      activityIdsBySiteId: cur.activityIdsBySiteId,
                    },
                  }),
                  {}
                );

                const sortedActivities = Object.keys(activitiesFromTags)
                  .sort()
                  .reduce((objEntries, key) => {
                    objEntries[key] = activitiesFromTags[key];

                    return objEntries;
                  }, {});

                this.store.dispatch(searchActivitiesByTagSuccess({ activities: sortedActivities }));
              }),
              catchError(() => {
                this.store.dispatch(searchActivitiesByTagFail());
                return EMPTY;
              })
            );
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<BookActivitiesActions>,
    private activityService: ActivityService,
    private configurationService: ConfigurationService,
    private store: Store
  ) {}
}

@Injectable()
export class SessionEffects {
  loadSessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_SESSIONS),
      mergeMap((action) =>
        this.sessionService.getAll(action.params).pipe(
          map((result) => loadSessionsSuccess({ ...result })),
          catchError(() => of(loadSessionsFail()))
        )
      )
    )
  );

  setFutureBookingsAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSessionsSuccess),
      map((action) => setFutureBookingsAvailability({ slotsByActivityId: action.slotsByActivityId }))
    )
  );

  loadSessionsByBooking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSearchParametersBySelectedBooking),
        exhaustMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(selectSiteById(action.booking.siteId)),
              of({
                activityId: [action.booking.activityId],
                activityName: action.booking.activityName,
                siteId: action.booking.siteId,
              })
            )
          )
        ),
        tap(([action, site, activity]) => {
          const lastBookedDate = new Date(action.booking.startTime);
          const endTime = addDays(new Date(), 6);
          const startTime = new Date();
          startTime.setHours(subHours(lastBookedDate, 2).getHours(), lastBookedDate.getMinutes());
          if (lastBookedDate.getHours() >= 22) {
            endTime.setHours(23, 59);
          } else {
            endTime.setHours(addHours(lastBookedDate, 2).getHours(), lastBookedDate.getMinutes());
          }
          this.store.dispatch(
            setDateBoundaries({
              startDate: startTime.toISOString(),
              currentStartTime: startTime.toISOString(),
              endDate: endTime.toISOString(),
              isTimeSet: true,
            })
          );
          this.store.dispatch(
            setSessionSearchFilters({
              parameters: {
                ...activity,
                ...site,
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
              },
            })
          );
          this.store.dispatch(navigateToUrl({ url: RoutingAbsolutePaths.bookSessionsPage }));
        })
      ),
    { dispatch: false }
  );

  loadSlotPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_SLOT_PRICE),
      concatLatestFrom(() => this.store.select(getAccountMemberId)),
      mergeMap(([action, memberId]) =>
        this.sessionService
          .getPrice(action.slot.slotReferences.inCentre, action.additionalBookees, memberId, action.isMemberAttending)
          .pipe(
            map((price) => loadSlotPriceSuccess({ pricing: price, slot: action.slot })),
            catchError(() => {
              this.store.dispatch(loadSlotPriceFail()); // TODO Handle this case
              return EMPTY;
            })
          )
      )
    )
  );

  loadSessionPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSessionPrice),
      concatLatestFrom(() => this.store.select(getAccountMemberId)),
      mergeMap(([action, memberId]) =>
        this.sessionService.getPriceSession(action.slot, memberId).pipe(
          map((priceInformation) => loadSessionPriceSuccess({ priceInformation, slot: action.slot })),
          catchError(() => of(loadSessionPriceFail()))
        )
      )
    )
  );

  loadRecommendedActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecommendedActivities),
      switchMap(() =>
        this.configService.getPromotedTagId().pipe(
          tap((promotedTagId) => this.store.dispatch(setPromotedTagId({ promotedTagId }))),
          switchMap((promotedTagId) =>
            this.activityService.getActivityIdsByTags([promotedTagId]).pipe(
              concatLatestFrom(() => [this.store.select(selectSites), this.store.select(selectFutureBookings)]),
              switchMap(([activities, sites, futureBookings]) =>
                this.sessionService.getRecommendedSessions(activities, sites, futureBookings).pipe(
                  map((recommendedActivities) => loadedRecommendedActivitiesSuccess({ recommendedActivities })),
                  catchError(() => of(loadedRecommendedActivitiesFail()))
                )
              ),
              catchError(() => of(loadedRecommendedActivitiesFail()))
            )
          ),
          catchError(() => of(loadedRecommendedActivitiesFail()))
        )
      )
    )
  );

  loadActivityDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadActivityDetails),
      switchMap((action) =>
        this.sessionService.getActivityDetails(action.activityId).pipe(
          map((activityDetails) => loadActivityDetailsSuccess({ activityDetails })),
          catchError((_) => of(loadActivityDetailsFail()))
        )
      )
    )
  );

  joinAlertList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joinAlertList),
      exhaustMap(({ memberId, slotReference }) =>
        this.sessionService.joinAlertList(memberId, slotReference).pipe(
          map((alertListPosition) =>
            joinedAlertListSuccess({
              alertListItem: { alertListPosition: alertListPosition.alertListPosition, slotReference: slotReference },
            })
          ),
          catchError(() => of(joinedAlertListFail()))
        )
      )
    )
  );

  unsubscribeFromAlertList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unsubscribeFromAlertList),
      exhaustMap(({ memberId, bookingId, activityDate, activityName }) =>
        this.sessionService.deleteAlertListBooking(memberId, bookingId).pipe(
          map(() => unsubscribedFromAlertListSuccess({ bookingId, activityName, activityDate })),
          catchError(() => of(unsubscribedFromAlertListFail()))
        )
      )
    )
  );

  loadFutureBookings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joinedAlertListSuccess),
      withLatestFrom(this.store.select(getAccountMemberId)),
      map(([_, memberId]) => loadFutureBookings({ memberId }))
    )
  );

  addBookees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addBookees),
      map((action) =>
        displaySuccessToast({
          message: ADDED_BOOKEES_SUCCESS_MESSAGE,
          iconName: TOAST_ONLINE_ICON_NAME,
          toastClass: SUCCESS_TOAST_CLASS,
          parameters: { count: action.bookees },
        })
      )
    )
  );

  joinedAlertListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joinedAlertListSuccess),
      concatLatestFrom(() => this.store.select(getAccountMemberId)),
      tap(() =>
        this.store.dispatch(
          displaySuccessToast({
            message: JOINED_ALERT_LIST_SUCCESS_MESSAGE,
            iconName: TOAST_ONLINE_ICON_NAME,
            toastClass: SUCCESS_TOAST_CLASS,
          })
        )
      ),
      map(([_action, memberId]) => loadFutureBookings({ memberId }))
    )
  );

  displayUnsubscribeSuccessToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unsubscribedFromAlertListSuccess),
      map(({ activityDate, activityName }) =>
        displaySuccessToast({
          message: UNSUBSCRIBE_FROM_ALERT_LIST_SUCCSES_MESSAGE,
          iconName: TOAST_ONLINE_ICON_NAME,
          toastClass: SUCCESS_TOAST_CLASS,
          parameters: {
            activity: activityName,
            date: format(new Date(activityDate), DATE_REMOVE_ALERT_LIST_TOAST_FORMAT),
          },
        })
      )
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions<BookSessionActions>,
    private sessionService: SessionService,
    private activityService: ActivityService,
    private configService: ConfigurationService
  ) {}
}

@Injectable()
export class DateTimeEffects {
  updateTimeBasedOnDate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setDateBoundaries),
        mergeMap((action) => {
          if (isSameDay(new Date(action.startDate), new Date(action.endDate)) && isToday(new Date(action.startDate))) {
            if (
              !action.isTimeSet &&
              compareAsc(new Date(action.currentStartTime), new Date(TimeUtilitiesHelper.getCurrentTimeRounded())) <= 0
            ) {
              this.store.dispatch(
                setTimeBoundaries({
                  startTime: TimeUtilitiesHelper.getCurrentTimeRounded(),
                  endTime: TimeUtilitiesHelper.newTime('23:59').toISOString(),
                })
              );
              this.store.dispatch(timeWasSetByTheUser({ shouldModify: false }));
            }
          } else {
            if (!action.isTimeSet) {
              this.store.dispatch(
                setTimeBoundaries({
                  startTime: TimeUtilitiesHelper.newTime('06:00').toISOString(),
                  endTime: TimeUtilitiesHelper.newTime('21:00').toISOString(),
                })
              );
              this.store.dispatch(timeWasSetByTheUser({ shouldModify: false }));
            }
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store) {}
}

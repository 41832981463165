import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TagGroupDTO } from '../dtos/tags.dto';
import { Observable, map } from 'rxjs';
import { environment } from '@environments/environment';
import {
  PROMOTION_TAG_GROUP_KEY,
  TAG_GROUPS_API_ENDPOINT,
  SYSTEM_CONFIG_API_ENDPOINT,
  ACTIVITIES_TAG_GROUP_KEY,
} from '@app/core/constants';
import { first } from 'radash';
import { Config } from '@ionic/angular';
import { ConfigurationSetting } from '@app/core/models/configuration.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private httpClient: HttpClient) {}

  public getPromotedTagId(): Observable<number> {
    return this.getTagGroups().pipe(
      map(
        (tagGroups) => first(first(tagGroups.filter((tagGroup) => tagGroup.key === PROMOTION_TAG_GROUP_KEY))?.tags)?.id
      )
    );
  }

  public getActivityTagGroup(): Observable<TagGroupDTO[]> {
    return this.getTagGroups().pipe(
      map((tagGroups) => tagGroups.filter((tagGroup) => tagGroup.key === ACTIVITIES_TAG_GROUP_KEY))
    );
  }

  private getTagGroups(): Observable<TagGroupDTO[]> {
    const endpoint = `${environment.apiUrl}${TAG_GROUPS_API_ENDPOINT}`;
    return this.httpClient.get<TagGroupDTO[]>(endpoint);
  }

  public getIsMemberWalletEnabled(): Observable<boolean> {
    const isWalletEnabledKey = ConfigurationSetting.GladstonePay_AllowMemberWallet;
    const endpoint = `${environment.apiUrl}${SYSTEM_CONFIG_API_ENDPOINT}`.replace(
      '{configurationKey}',
      isWalletEnabledKey
    );
    return this.httpClient.get<{ value: string }>(endpoint).pipe(map((response) => response.value === 'true'));
  }

  public getMerchantId(siteId?: string): Observable<Partial<Config>> {
    const merchantIdKey = ConfigurationSetting.GladstonePay_MerchantId;
    const endpoint = `${environment.apiUrl}${SYSTEM_CONFIG_API_ENDPOINT}?siteId=${siteId}`.replace(
      '{configurationKey}',
      merchantIdKey
    );
    return this.httpClient.get(endpoint);
  }
}

import { PersonalDetails, PersonalDetailsFormState } from '@app/core/models/member.model';
import { PhoneNumber, Email, CountryCode } from '@app/core/models/contact-details.model';
import { Member } from '@app/core/models/member.model';
import { createSelector } from '@ngrx/store';
import { AuthenticationState, IAppState } from '../state/app-config.state';
import { AccountState } from '../state/account.state';
import { listify, omit } from 'radash';
import { format } from 'date-fns';

export const selectAccountState = (state: IAppState) => state.account;
export const selectAuthenticationState = (state: IAppState) => state.authentication;

export const isAuthenticating = createSelector(selectAuthenticationState, (state: AuthenticationState): boolean => state.authenticating);

export const userProfileDetails = createSelector(selectAccountState, (state: AccountState): Member => state.personalDetails);

export const selectPrimaryPhoneNumber = createSelector(selectAccountState, (state: AccountState): PhoneNumber =>
  state.contactDetails.telephone.find(phoneNumber => phoneNumber.isPrimary));

export const selectPrimaryEmail = createSelector(selectAccountState, (state: AccountState): Email =>
  state.contactDetails.email.find(email => email.isPrimary));

export const selectAccountDetailsFormState = createSelector(selectAccountState, (state: AccountState): PersonalDetailsFormState =>
  state.personalDetailsFormState);

export const selectIsAccountDetailsFormValid = createSelector(
  selectAccountState,
  (state: AccountState): boolean =>
    listify(omit(state.personalDetailsFormState, ['formWasTouched']), (_, value) => ({
      isValid: value.isValid,
    })).every((field) => field.isValid)
);

export const selectPersonalDetails = createSelector(
  selectAccountState,
  (state: AccountState): PersonalDetails => ({
    firstName: state.personalDetails.firstName || '',
    lastName: state.personalDetails.lastName || '',
    email: state.personalDetails.email || '',
    phoneNumber: state.contactDetails
      ? Object.values(state.contactDetails.telephone.find(
          (phoneNumber) => phoneNumber.isPrimary) || {})?.find(field => field !== true && field !== undefined) || ''
      : '',
    dateOfBirth: state.personalDetails.dateOfBirth !== '' ? format(new Date(state.personalDetails.dateOfBirth), 'dd/MM/yyyy') : '',
    title: state.personalDetails.title,
    titleId: state.personalDetails.titleId,
    gender: state.personalDetails.gender
    })
);

export const selectCountryCode = createSelector(
  selectAccountState,
  (state: AccountState): CountryCode =>
    state?.contactDetails.countryDialCode
);

export const selectFormWasTouched = createSelector(
  selectAccountState,
  (state: AccountState): boolean =>
    state.personalDetailsFormState.formWasTouched
);

export const selectSelectedDateForBookings = createSelector(
  selectAccountState,
  (state: AccountState): string =>
  state.selectedDateForBookings
);

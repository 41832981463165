import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SiteDTO } from '@app/api/dtos/site.dto';
import { selectSiteById } from '@app/store/selectors/book.selectors';
import { Capacitor } from '@capacitor/core';
import { Store } from '@ngrx/store';
import { Subscription, first } from 'rxjs';
import SwiperCore, { Pagination, Swiper, Autoplay } from 'swiper';

SwiperCore.use([Pagination, Autoplay]);

@Component({
  selector: 'gs-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss']
})
export class SiteDetailsComponent implements OnInit, OnDestroy {
  @Input() selectedSiteId: string;
  selectedSite: SiteDTO;
  imageArray: [];
  areAllFacilitiesShown = false;
  areAllAmenitiesShown = false;

  platform = Capacitor.getPlatform();
  headerStyling = {
    fontSize: '20px',
    iconClass: '',
    iconFontSize: '20px'
  };

  private subscriptions = new Subscription();

  constructor(private store: Store) {}

  ngOnInit() {
    this.getSiteDetails();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getSiteDetails() {
    if (this.selectedSiteId) {
      const subscribedSite = this.store
        .select(selectSiteById(this.selectedSiteId))
        .pipe()
        .subscribe((res) => {
          this.selectedSite = res;
          this.checkForImages();
        });

      this.subscriptions.add(subscribedSite);
    }
  }

  checkForImages() {
    return this.selectedSite && this.selectedSite?.images?.length;
  }

  toggleFacilitiesDisplay(): void {
    this.areAllFacilitiesShown = !this.areAllFacilitiesShown;
  }

  checkFacilitiesOverflow(element: HTMLElement): boolean {
    if (!this.areAllFacilitiesShown) {
      return element.offsetHeight < element.scrollHeight;
    }
    return true;
  }

  toggleAmenitiesDisplay(): void {
    this.areAllAmenitiesShown = !this.areAllAmenitiesShown;
  }

  checkAmenitiesOverflow(element: HTMLElement): boolean {
    if (!this.areAllAmenitiesShown) {
      return element.offsetHeight < element.scrollHeight;
    }
    return true;
  }
}

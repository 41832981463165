import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'gs-slot-card',
  templateUrl: './slot-card.component.html',
  styleUrls: ['./slot-card.component.scss'],
})
export class SlotCardComponent implements OnInit, OnDestroy {
  @Input() title: string = null;
  @Input() subtitle: string = null;

  @Input() hasBorder = true;
  @Input() hasBorderRadius = true;
  @Input() hasBoxShadow = true;
  @Input() backgroundColorClass = 'default';
  constructor() { }

  ngOnInit() {}

  ngOnDestroy(): void {}
}

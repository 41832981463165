import { Component, OnInit, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { setDateBoundaries } from '@app/store/actions/book.actions';
import { selectBookDateBoundaries, selectBookTimeBoundaries, selectIsTimeSet } from '@app/store/selectors/book.selectors';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { compareAsc } from 'date-fns';
import { map, Observable } from 'rxjs';
import { DatePickerComponent } from '../../date-picker/date-picker.component';

@Component({
  selector: 'gs-date-picker-modal',
  templateUrl: './date-picker-modal.component.html',
  styleUrls: ['./date-picker-modal.component.scss'],
})
export class DatePickerModalComponent implements OnInit {
  @ViewChild(DatePickerComponent) datePickerComponent: DatePickerComponent;
  dateBoundaries$ = this.store.select(selectBookDateBoundaries);
  dateBoundariesDateRange$: Observable<DateRange<Date>> = this.store.select(selectBookDateBoundaries).pipe(map(({startDate, endDate}) => {
    if (compareAsc(new Date(startDate), new Date(endDate)) === 0) {
      return new DateRange(new Date(startDate), null);
    }
    return new DateRange(new Date(startDate), new Date(endDate));
  }));
  timeBoundaries$: Observable<{startTime: string; endTime: string}> = this.store.select(selectBookTimeBoundaries);
  isTimeSet$: Observable<boolean> = this.store.select(selectIsTimeSet);

  constructor(private store: Store, private modalController: ModalController) { }

  ngOnInit() {
  }

  ionViewWillEnter(): void {
    this.datePickerComponent.onDateButtonClick();
    this.datePickerComponent.onSaveDate = () => {
      this.modalController.dismiss(null, 'confirm');
      this.updateBookDateBoundaries({
        startDate: this.datePickerComponent.startDate,
        endDate: this.datePickerComponent.endDate,
      });
    };
  }

  getMinDate() {
    return new Date();
  }

  getMaxDate() {
    //TODO: this will come from store after login depending on the type of membership.
    const date = new Date();
    date.setDate(date.getDate() + 20);
    return date;
  }

  updateBookDateBoundaries(boundaries: {startDate: string; endDate: string}) {
    if (boundaries.endDate === null) {
      boundaries.endDate = boundaries.startDate;
    }
    this.store.dispatch(setDateBoundaries({
        startDate: boundaries.startDate,
        endDate: boundaries.endDate,
        currentStartTime: undefined, isTimeSet: true
      })
    );
  }
}

import { nrOfElementsThatFitHorizontally } from '@core/helpers/number-of-fitting-objects-helper';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gs-skeleton-placeholder-horizontally',
  templateUrl: './skeleton-placeholder-horizontally.component.html',
  styleUrls: ['./skeleton-placeholder-horizontally.component.scss'],
})
export class SkeletonPlaceholderHorizontallyComponent implements OnInit {
  @Input() widthtOfAnElement: number;
  @Input() heightOfAnElement: number;
  @Input() containerReference: HTMLElement;
  constructor() { }

  ngOnInit() {}


  counter(boundary: number) {
    return Array(boundary);
  }

  nrOfElementsThatFitHorizontally() {
    return nrOfElementsThatFitHorizontally(this.widthtOfAnElement, this.containerReference);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityDetails } from '@app/core/models/activity.model';
import { Site } from '@app/core/models/site.model';
import { SessionAdapterService } from '@app/core/services/session-adapter.service';
import {
  ACTIVITY_DETAILS_ENDPOINT,
  JOIN_ALERT_LIST_ENDPOINT,
  NUMBER_OF_RECOMMENDED_ACTIVITIES,
  REMOVE_FROM_ALERT_LIST_ENDPOINT,
  SESSIONS_API_ENDPOINT,
  SESSION_PRICE_API_ENDPOINT,
  SLOT_PRICE_API_ENDPOINT,
} from '@core/constants';
import { environment } from '@environments/environment';
import { addDays, addHours, isYesterday, max } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PriceInformation, PricingQuote } from '../../core/models/pricing.model';
import { AdditionalBooking, MemberPriceSearch, PriceSearchProps, SessionSearchParams } from '../../core/models/session-search-params.model';
import { Slot, SlotsByActivityIdMap, SlotsByResourceIdMap } from '../../core/models/session.model';
import { ActivityDetailsDTO } from '../dtos/activity.dto';
import { AlertListDTO, AlertListRequestDTO } from '../dtos/alert-list.dto';
import { PriceInformationDTO, PricingQuoteDTO } from '../dtos/pricing.dto';
import { SessionDTO } from '../dtos/session.dto';
import { SessionMapper } from '../mappers/session.mapper';
import { FutureBooking } from '@app/core/models/future-booking.model';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private httpClient: HttpClient, private sessionAdapter: SessionAdapterService) {}

  public getAll(params: SessionSearchParams): Observable<{
    slotsByActivityId: SlotsByActivityIdMap;
    allSlotsGroupedInOneSession: SlotsByResourceIdMap;
    additionalSlotsGroupedInOneSession: SlotsByResourceIdMap;
  }> {
    const sessionSearchParamsDTO = SessionMapper.mapSessionSearchParamsToDTO(params);

    const startDate = new Date(params.startDate);
    const startTime = new Date(params.startTime);
    const endDate = new Date(params.endDate);
    const endTime = new Date(params.endTime);

    if (endTime.getHours() === 23 && endTime.getMinutes() === 59) {
      endTime.setSeconds(59);
      endDate.setSeconds(59);
    }
    if(params.minimumNumberOfDays) {
      const minimumEndDate = addDays(startDate, params.minimumNumberOfDays);
      minimumEndDate.setHours(endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());
      params = {...params, endDate: max([endDate, minimumEndDate]).toISOString()};
    }

    const midnightUTC = new Date(sessionSearchParamsDTO.dateFrom).setUTCHours(0,0,0,0);
    if(isYesterday(midnightUTC)){
      sessionSearchParamsDTO.dateFrom = new Date().toISOString();
    }

    return this.httpClient
    .get<SessionDTO[]>(
      `${environment.apiUrl}${SESSIONS_API_ENDPOINT}`,
      {params: {...sessionSearchParamsDTO}})
    .pipe(
      map(sessions => this.sessionAdapter.mapSessions(sessions, params)),
    );
  }

  public getPrice(slotReference: string, additionalBookees: AdditionalBooking[], memberId: number,
    isMemberAttending: boolean): Observable<PricingQuote>{
    const priceProps: PriceSearchProps = {
      slotReference,
      additionalBookees,
      member: {
        id: memberId === null ? 0 : memberId,
        attending: isMemberAttending,
        priceLevelId: ''
      } as MemberPriceSearch
    };
    return this.httpClient.post<PricingQuoteDTO>(`${environment.apiUrl}${SLOT_PRICE_API_ENDPOINT}`, priceProps).pipe(
      map((pricingQuote: PricingQuoteDTO) => pricingQuote as PricingQuote)
    );
  }

  public getPriceSession(slot: Slot, memberId: number): Observable<PriceInformation>{
    const priceProps = {
      memberId: memberId === null ? 0 : memberId,
      slotReference: slot.slotReferences.inCentre,
    };
    return this.httpClient.post<PriceInformationDTO>(`${environment.apiUrl}${SESSION_PRICE_API_ENDPOINT}`, priceProps).pipe(
      map((priceInfo) => priceInfo as PriceInformation)
    );
  }

  public getRecommendedSessions(activityIds: string[], sites: Site[], futureBookings: FutureBooking[]): Observable<Slot[]> {
    const endpoint = `${environment.apiUrl}${SESSIONS_API_ENDPOINT}`;
    const fiveIds = this.pickRandomFive(activityIds).join(',');
    const params = {
      siteIds: [],
      activityIds: fiveIds,
      dateFrom: addHours(new Date(), 1).toISOString()
    };

    return this.httpClient.get<SessionDTO[]>(
      endpoint, {params: {...params}}
    ).pipe(map(sessions => SessionMapper.mapToRecommendedActivities(sessions, sites, futureBookings )));
  }

  public getActivityDetails(activityId: string): Observable<ActivityDetails>{
    const endpoint = `${environment.apiUrl}${ACTIVITY_DETAILS_ENDPOINT}`.replace('{id}', `${activityId}`);
    return this.httpClient.get<ActivityDetailsDTO>(endpoint)
      .pipe(map(activityDetails => SessionMapper.mapToActivityDetails(activityDetails)));
  }

  public joinAlertList(memberId: number, slotRefrence: string) {
    const endpoint = `${environment.apiUrl}${JOIN_ALERT_LIST_ENDPOINT}`.replace('{memberId}', `${memberId}`);
    const alertListRequestBody: AlertListRequestDTO = {slotReference: slotRefrence};
    return this.httpClient.post<AlertListDTO>(endpoint, alertListRequestBody);
  }

  public deleteAlertListBooking(memberId: number, bookingId: string): Observable<string> {
    const endpoint = REMOVE_FROM_ALERT_LIST_ENDPOINT.replace('{memberId}', memberId.toString());
    return this.httpClient.delete<string>(`${environment.apiUrl}${endpoint}`, {body: {bookingId}});
  }

  private pickRandomFive(activityIds: string[]): string[]{
    if(activityIds.length <= NUMBER_OF_RECOMMENDED_ACTIVITIES){
      return activityIds;
    }
    const set = new Set<string>();
    while(set.size < NUMBER_OF_RECOMMENDED_ACTIVITIES){
      const randomIndex = Math.floor(Math.random() * activityIds.length);
      set.add(activityIds[randomIndex]);
    }
    return Array.from(set);
  }
}

import { EntryIdentifierTypes } from '@app/core/constants';
import { createReducer, on } from '@ngrx/store';
import {
  createdOfflineIdentifierSuccess,
  createdShortIdentifierFail,
  deletedOfflineIdentifierSuccess,
  deletedShortIdentifierFail,
  deletedShortIdentifierSuccess,
  handleShortIdentifier,
  loadIdentifiersFail,
  loadIdentifiersSuccess,
  refreshEntryIdentifiers,
  resetEntryTimerTarget
} from '../actions/entry.actions';
import { logOut } from '../actions/authentication.actions';

const initialEntryState = {
  shortIdentifier: undefined,
    offlineIdentifier: undefined,
    primaryIdentifier: undefined,
    isRefreshingShortIdentifier: false,
    targetTime: undefined
};

const entryReducerCreator = createReducer(
  initialEntryState,
  on(loadIdentifiersSuccess, (state, data)=> ({
    ...state,
    shortIdentifier: data.identifiers.find(identifier => identifier.type === EntryIdentifierTypes.short),
    offlineIdentifier: data.identifiers.find(identifier => identifier.type === EntryIdentifierTypes.offline),
    primaryIdentifier: data.identifiers.find(identifier => identifier.type === EntryIdentifierTypes.primary),
    isRefreshingShortIdentifier: data.isLoadingIdentifiers ? state.isRefreshingShortIdentifier : false
  })),
  on(loadIdentifiersFail, (state, data) => ({
    ...state,
    isRefreshingShortIdentifier: data.isLoadingIdentifiers ? state.isRefreshingShortIdentifier : false
  })),
  on(deletedShortIdentifierSuccess, (state) => ({
    ...state,
    shortIdentifier: undefined
  })),
  on(deletedOfflineIdentifierSuccess, (state) => ({
    ...state,
    offlineIdentifier: undefined
  })),
  on(createdOfflineIdentifierSuccess, (state, data) => ({
    ...state,
    offlineIdentifier: data.identifier
  })),
  on(refreshEntryIdentifiers, (state) => ({
    //This is used to trigger the change detection to check if an identifier is still valid
    ...state,
    shortIdentifier: state.shortIdentifier ? {...state.shortIdentifier} : undefined,
    offlineIdentifier: state.offlineIdentifier ? {...state.offlineIdentifier} : undefined
  })),


  on(handleShortIdentifier, (state) => ({
    ...state,
    isRefreshingShortIdentifier: true
  })),
  on(deletedShortIdentifierFail, createdShortIdentifierFail, (state) => ({
    ...state,
    isRefreshingShortIdentifier: false
  })),
  on(resetEntryTimerTarget, (state, data) => ({
    ...state,
    targetTime: data.target
  })),
  on(logOut, (state) => ({
    ...initialEntryState
  }))
);

export function entryReducer(state, data) {
  return entryReducerCreator(state, data);
}

import { IdentifiersService } from '@app/core/services/identifiers.service';
import { createSelector } from '@ngrx/store';
import { IAppState, IEntryState } from '../state/app-config.state';

const _entryState = (state: IAppState) => state.entry;

export const selectSelectedIdentifier = createSelector(
  _entryState,
  (entryState: IEntryState) => {
  if(entryState.shortIdentifier &&
    !IdentifiersService.isIdentifierExpired(entryState.shortIdentifier)){
    return entryState.shortIdentifier;
  }
  if(entryState.offlineIdentifier && !IdentifiersService.isIdentifierExpired(entryState.offlineIdentifier)){
    return entryState.offlineIdentifier;
  }
  return entryState.primaryIdentifier;
});

export const selectShortIdentifier = createSelector(_entryState, (state: IEntryState) => state.shortIdentifier);

export const selectOfflineIdentifier = createSelector(_entryState, (state: IEntryState) => state.offlineIdentifier);

export const selectPrimaryIdentifier = createSelector(_entryState, (state: IEntryState) => state.primaryIdentifier);

export const selectIsRefreshingShortIdentifier = createSelector(
  _entryState,
  (entryState: IEntryState) => entryState.isRefreshingShortIdentifier
);
export const selectTargetTime = createSelector(_entryState, (state: IEntryState) => state.targetTime);

export const isAnIdentifierValidForEntry = createSelector(
  _entryState,
  (entryState: IEntryState) => {
    if(entryState.shortIdentifier && !IdentifiersService.isIdentifierExpired(entryState.shortIdentifier)){
      return true;
    }
    if(entryState.offlineIdentifier && !IdentifiersService.isIdentifierExpired(entryState.offlineIdentifier)){
      return true;
    };
    if(entryState.primaryIdentifier && IdentifiersService.isPrimaryIdentifierValidToUse(entryState.offlineIdentifier)){
      return true;
    };
    return false;
  }
);

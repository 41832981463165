import { NavigationState } from './../state/app-config.state';
import { IAppState } from '../state/app-config.state';
import { createSelector } from '@ngrx/store';


const navigationState = (state: IAppState) => state.navigation;

export const selectCurrentRouteUrl = createSelector(
    navigationState,
    (state: NavigationState) => state.currentPage
  );
export const selectPreviousRouteUrl = createSelector(
  navigationState,
  (state: NavigationState) =>
    state.navigationHistory[state.navigationHistory.length - 1]
);

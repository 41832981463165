import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationNavigationService {
  constructor() { }

  // these action will be implemented in the future stories, for now just an alert with where it will take you
  redirectToActivitySlot(parameters) {
    alert("redirect to: ACTIVITY_SLOT -> with parameters: " + JSON.stringify(parameters));
  }

  removeClassAlert(parameters) {
    alert("redirect to: REMOVE_CLASS_ALERT -> with parameters: " + JSON.stringify(parameters));
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { selectComputedColorTheme } from '@app/store/selectors/app-config.selectors';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Component({
  selector: 'gs-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent implements OnInit {

  @Input() searchText = '';
  noSearchResultsImageUrl$ = this.store.select(selectComputedColorTheme).pipe(
    map(colorTheme => `assets/icon/${colorTheme}/no-search-results.svg`)
  );
  constructor(private store: Store) { }

  ngOnInit() {}
}

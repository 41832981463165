import { DATE_REBOOK_FORMAT, TIME_24H_FORMAT } from '@core/constants';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FavouriteBooking } from '@app/core/models/favourite-booking.model';
import { PreviousBooking } from '@app/core/models/previous-booking.model';

@Component({
  selector: 'gs-latest-booking-card',
  templateUrl: './latest-booking-card.component.html',
  styleUrls: ['./latest-booking-card.component.scss'],
})
export class LatestBookingCardComponent implements OnInit {
  @Input() booking: PreviousBooking;
  @Input() isFavourite = false;
  @Output() favouriteStatusChanged = new EventEmitter<{favouriteBooking: FavouriteBooking; isFavourite: boolean}>();
  dateFormat = DATE_REBOOK_FORMAT;
  timeFormat = TIME_24H_FORMAT;
  constructor() { }

  ngOnInit() {}

  toDate(dateString: string): Date {
    if (!dateString) {
      return undefined;
    }
    return new Date(dateString);
  }

  onHeartClicked(event): void {
    const favouriteBooking = {
      activityId: this.booking.activityId,
      activityName: this.booking.activityName,
      siteId: this.booking.siteId,
      siteName: this.booking.siteName,
      lastBooked: this.booking.startTime
    } as FavouriteBooking;
    event.stopPropagation();
    this.favouriteStatusChanged.emit({
      favouriteBooking,
      isFavourite: this.isFavourite,
    });
  }
}

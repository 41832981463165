import { Component, Input, OnInit } from '@angular/core';
import { DateFnsFormat } from '@app/shared/pipes/date-fns-format.pipe';

@Component({
  selector: 'gs-last-booked',
  templateUrl: './last-booked.component.html',
  styleUrls: ['./last-booked.component.scss'],
})
export class LastBookedComponent implements OnInit {
  @Input() lastBooked: Date = null;
  lastBookedDateFormat = 'dd/MM/yyyy';
  constructor() { }

  ngOnInit() {}

}

import { PriceInformation, PriceLevel, PricingQuote } from './pricing.model';


export interface Availability {
  inCentre: number;
  virtual: number;
}

export interface SlotReference {
  inCentre: string;
  virtual: string;
}

export interface GroupActivityDetails {
  isGroupActivity: boolean;
}

export interface AdditionalBookees {
  priceLevels: PriceLevel;
  count: number;
}

export interface Slot {
  startTime: string;
  endTime: string;
  availability: Availability;
  slotReferences: SlotReference;
  activityId: string;
  siteId: string;
  activityName: string;
  siteName: string;
  locationId: string;
  locationName: string;
  description: string;
  imageUrl: string;
  alertListEnabled: boolean;
  webComments: string;
  bookableFrom: string;
  bookableUntil: string;
  groupActivityDetails: GroupActivityDetails;
};


export const areSlotReferencesEqual = (ref1: string, ref2: string): boolean => {
  //BookingId might change on the next API get call, after adding it to the basket and being fully booked
  const refObject1 = JSON.parse(ref1);
  const refObject2 = JSON.parse(ref2);
  refObject1.BookingId = 0;
  refObject2.BookingId = 0;
  return JSON.stringify(refObject1) === JSON.stringify(refObject2);
};


export interface ResourceGroup {
  resourceId: string;
  resourceName: string;
  slotsByDate: {[date: string]: Slot[]};
}

export interface SessionModel {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  inCentre: boolean;
  virtual: boolean;
  siteId: string;
  webBookable: boolean;
  webComments: string;
  resourceGroups: SlotsByResourceIdMap;
}

export interface Price{
  inCentre: number;
}

export interface SlotWithPrice {
  price: PriceInformation;
  isLoading: boolean;
}

export type SlotsByResourceIdMap = { [resourceId: string]: ResourceGroup};
export type SlotsByActivityIdMap = { [activityId: string]: SessionModel};

/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { setApiError, setApiErrorSuccess } from '../actions/notification-handling.actions';
import { createReducer, on } from '@ngrx/store';
import { NotificationHandlingState } from '../state/app-config.state';

const notificationHandlingReducerHandler = createReducer(
  {
    errorOnApiCall: false,
    errorOnConnection: false,
  },
  on(
    setApiError,
    (state): NotificationHandlingState => ({
      ...state,
      errorOnApiCall: true,
    })
  ),
  on(
    setApiErrorSuccess,
    (state): NotificationHandlingState => ({
      ...state,
      errorOnApiCall: false,
    })
  ),
);

export function notificationHandlingReducer(state, action) {
  return notificationHandlingReducerHandler(state, action);
}

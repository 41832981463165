import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DeviceInformation } from '../models/device-information.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceInformationService {

  constructor(private platform: Platform) { }

  public getDeviceInformation(): DeviceInformation {
    return {
      capabilities: 'java, javascript',
      screenResolution: `${this.platform.width()}x${this.platform.height()}x24`,
      timeZone: (new Date().getTimezoneOffset().toString())
    } as DeviceInformation;
  }
}

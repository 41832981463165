/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
import { environment } from '@environments/environment';

// API
export const ANONYMOUS_AUTH_ENDPOINT = '/validate/tenant/anonymous';
export const AUTH_IDENTITY_ENDPOINT = `${environment.apiUrl}/identity`;
export const LOCAL_ASSET_ENDPOINT = 'assets';
export const VALIDATE_TENANT_ENDPOINT = '/validate/tenant/valid';
export const MEMBER_PROFILE_ENDPOINT = `/api/members/{memberId}/profile`;
export const WALLET_API_ENDPOINT = 'api/members/{memberId}/wallet/gladstonepay';
export const SAVE_CARD_API_ENDPOINT = 'api/members/{memberId}/wallet/card';
export const MEMBER_CONTACT_DETAILS_ENDPOINT = '/api/members/{memberId}/contact-details';
export const FINANCIAL_ENDPOINT = `/api/payment/pay`;
export const MEMBER_PERSONALISATION_ENDPOINT = '/api/members/{memberId}/personalisation/{personalisationKey}';
export const SYSTEM_CONFIG_API_ENDPOINT = '/api/configuration/system/{configurationKey}';
export const SITES_API_ENDPOINT = '/api/configuration/sites';
export const ACTIVITIES_API_ENDPOINT = '/api/search/activities';
export const ACTIVITIES_BY_TAGS_API_ENDPOINT = '/api/configuration/activities-by-tags';
export const SESSIONS_API_ENDPOINT = '/api/availability/sessions';
export const FUTURE_BOOKINGS_API_ENDPOINT = '/api/bookings/{memberId}/future-bookings';
export const BOOKING_HISTORY_API_ENDPOINT = '/api/members/{memberId}/past-bookings';
export const SLOT_PRICE_API_ENDPOINT = '/api/pricing/slot';
export const SESSION_PRICE_API_ENDPOINT = '/api/pricing/session';
export const BOOKING_LEASE_API_ENDPOINT = '/api/bookings/lease';
export const BOOKING_API_ENDPOINT = '/api/bookings';
export const MEMBER_IDENTIFIER_API_ENDPOINT = '/api/members/{memberId}/identifier';
export const IDENTIFIER_API_ENDPOINT = '/api/members/identifier';
export const DELETE_BOOKING_ENDPOINT = '/api/members/{memberId}/bookings/{bookingId}';
export const ACTIVITY_DETAILS_ENDPOINT = '/api/configuration/activity/{id}';
export const JOIN_ALERT_LIST_ENDPOINT = '/api/bookings/{memberId}/alert-list';
export const TAG_GROUPS_API_ENDPOINT = '/api/configuration/tag-groups';
export const REMOVE_FROM_ALERT_LIST_ENDPOINT = '/api/bookings/{memberId}/alert-list';
export const WALLET_API = '/wallet/gladstonepay';

export const PUSH_NOTIFICATION_TOKEN = '/api/members/{memberId}/push-notification-token';

export const ERROR_ON_API_MSG_KEY = 'CONTENT.ERROR.MESSAGE_CALL_SUPPORT';
export const ERROR_ON_API_HEADER_KEY = 'CONTENT.ERROR.ERROR_CALL_SUPPORT';
export const TOAST_BASKET_SUCCESS_MESSAGE = 'CONTENT.BASKET.TOAST_SUCCESS_MESSAGE';
export const SUCCESS_TOAST_CLASS = 'success-toast';
export const WARNING_TOAST_CLASS = 'warning-toast';
export const SEE_MORE = 'CONTENT.ERROR.SEE_MORE';
export const DISMISS = 'CONTENT.ERROR.DISMISS';
export const SET_LANGUAGE_SUCCESS_MESSAGE = 'CONTENT.CONFIG.CONFIRMATION';
export const JOINED_ALERT_LIST_SUCCESS_MESSAGE = 'CONTENT.ALERT_LIST.JOINED_ALERT_LIST_SUCCESS_MESSAGE';
export const UNSUBSCRIBE_FROM_ALERT_LIST_SUCCSES_MESSAGE =
  'CONTENT.ALERT_LIST.UNSUBSCRIBE_FROM_ALERT_LIST_SUCCESS_MESSAGE';
export const ADDED_BOOKEES_SUCCESS_MESSAGE = 'CONTENT.BOOK.ACTIVITY_DETAILS.ADDED_BOOKEES';
export const TOASTER_DURATION = 4000;
export const DROP_DOWN_ANIMATION_DURATION = 300;
export const FADE_IN_OUT_DURATION = 200;
export const SHORT_IDENTIFIER_REFRESH_RATE = 120000;

// Icons
export const DOWN_ARROW = 'chevron-down-outline';
export const UP_ARROW = 'chevron-up-outline';
export const DEFAULT_AVATAR_PATH = 'assets/icon/light/default-avatar.svg';

//Must be also changed from skeleton-component.css
export const SKELETON_ITEMS_MARGIN_PX = 12;
export const HIDDEN_TOOLBAR_HEIGHT = 44;
export const BOOK_HISTORY_CARD_WIDTH = 212;
export const BOOK_HISTORY_CARD_HEIGHT = 100;
export const DATE_DOT_WIDTH = 58;
export const DATES_CONTAINER_PADDING = 122;
export const RECOMMENDED_ACTIVITY_CARD_WIDTH = 220;
export const RECOMMENDED_ACTIVITY_CARD_HEIGHT = 162;
export const NUMBER_OF_RECOMMENDED_ACTIVITIES = 5;
export const NR_OF_ADDITIONAL_SLOTS_PER_DAY = 5;

export const SESSION_CARD_HEIGHT = 90;
export const DATE_SESSION_HEIGHT = 24;
export const DATE_HEADER_FORMAT = 'EEEE, do';
export const DATE_REMOVE_ALERT_LIST_TOAST_FORMAT = 'EEEE do';
export const DATE_BASKET_FORMAT = 'EEE do, LLLL';
export const DATE_REBOOK_FORMAT = "eeee, do 'of' MMMM";
export const TIME_24H_FORMAT = 'HH:mm';

export const VAT_PERCENTAGE_OF_TOTAL = 1 / 6;

export const PROMOTION_TAG_GROUP_KEY = 'ACTIVITY_PROMOTION';
export const PROMOTED_TAG_KEY = 'PROMOTED_ACTIVITY';
export const ACTIVITIES_TAG_GROUP_KEY = 'ACTIVITY_TYPE';

export const LANGUAGE_PERSONALISATION_KEY = 'language';
export const LANGUAGE_PERSONALISATION_API = `/personalisation/${LANGUAGE_PERSONALISATION_KEY}`;

export enum ColorThemes {
  system = 'system',
  light = 'light',
  dark = 'dark',
}

export enum FavouritePageTabs {
  bookings = 'bookings',
  centres = 'centres',
}

export enum HeaderTabs {
  book = 'book',
  discover = 'discover',
  myBookings = 'myBookings',
  myProgress = 'myProgress',
}
export enum MyActivityBookingsTabs {
  bookings = 'bookings',
  alertList = 'alert-list',
}
export enum TimePickerComponentState {
  startTimeSelected = 'StartTimeSelected',
  endTimeSelected = 'EndTimeSelected',
  noTimeSelected = 'NoTimeSelected',
}

export enum FormFieldState {
  initialState = 'initial',
  editingState = 'editing-mode',
  completedState = 'completed',
  errorState = 'error',
  successState = 'success',
}

export enum EntryIdentifierTypes {
  short = 'MOBILEID',
  offline = 'MOBILEOFFLINEID',
  primary = 'CARD',
}

export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
};

export enum FormMessageType {
  information = 'information',
  error = 'error',
}

export enum InputType {
  email = 'email',
  text = 'text',
  numeric = 'numeric',
  date = 'date',
}

export const updateDetailsFormValidationErrorKey = 'CONTENT.ACCOUNT.ACCOUNT_DETAILS.VALIDATION_ERROR';
export const updateDetailsEmailInformationMessageKey = 'CONTENT.ACCOUNT.ACCOUNT_DETAILS.INFORMATION_EMAIL';

export const TOAST_ONLINE_ICON_NAME = 'checkmark-circle';
export const TOAST_ALERT_CIRCLE_ICON_NAME = 'alert-circle';
export const TOAST_ONLINE_MESSAGE = 'CONTENT.ERROR.ONLINE_TOAST_MESSAGE';
export const TOAST_OFFLINE_MESSAGE = 'CONTENT.ERROR.OFFLINE_TOAST_MESSAGE';
export const TOAST_NO_BOOKEE_MESSAGE = 'CONTENT.BOOK.ACTIVITY_DETAILS.NO_BOOKEE_WARNING';
export const TOAST_CONNECTION_CLASS = 'connection-status-toast';
export const ASSETS_PATH_HTTP = '/assets/';
export const TOAST_SUCCESSFULLY_SAVED_CARD = 'CONTENT.ACCOUNT.SAVED_CARDS.ADD_CARD_PAGE.SUCCESS_SAVE_TOAST';

export enum RoutingAbsolutePaths {
  emptyUrl = '',
  bookHomePage = '/book',
  bookSearchPage = '/book/search',
  bookSessionsPage = '/book/sessions',
  bookActivityDetailsPage = '/book/sessions/activity-details',
  bookSiteDetailsPage = '/book/sessions/activity-details/site-details-page',
  bookConfirmationPage = '/book/sessions/confirmation-page',
  basketPage = '/basket',
  paymentConfirmationPage = '/basket/payment/payment-confirmation-page',
  basketPaymentPage = '/basket/payment',
  basketAddCardPage = '/basket/add-card',
  favouritesPage = '/favourites',
  myAccountPage = '/my-account',
  myAccountDetailsPage = '/my-account/account-details',
  myAccountMyActivityPage = '/my-account/my-activity',
  myAccountSavedCardsPage = '/my-account/saved-cards',
  myAccountAddNewCardPage = '/my-account/saved-cards/add-card-from-my-account',
  myAccountBookingsListPage = '/my-account/my-activity/bookings-list',
  myAccountSettingsPage = '/my-account/account-settings',
  entryPage = '/entry',
}

export const BACK_BUTTON_HANDLING_PRIORITY = 10;

export enum OriginForPaymentPage {
  basket = 'basket',
  myAccout = 'my-account',
}

export enum OpenedNotificationActionType {
  NotificationClicked = 0,
  ButtonClicked = 1
}

export enum NotificationButtonActions {
  Action1ID = "Action1",
  Action1RedirectTo = "action1RedirectTo",
  Action2ID = "Action2",
  Action2RedirectTo = "action2RedirectTo"
}

export enum NotificationActions {
  RedirectToActivitySlot = "Activity slot",
  RemoveClassAlert = "Remove class alert"
}

import { selectSelectedDateDots } from '@store/selectors/book.selectors';
import { Component, EventEmitter, OnInit, Output, ViewChildren } from '@angular/core';
import { TimeUtilitiesHelper } from '@app/core/helpers/time-utilities-helper';
import { selectBookDateBoundaries } from '@app/store/selectors/book.selectors';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { addDays, isSameDay, max } from 'date-fns';
import { first, map, Observable, combineLatest } from 'rxjs';
import { DatePickerModalComponent } from '../modals/date-picker-modal/date-picker-modal.component';
import { nrOfFittingDots } from '@core/helpers/number-of-fitting-objects-helper';
import { DateButtonComponent } from './date-button/date-button.component';

@Component({
  selector: 'gs-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {
  @ViewChildren('dateButton') dateButtons: DateButtonComponent[];
  @Output() selectedDateButtonsChange = new EventEmitter<Date[]>();
  @Output() selectedDateRangeChange = new EventEmitter<void>();
  dates$: Observable<{date: Date; selected: boolean}[]>;
  selectedDateDots$: Observable<Date[]> = this.store.select(selectSelectedDateDots);

  constructor(private store: Store, private modalController: ModalController) { }
  ngOnInit() {
    const datePickerInterval$ = this.store.select(selectBookDateBoundaries).pipe(
      map(({startDate, endDate}) => {
        const finalEndDate = max([new Date(endDate), addDays(new Date(startDate), nrOfFittingDots())]).toISOString();
        return TimeUtilitiesHelper.eachDayInterval(startDate, finalEndDate);
      })
    );
    this.dates$ = combineLatest([datePickerInterval$, this.selectedDateDots$]).pipe(
      map(([allDates, selectedDates]) => allDates.map((date) =>
          selectedDates.some((selectedDate) =>
            isSameDay(date, selectedDate)
          )
          ? { date, selected: true }
          : { date, selected: false }
        ))
    );
  }


  onDateButtonsChange() {
    const selectedDates = this.dateButtons.filter(button => button.selected === true).map(button => button.date);
    this.selectedDateButtonsChange.emit(selectedDates);
  }

  openDatePickerModal() {
    this.store.select(selectBookDateBoundaries).pipe(first()).subscribe(async (dateBoundaries) => {
      const modal = await this.modalController.create({
        component: DatePickerModalComponent,
        initialBreakpoint: 1,
        breakpoints: [0, 1],
        backdropDismiss: true,
        cssClass: 'date-picker-modal'
      });

      modal.present();
      const {data, role} = await modal.onWillDismiss();

      if (role === 'confirm') {
        this.selectedDateRangeChange.emit();
      }
    });
  }

}

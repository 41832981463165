import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightMatch'
})
export class HighlightMatchPipe implements PipeTransform {
  transform(value: string, args: any): string {
    if (!value || !args) {
      return value;
    }
    const re = new RegExp(args, 'gi');
    return value.replace(re, '<span class="highlight">$&</span>');
  }
}

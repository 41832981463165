import { createReducer, on } from '@ngrx/store';
import { addFavouriteBooking, removeFavouriteBooking, removeSelectedBookings, selectAllBookings, selectFavouriteBooking,
  unselectAllBookings,
  unselectFavouriteBooking } from '../actions/favourites.actions';

const initialFavouriteState = {
  favouriteBookings: [],
};

const favouritesReducerCreator = createReducer(
  initialFavouriteState,
  on(addFavouriteBooking, (state, data)=>({
    ...state,
    favouriteBookings: [...state.favouriteBookings,{ ...data.favouriteBooking, isChecked: false }]
  })),
  on(removeFavouriteBooking, (state, data)=>({
    ...state,
    favouriteBookings: state.favouriteBookings.filter(favouriteBooking =>
      !(favouriteBooking.siteId === data.siteId && favouriteBooking.activityId === data.activityId ))
  })),
  on(removeSelectedBookings, (state)=>({
    ...state,
    favouriteBookings: state.favouriteBookings.filter(favouriteBooking => !favouriteBooking.isChecked),
  })),
  on(selectFavouriteBooking, (state, data)=>({
    ...state,
    favouriteBookings: state.favouriteBookings.map((favouriteBooking) => {
      if (data.siteId === favouriteBooking.siteId && data.activityId === favouriteBooking.activityId) {
        return { ...favouriteBooking, isChecked: true };
      } else {
        return { ...favouriteBooking, isChecked: favouriteBooking.isChecked || false };
      }
    })
  })),
  on(unselectFavouriteBooking, (state, data)=>({
    ...state,
    favouriteBookings: state.favouriteBookings.map((favouriteBooking) => {
      if (data.siteId === favouriteBooking.siteId && data.activityId === favouriteBooking.activityId) {
        return { ...favouriteBooking, isChecked: false };
      } else {
        return { ...favouriteBooking, isChecked: favouriteBooking.isChecked || false };
      }
    })
  })),
  on(selectAllBookings, (state)=>({
    ...state,
    favouriteBookings: state.favouriteBookings.map((favouriteBooking) => ({
      ...favouriteBooking,
      isChecked: true
    }))  })),
  on(unselectAllBookings, (state)=>({
    ...state,
    favouriteBookings: state.favouriteBookings.map((favouriteBooking) => ({
      ...favouriteBooking,
      isChecked: false
    }))  })),
);

export function favouritesReducer(state, action) {
  return favouritesReducerCreator(state, action);
}

import { CountryCode, PhoneNumber } from '@app/core/models/contact-details.model';
import { ContactDetails } from '@app/core/models/contact-details.model';
import { Member, PersonalDetails } from '@app/core/models/member.model';
import { Action, createAction, props } from '@ngrx/store';

export enum AccountActionTypes {
  FetchProfileDetails = '[Account] Fetch Profile Details',
  FetchProfileDetailsSuccess = '[Account] Fetch Profile Details Success',
  FetchProfileDetailsError = '[Account] Fetch Profile Details Error',
  FetchContactDetails = '[Account] Fetch contact details',
  FetchContactDetailsSuccess = '[Account] Fetch contact details successfully',
  FetchContactDetailsError = '[Account] Fetch contact details error',
  BlurFirstname = '[Account] Firstname lost focus',
  BlurSurname = '[Account] Surname lost focus',
  BlurEmail = '[Account] Email lost focus',
  BlurPhoneNumber = '[Account] PhoneNumber lost focus',
  BlurDateOfBirth = '[Account] DateOfBirth lost focus',
  ChangeFirstname = '[Account] Change firstname',
  ChangeSurname = '[Account] Change surname',
  ChangeEmail = '[Account] Change email',
  ChangePhoneNumber = '[Account] Change phone number',
  ChangeDateOfBirth = '[Account] Change date of birth',
  SetCountryDialCode = '[Account] Set the dial code (country code) of user phone number',
  UpdatePersonalDetails = '[Account] Update personal details',
  UpdatedProfileDetailsSuccess = '[Account] Updated profile details successfully',
  UpdateProfileDetailsError = '[Account] Update profile details failed',
  UpdatedContactDetailsSuccess = '[Account] Updated contact details successfully',
  UpdateContactDetailsError = '[Account] Update contact details failed',
  ResetAccountDetailsFormState = '[Account] Reset the state of the account details form',
  ResetAccountDetailsFormWasTouched = '[Account] Reset account details form was touched',
  SetProfileDetails = '[Account] Set Profile Details',
}
const SET_SELECTED_DATE_FOR_BOOKINGS = '[Account] Set selected month for bookings';

export const fetchProfileDetails = createAction(AccountActionTypes.FetchProfileDetails, props<{ memberId: number }>());
export const fetchProfileDetailsSuccess = createAction(
  AccountActionTypes.FetchProfileDetailsSuccess,
  props<{ profileDetails: Partial<Member> }>()
);
export const fetchProfileDetailsError = createAction(AccountActionTypes.FetchProfileDetailsError);
export const setProfileDetails = createAction(
  AccountActionTypes.SetProfileDetails,
  props<{ personalDetails: Partial<Member> }>(),
);

export const fetchContactDetails = createAction(
  AccountActionTypes.FetchContactDetails,
  props<{memberId: number}>()
);
export const fetchContactDetailsSuccess = createAction(
  AccountActionTypes.FetchContactDetailsSuccess,
  props<{payload: ContactDetails}>()
);
export const fetchContactDetailsError = createAction(AccountActionTypes.FetchContactDetailsError);


export const blurFirstname = createAction(AccountActionTypes.BlurFirstname);

export const blurSurname = createAction(AccountActionTypes.BlurSurname);

export const blurEmail = createAction(AccountActionTypes.BlurEmail);

export const blurDateOfBirth = createAction(AccountActionTypes.BlurDateOfBirth);

export const blurPhoneNumber = createAction(AccountActionTypes.BlurPhoneNumber);

export const changeFirstname = createAction(
  AccountActionTypes.ChangeFirstname,
  props<{fieldValue: string}>()
);

export const changeSurname = createAction(
  AccountActionTypes.ChangeSurname,
  props<{fieldValue: string}>()
);

export const changeEmail = createAction(
  AccountActionTypes.ChangeEmail,
  props<{fieldValue: string}>()
);

export const changePhoneNumber = createAction(
  AccountActionTypes.ChangePhoneNumber,
  props<{fieldValue: string}>()
);

export const changeDateOfBirth = createAction(
  AccountActionTypes.ChangeDateOfBirth,
  props<{fieldValue: string}>()
);

export const setCountryDialCode = createAction (
  AccountActionTypes.SetCountryDialCode,
  props<{dialCode: CountryCode}>()
);

export const updatePersonalDetails = createAction (
  AccountActionTypes.UpdatePersonalDetails,
  props<{personalDetailsUpdated: PersonalDetails; primaryPhoneNumber: PhoneNumber; memberId: number}>()
);

export const updatedProfileDetailsSuccess = createAction (
  AccountActionTypes.UpdatedProfileDetailsSuccess,
  props<{personalDetailsUpdated: PersonalDetails; primaryPhoneNumber: PhoneNumber; memberId: number}>()
);

export const updateProfileDetailsError = createAction (
  AccountActionTypes.UpdateProfileDetailsError
);

export const updatedContactDetailsSuccess = createAction (
  AccountActionTypes.UpdatedContactDetailsSuccess
  );

export const updateContactDetailsError = createAction (
  AccountActionTypes.UpdateContactDetailsError
);

export const resetAccountDetailsFormState = createAction (
  AccountActionTypes.ResetAccountDetailsFormState
);

export const resetFormWasTouched = createAction (
  AccountActionTypes.ResetAccountDetailsFormWasTouched
);

export const setSelectedDateForBookings = createAction(
  SET_SELECTED_DATE_FOR_BOOKINGS,
  props<{dateIso: string}>()
);

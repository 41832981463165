import { BasketItem } from '@app/core/models/basket-item.model';
import { VAT_PERCENTAGE_OF_TOTAL } from '@app/core/constants';
import { sum } from 'radash';

export class PricingHelper{
  public static computeTotalPrice(basketItems: BasketItem[]): number{
    let totalPrice = 0;
    basketItems.forEach((basketItem) => {
      totalPrice += basketItem.price.totalPrice.amount;
    });
    return totalPrice;
  }

  public static computeVAT(totalPrice: number): number{
    // TODO: need to discuss how to manage percentage for VAT
    return totalPrice * VAT_PERCENTAGE_OF_TOTAL;
  }
}
